import React, { useState } from 'react'
import { Col, Row, Accordion } from 'react-bootstrap'
import { useSelector } from 'react-redux';

function Faq({handleShow,showSidebar}) {
    const theme = useSelector((state) => state?.User?.theme);
     const [apiTypes,setApiTypes] = useState([
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Wallet-enabled API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },


        {
            type : 'Subscribe and trade open API '
        },
    ])
  return (
    <>
      <div className="notices_holder p-2 p-sm-3 pb-md-5">
      <div className="sidebar_canva_shower d-xl-none mb-3">
              <button
                className="sidebar_canva_burg rounded-5 pt-1 px-2"
                onClick={handleShow}
              >
                <i class="fa-solid fa-bars" />
              </button>
            </div>
    
<Row className=''>
    <Col lg={4} md={12} className='mb-4 mb-lg-0'>
        <div className='api_types_holder rounded-4 p-3'>
            <h4 className='api_types_title inter_font'>Contact us</h4>
            <div className='api_types_scroller mt-3'>
            <Accordion defaultActiveKey="0" flush className='pe-2'>
        {apiTypes?.map((e,index) =>
      <Accordion.Item className='mb-2' eventKey={index}>
        <Accordion.Header >{e.type}</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet
        </Accordion.Body>
        <Accordion.Body>
          Lorem ipsum dolor sit amet
        </Accordion.Body>
        <Accordion.Body>
          Lorem ipsum dolor sit amet
        </Accordion.Body>
        <Accordion.Body>
          Lorem ipsum dolor sit amet
        </Accordion.Body>
        <Accordion.Body>
          Lorem ipsum dolor sit amet
        </Accordion.Body>
      </Accordion.Item>
       )}
    </Accordion>
            </div>
        </div>

       
    </Col>
    <Col lg={8} md={12}>
        <div className='api_content_holder p-3 rounded-4 h-100'>
    <div className='api_content_scroller pe-2'>
        <p className='api_content_title'>Introduction</p>
        <p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>

<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>

<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
    </div>
    </div>
    </Col>
</Row>
</div>
    </>
  )
}

export default Faq