const BASE_URL = "p2p";

export const CreateOfferApi = BASE_URL + "/create-offer";

export const GetPaymentTypesApi = BASE_URL + "/get-paymenttypes";

export const GetCurrencyApi = BASE_URL + "/get-currency";

export const GetUserOrderApi = BASE_URL + "/get-user-order";

export const GetOfferListApi = BASE_URL + "/get-offer-list";

export const GetSpecificOfferApi = BASE_URL + "/get-specificorder-detail";