import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Screens/Home";
import "./assets/css/Style.css";
import Market from "./Screens/Market";
import Login from "./Screens/Login";
import StoreLogin from "./Screens/StoreLogin";
import StorePage from "./Screens/StorePage";
import StoreDetails from "./Screens/StoreDetails";
import StoreInfo from "./Screens/StoreInfo";
import Convert from "./Screens/Convert";
import CmnTabs from "./pages/CmnTabs";
import MarketTransaction from "./Screens/MarketTransaction";
import MarketList from "./Screens/MarketList";
import Preferences from "./Screens/Preferences";
import Register from "./Screens/Register";
import Welcome from "./Screens/Welcome";
import CreateAccount from "./Screens/CreateAccount";
import EmailVerification from "./Screens/EmailVerification";
import SetPasscode from "./Screens/SetPasscode";
import SetUpAccount from "./Screens/SetUpAccount";
import StayLogged from "./Screens/StayLogged";
import AssetPage from "./Screens/AssetPage";
import DepositPage from "./Screens/DepositPage";
import WithDrawPage from "./Screens/WithDrawPage";
import SpotPage from "./Screens/SpotPage";
import ForgotPassword from "./Screens/ForgotPassword";
import ForgotPasswordChange from "./Screens/ForgotPasswordChange";
import CreateOffer from "./Screens/CreateOffer";
import CreateOfferList from "./Screens/CreateOfferList";
import UserOfferDetail from "./Screens/UserOfferDetail";
import StoreManagementRegister from "./Screens/StoreManagementRegister";
import StoreRegistration from "./Screens/StoreRegistration";
import ProductRegistration from "./Screens/ProductRegistration";
import MarketKyc from "./Screens/MarketKyc";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/market" element={<Market />} />
        <Route path="/market/:id" element={<Market />} />
        <Route path="/marketList" element={<MarketList />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/createpassword" element={<ForgotPasswordChange />} />
        <Route path="/createAccount" element={<CreateAccount />} />
        <Route path="/mailVerification" element={<EmailVerification />} />
        <Route path="/setPasscode" element={<SetPasscode />} />
        <Route path="/setupAccount" element={<SetUpAccount />} />
        <Route path="/stayLogged" element={<StayLogged />} />
        <Route path="/storeLogin" element={<StoreLogin />} />
        <Route path="/storePage" element={<StorePage />} />
        <Route path="/storeDetail" element={<StoreDetails />} />
        <Route path="/storeInfo" element={<StoreInfo />} />
        <Route path="/cmntabs" element={<CmnTabs />} />
        <Route path="/marketTransaction" element={<MarketTransaction />} />
        <Route path="/marketTransaction/:id" element={<MarketTransaction />} />
        <Route path="/preferences" element={<Preferences />} />
        <Route path="/convert" element={<Convert />} />
        <Route path="/assets" element={<AssetPage />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/withdraw" element={<WithDrawPage />} />
        <Route path="/spot" element={<SpotPage />} />
        <Route path="/createOffer" element={<CreateOffer />} />
        <Route path="/createOfferList" element={<CreateOfferList />} />
        <Route path="/userOfferDetails" element={<UserOfferDetail />} />
        <Route path="/storeManagementRegister" element={<StoreManagementRegister />} />
        <Route path="/storeRegistration" element={<StoreRegistration />} />
        <Route path="/productRegistration" element={<ProductRegistration />} />
        <Route path="/marketKyc" element={<MarketKyc />} />
      </Routes>
    </>
  );
}

export default App;
