import React from "react";
import { NavLink } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";

function Sidebar({ showSidebar, handleCloseSidebar, activeCont, setActiveCont }) {
  return (
    <>
      <div className="cmn_sidebar d-flex flex-column ">
        <div className="cmn_sidebar_head d-flex justify-content-start align-items-center gap-2 py-3 ps-5 ps-xl-3">
          <img
            src={require("../assets/images/blurperson.svg").default}
            className="img-fluid cmn_sidebar_profileImg"
          />
          <p>JohnDoe@gmail.com</p>
        </div>
        <div className="cmn_sidebar_body flex-grow-1">
          <ul>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'notice' ? 'active' : ''}`} onClick={() => {setActiveCont('notice')}}>
              <>Notice</>
            </li>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'profile' ? 'active' : ''}`} onClick={() => setActiveCont('profile')}>
              <>Profile</>
            </li>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'payment' ? 'active' : ''}`} onClick={() => setActiveCont('payment')}>
              <>Payment Methods</>
            </li>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'security' ? 'active' : ''}`} onClick={() => setActiveCont('security')}>
              <>Security</>
            </li>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'inquiry' ? 'active' : ''}`} onClick={() => setActiveCont('inquiry')}>
              <>1:1 Inquiry</>
            </li>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'history' ? 'active' : ''}`} onClick={() => setActiveCont('history')}>
              <>History</>
            </li>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'api' ? 'active' : ''}`} onClick={() => setActiveCont('api')}>
              <>Enterprise-API</>
            </li>
            <li className={`ps-5 ps-xl-3 inter_font mb-2 ${activeCont == 'recommend' ? 'active' : ''}`} onClick={() => {setActiveCont('recommend')}}>
              <>Invite a Friend</>
            </li>
          </ul>
          <div className="cmn_sidebar_footer px-3 py-5">
            <p className="inter_font text-center">
              Register and Get 3,000 T2T2
            </p>
            <h6 className="inter_font text-center mt-2">
              Earn 30,000 T2T2 Per Referral
            </h6>
          </div>
        </div>
      </div>

      {/* sidebar canva */}
      <Offcanvas
        show={showSidebar}
        onHide={handleCloseSidebar}
        backdrop="static"
      >
        <Offcanvas.Body className="p-0">
          <div className="cmn_sidebar cmn_sidebar_offcanva d-flex flex-column justify-content-between h-100">
            <div className="cmn_sidebar_head d-flex justify-content-between align-items-center py-3 px-3 px-md-4 px-lg-5">
              <div className="d-flex justify-content-start align-items-center gap-2 ">
                <img
                  src={require("../assets/images/blurperson.svg").default}
                  className="img-fluid cmn_sidebar_profileImg"
                />
                <p>JohnDoe@gmail.com</p>
              </div>
              <button
                className="sidebar_canva_closer"
                onClick={() => handleCloseSidebar()}
              >
                <i class="fa-solid fa-xmark" />
              </button>
            </div>
            <div className="cmn_sidebar_body flex-grow-1 d-flex align-items-center">
            <ul>
            <li className={`ps-5 ps-xl-3 py-2 inter_font mb-2 ${activeCont == 'notice' ? 'active' : ''}`} onClick={() => {setActiveCont('notice');handleCloseSidebar()}}>
              <>Notice</>
            </li>
            <li className={`ps-5 ps-xl-3 py-2  inter_font mb-2 ${activeCont == 'profile' ? 'active' : ''}`} onClick={() => {setActiveCont('profile');handleCloseSidebar()}}>
              <>Profile</>
            </li>
            <li className={`ps-5 ps-xl-3 py-2  inter_font mb-2 ${activeCont == 'payment' ? 'active' : ''}`} onClick={() => {setActiveCont('payment');handleCloseSidebar()}}>
              <>Payment Methods</>
            </li>
            <li className={`ps-5 ps-xl-3 py-2  inter_font mb-2 ${activeCont == 'security' ? 'active' : ''}`} onClick={() => {setActiveCont('security');handleCloseSidebar()}}>
              <>Security</>
            </li>
            <li className={`ps-5 ps-xl-3 py-2  inter_font mb-2 ${activeCont == 'inquiry' ? 'active' : ''}`} onClick={() => {setActiveCont('inquiry');handleCloseSidebar()}}>
              <>1:1 Inquiry</>
            </li>
            <li className={`ps-5 ps-xl-3 py-2  inter_font mb-2 ${activeCont == 'history' ? 'active' : ''}`} onClick={() => {setActiveCont('history');handleCloseSidebar()}}>
              <>History</>
            </li>
            <li className={`ps-5 ps-xl-3 py-2  inter_font mb-2 ${activeCont == 'api' ? 'active' : ''}`} onClick={() => {setActiveCont('api');handleCloseSidebar()}}>
              <>Enterprise-API</>
            </li>
            <li className={`ps-5 ps-xl-3 py-2  inter_font ${activeCont == 'recommend' ? 'active' : ''}`} onClick={() => {setActiveCont('recommend');handleCloseSidebar()}}>
              <>Invite a Friend</>
            </li>
          </ul>
            </div>
            <div className="cmn_sidebar_footer px-3 py-5">
              <p className="inter_font text-center">
                Register and Get 3,000 T2T2
              </p>
              <h6 className="inter_font text-center mt-2">
                Earn 30,000 T2T2 Per Referral
              </h6>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* end of sidebar canva */}
    </>
  );
}

export default Sidebar;
