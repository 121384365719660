import React, { useState } from "react";
import Header from "../Components/Header";
import Assets from "../Components/Assets";
import Footer from "../Components/Footer";
import Deposit from "../Components/Deposit";
import Withdraw from "../Components/Withdraw";
import Spot from "../Components/Spot";

import bg_shadow from "../assets/images/cmn_tabs/bg_shadow.png";
import { useSelector } from "react-redux";
function CmnTabs() {
  const theme = useSelector((state) => state?.User?.theme);
  const [activeTab, SetActiveTab] = useState("asset");

  const handleClick = (val) => {
    SetActiveTab(val);
  };
  return (
    <div className="cmn_tabs">
      <Header />
      <div className="container cnt_sec mt-4 mb-4">
        {/* <img src={bg_shadow} className="cmn_bg_shadow img-fluid" alt="bg shadow" /> */}

        <div className="custom_tabs d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between  align-items-stretch">
          <p className="mb-0 heading inter_font">
            {activeTab == "asset"
              ? "Assets"
              : activeTab == "spot"
              ? "Spot"
              : activeTab == "deposit"
              ? "Deposit"
              : activeTab == "withdraw"
              ? "Withdraw"
              : "Transfer"}
            {/* Assets */}
          </p>
          <div className="tabs_head_cnt d-flex justify-content-between justify-content-md-end gap-sm-2 gap-md-3 align-items-center">
            <span
              className={
                activeTab == "asset" ? "tabs_heading_active" : "tabs_heading"
              }
              onClick={() => handleClick("asset")}
            >
              Assets
            </span>
            <span
              className={
                activeTab == "spot" ? "tabs_heading_active" : "tabs_heading"
              }
              onClick={() => handleClick("spot")}
            >
              Spot
            </span>
            <span
              className={
                activeTab == "deposit" ? "tabs_heading_active" : "tabs_heading"
              }
              onClick={() => handleClick("deposit")}
            >
              Deposit
            </span>
            <span
              className={
                activeTab == "withdraw" ? "tabs_heading_active" : "tabs_heading"
              }
              onClick={() => handleClick("withdraw")}
            >
              Withdraw
            </span>
            <span
              className={
                activeTab == "tranfer" ? "tabs_heading_active" : "tabs_heading"
              }
              onClick={() => handleClick("tranfer")}
            >
              Tranfer
            </span>
          </div>
        </div>

        {activeTab == "asset" ? (
          <>
            <Assets />
          </>
        ) : activeTab == "spot" ? (
          <>
            <Spot />
          </>
        ) : activeTab == "deposit" ? (
          <>
            <Deposit />
          </>
        ) : activeTab == "withdraw" ? (
          <>
            <Withdraw />
          </>
        ) : (
          <></>
        )}
      </div>
      <Footer />
      <img
        src={bg_shadow}
        className={theme == 'dark' ? "cmn_bg_shadow img-fluid" : 'd-none'}
        alt="bg shadow"
      />
    </div>
  );
}

export default CmnTabs;
