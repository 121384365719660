import React, { useEffect, useState } from "react";
import browser from 'browser-detect';
import { Col, Container, Form, Row, Toast } from "react-bootstrap";
import Header from "../Components/Header";
import { NavLink, useNavigate, useNavigation } from "react-router-dom";
import Footer from "../Components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { ValidateLogin } from "../Validation/UserValidation";
import { getGeoInfoData, LoginHooks } from "../Hooks/UserHooks";
import { toast } from "react-hot-toast";
import PhoneInput from 'react-phone-input-2';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state?.User?.theme); //state?.LoginReducer?.theme
  const [loginSource, setLoginSource] = useState("email");
  const navigation = useNavigate();


  const [email , setEmail] = useState("");
  const [phoneno , setPhoneno] = useState("");
  const [phonecode , setPhonecode] = useState("");
  const [password , setPassword] = useState("");
  const [termsandcondition , setTermsandcondition] = useState(false);
  const [error , setError] = useState({});
  const [loginhistory , setLoginHistory] = useState({});


  const getGeoInfo = async () => {
    try {
        let { result } = await getGeoInfoData();
        const browserResult = browser();
        if(!result.ip){
            getGeoInfo();
        }
        console.log("login history", result, browserResult);
        setLoginHistory({
            countryName: result.country_name,
            countryCode: result.country_calling_code,
            ipaddress: result.ip, //ipString
            region: result.region,
            broswername: browserResult.name,
            ismobile: browserResult.mobile,
            os: browserResult.os,
        })
    }
    catch (err) {
    }
};

useEffect(() => {
  getGeoInfo();
} , [])



  const handleLogin = async() => {
    try{
      let payload = {
        registerType : loginSource,
        email : email,
        phoneNo : phoneno,
        reftype : "",
        twoFACode : "",
        otp : "",
        password : password,
        loginHistory : loginhistory,
        termsandcondition : termsandcondition
      }
      let validate = ValidateLogin(payload);
      console.log("validate" , validate);
      if(validate?.isvalid){
        let login = await LoginHooks(payload , dispatch);
        console.log("login" , login);
        if(login?.type == "Success" && login?.error){
          toast.error(login?.error);
          
        }
        else if(login?.type == "Success" && login?.message){
          toast.success("Login Successfully");
          navigate("/createOffer");
        }
      }
      else{
        setError(validate?.error);
      }
    }
    catch(e){
      console.log("Error on handle login" , e);
    }
  }

  useEffect(() => {
    window.scroll(0,0)
  },[])
  return (
    <>
      <Container fluid className="common_spacer pb-5 position-relative">
        <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
          <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="box_container login_box_container  p-0 p-sm-2">
            <Row className="justify-content-center mb-5 px-1 px-sm-3 px-sm-0">
              <Col lg={8} md={8} sm={10} xs={12} className="login_cont_holder">
                <h6 className="inter_font mt-5">Login</h6>
                <p className="inter_font mt-2">
                  Anonymus P2P deals on your terms. Trade globally.
                </p>
                <div className="login_selector d-flex justify-content-start align-items-cente mt-4">
                  <div
                    className={`login_via text-center ${
                      loginSource == "email" ? "active" : ""
                    }`}
                    onClick={() => {
                      setLoginSource("email");
                      setEmail("");
                      setPhoneno("");
                      setError({});
                      setPassword("");
                    }}
                  >
                    <p className="inter_font">Email</p>
                  </div>
                  <div
                    className={`login_via rounded-end text-center ${
                      loginSource == "mobile" ? "active" : ""
                    }`}
                    onClick={() => {
                      setLoginSource("mobile");
                      setEmail("");
                      setPhoneno("");
                      setError({});
                      setPassword("");
                    }}
                  >
                    <p className="inter_font">Mobile</p>
                  </div>
                </div>
                <div className="login_form_holder p-3 p-sm-3 p-md-4 p-lg-5">
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">{
                      loginSource == "email" ? 'Email Address' : 'Mobile Number' }</label>
                     {loginSource == 'email' ? 
                    <input
                      type="email"
                      placeholder="Enter registered Email ID"
                      className="primary_input rounded-1 p-2 inter_font"

                      onChange={(e) => {
                        if(loginSource == "email"){
                          setEmail(e?.target?.value);
                        }
                        else{
                          setPhoneno(e?.target?.value);
                        }
                      }}

                    />
                    :
                    <div className='sm_mobnum_select_holder rounded-1 editprfl__mob_input px-2'>
                <PhoneInput
                  placeholder='Enter Phone Number'
                  country={'us'}

                />
              </div>
              }
                    <p className="text-danger">{loginSource == "email" ? error?.email : error?.phoneNo}</p>
                  </div>
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 mt-4 inter_font">Password</label>
                    <input
                      type="password"
                      placeholder="Enter your Password"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => setPassword(e?.target?.value)}
                    />
                    <p className="text-danger">{error?.password}</p>
                  </div>
                  <div className="login_check_holder d-flex justify-content-start align-items-center gap-2 mt-2">
                    <Form>
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid checked = {termsandcondition} 
                        onChange = {(e) => setTermsandcondition(e?.target?.checked)}
                        />
                        {/* <Form.Control.Feedback type="valid">
              You did it!
            </Form.Control.Feedback> navigation('/') */}
                      </Form.Check>
                    </Form>
                    <span className="login_check_label inter_font">
                      Keep me signed in on this computer
                    </span>
                    
                  </div>
                  <p className="text-danger">{error?.termsandcondition}</p>
                  <div className="text-end mt-3">
                    <button className="primary_btn" onClick={handleLogin}>Login</button>
                  </div>
                  <div className="login_form_bottom d-flex justify-content-between align-items-center mt-4">
                    <NavLink className="inter_font" to="/forgotpassword">
                      Forgot Password?
                    </NavLink>
                    <NavLink className="inter_font" to="#">
                      Sign up for free
                    </NavLink>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default Login;
