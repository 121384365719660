import axios from 'axios';
import { toast } from 'react-hot-toast';

import config from "../Config/env";
import { AddEmailApi, AddMobileNumberApi, AddupiPaymentApi, ChangeEmailApi, CreateNewUserApi, Disable2faCodeApi, Get2faCodeApi, GetLoginHistoryApi, GetUserApi, LoginApi, ResetPasswordApi, SendOtpForgotApi, sendRegisterOtpApi, Update2faCodeApi, UpdateNewEmailApi, UpdatePasswordApi, UpdateProfileApi, UploadBankStatementApi, UploadKycApi, VerifyAddEmailApi, VerifyAddMobileNumberApi, VerifyForgotOtpApi, VerifyOldEmailApi, VerifyRegisterotpApi } from '../Routes/UserRoutes';

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
    }
}

export const SendRegisterOtpHooks = async(data) => {
    try{console.log("hooks" , data);
        var payload = {...data}
        let result =  await axios.post(config?.API_URL + sendRegisterOtpApi, payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on SendRegisterOtpHooks" , e);
    }
}

export const VerifyRegisterOtpHooks = async(data) => {
    try{
        var payload = {...data}
        let result =  await axios.post(config?.API_URL + VerifyRegisterotpApi, payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on SendRegisterOtpHooks" , e);
    }
}

export const CreateNewUserHooks = async(data) => {
    try{
        var payload = {...data}
        let result =  await axios.post(config?.API_URL + CreateNewUserApi, payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on SendRegisterOtpHooks" , e);
    }
}

export const LoginHooks = async(data , dispatch) => {
    try{
        var payload = {...data}
        let result =  await axios.post(config?.API_URL + LoginApi, payload );
        if(result?.data?.type == "Success" && result?.data?.message){
            localStorage.setItem("KEY", window.btoa(result?.data?.checkUser?.email))
            localStorage.setItem("TKN", window.btoa(result?.data?.jwttkn));
            localStorage.setItem("OTXUSR", window.btoa(result?.data?.checkUser?.userId));
            dispatch({
                type: "login_Detail",
                payload: result?.data?.checkUser
            });

            dispatch({
                type: "token_Detail",
                payload: result?.data?.jwttkn
            });
            return result?.data;
        }
        else if(result?.data?.type == "Success" && result?.data?.error){
            toast.error(result?.data?.error);
        }
        
    }
    catch(e){
        console.log("Error on SendRegisterOtpHooks" , e);
    }
}

export const getGeoInfoData = async (data) => {
    try {
      let respData = await axios({
        method: "get",
        url: `${config.getGeoInfo}`,
      });
  
      return {
        status: "success",
        loading: false,
        result: respData.data,
      };
    } catch (err) {
      return {
        status: "failed",
        loading: false,
        error: err.response.data.errors,
      };
    }
  };

export const GetEmail = () => {
    let email = atob(localStorage.getItem("key"));
    return email;
}

export const GetUserId = () => {
    let email = atob(localStorage.getItem("OTXUSR"));
    return email;
}

export const GetUserHooks = async(data , dispatch) => {
    try{
        var payload = {...data}
        let result =  await axios.post(config?.API_URL + GetUserApi, payload );
        // localStorage.setItem("KEY" , window.btoa(result?.data?.checkUser?.email));
        // localStorage.setItem("TKN" , window.btoa(result?.data?.jwttkn));
        // localStorage.setItem("OTXUSR" , window.btoa(result?.data?.checkUser?.id));
        dispatch({
            type: "login_Detail",
            payload: result?.data?.checkUser
          });
        return result?.data;
    }
    catch(e){
        console.log("Error on get user hooks" , e);
    }
}

export const SendOtpForgotHooks = async(data) => {
    try{
        var payload = {...data};
        let result =  await axios.post(config?.API_URL + SendOtpForgotApi , payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const VerifyOtpForgotHooks = async(data) => {
    try{
        var payload = {...data};
        let result =  await axios.post(config?.API_URL + VerifyForgotOtpApi , payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const UpdatePasswordHooks = async(data) => {
    try{
        var payload = {...data};
        let result =  await axios.post(config?.API_URL + UpdatePasswordApi , payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const UpdateProfileHooks = async(data) => {
    try{
        var payload = {...data};
        let result =  await axios.post(config?.API_URL + UpdateProfileApi , payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const UploadKycHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + UploadKycApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const ResetPasswordHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + ResetPasswordApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const UploadBankStatementHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + UploadBankStatementApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}


export const AddUpiPaymentHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + AddupiPaymentApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}


export const AddEmailHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + AddEmailApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const VerifyAddEmailHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + VerifyAddEmailApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const ChangeEmailHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + ChangeEmailApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const VerifyOldEmailHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + VerifyOldEmailApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}


export const UpdateNewEmailHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + UpdateNewEmailApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const AddMobileNumberHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + AddMobileNumberApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const VerifyMobileNumberHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + VerifyAddMobileNumberApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}


export const GetLoginHistoryHooks = async(data) => {
    try{
        var payload = {...data}
        let result =  await axios.get(config?.API_URL + GetLoginHistoryApi, {params: payload} );
        return result?.data;
    }
    catch(e){
        console.log("Error on get user hooks" , e);
    }
}

export const Get2faCodeHooks = async(data) => {
    try{
        var payload = {...data}
        let result =  await axios.get(config?.API_URL + Get2faCodeApi, {params: payload} );
        return result?.data;
    }
    catch(e){
        console.log("Error on get user hooks" , e);
    }
}

export const Update2faCodeHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + Update2faCodeApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}

export const Disable2faCodeHooks = async(data) => {
    try{
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
            }
        }
        // var payload = {...data};
        let result =  await axios.post(config?.API_URL + Disable2faCodeApi , data , headers);
        return result?.data;
    }
    catch(e){
        console.log("Error on send otp forgot hooks" , e);
    }
}