import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ValidatePassword } from '../Validation/UserValidation';
import { UpdatePasswordHooks } from '../Hooks/UserHooks';

function ForgotPasswordChange() {
    const theme = useSelector((state) => state?.User?.theme);
    const location = useLocation();
    const navigate = useNavigate();
    const [password,setPassword] = useState(false);
    const [confirmpassword , setConfirmpassword] = useState(false);


    const [passcode , setPasscode] = useState("");
    const [confirmPasscode , setConfirmpasscode] = useState("");
    const [error , setError] = useState("");
    const [confirmerror , setConfirmerror] = useState("");
    console.log("location" , location?.state);

    const HandleSubmit = async() => {
      try{
        debugger
        let payload = location?.state;
        payload.password = passcode;
        payload.confirmpassword = confirmPasscode;
        let validate = ValidatePassword(passcode);
        if(validate){
          setError(validate);
        }
        else{
          if(passcode!= confirmPasscode){
            setConfirmerror("Confirm Passcode must be same as Passcode")
          }
          else{
            let result = await UpdatePasswordHooks(payload);
            if(result?.type == "Success" && result?.message){
              navigate("/login")
            }
          }
        }
      }
      catch(e){
        console.log("Error on handle submit" , e);
      }
    }



  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center'>Welcome to Otex!</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <Row className='align-items-center justify-content-between'>
                <Col lg={6} md={7} sm={12} className='mb-5 mb-md-0'>
                
                <div className="login_single_input d-flex flex-column mt-4">
                    <label className="mb-2 inter_font">New Password</label>
                    <div className='mailver_inputHolder p-2 rounded-2 d-flex justify-content-between align-items-center gap-3'>
                    <input
                      type={password ? "text" : 'password'}
                      className="inter_font flex-grow-1"
                      onChange={(e) => {
                        setPasscode(e?.target?.value);
                      }}
                    />
                    <p className='mailver_eye inter_font m-0' onClick={() => setPassword(!password)}><i class={password ? 'fa-regular fa-eye-slash' : "fa-regular fa-eye"}/></p>
                    <p className='text-danger'>{error}</p>
                    </div>
                  </div>

                  <div className='sp_passcode_steps mt-3'>
                    <div className='sp_passcode_singleStep d-flex justify-content-start align-items-center gap-2 mb-2'>
                    <img src={require('../assets/images/checker.svg').default} className='img-fluid sp_passcode_checker' />
                    <p className='mailver_hint inter_font m-0 mt-2'>At least 8 characters</p>
                    </div>
                    <div className='sp_passcode_singleStep d-flex justify-content-start align-items-center gap-2 mb-2'>
                    <img src={require('../assets/images/checker.svg').default} className='img-fluid sp_passcode_checker' />
                    <p className='mailver_hint inter_font m-0 mt-2'>At least 1 number</p>
                    </div>
                    <div className='sp_passcode_singleStep d-flex justify-content-start align-items-center gap-2 mb-2'>
                    <img src={require('../assets/images/checker.svg').default} className='img-fluid sp_passcode_checker' />
                    <p className='mailver_hint inter_font m-0 mt-2'>At least 1 upper case letter</p>
                    </div>
                  </div>

                  <div className="login_single_input d-flex flex-column mt-4">
                    <label className="mb-2 inter_font">Confirm New Password</label>
                    <div className='mailver_inputHolder p-2 rounded-2 d-flex justify-content-between align-items-center gap-3'>
                    <input
                      type={confirmpassword ? "text" : 'password'}
                      className="inter_font flex-grow-1"
                      onChange={(e) => {
                        setConfirmpasscode(e?.target?.value);
                      }}
                    />
                    <p className='mailver_eye inter_font m-0' onClick={() => setConfirmpassword(!confirmpassword)}><i class={confirmpassword ? 'fa-regular fa-eye-slash' : "fa-regular fa-eye"}/></p>
                    <p className='text-danger'>{confirmerror}</p>
                    </div>
                  </div>

                  <button className='primary_btn w-100 mt-4'
                  onClick={HandleSubmit}
                  >Confirm</button>
                </Col>
                <Col lg={5} md={5} sm={12}>
                    <img src={require('../assets/images/lockkey.svg').default} className='img-fluid' />
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default ForgotPasswordChange