import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { NavLink, useNavigate } from 'react-router-dom'
import { CreateOfferHooks } from '../Hooks/P2pHooks'

function VendorTermsModal({show,handleClose , payload}) {
  const navigate = useNavigate();

  const HandleCreateOffer = async() => {
    let payld = {
      createdata : payload
    }
    let result = await CreateOfferHooks(payld);
    if(result?.type == "Success" && result?.data){
      handleClose()
      toast.success("Offer Created Successfully");
      navigate("/createOfferList")
    }
    else{
      toast.error("Error on Server");
    }
    console.log("result" , result);
  }

  return (
    <>
    <Modal
        className="purchase_modal"
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-4">
          <button
            className="cmn_modal_closer inter_font rounded-5"
            onClick={handleClose}
          >
            <i class="fa-solid fa-xmark" />
          </button>

          <h6 className='inter_font m-0 offertags__title'>Vendor Terms and Conditions</h6>
          <small className='inter_font vendorTerms__small'>It is a long established fact that a reader</small>

          <div className="createOffer__borbot my-3"></div>

          <h6 className='inter_font m-0 vendorTerms__cont_title'></h6>
          <p className='inter_font m-0 vendorTerms__cont_hint my-4'>Effective Date: March 15,2024</p>


          <p className='inter_font m-0 vendorTerms__cont_hint'>This agreement document is provided only in English. You acknowledge that you possess sufficient knowledge of the English language. You 
understand the information included in the terms to the extent required and fully comprehend the legal implication of this document. In the 
event that you do not  understand or comprehend English adequately, you Sed libero ligula, vulputate hendrerit venenatis accumsan, dapibus in eros. Fusce at libero dapibus, rhoncus ipsum sit amet, aliquam dui. Donec justo eros, convallis id maximus nec, condimentum in nibh. Praesent sit amet varius orci, in porttitor felis. Etiam sagittis fermentum quam, et faucibus neque. Sed ultrices turpis ac feugiat varius. Nam id pulvinar leo, et venenatis nibh. Pellentesque molestie laoreet turpis et placerat. Nullam consectetur risus nisi, sed ullamcorper enim mollis ac. </p>

<Row className='justify-content-center justify-content-sm-between mt-5'>
    <Col lg={4} md={5} sm={7} className='mb-3 mb-sm-0 d-flex justify-content-center justify-content-sm-start'>
    <NavLink to='/createOfferList' className="header_links">
            <button className='secondary_btn rounded-1' onClick={handleClose}>I don't want to be a vendor</button>
            </NavLink>

    </Col>
    {/* to='/createOfferList' */}
    <Col lg={4} md={4} sm={5} className='d-flex justify-content-center justify-content-sm-end'>
    <NavLink className="header_links"> 
            <button className='primary_btn rounded-1' onClick={HandleCreateOffer}>I Agree</button>
            </NavLink>

    </Col>
</Row>

         

        </Modal.Body>
      </Modal>
    </>
  )
}

export default VendorTermsModal