import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactApexChart from 'react-apexcharts'
import { useSelector } from 'react-redux';
import BlackListBox from './BlackListBox';
import StoreRegistrationCard from './StoreRegistrationCard';
import DatePicker from "react-datepicker";

const SaleStatus = () => {
    const theme = useSelector((state) => state?.User?.theme);
    const [pieChart,setPieChart] = useState([10,20,70,50])
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [startDate, endDate] = dateRange;

    const datas = {

        series: [10,20,40,50,15],
    
        options: {
          labels: [`BTC &nbsp;&nbsp; - &nbsp;&nbsp; ${pieChart[0]} `, `BCH &nbsp; - &nbsp; ${pieChart[1]}`, `USDT  &nbsp; &nbsp;&nbsp; - &nbsp; ${pieChart[2]}`, `LTC &nbsp; &nbsp; - &nbsp; ${pieChart[3]}`, `ETH &nbsp; &nbsp; - &nbsp; ${pieChart[3]}`],
          colors: ['#00CABA', '#BC8026', '#0B80ED','#A730D1','red'],
          chart: {
            type: 'donut',
            height: 250,
            width: '100%',
            border: 'none',
            foreColor: theme == 'dark' ? '#fff' : '#000'
    
          },
          legend: {
            // show: false,
            position: 'right'
    
          },
          stroke:{
            colors:['transperant']
           },
          fill: {
            colors: ['#00CABA', '#BC8026', '#0B80ED','#A730D1','#f630ef'],
          },
    
    
           dataLabels: {
                  enabled: true,},
    
    
          responsive: [{
            breakpoint: 992,
            options: {
              chart: {
                width: '100%'
              },
              legend: {
                // show: false,
                position: 'bottom'
        
              },
              
            }
          }]
        },
    
      };
  return (
    <>
    <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-3 p-lg-4'>
    <Row className='h-100'>
        <Col md={3} className='d-flex flex-column flex-sm-row flex-md-column justify-content-center gap-3 gap-md-0 align-items-center mb-3'>
            <button className='prfl_orange_btn rounded-2 inter_font w-100'>Request for consulation</button>
            <button className='primary_btn rounded-2 inter_font w-100 mt-md-3'>Buyer chat requeset</button>
        </Col>

        <Col md={7} className='mb-4'>
            <Row className='align-items-center'>
                <Col lg={3} xs={12} className='d-flex justify-content-end'>
                <div className="login_check_holder d-flex justify-content-start align-items-center gap-2 mt-3">
                    <Form>
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid
                        />
                      </Form.Check>
                    </Form>
                    <span className="createacc_checklabel inter_font m-0">Hero Zero Balance</span>
                  </div>
                </Col>
                <Col lg={9}  xs={12}>
                <div className="cmntabs_donut_holder">
            <ReactApexChart options={datas.options} series={datas.series} type="donut" height={350} />
            </div>
                </Col>
            </Row>
       

                  
        </Col>

        <Col md={2}>
        <button className='primary_btn rounded-2 inter_font w-100'>Competition</button>
        </Col>
    </Row>

    <Row className='mt-4'>
      <Col lg={9}>

<Row>
  <Col lg={5} md={5} sm={6} className='mb-4 mb-md-0'>
  <div className='ss__searchHolderer rounded-2 px-2'>
        <img src={require('../assets/images/searchglass.svg').default} className='img-fluid mk__searchGlass' />
        <input
                      type="text"
                      placeholder="Enter your Password"
                      className="ss__emptyInput rounded-1 p-2 inter_font"
                    />
      </div>
  </Col>
  <Col lg={7} sm={12}>
  <div className='ss__calendarHolder d-flex justify-content-start align-items-center'>
          <div className='ss_timeBadge py-2 px-4'>
            <p className='m-0 inter_font ss__timeBadgeTime text-center'><span>*</span>Time</p>
          </div>
          <div className="deposit_custom_datepoicker ss_calendar rounded-2 d-flex justify-content-start align-items-center px-2 position-relative">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                // monthsShown={2}
              />
              <img src={require('../assets/images/white_calendar.svg').default} className={`img-fluid ${theme == 'dark' ? 'deposit_calImg' : 'deposit_calImgInvert'}`} /> 
            </div>

        </div>
  </Col>
</Row>
     

      
      </Col>
    </Row>

    <div className='mt-4'>
    <StoreRegistrationCard/>
    </div>


 <BlackListBox/>
    </div>
    </>
  )
}

export default SaleStatus