import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import InquiryModal from '../Modals/InquiryModal';

function Inquiry() {

    // modal
    const [show,setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const theme = useSelector((state) => state?.User?.theme);
  return (
    <>
     <div className="notices_holder p-2 p-sm-3 pb-md-5">
        <Row className='justify-content-between align-items-center'>
            <Col lg={4} md={4} sm={4} className='d-flex flex-column flex-lg-row justify-content-start align-items-center align-items-sm-start gap-2 mb-3 mb-sm-0'>
                <img src={require('../assets/images/helpcenter.svg').default} className='img-fluid inquiry_cc_img' />
                <div>
        <h5 className="notices_hader_title inter_font ">Help Center</h5>
        <p className='inquiry_left_hint inter_font'>1:1 Inquiry</p>

                </div>
            </Col>
            <Col lg={8} md={8} sm={8} className='d-flex flex-column flex-lg-row justify-content-end align-items-center align-items-sm-end gap-3'>
                <div className='inquiry_right_textAlign'>
        <p className='inquiry_left_hint inter_font'>Nice to meet you. How may i help you?</p>
        <p className='inquiry_right_hint inter_font mt-2'>Contact the Otex manager. (24 hours inquiry) If there is a lot of consultation from members, the answer may be a little delayed.</p>
        </div>
        <button className='primary_btn' onClick={handleShow}>Contact us</button>

            </Col>
        </Row>

        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

        <div className='inquiry_blackBox_holder p-2 rounded-4'>
            <div className='inquiry_blackBox p-2 p-sm-3 p-lg-4 rounded-4'>

                <div className='inquiry_response_holder'>
                    <div className='inquiry_timeAction_holder'>
                        <Row className='justify-content-end'>
                            <Col lg={5} md={6} sm={9}>
                                <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center gap-3'>
                                    <p className='im_time_text inter_font m-0'><i class="fa-regular fa-clock me-2"></i>2024-10-06  17:57:25</p>
                                    <p className='im_action_text inter_font m-0'> <span onClick={handleShow}>Modify</span> &nbsp; | &nbsp; <span className='im_inquiry_redText'>Delete</span></p>
                                </div>
                            </Col>

                           
                        </Row>
                        <div className='im_inquiry_box py-2 px-3 rounded-2 mt-2'>
                                <Row className='align-items-center'>
                                    <Col lg={10} md={9} sm={8} className='mb-3 mb-sm-0'>
                                    <p className='im_time_text inter_font m-0'>Hello, I would like to inquire about the transaction, and i look forward to hearing from you soon.</p>
                                    </Col>
                                    <Col lg={2} md={3} sm={4}  className='text-end'>
                                    <p className='im_inquiry_redText inter_font m-0 text-center'>Awaiting response</p>
                                    </Col>
                                </Row>
                            </div>
                            <div className='im_inquiryAnswer_box p-1 ps-sm-2 p-md-3'>
                            <p className='im_time_text inter_font m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce egestas nulla ut metus blandit gravida. Suspendisse potenti. 
Cras vel leo a dui fringilla dapibus. Nullam in imperdiet nisi. Fusce imperdiet massa sit amet risus tempor euismod. Nullam
fermentum lacus nisi, ac rutrum ex venenatis non. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
inceptos himenaeos. Etiam vestibulum tortor sed mattis sodales. Vivamus vel ultrices lacus.</p>

<Row className='justify-content-end mt-2'>
                            <Col lg={3} className='d-flex justify-content-end'>
                                <div className='d-flex justify-content-between align-items-center gap-3'>
                                    <p className='im_time_text inter_font m-0'><i class="fa-regular fa-clock me-2"></i>2024-10-06  17:57:25</p>
                                </div>
                            </Col>

                           
                        </Row>
                            </div>
                    </div>
                </div>

            </div>
        </div>
     </div>

     {/* inquiry modal */}
        {show && <InquiryModal show={show} setShow={setShow} handleClose={handleClose} />}  
     {/* end of inquiry modal */}
    </>
  )
}

export default Inquiry