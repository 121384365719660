import React from 'react'
import { allData } from '../CommonData/Data'
import { Dropdown } from 'react-bootstrap'

const StoreRegistrationCard = () => {
  return (
    <>
    {allData.saleSettlementCard.map((item) =>
    <div className='src__gridHolder p-3 rounded-3 mb-3'>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>No:</label>
            <p className='m-0 inter_font'>{item.no}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>User ID:</label>
            <p className='m-0 inter_font'>{item.userId}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Buyer Name:</label>
            <p className='m-0 inter_font'>{item.buyerName}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Phone Number:</label>
            <p className='m-0 inter_font'>{item.phoneNumber}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Product Name:</label>
            <p className='m-0 inter_font'>{item.productName}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Token type:</label>
            <p className='m-0 inter_font'>Kkkkkkkaddsadgsdf@gmail.com</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Buyer Address:</label>
            <p className='m-0 inter_font'>{item.buyerAddress}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>commodity price:</label>
            <p className='m-0 inter_font'>{item.commodityPrice}<br/><span>(300,000,000 KRW)</span></p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Asset before payment:</label>
            <p className='m-0 inter_font'>{item.assetBeforePayment}<br/><span>(300,000,000 KRW)</span></p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Order Quantity:</label>
            <p className='m-0 inter_font'>{item.orderQuantity}</p>
        </div>

        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Payment Amount:</label>
            <p className='m-0 inter_font'>{item.paymentAmount}<br/><span>(300,000,000 KRW)</span></p>
        </div>

        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Fee:</label>
            <p className='m-0 inter_font'>{item.fee}%</p>
        </div>

        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Fee amount:</label>
            <p className='m-0 inter_font'>{item.feeAmount}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Asset after Payment:</label>
            <p className='m-0 inter_font'>{item.assetAfterPayment}</p>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Payment Date:</label>
            <p className='m-0 inter_font'>{item.paymentDate}</p>
        </div>

        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Sales Decision:</label>
            <Dropdown className='src__dropdown'>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Sale
      </Dropdown.Toggle>

      <Dropdown.Menu className='src__dropdownMenu'>
        <Dropdown.Item className='src__dropdownItem' >Action</Dropdown.Item>
        <Dropdown.Item className='src__dropdownItem' >Another action</Dropdown.Item>
        <Dropdown.Item className='src__dropdownItem' >Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </div>

        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Classification:</label>
            <Dropdown className='src__dropdown'>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Normal
      </Dropdown.Toggle>

      <Dropdown.Menu className='src__dropdownMenu'>
        <Dropdown.Item className='src__dropdownItem' >Action</Dropdown.Item>
        <Dropdown.Item className='src__dropdownItem' >Another action</Dropdown.Item>
        <Dropdown.Item className='src__dropdownItem' >Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </div>
        <div className='src__singleGrid d-flex justity-content-start align-items-start gap-2'>
            <label className='m-0 inter_font'>Result:</label>
            <button className='primary_btn inter_font src__completeBtn rounded-2'>Complete</button>
        </div>
       
    </div>
     )}
    </>
  )
}

export default StoreRegistrationCard