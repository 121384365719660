/** Action-File */
import { login_Detail , theme_Setter , token_Detail} from "./Constant";

const initialState = {
    loginData: {},
    theme : 'dark',
    token : ""
};

const frontReducer = (state = initialState, action) => {
    switch (action.type) {
        case login_Detail:
            return { ...state, loginData: action?.payload};
        case theme_Setter:
            return {...state , theme : action?.theme};
        case token_Detail:
            return {...state , token : action?.payload};

        default:
            return state;
    }
};


export default frontReducer