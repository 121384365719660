import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <Container fluid className="footer pt-4">
      <Container className="custom_container p-xl-5">
        <img
          src={require("../assets/images/logo.svg").default}
          className="img-fluid"
        />
        <div className="footer_gridHolder mt-4 pb-4">
          <div className="footer_singleGrid">
            <p className="inter_font footer_linksTitle">Purchase only</p>
            <ul className="footer_links mt-3">
              
                <li className="mb-1"><NavLink>Purchase USDT (ERC20)</NavLink></li>
                <li className="mb-1"><NavLink>Purchase KUSDT (KRC20)</NavLink></li>
                <li className="mb-1"><NavLink>Purchase USDT (TRC20)</NavLink></li>
                <li className="mb-1"><NavLink>Purchase USDC (ERC20)</NavLink></li>
                <li className="mb-1"><NavLink>Buy Bitcoin (BTC)</NavLink></li>
                <li className="mb-1"><NavLink>Buy XRP (Ripple)</NavLink></li>
                <li className="mb-1"><NavLink>Purchase TRX (Tron)</NavLink></li>
            </ul>
          </div>
          <div className="footer_singleGrid">
            <p className="inter_font footer_linksTitle">For sale only</p>
            <ul className="footer_links mt-3">
              
                <li className="mb-1"><NavLink>Sales USDT (ERC20)</NavLink></li>
                <li className="mb-1"><NavLink>Sales KUSDT (KRC20)</NavLink></li>
                <li className="mb-1"><NavLink>Sales USDT (TRC20)</NavLink></li>
                <li className="mb-1"><NavLink>Sales USDC (ERC20)</NavLink></li>
                <li className="mb-1"><NavLink>Sales Bitcoin (BTC)</NavLink></li>
                <li className="mb-1"><NavLink>Sales XRP (Ripple)</NavLink></li>
                <li className="mb-1"><NavLink>Sales TRX (Tron)</NavLink></li>
            </ul>
          </div>

          <div className="footer_singleGrid">
            <p className="inter_font footer_linksTitle">Stable liquidity supply</p>
            <ul className="footer_links mt-3">
              
                <li className="mb-1"><NavLink>What is Vander?</NavLink></li>
                <li className="mb-1"><NavLink>Vander earnings</NavLink></li>
                <li className="mb-1"><NavLink>Vander request</NavLink></li>
            </ul>
          </div>
          <div className="footer_singleGrid">
            <p className="inter_font footer_linksTitle">Corporate business</p>
            <ul className="footer_links mt-3">
              
                <li className="mb-1"><NavLink>Wallet API for enterprise</NavLink></li>
                <li className="mb-1"><NavLink>Vander earnings</NavLink></li>
                <li className="mb-1"><NavLink>Corporate store</NavLink></li>
            </ul>
          </div>
          <div className="footer_singleGrid">
            <p className="inter_font footer_linksTitle">Introduction to OTEX</p>
            <ul className="footer_links mt-3">
              
                <li className="mb-1"><NavLink>Company introduction</NavLink></li>
                <li className="mb-1"><NavLink>Business contact</NavLink></li>
                <li className="mb-1"><NavLink>Technical recruitment</NavLink></li>
            </ul>
          </div>
        </div>
        <div className="footer_copyright_holder p-3 d-flex justify-content-center align-items-center">
            <p className="inter_font text-center">Copyright @ 2024 Otex Escrow. All Rights Reserved</p>
        </div>
      </Container>
    </Container>
  );
}

export default Footer;
