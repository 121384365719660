import React, { useState } from 'react'
import {Modal} from 'react-bootstrap';
import { useSelector } from 'react-redux';

function SecurityAccountActivity({show,handleClose , loginhistory , operationhistory}) {
    const theme = useSelector((state) => state?.User?.theme);
    const [activeTab,setActiveTab] = useState('login');
    const [loginHistory,setLoginHistory] = useState([
        {
            img : require('../assets/images/syscheck.svg').default,
            loginTime : '2023.12.17   18:23:00',
            location : 'South Korea-Daejeon-Daejeon',
            ip : '123.456.78.90',
            device : 'Chrome 12 12.0.0.0 (Windows 10)'
        },
        {
            img : require('../assets/images/sys.svg').default,
            loginTime : '2023.12.17   18:23:00',
            location : 'South Korea-Daejeon-Daejeon',
            ip : '123.456.78.90',
            device : 'Chrome 12 12.0.0.0 (Windows 10)'
        },
        {
            img : require('../assets/images/syscheck.svg').default,
            loginTime : '2023.12.17   18:23:00',
            location : 'South Korea-Daejeon-Daejeon',
            ip : '123.456.78.90',
            device : 'Chrome 12 12.0.0.0 (Windows 10)'
        },
        {
            img : require('../assets/images/sys.svg').default,
            loginTime : '2023.12.17   18:23:00',
            location : 'South Korea-Daejeon-Daejeon',
            ip : '123.456.78.90',
            device : 'Chrome 12 12.0.0.0 (Windows 10)'
        },
    ])

    const [operationHistory,setOperationHistory] = useState([
        {
           date : '2023-12-25    10:03:54',
           operationType : 'Retrieve fund password',
           location : 'South Korea-Daejeon-Daejeon',
           ip : '123.456.78.90',
           device : 'Chrome 12 12.0.0.0 (Windows 10)'
        },
        {
            date : '2023-12-25    10:03:54',
            operationType : 'Retrieve fund password',
            location : 'South Korea-Daejeon-Daejeon',
            ip : '123.456.78.90',
            device : 'Chrome 12 12.0.0.0 (Windows 10)'
         },
         {
            date : '2023-12-25    10:03:54',
            operationType : 'Retrieve fund password',
            location : 'South Korea-Daejeon-Daejeon',
            ip : '123.456.78.90',
            device : 'Chrome 12 12.0.0.0 (Windows 10)'
         },
         {
            date : '2023-12-25    10:03:54',
            operationType : 'Retrieve fund password',
            location : 'South Korea-Daejeon-Daejeon',
            ip : '123.456.78.90',
            device : 'Chrome 12 12.0.0.0 (Windows 10)'
         },
    ])
  return (
    <Modal size='lg'
    centered
    className="purchase_modal"  
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
         <button
            className="cmn_modal_closer rounded-5"
            onClick={handleClose}
          >
            <i class="fa-solid fa-xmark" />
          </button>
        <Modal.Body>
            <h6 className='security_modal_titles inter_font'><i class="fa-solid fa-arrow-left-long me-2" /> Account activity</h6>
          
         <div className='sm_acntact_taber d-flex justify-content-start align-items-center gap-5 mt-4'>
            <div className='sm_acntact_single_tab ' onClick={() => setActiveTab('login')}><p className={`inter_font sm_tabtext ${activeTab == 'login' ? 'active' : ''}`}>Login history</p></div>
            <div className='sm_acntact_single_tab ' onClick={() => setActiveTab('operation')}><p className={`inter_font sm_tabtext ${activeTab == 'operation' ? 'active' : ''}`}>Operation history</p></div>
         </div>
         <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

{activeTab == 'login' ? 
        <div className='sm_loginHistory_holder'>
            {loginhistory?.map((e) =>
            <>
            <div className='sm_loginHistory_single mb-3'>
            <div className='sm_login_topFlex d-flex justify-content-start align-items-center gap-2 mb-2'>
                <img src={e?.isMobile == "true" ? require('../assets/images/syscheck.svg').default : require('../assets/images/sys.svg').default} className='img-fluid' />
                <p className='sm_login_titller'>{e?.broswerName}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='sm_loginHistory_label inter_font'>Login time</p>
                <p className='sm_login_titller inter_font me-2'>{new Date(e?.loginTime)?.toLocaleDateString() + " " + 
                new Date(e?.loginTime)?.toLocaleTimeString()}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='sm_loginHistory_label inter_font'>Location</p>
                <p className='sm_login_titller inter_font me-2'>{e?.regionName + " " +e?.countryName}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='sm_loginHistory_label inter_font'>IP</p>
                <p className='sm_login_titller inter_font me-2'>{e?.ipAddress}</p>
            </div>
            </div>
             <div
             className={`my-3 ${
               theme == "dark"
                 ? "table_triple_grad_rel"
                 : "table_triple_grad_rel_light"
             }`}
           ></div>  
           </>
             )}
        </div> : <></> }

        {activeTab == 'operation' ? 
        <div className='sm_loginHistory_holder'>
            {operationhistory?.map((e) =>
            <>
            <div className='sm_loginHistory_single mb-3'>
            {/* <div className='sm_login_topFlex d-flex justify-content-start align-items-center gap-2 mb-2'>
                <p className='sm_login_titller'>{e.date}</p>
            </div> */}
            <div className='d-flex justify-content-between align-items-center'>
                <p className='sm_loginHistory_label inter_font'>Operation Type</p>
                <p className='sm_login_titller inter_font me-2'>{e.operation}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='sm_loginHistory_label inter_font'>Login location</p>
                <p className='sm_login_titller inter_font me-2'>{e?.regionName + " " +e?.countryName}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='sm_loginHistory_label inter_font'>IP</p>
                <p className='sm_login_titller inter_font me-2'>{e?.ipAddress}</p>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='sm_loginHistory_label inter_font'>Device</p>
                <p className='sm_login_titller inter_font me-2'>{e?.broswerName}</p>
            </div>
            </div>
             <div
             className={`my-3 ${
               theme == "dark"
                 ? "table_triple_grad_rel"
                 : "table_triple_grad_rel_light"
             }`}
           ></div>  
           </>
             )}
        </div> : <></> }

        </Modal.Body>
      
      </Modal>
  )
}

export default SecurityAccountActivity