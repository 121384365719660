import React, { useState } from 'react'
import {Modal} from 'react-bootstrap';
import { IoMdInformationCircleOutline } from "react-icons/io";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function SecurityPhoneNumber({showSeucityPhone,setShowSeucityPhone,handleCloseSeucityPhone}) {
    const [phoneNum,setPhoneNum] = useState()
  return (
    <Modal
    centered
    className="purchase_modal"  
        show={showSeucityPhone}
        onHide={handleCloseSeucityPhone}
        backdrop="static"
        keyboard={false}
      >
         <button
            className="cmn_modal_closer rounded-5"
            onClick={handleCloseSeucityPhone}
          >
            <i class="fa-solid fa-xmark" />
          </button>
        <Modal.Body>
            <h6 className='security_modal_titles inter_font'><i class="fa-solid fa-arrow-left-long me-2" /> Modify phone number</h6>
          
          <div className='sm_green_badge rounded-3 d-flex justify-content-start align-items-center gap-3 p-3 mt-4'>
          <IoMdInformationCircleOutline />
          <p className='inter_font'>for your assets security, it won’t be able to withdraw or sell in P2P within 24 hours after changing the phone number</p>
          </div>

          <div className='sm_inputs_holder mt-3'>
            <label className='sm_inputs_label inter_font'>New phone number</label>
<div className='sm_mobnum_select_holder rounded-2 px-2 py-1 mt-2'>
<PhoneInput
placeholder='New Phone Number'
  country={'us'}
  value={phoneNum}
  onChange={phone => setPhoneNum({ phone })}
/>
</div>
          </div>
          <div className='sm_inputs_holder mt-3'>
            <label className='sm_inputs_label inter_font'>SMS verification code</label>
            <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex justify-content-between align-items-center gap-3'>
<input type='number' className='inter_font flex-grow-1' placeholder='Enter your mobile verification code' />
<button className='security_edit_btn inter_font '>Send</button>
</div>

          </div>

<p className='pm_primary_text inter_font mt-3'>Didn’t receive the mobile phone verification code</p>

<button className='primary_btn w-100 mt-4'>Submit</button>
        </Modal.Body>
      
      </Modal>
  )
}

export default SecurityPhoneNumber