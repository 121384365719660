import React, { useEffect, useState } from "react";
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function Header({activeCont,setActiveCont}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [themer, setThemer] = useState(false);
  const setDarkMode = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
    setThemer(false);
    dispatch({
      type: "theme_Setter",
      theme: "dark"
    });
  };
  const setLightMode = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
    dispatch({
      type: "theme_Setter",
      theme: "light",
    });
    setThemer(true);
  };

  useEffect(() => {
    const selectedTheme = localStorage.getItem("selectedTheme");
    if (selectedTheme === "dark") setDarkMode();
    else setLightMode();
  }, []);

  const toggleTheme = (e) => {
    if (e.target.checked) setLightMode();
    else setDarkMode();
  };
  const theme = useSelector((state) => state?.User?.theme);

  console.log(theme, "theherjh");
  return (
    <>
      <Container className="header py-2 custom_container">
        <Row>
          <Col lg={7} md={3} sm={3} xs={4}>
            <Row>
              <Col lg={2} md={12} sm={12} xs={12}>
              <NavLink className="header_links" to="/">
                <img
                  src={theme == 'dark' ? require("../assets/images/logowhite.svg").default : require("../assets/images/logo.svg").default}
                  className="img-fluid"
                />
                </NavLink>
              </Col>
              <Col
                xl={9}
                md={0}
                className="header_navLinks_holder d-none d-xl-block"
              >
                <ul className="header_navlinks main_header_navlinks ms-2">
                  <li>
                    <NavLink className="header_links" to="/marketList">
                      Market
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="header_links" to="/convert">
                      Convert
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="header_links" to="/storeLogin">
                      Store
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="header_links" to="/spot">
                      Spot
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="header_links" to="/assets">
                      Asset
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="header_links" to="/deposit">
                      Deposit
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="header_links" to="/withdraw">
                      Withdraw
                    </NavLink>
                  </li>
                 
                  <li>
                    <p className="header_links m-0 px-2" > AI-Trading Bot
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>

          <Col
            lg={5}
            sm={9}
            xs={8}
            className="d-flex justify-content-end align-items-center gap-2"
          >
            <NavLink className="header_links" to="/login">
              <button className="secondary_btn d-none d-xl-block">Login</button>
            </NavLink>
            <NavLink className="header_links" to="/createAccount">
              <button className="primary_btn d-none d-xl-block">Register</button>
              </NavLink>
            <button className="primary_connetBtn">
              <img
                src={require("../assets/images/globe.svg").default}
                className={`img-fluid global_img ${theme == 'dark' ? 'darkglobe' : 'lightglobe'}`}
              />
            </button>
            <button
              className="primary_connetBtn d-flex justify-content-center align-items-center d-xl-none"
              onClick={handleShow}
            >
              <i class="fa-solid fa-bars header_theme_changer" />
            </button>
            <label class="switch position-relative">
              <input type="checkbox" checked={themer} onChange={toggleTheme} />
              <span class="slider round">
                <i class="fa-solid fa-moon theme_moon" />
                <i class="fa-solid fa-sun theme_sun" />
              </span>
            </label>
          </Col>
        </Row>
      </Container>
      {/* offcanvas */}
      <Offcanvas
        show={show}
        className={
          theme == "dark"
            ? "header_canvas dark_canva"
            : "white_canva header_canvas"
        }
        placement="end"
        onHide={handleClose}
        backdrop="static"
      >
        <Offcanvas.Body>
          <div className="canva_header d-flex justify-content-between align-items-center">
            <img
              src={theme == 'dark' ? require("../assets/images/logowhite.svg").default : require("../assets/images/logo.svg").default}
              className="offcanva_logo"
            />
            <i class="fa-solid fa-xmark" onClick={handleClose} />
          </div>

          <div className="canva_body mt-4">
            <ul className="header_navlinks d-block">
              <li className="mt-3">
                <NavLink className="header_links" to="/market">
                  Market
                </NavLink>
              </li>
              <li className="mt-3">
                <NavLink className="header_links" to="/convert">
                  Convert
                </NavLink>
              </li>
              <li className="mt-3">
                <NavLink className="header_links" to="/storeLogin">
                  Store
                </NavLink>
              </li>
              <li className="mt-3">
                <NavLink className="header_links" to="/spot">
                  Spot
                </NavLink>
              </li>
              <li className="mt-3">
                    <NavLink className="header_links" to="/assets">
                      Asset
                    </NavLink>
                  </li>
                  <li className="mt-3">
                    <NavLink className="header_links" to="/deposit">
                      Deposit
                    </NavLink>
                  </li>
                  <li className="mt-3">
                    <NavLink className="header_links" to="/withdraw">
                      Withdraw
                    </NavLink>
                  </li>
              <li className="mt-3">
                <p className="header_links m-0">
                  AI-Trading Bot
                </p>
              </li>
            </ul>
            <div className="d-flex flex-column justify-content-start align-items-start gap-3">
            <NavLink className="header_links" to="/login">
              <button className="secondary_btn">Login</button>
              </NavLink>
              <NavLink className="header_links" to="/register">
              <button className="primary_btn">Register</button>
              </NavLink>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* end of offcanvas */}
    </>
  );
}

export default Header;
