import React, { useState } from 'react'
import {Modal} from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { IoMdInformationCircleOutline } from "react-icons/io";
import 'react-phone-input-2/lib/style.css'
import { GetUserId, ResetPasswordHooks } from '../Hooks/UserHooks';
import { ValidatePassword, ValidateResetPassword } from '../Validation/UserValidation';

function SecurityPassowrd({show,handleClose}) {
  const [oldPassword,setOldPassword] = useState(false)
  const [newPassword,setNewPassword] = useState(false)
  const [confirmPassword,setConfirmPassword] = useState(false)

  const [initialstate , setInitialstate] = useState({
    oldPassword : "",
    newpassword : "",
    confirmnewpassword : ""
  })
  const [error , setError] = useState({});

  const HandleChange = (e) => {
    try{
      let init = initialstate;
      init[e?.target?.name] = e?.target?.value;
      setInitialstate({...initialstate , [e?.target?.name] : e?.target?.value});
      let validate = ValidateResetPassword(init , e?.target?.name , false);
      console.log("validate" , validate , e?.target?.name);
      setError(validate)
      // if(!e.target.value){
      //   setError({...error , [e?.target?.name] : "Field is required"});
      // }
      // else{
      //   setError({...error , [e?.target?.name] : ""})
      // }
    }
    catch(e){
      console.log("Error on handle change " , e);
    }
  }

  const HandleSubmit = async(e) => {
    try{
      let validate = ValidateResetPassword(initialstate , "" , true);
      console.log("validate" , validate);
      if(Object.keys(validate)?.length <= 0){
        let payload = {
          oldpassword : initialstate?.oldpassword,
          newpwd : initialstate?.newpassword,
          userid : GetUserId()
        }
        let result = await ResetPasswordHooks(payload);
        if(result?.type == "Success"){
          toast.success("Password Updated");
          handleClose()
        }
        else if(result?.type == "error"){
          toast.error(result?.error);
        }
      }
      else{
        setError(validate);
      }
    }
    catch(e){
      console.log("Error on handle submit" , e);
    }
  }


  return (
    
    <Modal
        centered
        className="purchase_modal"  
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
         <button
            className="cmn_modal_closer rounded-5"
            onClick={handleClose}
          >
            <i class="fa-solid fa-xmark" />
          </button>
        <Modal.Body>
            <h6 className='security_modal_titles inter_font'><i class="fa-solid fa-arrow-left-long me-2" /> Reset login password</h6>
          
          <div className='sm_green_badge rounded-3 d-flex justify-content-start align-items-center gap-3 p-3 mt-4'>
          <IoMdInformationCircleOutline />
          <p className='inter_font'>It won’t be able to withdraw coins in 24 hours after modifying the password</p>
          </div>

          <div className='sm_inputs_holder mt-3'>
            <label className='sm_inputs_label inter_font'>Old password</label>
            <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex justify-content-between align-items-center gap-3'>
<input type={oldPassword ? 'text' :'password'} 
name = "oldpassword"
onChange={(e) => HandleChange(e)}
className='inter_font flex-grow-1' placeholder='Enter your mobile verification code' />
<img src={oldPassword ? require('../assets/images/closeeye.svg').default : require('../assets/images/openeye.svg').default } className='img-fluid sm_eyeImg' onClick={() => setOldPassword(!oldPassword)} />
</div><p className='text-danger'>{error?.oldpassword}</p>
          </div>
          <div className='sm_inputs_holder mt-3'>
            <label className='sm_inputs_label inter_font'>New password</label>
            <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex justify-content-between align-items-center gap-3'>
<input type={newPassword ? 'text' :'password'} 
name = "newpassword"
onChange={(e) => HandleChange(e)}
className='inter_font flex-grow-1' placeholder='Enter your mobile verification code' />
<img src={newPassword ? require('../assets/images/closeeye.svg').default : require('../assets/images/openeye.svg').default } className='img-fluid sm_eyeImg' onClick={() => setNewPassword(!newPassword)} />

</div><p className='text-danger'>{error?.newpassword}</p>
          </div>
          <p className='pm_primary_text sm_gray_text inter_font mt-3'>a to 32 digits, at least 1 number, 1 uppercase letter, and 1 special character </p>
          <div className='sm_inputs_holder mt-3'>
            <label className='sm_inputs_label inter_font'>Confirm New password</label>
          <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex justify-content-between align-items-center gap-3'>
            <input type={confirmPassword ? 'text' : 'password'}
              name="confirmnewpassword"
              onChange={(e) => HandleChange(e)}
              className='inter_font flex-grow-1' placeholder='Enter your mobile verification code' />

            <img src={confirmPassword ? require('../assets/images/closeeye.svg').default : require('../assets/images/openeye.svg').default} className='img-fluid sm_eyeImg' onClick={() => setConfirmPassword(!confirmPassword)} />
          </div><p className='text-danger'>{error?.confirmnewpassword}</p>
        </div>

      <button className='primary_btn w-100 mt-4' onClick={HandleSubmit}>Submit</button>
        </Modal.Body>
      
      </Modal>
  )
}

export default SecurityPassowrd