import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { useSelector } from "react-redux";
import Select from "react-select";
import CreateOfferPaymentMethod from "../Components/CreateOfferPaymentMethod";
import CreateOfferTradePricing from "../Components/CreateOfferTradePricing";
import CreateOfferTradeInstructions from "../Components/CreateOfferTradeInstructions";
import Footer from "../Components/Footer";
import { GetEmail, GetUserHooks, GetUserId } from "../Hooks/UserHooks";

const CreateOffer = () => {
  const theme = useSelector((state) => state?.User?.theme);
  const [step, setStep] = useState(1);

  const [offerSec,setOfferSec] = useState('paymentMethod');

  const [initialvalue , setInitialvalue] = useState({});

  const HandleStep1 = (data) => {
    try{
      let init = initialvalue;
      init.currency = data?.coin;
      init.ordertype = data?.ordertype;
      init.preferedcurrency = data?.preferedcurrency;
      init.paymentmethod = data?.paymentmethod;
      init.createrid = GetUserId();
      setInitialvalue(init);
    }
    catch(e){
      console.log("error on handle step1" , e);
    }
  }

  const HandleStep2 = (data) => {
    try{
      let init = initialvalue;
      init.min = parseFloat(data?.min);
      init.max = parseFloat(data?.max);
      init.offermargin = data?.offermargin;
      init.fixedmarketrate = data?.fixedmarketrate;
      init.offertimelimit = data?.offertimelimit;
      init.offerPricing = data?.offerpricing
      setInitialvalue(init);
    }
    catch(e){
      console.log("Error on handle step2" , e);
    }
  }

  const HandleStep3 = (data) => {
    try{
      let init = initialvalue;
      init.offertags = data?.offertags;
      init.yourofferlable = data?.oferlabel;
      init.offerterms = data?.offerterms;
      init.tradeinstruction = data?.instruction;
      init.verifiyid = data?.verifyid;
      init.verifiyfullname = data?.verifyname;
      init.singleoffertag = data?.singleoffertag;
      
      setInitialvalue(init);
    }
    catch(e){
      console.log("Error on handle step3" , e);
    }
  }




  
  return (
    <>
      <Container fluid className="common_spacer pb-2 position-relative">
        <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="home_banner">
            <div className="banner_content">
              <h1>Stablecoin </h1>
              <p>Largest Liquidity P2P Exchange</p>
              <button className="orange_btn mt-2">Sign Up Now</button>
            </div>
          </div>
          <h5 className="reg_stats_shower inter_font m-0 text-center text-uppercase mt-3">
            Create Offer to Sell Bitcoin
          </h5>

          <div className="conver_bottom_card position-relative rounded-2 py-3 px-2 px-sm-3 p-lg-4 p-xl-5 mb-5 mt-4">
            <div className="createOffer__progress_holder rounded-2 p-2 p-sm-3 position-relative">
              <div className="createOffer__progress_bar"></div>
              <Row className="justify-content-between">
                <Col
                  xs={3}
                  className="d-flex flex-column justify-content-center align-items-center position-relative"
                >
                  <img
                    src={require("../assets/images/quatprocess.svg").default}
                    className="img-fluid"
                  />
                  <p
                    className={`inter_font m-0 createOffer__hint_indicator text-center mt-2 ${
                      step == 1 ? "createOffer__hint_indicator_active" : ""
                    }`}
                  >
                    Payment Method
                  </p>
                </Col>
                <Col
                  xs={3}
                  className="d-flex flex-column justify-content-center align-items-center position-relative"
                >
                  <img
                    src={require("../assets/images/halfprocess.svg").default}
                    className="img-fluid"
                  />
                  <p
                    className={`inter_font m-0 createOffer__hint_indicator text-center mt-2 ${
                      step == 2 ? "createOffer__hint_indicator_active" : ""
                    }`}
                  >
                    Price
                  </p>
                </Col>
                <Col
                  xs={3}
                  className="d-flex flex-column justify-content-center align-items-center position-relative"
                >
                  <img
                    src={require("../assets/images/process.svg").default}
                    className="img-fluid"
                  />
                  <p
                    className={`inter_font m-0 createOffer__hint_indicator text-center mt-2 ${
                      step == 3 ? "createOffer__hint_indicator_active" : ""
                    }`}
                  >
                    Other settings
                  </p>
                </Col>
              </Row>
            </div>

{offerSec == 'paymentMethod' &&
           <CreateOfferPaymentMethod setOfferSec={setOfferSec} setStep={setStep} 
           Setdata = {(data) => HandleStep1(data)}
           defaultvalue = {initialvalue}
           /> }
{offerSec == 'tradePricing' &&
           <CreateOfferTradePricing setOfferSec={setOfferSec} setStep={setStep} defaultvalue = {initialvalue} 
           Setdata = {(data) => HandleStep2(data)}
           />}
{offerSec == 'tradeInstruct' &&
           <CreateOfferTradeInstructions setOfferSec={setOfferSec} setStep={setStep} defaultvalue = {initialvalue} 
           Setdata = {(data) => HandleStep3(data)}
           />}
          </div>
        </Container>
      </Container>
      <Footer/>
    </>
  );
};

export default CreateOffer;
