import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
// import { allData } from '../CommonData/Data'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Footer from '../Components/Footer'
import { GetUserOrderHooks } from '../Hooks/P2pHooks'
import { GetUserId } from '../Hooks/UserHooks'

function CreateOfferList() {
    const theme = useSelector((state) => state?.User?.theme);
    const [tableDetails, setTableDetails] = useState("buy");
    const [allData , setAlldata] = useState([]);
    const [limit , setLimit] = useState(10);
    const [page , setPage] = useState(1);
    const [count , setCount] = useState(0);
    const [disable , setDisable]= useState(false);

    const fetchdata = async() => {
      let payload= {
        id : GetUserId(),
        page : 1,
        limit : limit
      };
      let result = await GetUserOrderHooks(payload);
      setAlldata(result?.record);
      setPage(page+1)
      setCount(result?.count);
    }
    const LoadMore = async() => {
      if(allData?.length < count){
        setDisable(true);
        let payload= {
          id : GetUserId(),
          page : page,
          limit : limit
        };
        let result = await GetUserOrderHooks(payload);
        let newdata = allData;
        setAlldata(newdata.concat(result?.record));
        setPage(page+1);
        setDisable(false);
      }
    }
    useEffect(() => {
        window.scroll(0,0)
        fetchdata();
    },[])
  return (
   <>
   <Container fluid className="common_spacer pb-5 position-relative">
   <Header />
   <Container className="p-0 p-sm-2 custom_container">
   <div className="stable_boxContainer p-3 p-lg-4  p-xl-5">
    <Row className='justify-content-center justify-content-sm-between align-items-center '>
        <Col lg={4} md={5} sm={6} className='mb-4 mb-sm-0'>
            {/* <h5 className='inter_font m-0 offerList__title'>Buy Bitcoin (BTC) </h5>
            <p className='inter_font m-0 offerList__hint mt-3'>Buy or Sell Bitcoin from other users using any payment method and currency.</p> */}
        </Col>
        <Col lg={4} md={5} sm={6} className='d-flex justify-content-center justify-content-sm-end'>
            <NavLink to='/createOffer' className="header_links">
           <button className={`primary_btn  rounded-1 ${theme == 'dark' ? 'createOfferList_btn' : ''}`}>Create Offer <img src={require('../assets/images/rightLong.svg').default} className='img-fluid' /></button>
           </NavLink>
        </Col>
    </Row>

<div className='mt-5'>
    {allData?.length > 0 && allData?.map((item) => (
                  <div className="market_tablerView mb-4 p-3 d-flex justify-content-center justify-content-sm-between align-items-start align-items-sm-center flex-wrap gap-3 rounded-2">
                    <div className="market_tablerView_single d-flex justify-content-start mb-4">
                      <div>
                        <div className="market_table_username position-relative">
                     

                          <div className="table_userimg_holder">
                            {/* <div className="user_activity_holder position-relative">
                              <img
                                src={item.userImage}
                                className="img-fluid market_tv_userImg"
                              />
                              {tableDetails == "buy" && (
                                <div
                                  className={
                                    item.status
                                      ? "user_activity_online_indicator"
                                      : "user_activity_offline_indicator"
                                  }
                                ></div>
                              )}
                            </div> */}
                            <p className="market_tv_title inter_font text-center mb-2">
                       Order Id :
                      </p>
                            <p className="market_tbluser_name inter_font">
                              {item?.orderid}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="market_tablerView_single">
                      <p className="market_tv_title inter_font text-center mb-2">
                        Payment Method :
                      </p>
                      <button className="market_payment_btn rounded-5 inter_font">
                        {item?.paymentmethod[0]}
                      </button>
                    </div>
                    {/* <div className="market_tablerView_single">
                      <p className="market_tv_title inter_font text-center">
                        Price per 1 BTC :
                      </p>
                      <p className="market_table_intvalues inter_font text-center">
                        {item.price}
                      </p>
                      <div className="mt-2">
                        <p className="market_tv_title inter_font text-center">
                          Total Quantity :
                        </p>
                        <p className="market_table_intvalues inter_font text-center">
                          {item.quantity}
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="market_tablerView_single">
                     
                    </div> */}
                    <div className="market_tablerView_single mb-2">
                      <p className="market_tv_title inter_font text-center mb-2">
                        Purchase Limit :
                      </p>
                      <p className="market_table_intvalues inter_font text-center">
                      Min Purchase : <span>{item?.min} {item?.preferedcurrency}</span>
                      </p>
                      <p className="market_table_intvalues inter_font text-center">
                      Max Purchase : <span>{item?.max} {item?.preferedcurrency}</span>
                      </p>
                    </div>
                    <div className="market_tablerView_single mb-2">
                    <p className="market_tv_title inter_font text-center mb-2">
                        Order Type :
                      </p>
                        <p className='inter_font m-0 market_table_blur'>{item?.ordertype?.toUpperCase()}</p>
                        </div>

                        <div className="market_tablerView_single mb-2">
                        <p className="market_tv_title inter_font text-center mb-2">
                        Status :
                      </p>
                        <p className='inter_font m-0 market_table_blur'>{item?.offerstatus?.toUpperCase()}</p>
                        </div>

                    <div className="market_tablerView_single">                      
                          <button className="primary_btn">View</button>
                       
                    </div>
                  </div>
                ))}
                  {allData?.length < count && <button className="primary_btn" disable ={disable} onClick={LoadMore}>LoadMore</button>}

</div>
    </div>
   </Container>
   </Container>
   <Footer/>

   </>
  )
}

export default CreateOfferList