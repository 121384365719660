import React from 'react'
import { Modal } from 'react-bootstrap'

const TransactionCompletionModal = ({show,handleClose}) => {
  return (
    <>
    <Modal
    className="purchase_modal"
    centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
        <button
            className="cmn_modal_closer inter_font rounded-5"
            onClick={handleClose}
          >
            <i class="fa-solid fa-xmark" />
          </button>

          <div className='text-center'>
          <img
                  src={require("../assets/images/logo.svg").default}
                  className="img-fluid  "
                />

                <h6 className='m-0 inter_font reqTransactModal__title mt-4'>Your transaction has been<br/>
Successfully completed</h6>
                <h6 className='m-0 inter_font reqTransactModal__title reqTransactModal__ques mt-4'>Thank you</h6>

                <p className='m-0 inter_font reqTransacModal__desc mt-4'><span>Date of completion of transaction</span></p>
                <p className='m-0 inter_font reqTransacModal__desc mt-1'><span>2024-04-04  23:59:59</span></p>


                <div className='reqTransacModal_dualBtns mt-4 d-flex justify-content-center align-items-center gap-4'>
                    <button className='primary_btn inter_font rounded-1 ' onClick={() => {handleClose()}}>Confirm</button>
                </div>
          </div>
        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default TransactionCompletionModal