import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Components/Header";
import Select from "react-select";
import Countdown from "react-countdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoIosInformationCircleOutline } from "react-icons/io";
import MessageBox from "../Components/MessageBox";
import Footer from "../Components/Footer";
import { useSelector } from "react-redux";
import { GetSpecificOrderHooks } from "../Hooks/P2pHooks";
import RequestTransactionModal from "../Modals/RequestTransactionModal";
import TransactionCompletionModal from "../Modals/TransactionCompletionModal";

function MarketTransaction() {
  const   theme = useSelector((state) => state?.User?.theme);
  // modal states
  const [showTransactModal,setTransactModal] = useState(false)
  const handleCloseTransactModal = () => setTransactModal(false)
  const handleShowTransactModal = () => setTransactModal(true)


  const [showCompletionModal,setCompletionModal] = useState(false)
  const handleCloseCompletionModal = () => setCompletionModal(false)
  const handleShowCompletionModal = () => setCompletionModal(true)


  // end of modal states
  const [viewer,setViewer] = useState(false)
  const ref = useRef();
  const options = [
    {
      value: "outside",
      label: "Outside Escrow",
    },
    {
      value: "badOffer",
      label: "Bad Offer Terms",
    },
    {
      value: "scam",
      label: "Obvious Scam",
    },
    {
      value: "negotiation",
      label: "Negotiation",
    },
  ];
  const [selected, setSelected] = useState({
    value: "BNB",
    label: "BNB",
    img: require("../assets/images/BNB.png"),
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const Completionist = () => <span className="text-danger">Time Expired</span>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="inter_font">
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  const Completionist1 = () => (
    <span className="text-danger">Time Expired</span>
  );
  const renderer1 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist1 />;
    } else {
      return (
        <span className="inter_font">
          {minutes}&nbsp;:&nbsp;{seconds}
        </span>
      );
    }
  };
  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
      border:'none'
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : theme == 'dark' ? '#181818' : '#fff',
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : theme == 'dark' ? "#fff" : '#000',
        fontSize: "13px",
        border:'none'
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      border: "none",
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == 'dark' ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  useEffect(() => {
    fetchdata()
    window.scroll(0, 0);
  }, []);

  const fetchdata = async() => {
    try{
      let result = await GetSpecificOrderHooks({orderid : window.location.href.split("/")[4]})
    }
    catch(e){
      console.log("Error on fetchdata" , e);
    }
  }
  return (
    <>
      <Container fluid className="common_spacer pb-5">
          <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="mt_selects_holder ">
            <Row>
              <Col
                lg={4}
                md={6}
                sm={12}
                className="d-flex flex-column flex-sm-row  justify-content-start align-items-start align-items-sm-center gap-0 gap-md-3"
              >
                <p className="mt_selects_label inter_font mt-2">Token Type</p>
                <div className="market_coinname_select position-relative w-100">
                 
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint market_tv_drophint"
                  />
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className="d-flex flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center gap-0 gap-md-3"
              >
                <p className="mt_selects_label inter_font mt-2">Pay Via</p>
                <div className="market_coinname_select position-relative w-100">
                <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint market_tv_drophint"
                  />
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className="d-flex flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center gap-0 gap-md-3"
              >
                <p className="mt_selects_label inter_font mt-2">
                  Currency by Country
                </p>
                <div className="market_coinname_select position-relative w-100">
                <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint market_tv_drophint"
                  />
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt_transaction_counter  mt-2">
            <Row className="">
              <Col
                lg={6}
                md={6}
                sm={12}
                className="d-flex justify-content-center justify-content-md-start align-items-center gap-2 gap-sm-2 gap-lg-5 py-3 ps-4 ps-md-5"
              >
                <h6 className="mt_usdt_leftHead inter_font">
                  USDT(TRC20) Purchase
                </h6>
                <div className="mt_date_countdown">
                  <p className="mt_date_count_title inter_font">
                    Start of Trading
                  </p>
                  <p className="mt_tiny_datetimer inter_font">
                    2023/12/30{" "}
                    <span className="ms-2 ms-sm-3 ms-lg-5">08:13:45</span>
                  </p>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="d-flex justify-content-center justify-content-md-end align-items-center gap-2 ps-4 ps-md-0 pe-2 pe-md-5"
              >
                <p className="mt_date_count_title inter_font">
                  Trade ID: 123456789213
                </p>
                <CopyToClipboard onCopy={() => console.log("copycopycopu")}>
                  <p className="d-flex align-items-center mb-0">
                    <img
                      src={require("../assets/images/whitecopy.svg").default}
                      alt="copy"
                      className="mt_whitecopy_img"
                    />
                  </p>
                </CopyToClipboard>
                <button className="primary_btn ms-0 ms-md-4 mt_report_prblmBtn">
                  Report a Problem
                </button>
              </Col>

              <Col lg={6} className="pe-lg-1">
                <div className="mt_redgradient_box mt-3 mt-md-0 py-3 d-flex justify-content-start align-items-center">
                  <button className="mt_coin_batch me-2 me-md-0 rounded-2 p-1 inter_font">
                    <img
                      src={require("../assets/images/BNB.png")}
                      className="img-fluid me-1 me-sm-2"
                    />
                    BTC
                  </button>
                  <h6 className="mt_usdt_leftHead inter_font flex-grow-1 text-center">
                    Seller Transaction Information
                  </h6>
                </div>

                <div className="mt_seller_transact_side">
                  <Row>
                    <Col xl={4} lg={6} md={4} sm={4} xs={6}>
                      <div className="mt_seller_border_box d-flex justify-content-start align-items-center mb-3">
                        <div className="mt_seller_transDtls d-flex justify-content-start align-items-center gap-2 p-2 rounded-start">
                          <img
                            src={require("../assets/images/person.svg").default}
                            className="img-fluid mt_seller_personImg"
                          />
                          <p className="mt_date_count_title inter_font">
                            Seller
                          </p>
                        </div>
                        <div className="mt_seller_flagHolder d-flex justify-content-center align-items-center p-2 rounded-end">
                          <img
                            src={require("../assets/images/flag.svg").default}
                            className="img-fluid"
                          />
                        </div>
                      </div>

                      <div className="mt_seller_indication_dtls d-flex justify-content-end align-items-center ">
                        <div className="mt_seller_indication_holder p-2 d-flex justify-content-center align-items-center">
                          <div className="mt_Seller_indication_hint rounded-5"></div>
                        </div>
                        <div className="mt_seller_border_box d-flex justify-content-center align-items-center w-100">
                          <div className="mt_seller_transDtls d-flex justify-content-start align-items-center gap-2 p-2 rounded-start">
                            <p className="mt_date_count_title inter_font">
                              Vendor
                            </p>
                          </div>
                          <div className="mt_seller_flagHolder d-flex justify-content-center align-items-center p-2 rounded-end">
                            <img
                              src={
                                require("../assets/images/vendor.svg").default
                              }
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xl={4} lg={6} md={4} sm={4} xs={6}>
                      <div className="mt_seller_border_box d-flex justify-content-start align-items-center mb-3">
                        <div className="mt_seller_transDtls d-flex justify-content-start align-items-center p-2 rounded-2 w-100">
                          <p className="mt_date_count_title inter_font">
                            John Doe
                          </p>
                        </div>
                      </div>

                      <div className="mt_seller_indication_dtls d-flex justify-content-start align-items-center">
                        <div className="mt_seller_border_box w-100 d-flex justify-content-center align-items-center">
                          <div className="mt_seller_transDtls d-flex justify-content-start align-items-center gap-2 p-2 rounded-start">
                            <p className="mt_date_count_title inter_font">
                              Receipt <br />
                              Required
                            </p>
                          </div>
                          <div className="mt_seller_flagHolder d-flex justify-content-center align-items-center p-2 rounded-end">
                            <img
                              src={
                                require("../assets/images/vendor.svg").default
                              }
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col
                      xl={4}
                      lg={12}
                      md={4}
                      sm={4}
                      xs={12}
                      className="mt-3 mt-sm-0 mt-lg-3 mt-xl-0"
                    >
                      <p className="mt_date_count_title inter_font">
                        Payment method
                      </p>
                      <button className="mt_seller_bank_remit rounded-2 mt-2 inter_font">
                        Bank Remittance
                      </button>
                    </Col>
                  </Row>

                  <Row className="mt-3 justify-content-between">
                    <Col xl={4} lg={6} md={6} sm={6}>
                      <p className="mt_date_count_title inter_font">
                        Price per 1 BTC
                      </p>
                      <button className="mt_seller_grayBtn rounded-2 w-100 p-2 mt-1 inter_font">
                        56,351,423.54 KRW
                      </button>
                      <p className="mt_date_count_title inter_font mt-3">
                        Total quantity
                      </p>
                      <button className="mt_seller_grayBtn rounded-2 w-100 p-2 mt-1 inter_font">
                        56,351,423.54 KRW
                      </button>
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={6} className="mt-3 mt-sm-0">
                      <button className="secondary_btn_div rounded-2">
                        Transaction Limit
                      </button>

                      <button className="mt_seller_grayBtn rounded-2 w-100 p-2 mt-1 inter_font mt-2">
                        <div className="d-flex justify-content-between align-items-start">
                          <p className="mt_date_count_title inter_font">
                            Min Sale
                          </p>
                          <p className="mt_date_count_title inter_font">
                            3,000,000,000 KRW
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-start mt-3">
                          <p className="mt_date_count_title inter_font">
                            Min Sale
                          </p>
                          <p className="mt_date_count_title inter_font">
                            3,000,000,000 KRW
                          </p>
                        </div>
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="ps-lg-1">
                <div className="mt_greengradient_box d-flex justify-content-center align-items-center">
                  <h6 className="mt_usdt_leftHead inter_font text-center">
                    Transaction quantity registration
                  </h6>
                </div>
                <div className="mt_seller_quantity_side">
                  <Row className="align-items-end">
                    <Col lg={6} md={6}>
                      <div className="mt_seller_quanlabels_holder">
                        <p className="mt_date_count_title inter_font text-start text-lg-end mt-2">
                          <span className="mt_seller_qunalabels_span inter_font">
                            USDT{" "}
                          </span>
                          holding quantity
                        </p>
                        <p className="mt_seller_quanlabels_bold inter_font text-start text-lg-end mt-2">
                          1,000,000,000,000 USDT
                        </p>
                        <p className="inter_font text-start text-lg-end mt_seller_quanlabels_primary mt-2">
                          1,000,000,000 KRW
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} md={6} className="mt-4 mt-md-0">
                      <div class="mt_seller_border_box d-flex justify-content-end align-items-center mb-2">
                        <div class="mt_seller_transDtls p-2 d-flex justify-content-center align-items-center rounded-start rounded-start">
                          <input
                            type="number"
                            placeholder="1,000,000,000"
                            className="mt_seller_trans_input inter_font"
                          />
                        </div>

                        <div class="mt_seller_flagHolder d-flex justify-content-center align-items-center p-2 rounded-end">
                          <p className="mt_seller_qunalabels_whitespan inter_font">
                            USDT{" "}
                          </p>
                        </div>
                      </div>
                      <p className="mt_seller_transdtls_hint inter_font">
                        <IoIosInformationCircleOutline className="me-2" />
                        Enter the amount you want to buy
                      </p>
                    </Col>
                  </Row>
                  <Row className="align-items-end mt-5">
                    <Col lg={6} md={6}>
                      <div className="mt_seller_quanlabels_holder">
                        <p className="mt_date_count_title inter_font text-start text-lg-end  mt-2">
                          <span className="mt_seller_qunalabels_span inter_font">
                            USDT{" "}
                          </span>
                          Available quantity for sale
                        </p>
                        <p className="mt_seller_quanlabels_bold inter_font text-start text-lg-end  mt-2">
                          1,000,000,000,000 USDT
                        </p>
                        <p className="inter_font text-start text-lg-end  mt_seller_quanlabels_primary mt-2">
                          1,000,000,000 KRW
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} md={6} className="mt-4 mt-md-0">
                      <div class="mt_seller_border_box d-flex justify-content-end align-items-center mb-2">
                        <div class="mt_seller_transDtls p-2 d-flex justify-content-center align-items-center rounded-start">
                          <input
                            type="number"
                            placeholder="1,000,000,000"
                            className="mt_seller_trans_input inter_font"
                          />
                        </div>

                        <div class="mt_seller_flagHolder d-flex justify-content-center align-items-center p-2 rounded-end">
                          <p className="mt_seller_qunalabels_whitespan inter_font">
                            KRW{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>

          <Row className="justify-content-center">
            <Col xs={12}>
              <button className="primary_btn w-100 position-relative index_one" onClick={() => {handleShowTransactModal()}} >Request transaction</button>
            </Col>
          </Row>

{viewer ? 
          <div className="mt_transaction_counter px-2 p-3 rounded-5">
            <Row>
              <Col xl={5} lg={12} md={12} className="mb-4 mb-xl-0">
                <Row className="justify-content-md-center">
                  <Col xl={4} lg={3} md={3} sm={4}>
                    <div className="mt_deposit_div rounded-2 d-flex justify-content-center align-items-center">
                      <h6 className="inter_font text-center">
                        Confirm Deposit
                      </h6>{" "}
                    </div>
                  </Col>
                  <Col xl={8} lg={6} md={6} sm={8}>
                    <p className="mt_seller_quanlabels_bold inter_font text-start mt-1">
                      Coin type : Bitcoin
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start mt-1">
                      Coin price: 3,000,000,000 USD
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start mt-1">
                      Sales quantity: 3,000,000,000 BTC
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start mt-1">
                      Sales fee: 10%
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start mt-1">
                      Actual sales quantity : 2,000,000,000 BTC
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start mt-1">
                      Actual deposit amount : 9,000,000,000 KRW
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={2}
                lg={12}
                className="d-flex justify-content-center align-items-center mb-4 mb-xl-0"
              >
                <div>
                  <div className="mt_timermid_countdown ">
                    <Countdown
                      date={Date.now() + 3000000}
                      renderer={renderer1}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <p className="mt_date_count_title inter_font">Minutes</p>
                    <p className="mt_date_count_title inter_font">Seconds</p>
                  </div>
                </div>
              </Col>
              <Col xl={5} lg={12} md={12} className="mb-4 mb-xl-0">
                <Row className="flex-column-reverse flex-sm-row justify-content-md-center">
                  <Col xl={8} lg={6} md={6} sm={8}>
                    <p className="mt_seller_quanlabels_bold inter_font text-start  mt-1">
                      Coin type : Bitcoin
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start  mt-1">
                      Coin price: 3,000,000,000 USD
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start  mt-1">
                      Sales quantity: 3,000,000,000 BTC
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start  mt-1">
                      Sales fee: 10%
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start  mt-1">
                      Actual sales quantity : 2,000,000,000 BTC
                    </p>
                    <p className="mt_seller_quanlabels_bold inter_font text-start  mt-1">
                      Actual deposit amount : 9,000,000,000 KRW
                    </p>
                  </Col>
                  <Col
                    xl={4}
                    lg={3}
                    md={3}
                    sm={4}
                    className="d-flex justify-content-end"
                  >
                    <div className="mt_remitt_div rounded-2 d-flex justify-content-center align-items-center">
                      <h6 className="inter_font text-center">
                        Complete Remittance
                      </h6>{" "}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <MessageBox />
          </div>
:
          <div className="mt_transactionGuideHolder position-relative mt-5">
            <img src={require('../assets/images/bigBlur.png')} className="img-fluid mt_guideBlur" alt="" />
              <h6 className="m-0 inter_font mt_transactionGuideTitle text-center text-uppercase position-relative">Transaction Guide</h6>
            <Row className="mt-5">
              <Col lg={8}>
                    <ol className="mt_transactionGuidOl position-relative">
                      <li className="inter_font mb-3"> Start a deal with the seller. Chat with the seller.</li>
                      <li className="inter_font mb-3">Send the exact coin purchase amount to the seller account  information within 3 minutes of the transaction.</li>
                      <li className="inter_font mb-3">If you request a remittance certificate from the seller - Send a remittance
      certificate in the chat window (Type of payment method - remittance account 
      screenshot file and video)</li>
                      <li className="inter_font mb-3">Warning during transaction - Transaction obstruction security system will 
      automatically suspend transactions for 30 days if you cancel transactions
      more than three times within 10 minutes.</li>
                    </ol>
              </Col>
              <Col lg={3}>
                  <img src={require('../assets/images/louder.svg').default} className="img-fluid" />
              </Col>
            </Row>
          </div>
          }


        </Container>
      </Container>
      <Footer />

      {/* modals */}
<RequestTransactionModal show = {showTransactModal} handleClose = {handleCloseTransactModal} setViewer={setViewer}  />
<TransactionCompletionModal show={showCompletionModal} handleClose={handleCloseCompletionModal}/>
      {/* end of modals */}
    </>
  );
}

export default MarketTransaction;
