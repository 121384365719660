import React, { useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StoreRegistrationCard from './StoreRegistrationCard';
import SaleSettlementCard from './SaleSettlementCard';


const SaleSettlement = () => {
  const theme = useSelector((state) => state?.User?.theme);
  const currencyRef = useRef();
  const [closer, setCloser] = useState();
  const ref = useRef()
  const productRef = useRef()
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const options = [
    {
      value: "BNB",
      label: "BNB",
      img: require("../assets/images/BNB.png"),
    },
    {
      value: "bnb",
      label: "bnb",
      img: require("../assets/images/bnb.png"),
    },
    {
      value: "Cron",
      label: "Cron",
      img: require("../assets/images/CRON.png"),
    },
    {
      value: "Crons",
      label: "Crons",
      img: require("../assets/images/CRONS.png"),
    },
    {
      value: "Polygon",
      label: "Polygon",
      img: require("../assets/images/polygon.png"),
    },
  ];

  const options1 = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "normalSales",
      label: "Normal Sales",
    },
    {
      value: "refusalSale",
      label: "Refusal to Sale",
    },
    {
      value: "blockNumber",
      label: "Block Number",
    },
  ];

  const options2 = [
    {
      value: "electronics",
      label: "Electronics",
    },
    {
      value: "sales",
      label: "Sales",
    },
    {
      value: "medicines",
      label: "Medicines",
    }
  ];
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "",
    label: "",
    img: theme == 'dark' ? require("../assets/images/searchglass.svg").default : require('../assets/images/blacksearch.svg').default,
  });

  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedCurrency({ label, value, img: data.img });
        currencyRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const [selected, setSelected] = useState({
    value: "all",
    label: "All",
  });

  const [selectedProduct, setSelectedProduct] = useState({
    value: "electonics",
    label: "Electonics",
  });

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "30px",
      border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == 'dark' ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const styles1 = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      border: "none",
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color:theme == 'dark' ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <>
     <>
    <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-3 p-lg-4'>
    <Row className='h-100'>
       <Col lg={4} md={6}>
        <Row>
          <Col lg={6} className='mb-3'>
            <div className='ss__staticSearchHolder p-2 rounded-2 d-flex justify-content-start align-items-center gap-2'>
              <img src={require('../assets/images/searchglass.svg').default} className='img-fluid ss_searchGlass' alt='' />
              <input type='text' className='ss_staticSearch inter_font flex-grow-1' placeholder='Search by address' />
            </div>
          </Col>
          <Col lg={6} className='mb-3'>
          <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency.img}
                    className="img-fluid market_coinname_img  sd_store_searchImg"
                  />
                 
                  <Select
                    className=""
                    defaultValue={selectedCurrency}
                    onChange={selectedCurrency}
                    options={options}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
          </Col>
          <Col lg={6} className='mb-3'>
          <Select
                    className=""
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options1}
                    value={selected}
                    ref={ref}
                    styles={styles1}
                    placeholder=""
                  />
          </Col>

          <Col lg={6} className='mb-3'>
          <Select
                    className=""
                    defaultValue={selectedProduct}
                    onChange={setSelectedProduct}
                    options={options2}
                    value={selectedProduct}
                    ref={productRef}
                    styles={styles1}
                    placeholder=""
                  />
          </Col>
        </Row>
       </Col>

       <Col lg={8} md={6}>
        <div className='ss__gridHolder'>
          <div className='ss_singleGrid'>
          <button className="st_store_yellowbtn ss_yellowBtn inter_font rounded-2">
                  Total Balance
                </button>

                <p className='m-0 inter_font ss_gridPrimeValue text-center mt-2'>100,000,000 USDT</p>
                <p className='m-0 inter_font ss_gridRedValue text-center'>3,000,000,000 KRW</p>
          </div>

          <div className='ss_singleGrid'>
          <button className="ss_whiteOulineBtn ss_yellowBtn inter_font rounded-2">
          USDT (TRC20)
                </button>

                <p className='m-0 inter_font ss_gridPrimeValue text-center mt-2'>100,000,000 USDT</p>
                <p className='m-0 inter_font ss_gridRedValue text-center'>3,000,000,000 KRW</p>
          </div>
          <div className='ss_singleGrid'>
          <button className="ss_whiteOulineBtn ss_yellowBtn inter_font rounded-2">
          USDT (TRC20)
                </button>

                <p className='m-0 inter_font ss_gridPrimeValue text-center mt-2'>100,000,000 USDT</p>
                <p className='m-0 inter_font ss_gridRedValue text-center'>3,000,000,000 KRW</p>
          </div>
          <div className='ss_singleGrid'>
          <button className="ss_whiteOulineBtn ss_yellowBtn inter_font rounded-2">
          USDT (TRC20)
                </button>

                <p className='m-0 inter_font ss_gridPrimeValue text-center mt-2'>100,000,000 USDT</p>
                <p className='m-0 inter_font ss_gridRedValue text-center'>3,000,000,000 KRW</p>
          </div>
          <div className='ss_singleGrid'>
          <button className="ss_whiteOulineBtn ss_yellowBtn inter_font rounded-2">
          USDT (TRC20)
                </button>

                <p className='m-0 inter_font ss_gridPrimeValue text-center mt-2'>100,000,000 USDT</p>
                <p className='m-0 inter_font ss_gridRedValue text-center'>3,000,000,000 KRW</p>
          </div>
        </div>
       </Col>

       
    </Row>

    <Row className='justify-content-between align-items-start mt-5'>
    <Col lg={4} md={5} sm={8} className='mb-3 mb-sm-0'>
        <div className='ss__calendarHolder d-flex justify-content-start align-items-center'>
          <div className='ss_timeBadge py-2 px-4'>
            <p className='m-0 inter_font ss__timeBadgeTime text-center'><span>*</span>Time</p>
          </div>
          <div className="deposit_custom_datepoicker ss_calendar rounded-2 d-flex justify-content-start align-items-center px-2 position-relative">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                // monthsShown={2}
              />
              <img src={require('../assets/images/white_calendar.svg').default} className={`img-fluid ${theme == 'dark' ? 'deposit_calImg' : 'deposit_calImgInvert'}`} /> 
            </div>

        </div>
       </Col>
       <Col lg={3} md={4} sm={4} className='d-flex justify-content-end align-items-start'>
        <button className='primary_btn inter_font rounded-2'>Withdrawal</button>
       </Col>
    </Row>

<div className='mt-4'>
    <SaleSettlementCard/>
    </div>

    </div>
    </>
    
    </>
  )
}

export default SaleSettlement