import React from 'react'
import { Container } from 'react-bootstrap'
import Header from '../Components/Header'
import Withdraw from '../Components/Withdraw'
import Footer from '../Components/Footer'

function WithDrawPage() {
  return (
    <>
    <Container fluid className='common_spacer'>
        <Header/>
        <Container className='custom_container  p-0 p-sm-2'>
        <div className="box_container p-2 p-sm-3 p-md-4 p-lg-5 mt-3">
        <Withdraw />
            </div>
        </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default WithDrawPage