import React, { useEffect, useState } from 'react'
import {Col, Modal, Row} from 'react-bootstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Disable2faCodeHooks, Get2faCodeHooks, GetUserId, Update2faCodeHooks } from '../Hooks/UserHooks';

function SecurityGoogleAuth({show,handleClose}) {
    const theme = useSelector((state) => state?.User?.theme);
    const [step,setStep] = useState('stepOne');
    const [stepOneStatus,setStepOneStatus] = useState(false)
    const [stepTwoStatus,setStepTwoStatus] = useState(false)
    const [stepThreeStatus,setStepThreeStatus] = useState(false)
    const [stepFourStatus,setStepFourStatus] = useState(false)

    //functionality
    const [secretData , setSecretData] = useState({});
    const [code , setCode] = useState();
    const [checkValue, setCheckValue] = useState(false)


    const fetchTwoFA = async () => {
        try {
            const result = await Get2faCodeHooks({userid : GetUserId()});
            if (result?.type == 'Success') {
                setSecretData(result?.record)
            }
        } catch (err) { }
    }

    const disableTwoFa = async (data) => {
        let reqData = {
            "secret": secretData.secret,
            "uri": secretData.uri,
            "code": code,
            "userid" :GetUserId()
        }
        try {
            const result = await Disable2faCodeHooks(reqData);
            if (result?.type == 'Success') {
                toast.success("Disable 2fa successfully")
                setCode('')
                fetchTwoFA();
                // setSecretData(result?.record)
            } else {
                
            }
        } catch (err) { }
    }

    const enableTwoFa = async () => {
        let reqData = {
            "secret": secretData.secret,
            "uri": secretData.uri,
            "code": code,
            "checkValue": checkValue,
            "userid" : GetUserId()
        }
        try {
            const result = await Update2faCodeHooks(reqData);
           
            if (result?.type == 'Success') {
                toast.success('2fa enabled Successfully')
                setCode('')
                fetchTwoFA();
                // setSecretData(result)
            } else {
               
            }
        }
        catch (err) { }
    }

   



    useEffect(() => {
        fetchTwoFA()
    }, [])
 
   console.log("secretData" , secretData);
  return (
    <Modal size='lg'
    centered
    className="purchase_modal"  
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
         <button
            className="cmn_modal_closer rounded-5"
            onClick={handleClose}
          >
            <i class="fa-solid fa-xmark" />
          </button>
        <Modal.Body>
            <h6 className='security_modal_titles inter_font'><i class="fa-solid fa-arrow-left-long me-2" /> Modify Google Authenticator</h6>
          
          <div className='sm_stepper_holder'>
            
          </div>

<Row className='mt-5 justify-content-center'>
  <Col lg={4}>
    {secretData && <img src={secretData && secretData.imageUrl} className={`img-fluid reg_qrcoder ${theme == 'dark' ? '' : 'reg_qrcoder_filter'}`} />}
    </Col>  
    <Col lg={6}>
        <div className='cmnModal__securiyCode'>
            <p className='inter_font m-0 sm_inputs_label'>Your 32 digit Security Code</p>
            <div className='cmnModal__securityCodeCopier w-100 d-flex justify-content-between align-items-center gap-2 p-2 rounded-1 mt-2'>
            <p className='m-0 inter_font secrutiry__address'>{secretData && secretData.secret}</p>

                <CopyToClipboard text={secretData && secretData.secret} onCopy={() => toast.success("Copied")}>
                  <p className="d-flex align-items-center mb-0">
                    <img
                      src={require("../assets/images/whitecopy.svg").default}
                      alt="copy"
                      className={`${theme == 'dark' ? 'mt_whitecopy_img' : 'mt_whitecopy_img_invert'}`}
                    />
                  </p>
                </CopyToClipboard>
            </div>
        </div>

                      <div className='cmnModal__securiyCode mt-3'>
                          <p className='inter_font m-0 sm_inputs_label'>Enter 6 digit 2FA Code</p>

                          <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex justify-content-between align-items-center gap-3'>
                              <input type='number' className='inter_font flex-grow-1' placeholder='Enter 6 digit 2FA Code'
                              value={code} 
                              onChange={(e) => setCode(e?.target?.value)}
                              />
                          </div>
                      </div>
                      {secretData?.twoFaStatus == "enabled" && <div className='text-center mt-4'>
                          <button className='primary_btn inter_font rounded-2'
                          disabled={!code}
                          onClick={disableTwoFa}
                          >Disable 2FA</button>
                      </div>}

                      {secretData?.twoFaStatus == "disabled" && <div className='text-center mt-4'>
                          <button className='primary_btn inter_font rounded-2' disabled={!code}
                          onClick={enableTwoFa}
                          >Enable 2FA</button>
                      </div>}
    </Col>
</Row>

         

        </Modal.Body>
      
      </Modal>
  )
}

export default SecurityGoogleAuth