import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Header from "../Components/Header";
import { useSelector } from "react-redux";
import Footer from "../Components/Footer";
import { NavLink } from "react-router-dom";

function StayLogged() {
  const theme = useSelector((state) => state?.User?.theme);
  return (
    <>
      <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
        <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
            <div className="banner_content">
              <h1>Stablecoin </h1>
              <p>Largest Liquidity P2P Exchange</p>
              <button className="orange_btn mt-2">Sign Up Now</button>
            </div>
          </div>
        </Container>
      </Container>

      <h5 className="reg_stats_shower inter_font m-0 text-center">
        Welcome to Otex!
      </h5>

      <div className="reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3">
        <img
          src={require("../assets/images/greenLock.svg").default}
          className="img-fluid"
        />
        <p className="inter_font m-0 mt-1">
          URL verification: https://accounts.otex.com
        </p>
      </div>

      <Container fluid className="common_spacer pb-5 position-relative">
        <img
          src={require("../assets/images/fish_blurball.png")}
          className={theme == "dark" ? "inner_fishblur" : "d-none"}
        />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="conver_bottom_card position-relative rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4">
            <p className="mailver_backBtn inter_font m-0">&lt; Back</p>
            <Row className="align-items-center justify-content-between mt-2">
              <Col lg={7} md={7} sm={12} className="mb-4 mb-md-0">
                <h5 className="creacteacc_title m-0 inter_font mt-3 mt-lg-0">
                  Stay Logged In
                </h5>
                <p className="mailver_hint inter_font m-0 mt-3">
                  By clicking ‘Yes’, you can stay logged in for up to 5 days on
                  this device. To revoke your logged in status, log out of your
                  Otex account on this device.
                </p>
                <div className="login_check_holder d-flex justify-content-start align-items-center gap-2 mt-4">
                  <Form>
                    <Form.Check type="checkbox">
                      <Form.Check.Input type="checkbox" isValid />
                    </Form.Check>
                  </Form>
                  <span className="createacc_checklabel inter_font m-0">
                    Don’t show this message again on this device
                  </span>
                </div>
                <NavLink className="header_links" to='/'>
                <button className="primary_btn w-100 mt-4">Yes</button>
                </NavLink>
                <NavLink className="header_links" to='/'>
                <div className="reg_google_auth px-3 rounded-2 position-relative mt-3">
                  <p
                    className={`reg_googleText  inter_font m-0 text-center ${
                      theme == "dark" ? "white_text" : "black_text"
                    }`}
                  >
                    Not Now
                  </p>
                </div>
                </NavLink>
              </Col>

              <Col lg={4} md={5} sm={12}>
                <img
                  src={require("../assets/images/regCrypto.svg").default}
                  className="img-fluid"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default StayLogged;
