import React, { Fragment, useState } from "react";
import { FiEye } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import { LuHistory } from "react-icons/lu";
import ReactDatatable from "@ashvin27/react-datatable";
import bdr_table from "../assets/images/cmn_tabs/table_bdr.png";
import coinlogo from "../assets/images/cmn_tabs/usdt.png";
import { CiSearch } from "react-icons/ci";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Spot() {
  const [activetab, SetActiveTab] = useState("crypto");

  const handleTab = (val) => {
    SetActiveTab(val);
  };

  return (
    <div className="assets spot">
      <Row>
        <Col xl={5} lg={5} md={6} className="d-flex justify-content-center mb-3">
        <div className="total_bal">
            <p className="mb-0 balance">
              Total Balance{" "}
              <span className="eye_icon ms-2">
                <FiEye />
              </span>
            </p>
            <div>
              <Dropdown className="custom_usd_drop">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  117.6074237 USDT
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    129.6074237 USDT
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    346.6074237 USDT
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    872.6074237 USDT
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="sub_drop">
                <Dropdown className="">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    = $117.61
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">= $117.61</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">= $117.61</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">= $117.61</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
        
            <Col xl={4} lg={4} md={6} className="d-flex justify-content-center mb-3">
            <div className="autotrade_pnl">
            <div className="fst_sec">
              <div className="pnl">
                <span className="auto inter_font">AutoTrade PnL</span>
                {/* <a href="" className="analysis">
                  <span className="inter_font">PnL analysis &gt;</span>
                </a> */}
              </div>
              <h4 className="mb-0 inter_font spot_usdt_value">0USDT</h4>
              <span className="val inter_font">= $0</span>
            </div>
            </div>
            </Col>
            <Col xl={3} lg={3} className="d-flex justify-content-end">
            <div className="autotrade_pnl">
            <div className="snd_sec d-flex align-items-center">
              <span className="trans_his inter_font">
                <LuHistory /> Transaction history
              </span>
            </div>
            </div>
            </Col>
         
      </Row>

      <div className="custom_tabs_sec mt-5">
        <span
          className={activetab == "crypto" ? "active" : "inactive"}
          onClick={() => handleTab("crypto")}
        >
          Crypto
        </span>
        <span
          className={activetab == "store" ? "active ms-2" : "inactive ms-2"}
          onClick={() => handleTab("store")}
        >
          Store
        </span>
      </div>

      {activetab == "crypto" ? (
        <>
          <div className="custom_spot_table custom_deposit_table">
            <div className="custom_fields mt-3">
              <div className="table_custom_header_add">
                <div className="header_collec">
                  <Row className="align-items-center">
                    <Col
                      xxl={3}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      className="mb-4 mb-md-0"
                    >
                      <div className="search_sec position-relative">
                        <input type="text" placeholder="Search" />
                        <CiSearch className="search_icon" />
                      </div>
                    </Col>
                    <Col
                      xxl={3}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      className="mb-4 mb-md-0"
                    >
                      <div className="check_sec d-flex align-items-center">
                        <div class="form-group">
                          <input type="checkbox" id="html" className="" />
                          <label for="html" className="inter_font">
                            Hide Zero-balance assets
                          </label>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xxl={3}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      className="mb-4 mb-md-0"
                    >
                      <div className="convert_sec d-flex align-items-center">
                        <FaArrowRightArrowLeft className="arrow_icon me-2" />
                        <span className="inter_font">
                          Convert small amount to BGB
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <img src={bdr_table} alt="border" className="img-fluid" />
            </div>

            <Row className="py-3 spot_table_singlerow mb-3 rounded-2">
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Coin :
                </h6>
                <div className="d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
                  <img
                    src={require("../assets/images/tehther.svg").default}
                    className="img-fluid spot_table_img"
                  />
                  <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                    USDT
                  </p>
                </div>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Total balance :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  117.60744237 <br /> = $117.61
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Available :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  117.60744237
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Reserved :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  0.000000000
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Today's Pnl :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  $0.00
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Action :
                </h6>
                <div className="action_datas d-flex justify-content-center justify-content-sm-start gap-2 gap-xl-1">
                  <NavLink className='header_links' to='/deposit'><span className="depo_with inter_font"> Deposit</span></NavLink>
                  <NavLink className='header_links' to='/withdraw'><span className="depo_with inter_font">Withdraw</span></NavLink>
                  <NavLink className='header_links' to=''><span className="history inter_font">History</span></NavLink>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div className="custom_spot_table custom_deposit_table">
            <div className="custom_fields mt-3">
              <div className="table_custom_header_add">
                <div className="header_collec">
                  <Row className="align-items-center">
                    <Col
                      xxl={3}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      className="mb-4 mb-md-0"
                    >
                      <div className="search_sec position-relative">
                        <input type="text" placeholder="Search" />
                        <CiSearch className="search_icon" />
                      </div>
                    </Col>
                    <Col
                      xxl={3}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      className="mb-4 mb-md-0"
                    >
                      <div className="check_sec d-flex align-items-center">
                        <div class="form-group">
                          <input type="checkbox" id="html" className="" />
                          <label for="html" className="inter_font">
                            Hide Zero-balance assets
                          </label>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xxl={3}
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      className="mb-4 mb-md-0"
                    >
                      <div className="convert_sec d-flex align-items-center">
                        <FaArrowRightArrowLeft className="arrow_icon me-2" />
                        <span className="inter_font">
                          Convert small amount to BGB
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <img src={bdr_table} alt="border" className="img-fluid" />
            </div>

            <Row className="py-3 spot_table_singlerow mb-3 rounded-2">
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Coin :
                </h6>
                <div className="d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
                  <img
                    src={require("../assets/images/tehther.svg").default}
                    className="img-fluid spot_table_img"
                  />
                  <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                    USDT
                  </p>
                </div>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Total balance :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  117.60744237 <br /> = $117.61
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Available :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  117.60744237
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Reserved :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  0.000000000
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Today's Pnl :
                </h6>
                <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                  $0.00
                </p>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} className="mb-3 mb-xl-0">
                <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
                  Action :
                </h6>
                <div className="action_datas d-flex justify-content-center justify-content-sm-start gap-2 gap-xl-1">
                  <span className="depo_with inter_font"> Deposit</span>
                  <span className="depo_with inter_font">Withdraw</span>
                  <span className="history inter_font">History</span>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default Spot;
