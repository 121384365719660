import React, { useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import { allData } from '../CommonData/Data';

const RegisterProduct = () => {
    const theme = useSelector((state) => state?.User?.theme);
    const [selectedFlag, setSelectedFlag] = useState("");
    const [closer, setCloser] = useState();
    const [productTab,setProductTab] = useState(0)
    const ref = useRef();
    const currencyRef = useRef();
    const [selected,setSelected] = useState({
        value: "electronics",
      label: "Electronics",
      })

      const customCurrencyRenderer = ({ label, data, value }) => (
        <div
          className="market_select_component"
          onClick={() => {
            setSelectedCurrency({ label, value, img: data.img });
            currencyRef.current.blur();
            setCloser(!closer);
          }}
        >
          {console.log("sdhguisd", label, data, value)}
          <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
          {label}
        </div>
      );

      const options1 = [
        {
          value: "BNB",
          label: "BNB",
          img: require("../assets/images/BNB.png"),
        },
        {
          value: "bnb",
          label: "bnb",
          img: require("../assets/images/bnb.png"),
        },
        {
          value: "Cron",
          label: "Cron",
          img: require("../assets/images/CRON.png"),
        },
        {
          value: "Crons",
          label: "Crons",
          img: require("../assets/images/CRONS.png"),
        },
        {
          value: "Polygon",
          label: "Polygon",
          img: require("../assets/images/polygon.png"),
        },
      ];

      const [selectedCurrency, setSelectedCurrency] = useState({
        value: "coinType",
        label: "Coin Type",
        img: theme == 'dark' ? require("../assets/images/searchglass.svg").default : require('../assets/images/blacksearch.svg').default,
      });

      const options = [
        {
          value: "electronics",
          label: "Electronics",
        },
        {
          value: "marketing",
          label: "Marketing",
        },
        {
          value: "restaurant",
          label: "Restaurant",
        },
        {
            value: "voucher",
            label: "Voucher",
          },
      ];
      const styles = {
        option: (styles, { isFocused, isSelected, isHovered }) => ({
          ...styles,
          color: "#fff",
          background: isFocused
            ? "#00CABA"
            : isSelected
            ? "#00CABA"
            : isHovered
            ? "red"
            : "#00CABA",
    
          zIndex: 1,
          cursor: "pointer",
          fontSize: "13px",
        }),
    
        option: (styles, { isFocused, isSelected, isHovered }) => {
          // const color = chroma(data.color);
    
          return {
            ...styles,
            backgroundColor: isHovered
              ? "#16EBC3"
              : isSelected
              ? "#16EBC3"
              : isFocused
              ? "#16EBC3"
              : theme == 'dark' ? '#181818' : '#fff',
            cursor: "pointer",
            color: isHovered
              ? "#000"
              : isSelected
              ? "#000"
              : isFocused
              ? "#000"
              : theme == 'dark' ? "#fff" : '#000',
            fontSize: "13px",
          };
        },
        indicatorSeparator: (styles) => ({display:'none'}),
        valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "40px",
          border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "transperant",
          // border: "1px solid rgba(34, 34, 34, 0.32)",
          borderRadius: 5,
          fontSize: "13px",
          color: "#fff",
        }),
        control: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "40px",
          border: "1px solid #00CABA",
          borderRadius: 5,
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "transperant",
          // backgroundColor: "#fff",
          border: "none",
          outline: "none",
          boxShadow: "none",
          color: "#fff",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "40px",
          position: "absolute",
          right: 0,
          top: 0,
          border: "none",
          color: "#6C6A81",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: theme == 'dark' ? "#fff" : "#343434",
        }),
        menuList: (base) => ({
          ...base,
          // kill the white space on first and last option
          padding: 0,
        }),
      };

      const styles1 = {
        option: (styles, { isFocused, isSelected, isHovered }) => ({
          ...styles,
          color: "#fff",
          background: isFocused
            ? "#00CABA"
            : isSelected
            ? "#00CABA"
            : isHovered
            ? "red"
            : "#00CABA",
    
          zIndex: 1,
          cursor: "pointer",
          fontSize: "13px",
        }),
    
        option: (styles, { isFocused, isSelected, isHovered }) => {
          // const color = chroma(data.color);
    
          return {
            ...styles,
            backgroundColor: isHovered
              ? "#16EBC3"
              : isSelected
              ? "#16EBC3"
              : isFocused
              ? "#16EBC3"
              : "#151515",
            cursor: "pointer",
            color: isHovered
              ? "#000"
              : isSelected
              ? "#000"
              : isFocused
              ? "#000"
              : "#fff",
            fontSize: "13px",
          };
        },
        indicatorSeparator: (styles) => ({display:'none'}),
        valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "40px",
          paddingLeft: "30px",
          border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "transperant",
          // border: "1px solid rgba(34, 34, 34, 0.32)",
          borderRadius: 5,
          fontSize: "13px",
          color: "#fff",
        }),
        control: (provided, { isFocused, isSelected, isHovered }) => ({
          ...provided,
          height: "40px",
          border: "1px solid #00CABA",
          borderRadius: 5,
          backgroundColor: isHovered
            ? "transperant"
            : isSelected
            ? "transperant"
            : isFocused
            ? "transperant"
            : "transperant",
          // backgroundColor: "#fff",
          border: "none",
          outline: "none",
          boxShadow: "none",
          color: "#fff",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "40px",
          position: "absolute",
          right: 0,
          top: 0,
          color: "#6C6A81",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: theme == 'dark' ? "#fff" : "#343434",
        }),
        menuList: (base) => ({
          ...base,
          // kill the white space on first and last option
          padding: 0,
        }),
      };
  return (
    <>
  
          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5'>
            <Row className='align-items-center justify-content-between'>
               
            <Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Store name</label>
                    <input
                      type="text"
                      placeholder="Please enter store name"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                    </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Product Type</label>
<Select
                    className=""
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder="dfdfdf"
                  />
                    </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} className='mb-4'>
                    <div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font mt-2">Country selection</label>
<div className="cmn_flag_select rounded-2 d-flex align-items-center" title={selectedFlag}>
                  <ReactFlagsSelect
                  className=" pb-0 w-100"
    selected={selectedFlag}
    showSecondarySelectedLabel={true}
    selectedSize={10}
    onSelect={(code) =>{console.log("code",code); setSelectedFlag(code)}}
  />
  </div>
</div>
                    </Col>

                    <Col lg={6} md={6} sm={12} className='mb-4'>
                    <div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font mt-2">Payment Coin</label>
                    <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency.img}
                    className="img-fluid market_coinname_img storeReg__selectCoin  sd_store_searchImg"
                  />
                 
                  <Select
                    className=""
                    defaultValue={selectedCurrency}
                    onChange={selectedCurrency}
                    options={options1}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles1}
                    placeholder="All Payment Methods"
                  />
                </div>
                </div>
                    </Col>

                    
                  
                    <Col xs={12} className='mb-4'>
<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Payment Coin Address</label>
                    <input
                      type="text"
                      placeholder="Please enter store name"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                    </div>
    </Col>

      

            </Row>


           
         
          </div>

          <h5 className='reg_stats_shower storemanager__title inter_font m-0 text-center text-uppercase mt-4 mb-4 mb-sm-0'>PRODUCT REGISTRATION</h5>

          <div className="login_selector storeReg__producTaber d-flex justify-content-start align-items-cente mt-4">
            {['Product 1','Product 2','Product 3','Product 4'].map((item,index) =>
                  <div
                    className={`prodReg__productTaber text-center ${
                      productTab == index ? "active" : ""
                    }`}
                    onClick={() => {
                      setProductTab(index);
                    }}
                  >
                    <p className="inter_font">{item}</p>
                  </div>
                   )}
                  
                 
                </div>
          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5'>
            <Row className='align-items-center justify-content-between'>
               
            <Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Store name</label>
                    <input
                      type="text"
                      placeholder="Please enter store name"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                    </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Product Description</label>
<Select
                    className=""
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder="dfdfdf"
                  />
                    </div>
                    </Col>

                    <Col xs={12} className='mb-4'>
<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Price</label>
                    <input
                      type="text"
                      placeholder="Please enter store name"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                    </div>
    </Col>

            </Row>

            <Row>

<label className="mb-3 inter_font prodReg__label">Main Image</label>
{allData.productRegImages.map((item) => 
              <Col lg={2} md={3} sm={4} xs={6}  className='mb-3'>
<div className='prodReg__flexFiles d-flex justify-content-start align-items-center gap-2'>
  <button className='primary_btn storeReg__primaryBtn storeReg_mobBtn rounded-2 inter_font position-relative flex-grow-1'>File
  <input type='file' className='prodReg__fileInputter' /></button>
  <img src={item.img} className='img-fluid prodReg__uploadedImg rounded-2' alt=''  />
</div>

              </Col>
              )}




            </Row>

            <Row className='mt-5'>
            <Col sm={6} className='mb-3 mb-sm-0'>
                <button className='primary_btn rounded-2 inter_font w-100'>Product registration</button>
              </Col>
              <Col sm={6}>
                <button className='prfl_orange_btn rounded-2 inter_font w-100'>Change Information</button>
              </Col>
            </Row>


           
         
          </div>
    </>
  )
}

export default RegisterProduct