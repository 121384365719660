import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

function OfferTagsModal({show,handleClose}) {
  return (
    <>
     <Modal
        className="purchase_modal"
        centered
        size="md"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-4">
          <button
            className="cmn_modal_closer inter_font rounded-5"
            onClick={handleClose}
          >
            <i class="fa-solid fa-xmark" />
          </button>

          <h6 className='inter_font m-0 offertags__title'>Offer Tags</h6>

          <div className="createOffer__borbot my-3"></div>

          <Row className='mb-3'>
            <Col xs={4}>
                <p className='offerModal__offer_name inter_font m-0'>Name</p>
            </Col>
            <Col xs={8}>
                <p className='offerModal__offer_name inter_font m-0'>Description</p>
            </Col>
          </Row>

         <div className='offerModal__card rounded-2 p-3 mb-2'>
            <Row>
                <Col xs={4}>
                <p className='offerModal__box_con inter_font m-0'>Offer 4</p>
                </Col>
                <Col xs={8}>
                <p className='offerModal__box_con inter_font m-0'>Get a chance offer tag</p>
            </Col>
            </Row>
         </div>

         <div className='offerModal__card rounded-2 p-3 mb-2'>
            <Row>
                <Col xs={4}>
                <p className='offerModal__box_con inter_font m-0'>Offer 4</p>
                </Col>
                <Col xs={8}>
                <p className='offerModal__box_con inter_font m-0'>Get a chance offer tag</p>
            </Col>
            </Row>
         </div>

         <div className='offerModal__card rounded-2 p-3 mb-2'>
            <Row>
                <Col xs={4}>
                <p className='offerModal__box_con inter_font m-0'>Offer 4</p>
                </Col>
                <Col xs={8}>
                <p className='offerModal__box_con inter_font m-0'>Get a chance offer tag</p>
            </Col>
            </Row>
         </div>

         <div className='text-center mt-4'>
            <button className='primary_btn rounded-1' onClick={handleClose}>Next</button>
         </div>

         

        </Modal.Body>
      </Modal>
    </>
  )
}

export default OfferTagsModal