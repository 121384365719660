import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function CloseAccount() {
    const theme = useSelector((state) => state?.User?.theme);
  return (
    <>
     <div className="notices_holder p-2 p-sm-3 pb-md-5">
     <h5 className="notices_hader_title ">Close your account</h5>
     <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

        <p className='rec_table_ttl_light inter_font'>If you leave the membership, you can rejoin after 30 days, and all existing transaction data, coin withdrawal, and store purchase data will be deleted. Please check again and decide.</p>

        <Row className='align-items-start mt-5'>
            <Col lg={3} md={3} sm={4} xs={12} className='mb-3 mb-sm-0'>
                <button className='prfl_lable_badge inter_font rounded-2 w-100'>SMS authentication</button>
            </Col>
            <Col lg={7} md={7} sm={8} xs={12}>
                <input type='number' className='prfl_tiny_inputs rounded-2 p-2' placeholder='Enter authentication number' />
                <span className='rec_table_ttl_light inter_font'>Invalid authentication number, please re-enter.</span>
            </Col>
            <Col lg={2} md={2} sm={12} className='d-flex justify-content-end align-items-start justify-content-md-start mt-3 mt-md-0'>
                <button className='primary_btn inter_font w-md-100'>Send</button>
            </Col>
        </Row>
        <Row className='align-items-start mt-5'>
            <Col lg={3} md={3} sm={4} xs={12} className='mb-3 mb-sm-0'>
                <button className='prfl_lable_badge inter_font rounded-2 w-100'>E-mail authentication</button>
            </Col>
            <Col lg={7} md={7} sm={8} xs={12}>
                <input type='number' className='prfl_tiny_inputs rounded-2 p-2' placeholder='Enter authentication number' />
                <span className='rec_table_ttl_light inter_font'>Invalid authentication number, please re-enter.</span>
            </Col>
            <Col lg={2} md={2} sm={12} className='d-flex justify-content-end align-items-start justify-content-md-start mt-3 mt-md-0'>
                <button className='primary_btn inter_font w-md-100'>Send</button>
            </Col>
        </Row>

        <Row className='justify-content-center mt-5'>
            <Col lg={6} md={8} sm={10} xs={12}>
                <Row>
                    <Col xs={6}>
                <button className='prfl_orange_btn inter_font w-100'>Cancel</button>

                    </Col>
                    <Col xs={6}>
                <button className='primary_btn w-100'>Confirm</button>
                        
                    </Col>
                </Row>
            </Col>
        </Row>
     </div>

    </>
  )
}

export default CloseAccount