import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

function SecurityDeviceManage({ show, handleClose , loginhistory}) {
  const [tableValue, setTableValues] = useState([
    {
        device : 'Mobile',
        loginTime : '01/01/2024',
        location : 'London',
        ip : '3948th3948t4394th93t',
        operationTime : '11:11:11'
    },
    {
        device : 'Mobile',
        loginTime : '01/01/2024',
        location : 'London',
        ip : '3948th3948t4394th93t',
        operationTime : '11:11:11'
    },
    {
        device : 'Mobile',
        loginTime : '01/01/2024',
        location : 'London',
        ip : '3948th3948t4394th93t',
        operationTime : '11:11:11'
    },
    {
        device : 'Mobile',
        loginTime : '01/01/2024',
        location : 'London',
        ip : '3948th3948t4394th93t',
        operationTime : '11:11:11'
    },
  ]);
  return (
    <Modal
      size="lg"
      centered
      className="purchase_modal"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <button className="cmn_modal_closer rounded-5" onClick={handleClose}>
        <i class="fa-solid fa-xmark" />
      </button>
      <Modal.Body className="px-0 overflow-hidden">
        <h6 className="security_modal_titles inter_font px-3 ">
          <i class="fa-solid fa-arrow-left-long me-2" /> Device Manager
        </h6>

        <div className="sm_devicemanger_webView mt-4">
          {/* <div className='sm_single_devicemanage'></div> */}
          <Row className="d-none d-lg-block">
            <Col lg={12}>
              <Row className="sm_devicemanage_head px-3 px-lg-5 py-3">
                <Col lg={2}>
                  <p className="sm_devicemanage_title inter_font">Browser</p>
                </Col>
                <Col lg={2}>
                  <p className="sm_devicemanage_title inter_font">Login time</p>
                </Col>
                <Col lg={3}>
                  <p className="sm_devicemanage_title inter_font">
                    Login Location
                  </p>
                </Col>
                <Col lg={3}>
                  <p className="sm_devicemanage_title inter_font">IP</p>
                </Col>
                <Col lg={2}>
                  <p className="sm_devicemanage_title inter_font">
                   Device
                  </p>
                </Col>
              </Row>
            </Col>

            <Col lg={12}>
              {loginhistory.length == 0 ? (
                <div className="sm_nodata_holder flex-column justify-content-center align-items-center d-none d-lg-flex gap-3 mt-5">
                  <img
                    src={require("../assets/images/nodata.svg").default}
                    className="img-fluid"
                  />
                  <p className="sm_devicemanage_title inter_font">
                    No Data Found
                  </p>
                </div>
              ) : (
                <div className="sm_devicemanager_holder">
                  {loginhistory?.map((value) => (
                    <Row className="mt-3 px-3 px-lg-5 flex-column flex-lg-row">
                      <Col lg={2}>
                        <p className="sm_devicemanage_title inter_font">
                          {value?.broswerName}
                        </p>
                      </Col>
                      <Col lg={2}>
                        <p className="sm_devicemanage_title inter_font">
                          {new Date(value?.loginTime)?.toLocaleDateString() + " " + new Date(value?.loginTime)?.toLocaleTimeString()}
                        </p>
                      </Col>
                      <Col lg={3}>
                        <p className="sm_devicemanage_title inter_font">
                          {value?.regionName +" , "+ value?.countryName}
                        </p>
                      </Col>
                      <Col lg={3}>
                        <p className="sm_devicemanage_title inter_font">
                          {value?.ipAddress}
                        </p>
                      </Col>
                      <Col lg={2}>
                        <p className="sm_devicemanage_title inter_font">
                          {value?.isMobile == "true" ? "Mobile" : "Desktop"}
                        </p>
                      </Col>
                    </Row>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </div>

        {/* {tableValue.length == 0 ? (
          <div className="sm_nodata_holder d-flex flex-column justify-content-center d-lg-none align-items-center gap-3 mt-5">
            <img
              src={require("../assets/images/nodata.svg").default}
              className="img-fluid"
            />
            <p className="sm_devicemanage_title inter_font">No Data Found</p>
          </div>
        ) : (
          <div className="sm_devicemanager_holder">
            {tableValue?.map((value) => (
              <div className="sm_devicemanger_mobView mx-3 p-2 rounded-1 d-block d-lg-none mb-4">
                <div className="sm_mobView_single mb-2 d-flex justify-content-between align-items-start">
                  <p className="sm_devicemanage_title inter_font">Device</p>
                  <p className="sm_devicemanage_title inter_font">
                    {value.device}
                  </p>
                </div>
                <div className="sm_mobView_single mb-2 d-flex justify-content-between align-items-start">
                  <p className="sm_devicemanage_title inter_font">Login time</p>
                  <p className="sm_devicemanage_title inter_font">
                    {value.loginTime}
                  </p>
                </div>
                <div className="sm_mobView_single mb-2 d-flex justify-content-between align-items-start">
                  <p className="sm_devicemanage_title inter_font">
                    Login Location
                  </p>
                  <p className="sm_devicemanage_title inter_font">
                    {value.location}
                  </p>
                </div>
                <div className="sm_mobView_single mb-2 d-flex justify-content-between align-items-start">
                  <p className="sm_devicemanage_title inter_font">IP</p>
                  <p className="sm_devicemanage_title inter_font">{value.ip}</p>
                </div>
                <div className="sm_mobView_single mb-2 d-flex justify-content-between align-items-start">
                  <p className="sm_devicemanage_title inter_font">
                    Operation Type
                  </p>
                  <p className="sm_devicemanage_title inter_font">
                    {value.operationTime}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )} */}
      </Modal.Body>
    </Modal>
  );
}

export default SecurityDeviceManage;
