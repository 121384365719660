import React from 'react'

const BlackListBox = () => {
  return (
    <>
       <div className='saleStatus__blackListBox p-3 mt-5'>
        <h4 className='m-0 inter_font saleStatus__blackListTitle'>Blacklist Registered Member</h4>
        <ul className='mt-4'>
            <li className='mb-3'>
            <p className='m-0 inter_font saleStatus_blackListHint'>Coin transactions (date transactions, exchange transactions), no access to coin transaction pages, no access to coin transaction page, no access to coin deposit- coin
     withdrawal pages .</p>
            </li>
            <li className='mb-3'><p className='m-0 inter_font saleStatus_blackListHint'>If you are registered as a black list member, you will receive a message pop-up of “Contact your administration as a block member (display black list registration time).”</p></li>
            <li className='mb-3'><p className='m-0 inter_font saleStatus_blackListHint'>Blacklist registered users are checked on the My page of their account.</p></li>
            <li className='mb-3'><p className='m-0 inter_font saleStatus_blackListHint'>Blacklist users can be found in the membership list within the exchange’s administrator (marking stores registered as blacklists)</p></li>
            <li className='mb-3'><p className='m-0 inter_font saleStatus_blackListHint'>Exchange managers can register and unblock as block members and normal members in the membership list.</p></li>
        </ul>
        
    </div>
    </>
  )
}

export default BlackListBox