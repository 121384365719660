import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';

function EnterPriseAPI() {
    const theme = useSelector((state) => state?.User?.theme);
    const [apiTypes,setApiTypes] = useState([
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Wallet-enabled API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },
        {
            type : 'Subscribe and trade open API '
        },


        {
            type : 'Subscribe and trade open API '
        },
    ])
  return (
    <>
     <div className="notices_holder p-2 p-sm-3 pb-md-5">
     <Row>
          <Col lg={6} md={6} className="d-flex align-items-center mb-3 mb-md-0">
          <div className="prfl_profile_dtls_holder d-flex justify-content-start align-items-center gap-3">
          <div className="prfl_profile_img_holder position-relative">
            <img
              src={require("../assets/images/person.svg").default}
              className="img-fluid"
            />
            <div className="prfl_online_indicator rounded-5"></div>
          </div>
          <div className="prfl_username_holder d-flex jutify-content-start align-items-center gap-2">
            <p className="prfl_username">John Doe</p>
            <img
              src={require("../assets/images/vendor.svg").default}
              className="img-fluid"
            />
          </div>
        </div>
          </Col>
          <Col
            lg={6}
            md={6}
            className="d-flex flex-column flex-sm-row justify-content-end align-items-start gap-3"
          >
            <button className="profile_change_btn inter_font">Change</button>
          </Col>
        </Row>
        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>


<h5 className="notices_hader_title  inter_font">API Document</h5>

<Row className='mt-4'>
    <Col lg={4} md={4} className='mb-4 mb-md-0'>
        <div className='api_types_holder rounded-4 p-3'>
            <h4 className='api_types_title inter_font'>API Types</h4>
            <div className='api_types_scroller mt-3'>
                <ul >
                    {apiTypes?.map((e,index) =>
                    <li className='inter_font mb-2'>{index + 1}. &nbsp;{e.type}</li>
                    )}
                </ul>
            </div>
        </div>
    </Col>
    <Col lg={8} md={8}>
        <div className='api_content_holder p-3 rounded-4 h-100'>
    <div className='api_content_scroller pe-2'>
        <p className='api_content_title'>Introduction</p>
        <p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>

<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>

<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
<p className='api_content_hint mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus faucibus semper. Sed sit amet tincidunt est, in lobortis nibh. Quisque non felis aliquet, tincidunt lectus a, ultricies risus. Quisque et ipsum turpis. Pellentesque rutrum gravida efficitur. Fusce aliquet eu nibh id molestie. Maecenas tempor consequat neque eu mattis. In fermentum erat eget libero aliquam, placerat laoreet sapien ornare.
Mauris placerat nunc sit amet tellus molestie, at ornare nibh rutrum. Sed aliquet congue malesuada. Proin sit amet ante nunc. Integer erat nunc, tempor et lacus ac, sollicitudin porttitor nulla. </p>
    </div>
    </div>
    </Col>
</Row>
</div>
    </>
  )
}

export default EnterPriseAPI