import React, { useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Select from "react-select";

function HistoryPage({showSidebar,handleShow}) {
    const theme = useSelector((state) => state?.User?.theme);
    const [activeTab,setActiveTab] = useState('selling')
    const [selected,setSelected] = useState({
      value: "delete",
    label: "Delete",
    })
    const options = [
      {
        value: "delete",
        label: "Delete",
      },
      {
        value: "read",
        label: "Read",
      },
      {
        value: "save",
        label: "Save",
      },
    ];
    const styles = {
      option: (styles, { isFocused, isSelected, isHovered }) => ({
        ...styles,
        color: "#fff",
        background: isFocused
          ? "#00CABA"
          : isSelected
          ? "#00CABA"
          : isHovered
          ? "red"
          : "#00CABA",
  
        zIndex: 1,
        cursor: "pointer",
        fontSize: "13px",
      }),
  
      option: (styles, { isFocused, isSelected, isHovered }) => {
        // const color = chroma(data.color);
  
        return {
          ...styles,
          backgroundColor: isHovered
            ? "#16EBC3"
            : isSelected
            ? "#16EBC3"
            : isFocused
            ? "#16EBC3"
            : theme == 'dark' ? '#181818' : '#fff',
          cursor: "pointer",
          color: isHovered
            ? "#000"
            : isSelected
            ? "#000"
            : isFocused
            ? "#000"
            : theme == 'dark' ? "#fff" : '#000',
          fontSize: "13px",
        };
      },
      indicatorSeparator: (styles) => ({display:'none'}),
      valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
        ...provided,
        height: "40px",
        border: theme == 'dark' ? "1px solid #00CABA" : "1px solid #e3e0e0",
        backgroundColor: isHovered
          ? "transperant"
          : isSelected
          ? "transperant"
          : isFocused
          ? "transperant"
          : "transperant",
        // border: "1px solid rgba(34, 34, 34, 0.32)",
        borderRadius: 5,
        fontSize: "13px",
        color: "#fff",
      }),
      control: (provided, { isFocused, isSelected, isHovered }) => ({
        ...provided,
        height: "40px",
        border: "1px solid #00CABA",
        borderRadius: 5,
        backgroundColor: isHovered
          ? "transperant"
          : isSelected
          ? "transperant"
          : isFocused
          ? "transperant"
          : "transperant",
        // backgroundColor: "#fff",
        border: "none",
        outline: "none",
        boxShadow: "none",
        color: "#fff",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "40px",
        position: "absolute",
        right: 0,
        top: 0,
        border: "none",
        color: "#6C6A81",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: theme == 'dark' ? "#fff" : "#343434",
      }),
      menuList: (base) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
      }),
    };
    const ref = useRef();
  return (
    <>
    <div className="notices_holder p-2 p-sm-3 pb-md-5">
    <div className="sidebar_canva_shower d-xl-none mb-3">
              <button
                className="sidebar_canva_burg rounded-5 pt-1 px-2"
                onClick={handleShow}
              >
                <i class="fa-solid fa-bars" />
              </button>
            </div>
     <Row>
          <Col lg={6} md={6} className="d-flex align-items-center mb-3 mb-md-0">
          <div className="prfl_profile_dtls_holder d-flex justify-content-start align-items-center gap-3">
          <div className="prfl_profile_img_holder position-relative">
            <img
              src={require("../assets/images/person.svg").default}
              className="img-fluid"
            />
            <div className="prfl_online_indicator rounded-5"></div>
          </div>
          <div className="prfl_username_holder d-flex jutify-content-start align-items-center gap-2">
            <p className="prfl_username">John Doe</p>
            <img
              src={require("../assets/images/vendor.svg").default}
              className="img-fluid"
            />
          </div>
        </div>
          </Col>
          <Col
            lg={6}
            md={6}
            className="d-flex flex-column flex-sm-row justify-content-end align-items-start gap-3"
          >
            <button className="profile_change_btn inter_font">Change</button>
          </Col>
        </Row>
        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

<h5 className="notices_hader_title  inter_font">History</h5>


<div className='hp_tab_holder d-flex justify-content-start align-items-center gap-1 mt-4'>
    <div className={`hp_taber d-flex justify-content-center align-items-center  p-1 p-sm-2 p-md-3 ${activeTab == 'selling' ? 'hp_orange_tab' : ''}`} onClick={() => setActiveTab('selling')}>
        <p className='inter_font hp_taber_text'>Selling in Progress</p>
    </div>
    <div className={`hp_taber d-flex justify-content-center align-items-center p-1 p-sm-2 p-md-3 ${activeTab == 'buying' ? 'active' : ''}`} onClick={() => setActiveTab('buying')}>
        <p className='inter_font hp_taber_text'>Buying in Progress</p>
    </div>
    <div className={`hp_taber d-flex justify-content-center align-items-center  p-1 p-sm-2 p-md-3 ${activeTab == 'sell' ? 'active' : ''}`} onClick={() => setActiveTab('sell')}>
        <p className='inter_font hp_taber_text'>Sell data</p>
    </div>
    <div className={`hp_taber d-flex justify-content-center align-items-center  p-1 p-sm-2 p-md-3 ${activeTab == 'buy' ? 'active' : ''}`} onClick={() => setActiveTab('buy')}>
        <p className='inter_font hp_taber_text'>Buy data</p>
    </div>
</div>

{activeTab == 'selling' && 
<div className='mt-5 hp_table_singleRow py-3 px-2 rounded-1'>
<Row className=''>
  <Col lg={2} md={4} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
<div className='hp_table_singlevalue'>
  <p className='sm_loginHistory_label inter_font'>USDT (TRC20)</p>
  <p className='hp_small_text inter_font mt-2'>Registration Data:</p>
  <p className='sm_loginHistory_label inter_font'>2024.01.01    23:59:59</p>
  </div>
  </Col>
  <Col lg={3} md={4} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
<div className='hp_table_singlevalue'>
  <p className='sm_login_titller inter_font'>Total sell quantity:</p>
  <p className='sm_loginHistory_label inter_font'>3,000,000,000</p>
  </div>
  </Col>
  <Col lg={2} md={4} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
  <div className='hp_table_singlevalue'>
  <p className='sm_login_titller inter_font'>Fiat Money:</p>
  <p className='sm_loginHistory_label inter_font'>USD</p>
  </div>
  </Col>
  <Col lg={3} md={6} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
  <div className='hp_table_singlevalue'>
  <p className='sm_login_titller inter_font'>Min Quantity: <span className='sm_loginHistory_label'>300</span></p>
  <p className='sm_login_titller inter_font'>Max Quantity: <span className='sm_loginHistory_label'>300,000,000</span></p>
  </div>
  </Col>
  <Col lg={2} md={6} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
  <div className='hp_table_singlevalue'>
    <button className='primary_btn hp_primary_btn'>Cancel Registration</button>
    </div>
  </Col>
</Row>
</div>}


{activeTab == 'buying' &&
<div className='mt-5 hp_table_singleRow py-3 px-2 rounded-1'>
<Row className=''>
  <Col lg={2} md={4} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
<div className='hp_table_singlevalue'>
  <p className='sm_loginHistory_label inter_font'>USDT (TRC20)</p>
  <p className='hp_small_text inter_font mt-2'>Registration Data:</p>
  <p className='sm_loginHistory_label inter_font'>2024.01.01    23:59:59</p>
  </div>
  </Col>
  <Col lg={3} md={4} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
<div className='hp_table_singlevalue'>
  <p className='sm_login_titller inter_font'>Total sell quantity:</p>
  <p className='sm_loginHistory_label inter_font'>3,000,000,000</p>
  </div>
  </Col>
  <Col lg={2} md={4} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
  <div className='hp_table_singlevalue'>
  <p className='sm_login_titller inter_font'>Fiat Money:</p>
  <p className='sm_loginHistory_label inter_font'>USD</p>
  </div>
  </Col>
  <Col lg={3} md={6} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
  <div className='hp_table_singlevalue'>
  <p className='sm_login_titller inter_font'>Min Quantity: <span className='sm_loginHistory_label'>300</span></p>
  <p className='sm_login_titller inter_font'>Max Quantity: <span className='sm_loginHistory_label'>300,000,000</span></p>
  </div>
  </Col>
  <Col lg={2} md={6} sm={6} className='mb-3 mb-lg-0 d-flex justify-content-center align-items-start justify-content-sm-start'>
  <div className='hp_table_singlevalue'>
    <button className='primary_btn hp_primary_btn'>Cancel Registration</button>
    </div>
  </Col>
</Row>
</div>}

{activeTab == 'sell' && 
<div className='hp_selldata_table mt-5 p-3'>
  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>USDT (TRC20)</p>
    <p className='hp_selldata_small mb-1'>Transaction on time</p>
    <p className='hp_selldata_mid'>2024.01.01  23:59:59</p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Buyer ID: ASDFFASDFASDFSDF</p>
    <p className='hp_selldata_small mb-1'>Total Sell quantity</p>
    <p className='hp_selldata_mid'>3,000,000,000</p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Transaction method: Korea Bank</p>
    <p className='hp_selldata_small mb-1'>Total Sell quantity</p>
    <p className='hp_selldata_mid'>3,000,000,000</p>
  </div>
  
  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Fiat money: KRW</p>
    <p className='hp_selldata_small mb-1'>Coin price:</p>
    <p className='hp_selldata_mid'>3,000,000,000</p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Max legal currency amount</p>
    {/* <p className='hp_selldata_small mb-1'></p> */}
    <p className='hp_selldata_mid  mt-lg-3'>3,000,000,000</p>
  </div>

  <div className='hp_selldata_singlecell'>
  <Select
                    className="mt-3"
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder="dfdfdf"
                  />
  </div>
</div>}

{activeTab == 'buy' &&
<div className='hp_buydata_table mt-5 py-3 px-2'>
  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>USDT (TRC20)</p>
    <p className='hp_selldata_small mb-1'>Transaction on time</p>
    <p className='hp_selldata_mid'>2024.01.01  23:59:59</p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Seller ID: ASDFFASDFASDFSDF</p>
    <p className='hp_selldata_small mb-1'>Total Sell quantity</p>
    <p className='hp_selldata_mid'>3,000,000,000</p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Transaction method: Korea Bank</p>
    <p className='hp_selldata_small mb-1'>Total Sell quantity</p>
    <p className='hp_selldata_mid'>3,000,000,000</p>
  </div>
  
  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Fiat money: KRW</p>
    <p className='hp_selldata_small mb-1'>Coin price:</p>
    <p className='hp_selldata_mid'>3,000,000,000</p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Max legal currency amount</p>
    <p className='hp_selldata_mid'>3,000,000,000</p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_bold'>Transaction reporter</p>
    <p className='hp_selldata_mid hp_sellerdata_green'>Buyer <span className='ms-2'>Seller</span></p>
  </div>

  <div className='hp_selldata_singlecell'>
    <p className='hp_selldata_mid mt-3'>Transaction reporting time</p>
    <p className='hp_selldata_bold'>2024.01.01  23:59:59</p>
  </div>

  <div className='hp_selldata_singlecell'>
  <Select
                    className="mt-3"
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder="dfdfdf"
                  />
  </div>
</div>}

        </div>
    </>
  )
}

export default HistoryPage