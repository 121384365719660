import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

function ProfileProof({setVerifyStatus,setProcess , front , back , selfie , frontview , backview , selfieview , 
  handlefront , handleselfie , handleback , fronterror , backerror , selfieerror , handleSubmit}) {
  
    useEffect(() => {
      console.log("useft" , frontview);
    } , [])
  
    return (
    <>
     <div className="prfl_profilepic_verifier my-5">
        <Row>
          <Col lg={7}>
            <h5 className="prfl_pic_hinter">
              All photos should br bright and clear.
              <br />
              Upload a picture taken so that the doesn't reflect.
            </h5>
            <ul className="mt-4">
              <li>
                Take a picture so that all the letters on your passport, ID
                card, driver’s license are clearly visible.
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col lg={4} md={4} sm={6} className="mb-4 mb-md-0">
            <img
              src={frontview ? frontview : require("../assets/images/proof.svg").default}
              className="img-fluid prfl_sample_idImg"
            />
            <p className="prfl_acrd_value prfl_imgUpload_hint inter_font mt-3">
              Front view ID Card
            </p>
            <button className="secondary_btn mt-3 position-relative" >
              Upload
              <input type="file" className="prfl_image_uploader" onChange={(e) => handlefront(e)}/>
            </button>
            <p className='text-danger'>{fronterror}</p>
          </Col>
          <Col lg={4} md={4} sm={6} className="mb-4 mb-md-0">
            <img
              src={backview ? backview : require("../assets/images/backside.svg").default}
              className="img-fluid prfl_sample_idImg"
             
            />
            <p className="prfl_acrd_value prfl_imgUpload_hint inter_font mt-3">
              Back view of ID Card
            </p>
            <button className="secondary_btn mt-3 position-relative">
              Upload
              <input type="file" className="prfl_image_uploader"  onChange={(e) => handleback(e)}/>
            </button>
            <p className='text-danger'>{backerror}</p>
          </Col>
          <Col lg={4} md={4} sm={6} className="mb-4 mb-md-0">
            <img
              src={selfieview ? selfieview : require("../assets/images/faceside.svg").default}
              className="img-fluid prfl_sample_idImg"
            />
            <p className="prfl_acrd_value prfl_imgUpload_hint inter_font mt-3">
              A self-portrait of you holding ID card and a statement (Contains
              OTEX and date of the day)
            </p>
            <button className="secondary_btn mt-3 position-relative">
              Upload
              <input type="file" className="prfl_image_uploader" onChange={(e) => handleselfie(e)}/>
            </button>
            <p className='text-danger'>{selfieerror}</p>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col lg={5} md={6} sm={8} xs={10}>
            <button className="primary_btn w-100" onClick={() => {
              if(!fronterror && !backerror && !selfieerror && front && back && selfie){
                // setVerifyStatus('progress');
                // setProcess('')
                handleSubmit()
              }
              }}>Submit</button>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ProfileProof