import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from 'react-redux';

function Recommend({activeCont,setActiveCont}) {
    const theme = useSelector((state) => state?.User?.theme);
  return (
    <>
    <div className='notices_holder p-2 p-sm-3'>
    <Row>
        <Col lg={4}>
        <h5 className="notices_hader_title ">Invite a Friend</h5>
        </Col>
        <Col lg={4}>
        <p className="notices_title_hints inter_font">My recommended link address</p>
        </Col>
        <Col lg={4} className='d-flex jutify-content-start align-items-center gap-2'>
        <p className="notices_title_hints rec_blue_link inter_font">My recommended link address</p>
        <CopyToClipboard onCopy={() => console.log("copycopycopu")}>
                  <p className="d-flex align-items-center mb-0">
                    <img
                      src={require("../assets/images/whitecopy.svg").default}
                      alt="copy"
                      className="mt_whitecopy_img"
                    />
                  </p>
                </CopyToClipboard>
        </Col>
    </Row>
    <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

<div className='rec_single_card rounded-2 p-3'>
        <Row className='align-items-start'>
            <Col xl={2} lg={4} md={6} sm={6} className='d-flex flex-column align-items-center justify-content-start gap-2 gap-sm-3 align-items-sm-start gap-md-0'>
                <div className='rec_single_set'>
                <p className='rec_table_ttl_light'>Serial Number:</p> <p className='rec_table_ttl text-center text-sm-start'>12345</p>
                </div>
                <div className='rec_single_set'>
                <p className='rec_table_ttl_light mt-0 mt-md-2'>User ID: </p><p className='rec_table_ttl text-center text-sm-start'>12345</p>
                </div>
            </Col>
            <Col xl={2} lg={4} md={6} sm={6} className='d-flex flex-column align-items-center justify-content-start gap-3 align-items-sm-start gap-md-0'>
            <div className='rec_single_set'>
                <p className='rec_table_ttl_light mt-3 mt-sm-0'>Assets: </p>
                </div>
                <div className='rec_single_set'>
                <p className='rec_table_ttl_light '>Recommended Partner:</p> <p className='rec_table_ttl text-center text-sm-start'>John Doe</p>
                </div>
            </Col>
            <Col xl={2} lg={4} md={6} sm={6} className='d-flex flex-column align-items-center justify-content-start gap-3 align-items-sm-start gap-md-0'>
            <div className='rec_single_set'>
                <p className='rec_table_ttl_light mt-3 mt-sm-0'>Direct recommend:</p><p className='rec_table_ttl text-center text-sm-start'>Yes</p> 
                </div>
                <div className='rec_single_set'>
                <p className='rec_table_ttl_light mt-0 mt-md-2 mt-xl-0'> Total Transaction amount:</p><p className='rec_table_ttl text-center text-sm-start'>1212</p>
                </div>
            </Col>
            <Col xl={2} lg={4} md={6} sm={6} className='d-flex flex-column align-items-center justify-content-start gap-3 align-items-sm-start gap-md-0'>
            <div className='rec_single_set'>
                <p className='rec_table_ttl_light mt-3 mt-lg-2 mt-xl-0'>Referal Code:</p><p className='rec_table_ttl text-center text-sm-start'>2342342</p> 
                </div>
            </Col>
            <Col xl={2} lg={4} md={6} sm={6} className='d-flex flex-column align-items-center justify-content-start gap-3 align-items-sm-start gap-md-0'>
            <div className='rec_single_set'>
                <p className='rec_table_ttl_light mt-3 mt-sm-2 mt-xl-0'>Date of  subscription:</p><p className='rec_table_ttl text-center text-sm-start'>23 OCt 2023 </p>
                </div>
                <div className='rec_single_set'>
                <p className='rec_table_ttl_light mt-3'> Date of KYC registration:</p><p className='rec_table_ttl text-center text-sm-start'>23 OCt 2023</p>
                </div>
            </Col>
            <Col xl={2} lg={4} md={6} sm={6} className='d-flex flex-column align-items-center justify-content-start gap-3 align-items-sm-start gap-md-0'>
                <div className='rec_table_last_cell d-flex justify-content-start align-items-start gap-2'>
                <p className='rec_table_ttl_light'>State:</p> <p className='rec_table_ttl text-center text-sm-start'>Normal</p>

                </div>
                <div className='rec_twin_btns'>
                    <button className='rec_primary_btn rounded-2 inter_font w-100' onClick={() => setActiveCont('closeAcc')}>Close account</button>
                    <button className='rec_red_btn rounded-2 inter_font w-100 mt-2'>Stop</button>
                </div>
            </Col>
            
        </Row>
        </div>
        </div>

    </>
  )
}

export default Recommend