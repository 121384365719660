import React from 'react'
import { Modal } from 'react-bootstrap'

const RequestTransactionModal = ({show,handleClose,setViewer}) => {
  return (
    <>
    <Modal
    className="purchase_modal"
    centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
        <button
            className="cmn_modal_closer inter_font rounded-5"
            onClick={handleClose}
          >
            <i class="fa-solid fa-xmark" />
          </button>

          <div className='text-center'>
          <img
                  src={require("../assets/images/logo.svg").default}
                  className="img-fluid  "
                />

                <h6 className='m-0 inter_font reqTransactModal__title mt-4'>We have a request for a transaction.</h6>

                <p className='m-0 inter_font reqTransacModal__desc mt-4'>Trading coin :<span> BITCOIN (BTC)</span></p>
                <p className='m-0 inter_font reqTransacModal__desc mt-1'>Transaction Quantity:<span> 9,000,000,000 BTC</span></p>

                <h6 className='m-0 inter_font reqTransactModal__title reqTransactModal__ques mt-4'>Do you want to start a deal?</h6>

                <div className='reqTransacModal_dualBtns mt-4 d-flex justify-content-center align-items-center gap-4'>
                    <button className='inter_font rounded-1 im_red_btn ' onClick={() => {setViewer(true);handleClose()}}>Refusal</button>
                    <button className='primary_btn inter_font rounded-1 ' onClick={() => {setViewer(true);handleClose()}}>Approved</button>
                </div>
          </div>
        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default RequestTransactionModal