import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SendRegisterOtpHooks, VerifyRegisterOtpHooks } from '../Hooks/UserHooks';

function EmailVerification() {
    const theme = useSelector((state) => state?.User?.theme);
    const location = useLocation();
    const navigate = useNavigate();

    const [otp , setOtp] = useState("");
    const [statedata , setStatedata] = useState(location?.state);
    const [error , setError] = useState("");

    useEffect(() => {
      console.log("location" , location?.state);
    } , [])
    const VerifyOtp = async() => {
      if(otp){
        if(otp?.length == 6){
          // if(otp == statedata?.otp){
            let verify = await VerifyRegisterOtpHooks({
              email : statedata?.email,
              otp : otp,
              type : statedata?.email ? "email" : "mobile"
            });
            if(verify?.type == "Success" && verify?.error){
              setError(verify?.error)
            }
            else if(verify?.type == "Success" && verify?.message){
              navigate("/setPasscode" , {state : statedata});
            }
            console.log("Verify otp" , verify);
            
          // }
          // else{
          //   setError("Invalid verification code")
          // }
        }
        else{
          setError("Enter 6 digit verification code")
        }
      }
      else{
        setError("Verification field is required")
      }
    }

    const ResendOtp = async() => {
      try{
        let payload = {
          emailorphone : statedata?.email ? statedata?.email : statedata?.phoneNo,
          registerType : statedata?.email ? "Email" : "Mobile",
          phoneCode : statedata?.phoneCode,
          country : statedata?.country,
          termsandcondition : true
        }
        let result = await SendRegisterOtpHooks(payload);
        console.log("result in send register otp" , result);
        setStatedata(result?.record)
        location.state = result?.record
        // if(result?.type == "Success"){
        //   navigate("/setPasscode" , {state : result?.record});
        // }
      }
      catch(e){
        console.log("Error on resend otp" , e);
      }
    }
  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center'>Welcome to Otex!</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card position-relative rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <p className='mailver_backBtn inter_font m-0'>&lt; Back</p>
            <Row className='align-items-center justify-content-between mt-2'>
                <Col lg={7} md={7} sm={12} className='mb-4 mb-md-0'>
                    <h5 className='creacteacc_title m-0 inter_font mt-3 mt-lg-0'>Verification</h5>
                    <p className='mailver_hint inter_font m-0 mt-2'>Please enter the 6-digit verification code that was sent to dummy123@gmail.com. The code is valid for 30 minutes.</p>
                <div className="login_single_input d-flex flex-column mt-4">
                    <label className="mb-2 inter_font">Verification code</label>
                    <div className='mailver_inputHolder p-2 rounded-2 d-flex justify-content-between align-items-center gap-3'>
                    <input maxLength={6}
                      type="number"
                      placeholder="Enter verification code"
                      className="inter_font flex-grow-1"
                      onChange = {(e) => setOtp(e?.target?.value)}
                    />
                    <p className='mailver_inpBtb inter_font m-0' onClick={ResendOtp}>Resend Code</p>
                    </div>
                    <p className='text-danger'> {error}</p>
                  </div>

                 
                  {/* <NavLink className="header_links" to='/setPasscode'> */}
                  <button className='primary_btn w-100 mt-4' 
                  disabled={otp?.length != 6}
                  onClick={VerifyOtp}>Submit</button>
                  {/* </NavLink> */}
                  <p className='reg_primaryText inter_font mt-4'>Didn’t receive the code?</p>
                </Col>

                <Col lg={4} md={5} sm={12}>
                    <img src={require('../assets/images/regCrypto.svg').default} className='img-fluid' />
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default EmailVerification