import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function StoreDetailCard() {
  const [favo, setFavo] = useState(false);
  return (
    <div className="store_detail_card p-3 position-relative rounded-2">
      <div className="sd_favourite_holder">
        <i
          class={favo ? "fa-solid fa-heart" : "fa-regular fa-heart"}
          onClick={() => setFavo(!favo)}
        />
      </div>
      <Row className="align-items-center">
        <Col lg={2} md={2} sm={3} xs={3} className="mb-3 mb-lg-0">
          <img
            src={require("../assets/images/shop.svg").default}
            className="img-fluid rounded-4 sd_card_prodImg"
          />
        </Col>
        <Col
          lg={{ span: 3, offset: 0 }}
          md={{ span: 9, offset: 1 }}
          sm={9}
          xs={9}
          className="mb-3 mb-lg-0"
        >
          <div className="sd_seller_dtls">
            <h6 className="inter_font">MyShopPrime</h6>
            <p className="inter_font sd_seller_name">Seller: Harini</p>
          </div>
          <div className="sd_product_dtls mt-2">
            <p className="inter_font">Product : Pizza baccon </p>
            <span className="inter_font">Payment Coin : USDT(TRC20)</span>
          </div>
        </Col>
        <Col lg={5} md={8} className="mb-3 mb-md-0">
          <div className="sd_product_prices_holder d-flex justify-content-center  justify-content-md-start align-items-center gap-2 gap-sm-3 ga-md-4">
            <p className="inter_font sd_hinter">Min Buy</p>
            <div className="sd_product_prices d-flex justify-content-center align-items-center gap-3">
              <p className="inter_font sd_seller_name">Minimum Purchase</p>
              <div>
                <p className="inter_font sd_seller_name">10,000,000 USD</p>
                <p className="inter_font sd_seller_name">300,000,000 KRW</p>
              </div>
            </div>
          </div>
          <div className="sd_product_prices_holder d-flex justify-content-center justify-content-md-start align-items-center gap-2 gap-sm-3 ga-md-4 mt-3">
            <p className="inter_font sd_hinter">Max Buy</p>
            <div className="sd_product_prices d-flex justify-content-center align-items-center gap-3">
              <p className="inter_font sd_seller_name">Maximum Purchase</p>
              <div>
                <p className="inter_font sd_seller_name">10,000,000 USD</p>
                <p className="inter_font sd_seller_name">300,000,000 KRW</p>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={2} md={4}>
          <p className="inter_font sd_seller_name text-center">escrow</p>
          <div className="w-100 text-center">
            <NavLink to="/storeInfo" className="header_links">
              <button className="primary_btn mt-2">Buy</button>
            </NavLink>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default StoreDetailCard;
