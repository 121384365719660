import React from 'react'
import { Col, Row, Accordion } from 'react-bootstrap'

function ProfileAccord({user}) {
  return (
    <>
    <Row className='mt-5'>
    <Col lg={4} md={4} className="mb-3">
            <div className="prfl_accord_holder p-2 rounded-3 h-100">
              <Accordion defaultActiveKey="0" flush className="inter_font">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Personal Information</Accordion.Header>
                  <Accordion.Body className="mt-4">
                    <div className="">
                      <div className="prfl_acrd_single_flex d-flex justify-content-between align-items-center mt-3">
                        <p className="rec_table_ttl_light inter_font">Name</p>
                        <p className="prfl_acrd_value inter_font">{user?.firstName +" "+ user?.lastName}</p>
                      </div>
                      <div className="prfl_acrd_single_flex d-flex justify-content-between align-items-center mt-3">
                        <p className="rec_table_ttl_light inter_font">
                          Country/Region
                        </p>
                        <p className="prfl_acrd_value inter_font">
                          {user?.country}
                        </p>
                      </div>
                      <div className="prfl_acrd_single_flex d-flex justify-content-between align-items-center mt-3">
                        <p className="rec_table_ttl_light inter_font">
                          Status
                        </p>
                        <p className="prfl_acrd_value inter_font">
                          {user?.status}
                        </p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>

          <Col lg={8} md={8} className="mb-3">
            <div className="prfl_accord_holder p-2 rounded-3">
              <Accordion defaultActiveKey="0" flush className="inter_font">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="prfl_sec_acrd_btn">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <p className="mb-0 inter_font">Identity verification</p>
                      <img
                        src={require("../assets/images/vendor.svg").default}
                        className="img-fluid me-4"
                      />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="mt-3">
                    <Row>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className="mb-4 mb-sm-0"
                      >
                        <p className="prfl_acrd_value inter_font mb-2 text-center text-sm-start">
                          Account Allow List
                        </p>

                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          Withdrawal
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          Crypto deposit
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          P2P Trade
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          Store Use
                        </p>
                      </Col>

                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className="mb-4 mb-sm-0"
                      >
                        <p className="prfl_acrd_value inter_font mb-2 text-center text-sm-start">
                          Before verification
                        </p>

                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                      </Col>

                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className="mb-4 mb-sm-0"
                      >
                        <p className="prfl_acrd_value inter_font mb-2 text-center text-sm-start">
                          After verification
                        </p>

                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                        <p className="rec_table_ttl_light inter_font text-center text-sm-start">
                          1st 3k : 1Day 35 USDT
                        </p>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
    </Row>
    </>
  )
}

export default ProfileAccord