import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

function Register() {
    const theme = useSelector((state) => state?.User?.theme);

  const [loginSource, setLoginSource] = useState("email");
    const [qrcode,setQrcode] = useState(false);
    
    const [emailOrPhone , setEmailOrPhone] = useState("");
    const [disablenext , setDisablenext] = useState(true);
    const [type , setType] = useState("");

    const HandleNext = async() => {
      var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(emailRegx.test(emailOrPhone)){
        setType("Email");
      }
      else{
        setType("Mobile");
      }
    }

   

  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center text-uppercase'>join membership</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <Row className='align-items-center justify-content-between'>
                <Col lg={7} md={6} sm={12}>

                <div className="login_selector d-flex justify-content-start align-items-cente mt-4">
                  <div
                    className={`login_via text-center ${
                      loginSource == "email" ? "active" : ""
                    }`}
                    onClick={() => {
                      setLoginSource("email");
                    }}
                  >
                    <p className="inter_font">Email</p>
                  </div>
                  <div
                    className={`login_via rounded-end text-center ${
                      loginSource == "mobile" ? "active" : ""
                    }`}
                    onClick={() => {
                      setLoginSource("mobile");
                    }}
                  >
                    <p className="inter_font">Mobile</p>
                  </div>
                </div>
                <div className="login_single_input d-flex flex-column mt-4">
                    <label className="mb-2 inter_font">{loginSource == 'email' ? 'Enter Email ID' : 'Enter Phone Number'}</label>

                    {loginSource == 'email' ? 
                    <input
                      type="text"
                      placeholder="Enter Email ID"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={e => setEmailOrPhone(e?.target?.value)}
                    />
:
<div className='sm_mobnum_select_holder rounded-1 editprfl__mob_input px-2'>
                <PhoneInput
                  placeholder='Enter Phone Number'
                  country={'us'}
                />
                </div>
}
                  </div>
                  <NavLink className="header_links" to='/welcome'>
                  <button className='primary_btn w-100 mt-4' disabled = {!emailOrPhone}>Next</button>
                  </NavLink>

                  <div className='reg_orhr_holder position-relative my-5'>
                    <p className='reg_or m-0 inter_font px-4'>or</p>
                    <div className='reg_hr'></div>
                  </div>

                  <div className='reg_google_auth px-3 rounded-2 position-relative'>
                    <img src={require('../assets/images/google.svg').default} className={`img-fluid ${theme == 'dark' ? 'reg_google_imgerdark' : 'reg_google_imger'}`} /> 
                    <p className={`reg_googleText  inter_font m-0 text-center ${theme == 'dark' ? 'white_text' : 'black_text'}`}>Continue with Google</p>
                  </div>

                  <p className='reg_primaryText inter_font mt-4'>Create a Binance Account</p>
                </Col>

                <Col lg={4} md={6} sm={12}>
                  <p className='reg_rightTtl m-0 inter_font'>Login with QR Code</p>
                  <div className='reg_right_imgHolder d-flex justify-content-center justify-content-md-start align-items-center gap-2 mt-3'>
                      <div className='reg_qrcode_holder position-relative'>
                        <img src={require('../assets/images/qrcode.svg').default} className={`img-fluid reg_qrcoder ${qrcode ? '' :'reg_qrcode_img'} ${theme == 'dark' ? '' : 'reg_qrcoder_filter'}`} />
                        {qrcode ? <></> : 
                        <button className='reg_qrcode_btn inter_font p-1 p-sm-2 rounded-2 w-100' onClick={() => setQrcode(true)}><img src={require('../assets/images/scanner.svg').default} className='img-fluid me-1 me-sm-2' />Get QR Code</button>}
                      </div>
                      <img src={require('../assets/images/mobqr.svg').default} className='img-fluid reg_mobqr' />

                      </div>
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default Register