import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink, useNavigate } from 'react-router-dom';
import { ValidateForgototp } from '../Validation/UserValidation';
import { SendOtpForgotHooks, VerifyOtpForgotHooks } from '../Hooks/UserHooks';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import { toast } from 'react-hot-toast';

function ForgotPassword() {
    const theme = useSelector((state) => state?.User?.theme);
    const navigate = useNavigate();
    const [otpShow,setOtpShow] = useState(false)
    const [otp, setOtp] = useState('');

    const [phoneoremail , setPhoneoremail] = useState("");
    const [error , setError] = useState({});
    const [type , setType] = useState("");
    const [temppayload , setTemppayload] = useState({});
    const [resendotp , setResendotp] = useState(false);

  const Completionist = () => <span className="text-danger">OTP Expired</span>
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist />;
      } else {
        return <span className="inter_font">{minutes}:{seconds}</span>;
      }
    };

    const HandleSubmit = async() => {
      try{
        var type ;
        var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(emailRegx.test(phoneoremail)){
          type = "email";
        }
        else{
          type = "mobile";
        }
        let payload = {
          type : type,
        }
        if(type == "email"){
          payload.email = phoneoremail
        }
        else{
          payload.phoneNo = phoneoremail
        }
        setTemppayload(payload);
        let validate = await ValidateForgototp(payload);
        console.log("validate" , validate);
        if(validate?.isvalid){
          let result = await SendOtpForgotHooks(payload);
          if(result?.type == "Success" && result?.message){
            setOtpShow(true);
            setError({});
          }
          else if(result?.type == "Success" && result?.error){
            toast.error(result?.error);
          }
        }
        else{
          setError(validate?.error);
        }
      }
      catch(e){
        console.log("Error on handle submit" , e);
      }
    }

    const HandleVerifyOtp = async() => {
      try{
        if(otp?.length == 6){
          let payload = temppayload;
          payload.otp = otp;
          let result = await VerifyOtpForgotHooks(payload);
          if(result?.type == "Success" && result?.message){
            setError({});
            navigate("/createpassword" , {state : payload});
          }
        }
        else{
          let err = {otp : "Invalid Otp"};
          setError(err);
        }
      }
      catch(e){
        console.log("Error on handle verify otp" , e);
      }
    }

   


  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center'>Welcome to Otex!</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <Row className='align-items-center justify-content-between'>
                <Col lg={6} md={7} sm={12} className='mb-5 mb-md-0'>
                <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Email/Phone Number</label>
                    <input
                      type="text"
                      placeholder="Enter Email"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => setPhoneoremail(e?.target?.value)}
                      readOnly = {otpShow}
                    />
                    <p className='text-danger'>{error?.phoneoremail}</p>
                  </div>

                 {!otpShow && <button className='primary_btn w-100 mt-4'
                  onClick={() => {HandleSubmit()}}
                  disabled = {!phoneoremail}
                  >Submit</button>}

                {otpShow &&
                  <Row className="justify-content-center mt-4">
                    <Col lg={5} md={7} sm={10} xs={12}>
                      <div className="login_otp_holder d-flex justify-content-center align-items-center mt-4">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <div className="login_otpOption_holder inter_font text-center mt-2">
                        <Countdown
                          date={Date.now() + 300000}
                          renderer={renderer}
                        />
                      </div>
                      <div className="text-center">
                        <button className="login_resendOTP inter_font ms-3 mt-4" 
                        onClick={HandleSubmit}>Resend OTP</button>
                      </div>
                    </Col>
                    <div className="text-end mt-3">
                        <button className="primary_btn" onClick={HandleVerifyOtp}>Submit</button>
                    </div>
                  </Row>} 

               <NavLink to='/login' className='header_links'>
                  <p className='inter_font mt-4 m-0 reg_primaryText'>Back to Log in</p>
                  </NavLink>
                </Col>
                <Col lg={5} md={5} sm={12}>
                    <img src={require('../assets/images/lockkey.svg').default} className='img-fluid' />
                </Col>
            </Row>


           
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default ForgotPassword