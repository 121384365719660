const BASE_URL = "user";

export const sendRegisterOtpApi = BASE_URL + "/send-register-otp";

export const CreateNewUserApi = BASE_URL + "/create-new-user";

export const VerifyRegisterotpApi = BASE_URL + "/verify-register-otp";

export const LoginApi = BASE_URL + "/login";

export const GetUserApi = BASE_URL + "/get-user";

export const SendOtpForgotApi = BASE_URL + "/send-otp-forgot";

export const VerifyForgotOtpApi = BASE_URL + "/verify-forgot-otp";

export const UpdatePasswordApi = BASE_URL + "/update-password";

export const ChangeNewPhoneApi = BASE_URL + "/change-new-phone";

export const UpdateProfileApi = BASE_URL + "/update-profile";

export const UploadKycApi = BASE_URL + "/uploadkyc";

export const ResetPasswordApi = BASE_URL + "/reset-password";

export const UploadBankStatementApi = BASE_URL + "/upload-bank-statement";

export const AddupiPaymentApi = BASE_URL + "/add-upi-payment";

export const AddEmailApi = BASE_URL + "/add-email";

export const VerifyAddEmailApi = BASE_URL + "/verify-add-email";

export const ChangeEmailApi = BASE_URL + "/change-email";

export const VerifyOldEmailApi = BASE_URL + "/verify-old-email";

export const UpdateNewEmailApi = BASE_URL + "/update-new-email";

export const AddMobileNumberApi = BASE_URL + "/add-mobile-number";

export const VerifyAddMobileNumberApi = BASE_URL + "/verify-add-mobilenumber";

export const GetLoginHistoryApi = BASE_URL + "/get-loginhistory";

export const Get2faCodeApi = BASE_URL + "/get-2fa-code";

export const Update2faCodeApi = BASE_URL + "/update-2fa";

export const Disable2faCodeApi = BASE_URL + "/disable-2fa";