import React, { useState, Fragment, useRef, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { MdArrowForwardIos } from "react-icons/md";
import qr from "../assets/images/cmn_tabs/QR_Code.png";
import copy from "../assets/images/cmn_tabs/copy.png";
import usdt from "../assets/images/cmn_tabs/usdt.png";
import tips from "../assets/images/cmn_tabs/tips.png";
import chain from "../assets/images/cmn_tabs/address_chain.png";
import bdr_btm from "../assets/images/cmn_tabs/border_btm.png";
import bdr_table from "../assets/images/cmn_tabs/table_bdr.png";
import ReactDatatable from "@ashvin27/react-datatable";
import DatePicker from "react-datepicker";
import coinlogo from "../assets/images/cmn_tabs/usdt.png";
import "react-datepicker/dist/react-datepicker.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import { GetUserHooks, GetUserId } from "../Hooks/UserHooks";
import { GetCurrencyHooks } from "../Hooks/P2pHooks";
import { useSelector } from "react-redux";

function Deposit() {

  const theme = useSelector((state) => state?.User?.theme);
  // const [startDate, setStartDate] = useState(new Date());

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const ref = useRef()
  const [selected, setSelected] = useState({
    value: "",
    label: "",
  });
  const [options , setOptions] = useState([]);
  // const options = [
  //   {
  //     value: "BNB",
  //     label: "BNB",
  //   },
  //   {
  //     value: "bnb",
  //     label: "bnb",
  //   },
  //   {
  //     value: "Cron",
  //     label: "Cron",
  //   },
  //   {
  //     value: "Crons",
  //     label: "Crons",
  //   },
  //   {
  //     value: "Polygon",
  //     label: "Polygon",
  //   },
  // ];

  const options1 = [
    {
      value: "trs",
      label: "TRS",
    },
    {
      value: "eth",
      label: "ETH",
    },
    {
      value: "bsc",
      label: "BSC",
    },
    {
      value: "ht",
      label: "HT(Heco)",
    },
  ];
  
  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      border: "none",
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00CABA",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  //dev
  const [user , setUser] = useState({});
  const [userkyc , setUserkyc] = useState({});
  const [userwallet , setUserwallet] = useState({});
  const [currency , setCurrency] = useState({});

  const fetchdata = async() => {
    let result = await GetUserHooks({userid : GetUserId()});
    if(result?.type == "Success"){
      setUser(result?.record);
      setUserkyc(result?.kyc);
      setUserwallet(result?.wallet);
    }
    let currencyresult = await GetCurrencyHooks();
    if(currencyresult?.type == "Success"){
      setOptions(currencyresult?.record);
      setSelected(currencyresult?.record[0]);
    }
  }

  useEffect(() => {
    fetchdata();
  } , [])

  return (
    <div className="deposit">
      <div className="row">
        <div className="col-xl-6 mb-3">
          <div className="fst_sec">
            <div className="mt-4">
              <span className="step inter_font">Step 1</span>
              <div className="coin mt-4">
                <p className="mb-0 inter_font">Select a coin</p>
                <div className="market_coinname_select position-relative w-100">
          <Select
                    className=""
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <span className="step inter_font">Step 2</span>
              <div className="coin mt-4">
                <p className="mb-0 inter_font">Choose a Network</p>
                <div className="market_coinname_select position-relative w-100">
          <Select
                    className=""
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options1}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="">
             
              <div className="coin mt-4">
                <div className="dpt_add">
                  <p className="mb-0 inter_font">Deposit address</p>
                  <a href="" className="manage_address">
                    <p className="mb-0 manage inter_font">
                      Manage address
                      <MdArrowForwardIos />
                    </p>
                  </a>
                </div>
                <div className="qrcode mt-4">
                  <img src={qr} alt="qr" className="qr img-fluid" />
                  <div className="w-100">
                    <p className="mb-0 usdt_add">USDT address</p>
                    <div>
                      <p className="address_val">
                        <img src={usdt} alt="usdt" className="usdt img-fluid" />
                        <span className="inter_font">USDT</span>
                      </p>
                      <CopyToClipboard
                        onCopy={() => console.log("copycopycopu")}
                      >
                        <p className="d-flex align-items-center mb-0">
                          <img
                            src={copy}
                            alt="copy"
                            className="copy img-fluid me-1"
                          />
                          <span className="copy_add">Copy address</span>
                        </p>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="snd_sec rounded-2">
            <p className="tips mb-0 px-3 py-3 inter_font">
              <img src={tips} alt="tips" className="img-fluid tips me-2" />
              Tips
            </p>
            <div className="mb-3 mb-lg-4 px-4 px-lg-5 dec inter_font">
              <p className="">
                For the safety of your funds, our customer support team may
                contact you by phone to confirm your withdrawal request. Please
                pay attention to incoming calls.
              </p>
              <p>
                {" "}
                Ensure the security of your computer and browser to prevent
                information from being tampered with or leaked.
              </p>{" "}
              <p>
                Be aware of scams! Otex will never ask you for your account,
                Password or any personal information, nor will it request
                private transfers or withdrawals.
              </p>
            </div>
            <div className="cnt_details my-4">
              <div className="details mb-2">
                <span className="inter_font">Minimum deposit amount</span>
                <span className="inter_font">0.01 USDT</span>
              </div>
              <div className="details mb-2">
                <span className="inter_font">Transfer in</span>
                <Dropdown className="transfer inter_font">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Spot account
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Spot account
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Spot account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="details mb-2">
                <span className="inter_font">Contact address</span>
                <span className="inter_font">
                  ***Jlj5t{" "}
                  <img
                    src={chain}
                    alt="chain"
                    className="img-fluid chain ms-2"
                  />
                </span>
              </div>
              <div className="details mb-2">
                <span className="inter_font">Deposit confirmation</span>
                <span className="inter_font">1 Network confirmation</span>
              </div>
              <div className="details">
                <span className="inter_font">Withdrawal unlocking</span>
                <span className="inter_font">1 Network confirmation</span>
              </div>
              {/* <span className="border_btn"></span> */}
              <img
                src={bdr_btm}
                alt="bdr_btm"
                className="img-fluid mt-3 mb-3"
              />
              <p className="warning_msg mb-1 inter_font">
                Minimum deposit amount 0.01 USDT. Any deposits less than minimum
                will not be credited or refunded
              </p>
              <p className="note mb-1 inter_font">
                TRC20-USDT only support direct transfer instead of transactions
                generated from smart contract
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="my-3 my-xl-5 deposit_credit inter_font">
        Deposit not credited,{" "}
        <NavLink className="refund_request ms-1">
          <span>
            {" "}
            request refund <MdArrowForwardIos />
          </span>
        </NavLink>
      </p>
      <div className="custom_deposit_table">
        <Row className="mt-3">
          <Col lg={4} md={6} sm={6} className="mb-3 mb-sm-0">
          <div className="market_coinname_select position-relative w-100">
          <Select
                    className=""
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="mb-3 mb-sm-0">
            <div className="deposit_custom_datepoicker rounded-2 d-flex justify-content-start align-items-center px-2 position-relative">
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                // monthsShown={2}
              />
              <img src={require('../assets/images/white_calendar.svg').default} className="img-fluid deposit_calImg" /> 
            </div>
          </Col>
        </Row>
        <div className="custom_fields mt-3">
          <img src={bdr_table} alt="border" className="img-fluid" />
        </div>
        <Row className="py-3 spot_table_singlerow mb-3 rounded-2" >
          <Col lg={3} md={3} sm={4} className="d-flex justify-content-center justify-content-sm-start align-items-sm-center align-items-md-start mb-3 mb-md-0">
            <div>
            <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
              Coin :
            </h6>
            <div className="d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
              <img
                src={require("../assets/images/tehther.svg").default}
                className="img-fluid spot_table_img"
              />
              <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                USDT
              </p>
            </div>
            </div>
          </Col>
          <Col lg={9} md={9} sm={8}>
            <Row>
              <Col lg={3} md={3} sm={6} xs={6} className="mb-3 mb-md-0">
              <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
              Time :
            </h6>
            <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
            12:01
              </p>

              </Col>
              <Col lg={3} md={3} sm={6} xs={6} className="mb-3 mb-md-0">
              <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
              Amout :
            </h6>
            <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                1000
              </p>

              </Col>
              <Col lg={3} md={3} sm={6} xs={6} className="mb-3 mb-md-0">
              <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
              Status :
            </h6>
            <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                Active
              </p>

              </Col>
              <Col lg={3} md={3} sm={6} xs={6} className="mb-3 mb-md-0">
              <h6 className="spot_tabler_title mb-2 text-center text-sm-start inter_font">
              Action :
            </h6>
            <p className="spot_img_hinter inter_font text-center text-sm-start inter_font">
                Deposit
              </p>

              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Deposit;
