import React, { useEffect, useState } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-hot-toast";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from 'react-phone-input-2';
import {Country} from "../Config/Country";
import { ValidateEmptyString , ValidateEmail} from '../Validation/UserValidation';
import { AddEmailHooks, AddMobileNumberHooks, GetUserHooks, GetUserId, UpdateProfileHooks, VerifyAddEmailHooks } from '../Hooks/UserHooks';
import OTPInput from 'react-otp-input';
import Countdown from 'react-countdown';

function EditProfile({setActiveCont}) {
    const theme = useSelector((state) => state?.User?.theme);
    const dispatch = useDispatch();
    // const user = useSelector((state) => state?.User?.theme);
    const [selectedFlag, setSelectedFlag] = useState("");
    const [phoneNum,setPhoneNum] = useState();

    //personaldetail
    const [initialvalue , setInitialvalue] = useState({
      firstname : "",
      lastname : "",
      buildingblock : "",
      address : "",
      city : "",
      postalcode : "",
      state : "",
      country : "India",
      countrycode : "+91"
    })
    const [error , setError] = useState({
      firstname : "",
      lastname : "",
      buildingblock : "",
      address : "",
      city : "",
      postalcode : "",
      state : "",
      country : ""
    });
    const [firstname , setFirstname] = useState("");
    const [lastname , setLastname] = useState("");
    const [buildingblock , setBuildingblock] = useState("");
    const [address , setAddress] = useState("");
    const [city , setCity] = useState("");
    const [postalcode , setPostalcode] = useState("");
    const [state , setState] = useState("");
    const [country , setCountry] = useState("");
    const [otpShow,setOtpShow] = useState(false)
    const [otp, setOtp] = useState('');

    const [userdata , setUserdata] = useState({});
    const [userkyc , setUserkyc] = useState({})

    const [email , setEmail] = useState("");
    const [emailotpshow , setEmailotpshow] = useState(false);
    const [emailotp , setEmailotp] = useState("");
    // const [isvalid , setIsvalid] = useState(true);

    const [phoneinitial , setPhoneinitial] = useState({
      phoneNo : "",
      phonecode : ""
    });
    const [mobileerror , setMobileerror] = useState("");
    const [phoneerror , setPhoneerror] = useState({});
    const user = useSelector((state) => console.log("selector" , state?.User));

    const [emailerror , setEmailerror] = useState("");
    const [emailcountdown , setEmailcountdown] = useState(0);
    const [mobilecountdown , setMobilecountdown] = useState(0);

    console.log(theme,"sidfhojhnsdf");

    const HandleChange = (e) => {
      try{
        let init = initialvalue;
        init[e?.target?.name] = e?.target?.value;
        setInitialvalue(init);
        let validate = ValidateEmptyString(e?.target?.value);
        console.log("validate" , validate);
        if(!validate){
          let err = error;
          err[e?.target?.name] = "Field is required"
          // setError(err);
          setError({...error , [e?.target?.name] : "Field is required"})
          console.log("Error" , err);
        }
        else{
          let err = error;
          err[e?.target?.name] = "";
          // setError(err);
          setError({...error , [e?.target?.name] : ""})
          console.log("Error" , err);
        }
      }
      catch(e){
        console.log("Error on handlechange" , e);
      }
    } 

    const HandleSave = async() => {
      try{
        let payload = {
          firstname : initialvalue?.firstname,
          lastname : initialvalue?.lastname,
          address : initialvalue?.address,
          city : initialvalue?.city,
          postalcode : initialvalue?.postalcode,
          state : initialvalue?.state,
          country : initialvalue?.country,
          buildingName : initialvalue?.buildingblock,
          userid : GetUserId()
        }
        if(initialvalue?.firstname && initialvalue?.lastname && initialvalue?.address && initialvalue?.city && initialvalue?.postalcode
          && initialvalue?.state && initialvalue?.country){
            let result = await UpdateProfileHooks(payload);
            if(result?.type == "Success" && result?.message){
              toast.success(result?.message);
            }
          }
          else{
            toast.error("Please fill all the fields")
          }
      }
      catch(e){
        console.log("Error on handle save" , e);
      }
    }
    const Completionist = () => <span className="text-danger">OTP Expired</span>
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist />;
      } else {
        return <span className="inter_font">{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</span>;
      }
    };

    const fetchdata = async() => {
      let user = await GetUserHooks({userid : GetUserId()} , dispatch);
      console.log("userdata ," , user);
      setUserdata(user?.record);
      setUserkyc(user?.kyc); //new, pending, approved, rejected
      let ctry = Country.find(e => e?.name == user?.record?.country)
      setInitialvalue({...initialvalue , ["firstname"] : user?.record?.firstName , ["lastname"] : user?.record?.lastName
      , ["buildingblock"] : user?.record?.buildingName , ["address"] : user?.record?.address 
      , ["city"] : user?.record?.city , ["postalcode"] : user?.record?.postalCode , ["state"] : user?.record?.state
      , ["country"] : user?.record?.country});
      if(user?.kyc?.idProof?.status == "pending" || user?.kyc?.idProof?.status == "approved" 
      || user?.kyc?.idProof?.status == "rejected"){
        // setProcess("");
      }
    }
  
    useEffect(() => {
      fetchdata();
    } , [])

    const handlePhoneNumber = (value, country) => {
      const { dialCode } = country;
      let newPhoneNo = value;
      console.log("dial" , dialCode , newPhoneNo);
      // let formData = formValue;
      if (dialCode) {
        setPhoneinitial({...phoneinitial , ["phonecode"] : dialCode , ["phoneNo"] : newPhoneNo.slice(dialCode.length)})
      } else if (value) {
          setPhoneinitial({...phoneinitial , ["phoneNo"] : newPhoneNo.slice(dialCode.length)})
      }
      if(!value?.slice(dialCode.length) || !dialCode){
        setMobileerror("Field is required");
      }
      else{
        setMobileerror("");
      }
      // setValidateError(validation(formData))
  }

  const handleAddPhoneNumber = async() => {
    try{
      let payload = {
        userid : GetUserId(),
        phoneno : phoneinitial?.phoneNo,
        phonecode : phoneinitial?.phonecode
      }
      let result = await AddMobileNumberHooks(payload);
      console.log("Result in add mobile" , result);
      if(result?.type == "Success" && result?.message){
        setOtpShow(true);
        setMobilecountdown(Date.now());
      }
      else{}
    }
    catch(e){
      console.log("error on handle add phone number" , e);
    }
  }

  


  const handleAddEmail = async() => {
    try{
      let payload = {
        email : email,
        userid : GetUserId()
      }
      let result = await AddEmailHooks(payload);
      if(result?.type == "Success" && result?.message){
        setEmailcountdown(Date.now())
        setEmailotpshow(true);
      }
      else if(result?.type == "error" && result?.error){
        toast.error(result?.error);
      }
      console.log("result in add email" , result);
    }
    catch(e){
      console.log("Error on handle Add Email" , e);
    }
  }

  const handleVerifyEmail = async() => {
    try{
      let payload = {
        userid : GetUserId(),
        otp : emailotp
      }
      let result = await VerifyAddEmailHooks(payload);
      if(result?.type == "Success" && result?.message){
        
        setEmailotpshow(false);
        toast.success("Email updated Successfully");
      }
      else if(result?.type == "error" && result?.error){
        toast.error("Error on server")
      }
    }
    catch(e){
      console.log("Error on handle verify email" , e);
    }
  }


  return (
    <>
    <div className="notices_holder p-2 p-sm-3 pb-md-5">
    <Row>
          <Col lg={5} sm={5} className="d-flex align-items-center mb-3 mb-md-0">
            <h5 className="notices_hader_title ">Edit Profile</h5>
          </Col>
          <Col
            lg={7}
            sm={7}
            className="d-flex flex-column flex-sm-row justify-content-end align-items-start gap-3"
          >
            <div className='editProfile__userId_holder d-flex justify-content-end align-items-center gap-2'>
                <p className='m-0 inter_font editprfl__userId'>User ID: <span>{userdata?.userId}</span></p>
                <CopyToClipboard onCopy={() => console.log("copycopycopu")}>
                  <p className="d-flex align-items-center mb-0">
                    <img
                      src={require("../assets/images/outlinecopy.svg").default}
                      alt="copy"
                      className={`${theme == 'dark' ? 'mt_whitecopy_img' : 'mt_whitecopy_img_invert'}`}
                    />
                  </p>
                </CopyToClipboard>
            </div>
          </Col>
        </Row>

        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

        <Row className='mt-5'>
            <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">First Name</label>
                    <input
                      type="text"
                      value={initialvalue?.firstname}
                      name = "firstname"
                      placeholder="Enter First Name"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => HandleChange(e)}
                    />
                    {error && <p className='text-danger'>{error?.firstname}</p>}
                  </div>
            </Col>

            <Col sm={6} className='mb-3'>
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Last Name</label>
                    <input
                      value={initialvalue?.lastname}
                      type="text"
                      name = "lastname"
                      placeholder="Enter Last Name"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => HandleChange(e)}
                    />
                    {error && <p className='text-danger'>{error?.lastname}</p>}
                  </div>
            </Col>

            <Col sm={6} className='mb-3'>
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Building/Block Name</label>
                    <input
                      value={initialvalue.buildingblock}
                      type="text"
                      name = "buildingblock"
                      placeholder="Enter Building/Block Name"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => HandleChange(e)}
                    />
                    {error && <p className='text-danger'>{error?.buildingblock}</p>}
                  </div>
            </Col>

            <Col sm={6} className='mb-3'>
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Address</label>
                    <input
                      type="text"
                      value = {initialvalue?.address}
                      name = {"address"}
                      placeholder="Enter Address"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => HandleChange(e)}
                    />
                    {error && <p className='text-danger'>{error?.address}</p>}
                  </div>
            </Col>

            <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">City</label>
                    <input
                    value={initialvalue?.city}
                      type="text"
                      name = {"city"}
                      placeholder="Enter City"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => HandleChange(e)}
                    />
                    {error && <p className='text-danger'>{error?.city}</p>}
                  </div>
            </Col>

            <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">PostalCode</label>
                    <input
                    value={initialvalue?.postalcode}
                      type="text"
                      name='postalcode'
                      placeholder="Enter PostalCode"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => HandleChange(e)}
                    />
                    {error && <p className='text-danger'>{error?.postalcode}</p>}
                  </div>
            </Col>

          <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
              <label className="mb-2 inter_font">State</label>
              <input
              value={initialvalue?.state}
                type="text"
                name='state'
                placeholder="Enter Last Name"
                className="primary_input rounded-1 p-2 inter_font"
                onChange={(e) => HandleChange(e)}
              />
              {error && <p className='text-danger'>{error?.state}</p>}
            </div>
          </Col>

          <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
              <label className="mb-2 inter_font">Counry/Region</label>
              <div className="cmn_flag_select rounded-2 d-flex align-items-center" title={selectedFlag}>
                {/* <ReactFlagsSelect
                  className=" pb-0 w-100"
                  selected={selectedFlag}
                  showSecondarySelectedLabel={true}
                  selectedSize={10}
                  onSelect={(code) => {setSelectedFlag(code)}}
                /> */}
                <Dropdown className='register_dropDown w-100' >
                  <Dropdown.Toggle id="dropdown-basic" >
                    {initialvalue?.country ? initialvalue?.country : "Select Country..."}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='register_dropdown_menu rounded-2'>
                    {Country?.map((data, i) =>
                      <Dropdown.Item className='register_dropdown_item inter_font'
                        onClick={() => {
                          // let init = initialvalue
                          // init.country = data?.name
                          // init.countrycode =  data?.dial_code
                          setInitialvalue({...initialvalue , ["country"] : data?.name , ["countrycode"] : data?.dial_code});
                        }}
                      >{data?.name}</Dropdown.Item>

                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <p className='text-danger'>{error?.country}</p>
              </div>
            </div>
          </Col>

           

            {/* <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Email</label>
                    <input
                      type="email"
                      placeholder="Enter Last Name"
                      className="primary_input rounded-1 p-2 inter_font"
                    />
                  </div>
            </Col> */}

            {/* <Col sm={6} className='mb-3'>
            <div className='sm_inputs_holder '>
            <label className='sm_inputs_label inter_font'>New phone number</label>
<div className='sm_mobnum_select_holder rounded-1 editprfl__mob_input px-2 mt-2'>
<PhoneInput
placeholder='New Phone Number'
  country={'us'}
  value={phoneNum}
  onChange={phone => setPhoneNum(phone)}
/>
</div>
          </div>
            </Col> */}

            {/* <Col lg={12} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Address</label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="primary_input rounded-1 p-2 inter_font"
                    />
                  </div>
            </Col> */}

            {/* <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Date of subscription : &nbsp;&nbsp;&nbsp;<span className='editprfl__subs_date'>{new Date().toLocaleDateString()} {' '}{new Date().toLocaleTimeString()}</span></label>
                  </div>
            </Col> */}
        </Row>

        <div className='editprfl__dual_btns d-flex justify-content-start align-items-center gap-3 mt-4'>
            <button className='secondary_btn rounded-1' onClick={() => setActiveCont("profile")}>Cancel</button>
            <button className='primary_btn rounded-1' onClick={HandleSave}>Save</button>
        </div>

        {!userdata?.phoneCode && !userdata?.phoneNo && <Row className='mt-5'>
          <Col sm={6} className='mb-3'>
            <div className='sm_inputs_holder '>
              <label className='sm_inputs_label inter_font'>New phone number</label>
              <div className='sm_mobnum_select_holder rounded-1 editprfl__mob_input px-2 mt-2'>
                <PhoneInput
                  placeholder='New Phone Number'
                  country={'us'}
                  value={phoneinitial?.phonecode + phoneinitial?.phoneNo}
                  onChange={handlePhoneNumber}
                  disabled = {otpShow}

                />
              </div>
              <p className='text-danger'>{mobileerror}</p>
              <button className='primary_btn rounded-1'
              disabled = {mobileerror}
              onClick={handleAddPhoneNumber} >Send Otp</button>
            </div>
          </Col>
          {otpShow &&
                  <Row className="justify-content-center mt-4">
                    <Col lg={5} md={7} sm={10} xs={12}>
                      <div className="login_otp_holder d-flex justify-content-center align-items-center mt-4">
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <div className="login_otpOption_holder inter_font text-center mt-2">
                        <Countdown
                          date={Date.now() + 300000}
                          renderer={renderer}
                        />
                      </div>
                      <div className="text-center">
                        <button className="login_resendOTP inter_font ms-3 mt-4" 
                        // onClick={HandleSubmit}
                        >Resend OTP</button>
                      </div>
                    </Col>
                    <div className="text-end mt-3">
                        <button className="primary_btn" 
                        // onClick={HandleVerifyOtp}
                        >Submit</button>
                    </div>
                  </Row>}
        </Row>}


        {!userdata?.email && <Row className='mt-5'>
          <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
              <label className="mb-2 inter_font">Email</label>
              <input
                readOnly = {emailotpshow}
                type="email"
                name="email"
                value = {email}
                placeholder="Enter Email"
                className="primary_input rounded-1 p-2 inter_font"
              onChange={(e) => {
                setEmail(e?.target?.value);
                if(e?.target?.value){
                  let validate = (e?.target?.value)?.toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ); //ValidateEmail(e?.target?.value);
                  console.log("validateion" , validate);
                  if(validate){
                    setEmailerror("");
                  }
                  else{
                    setEmailerror("Invalid Email");
                  }
                }
                else{
                  setEmailerror("Field is required");
                }
              }}
              />
              {emailerror && <p className='text-danger'>{emailerror}</p>}
              {!emailotpshow && <button className='primary_btn rounded-1' disabled={!email && emailerror}
              onClick={handleAddEmail}
              >Send Otp</button>}
            </div>
          </Col>
          {emailotpshow &&
                  <Row className="justify-content-center mt-4">
                    <Col lg={5} md={7} sm={10} xs={12}>
                      <div className="login_otp_holder d-flex justify-content-center align-items-center mt-4">
                        <OTPInput
                          value={emailotp}
                          onChange={setEmailotp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <div className="login_otpOption_holder inter_font text-center mt-2">
                        <Countdown
                          date={emailcountdown + 300000}
                          renderer={renderer}
                        />
                      </div>
                      <div className="text-center">
                        <button className="login_resendOTP inter_font ms-3 mt-4" 
                        onClick={handleAddEmail}
                        >Resend OTP</button>
                      </div>
                    </Col>
                    <div className="text-end mt-3">
                        <button className="primary_btn" 
                        disabled = {emailotp?.length < 6}
                        onClick={handleVerifyEmail}
                        >Submit</button>
                    </div>
                  </Row>}
        </Row>}
        {userdata?.email && <Row className='mt-5'>
          <Col sm={6} className='mb-3'>
            <div className="login_single_input d-flex flex-column">
              <label className="mb-2 inter_font">Email</label>
              <input
                readOnly = {true}
                type="email"
                name="email"
                value = {userdata?.email}
                placeholder="Enter Email"
                className="primary_input rounded-1 p-2 inter_font"
             
              />
            </div>
          </Col>
        </Row>}
    </div>
    </>
  )
}

export default EditProfile