import React from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux';

function NoticeInfo() {
  const theme = useSelector((state) => state?.User?.theme);
  return (
    <>
    <div className='notices_holder p-3'>
    <h5 className="notices_hader_title ">Notices</h5>
    <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

        <div className='ni_hintDate_holder'>
          <p className='ni_hintdate_text inter_font'>[Note] Upbit Singapore is now officially licensed by the MAS!</p>
          <span className='ni_date_span'>08 jan 2024</span>
        </div>
        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>
        <div className='ni_commont_subject'>
          <p className='ni_common_sub'>Hello traders,</p>
          <p className='ni_common_sub'>Happy new year</p>
        </div>
        <img src={require('../assets/images/blurgraph.svg').default} className='img-fluid ni_demo_img mt-4' />
          <p className='ni_common_sub mt-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi at cursus erat. Ut tincidunt tempus fermentum. Nullam consequat diam in ligula luctus, porttitor vulputate eros porta. Etiam posuere enim ut venenatis interdum. Nulla vel pretium augue. Sed a turpis eget dui maximus tristique vel nec arcu. Fusce id mollis diam, et varius lacus. Cras ut ultrices nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis nec arcu tincidunt, lobortis mauris 
</p>
    </div>
    </>
  )
}

export default NoticeInfo