import React, { useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Header from '../Components/Header';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import Footer from '../Components/Footer';

function Convert() {
    const theme = useSelector((state) => state?.User?.theme);
    const [closer, setCloser] = useState();
    const currencyRef = useRef();
    const [selectedFlag, setSelectedFlag] = useState("");

    const [swiperCont,setSwiperCont] = useState([
        {
            img : require('../assets/images/swiper.png'),
            title : 'Feel free to check the market price of Cryptocurrency in fiat currency.',
            hint : 'The cryptocurrency price standard is based on the Binance Centralized Exchange.The legal currency price is calculated by converting is based on the exchange rate of each country.'
        },
        {
            img : require('../assets/images/swiper.png'),
            title : 'Feel free to check the market price of Cryptocurrency in fiat currency.',
            hint : 'The cryptocurrency price standard is based on the Binance Centralized Exchange.The legal currency price is calculated by converting is based on the exchange rate of each country.'
        },
        {
            img : require('../assets/images/swiper.png'),
            title : 'Feel free to check the market price of Cryptocurrency in fiat currency.',
            hint : 'The cryptocurrency price standard is based on the Binance Centralized Exchange.The legal currency price is calculated by converting is based on the exchange rate of each country.'
        },
    ])

    const customCurrencyRenderer = ({ label, data, value }) => (
      <div
        className="market_select_component"
        onClick={() => {
          setSelectedCurrency({ label, value, img: data.img });
          currencyRef.current.blur();
          setCloser(!closer);
        }}
      >
        {console.log("sdhguisd", label, data, value)}
        <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
        {label}
      </div>
    );
    const options = [
      {
        value: "BNB",
        label: "BNB",
        img: require("../assets/images/BNB.png"),
      },
      {
        value: "bnb",
        label: "bnb",
        img: require("../assets/images/bnb.png"),
      },
      {
        value: "Cron",
        label: "Cron",
        img: require("../assets/images/CRON.png"),
      },
      {
        value: "Crons",
        label: "Crons",
        img: require("../assets/images/CRONS.png"),
      },
      {
        value: "Polygon",
        label: "Polygon",
        img: require("../assets/images/polygon.png"),
      },
    ];

    const [selectedCurrency, setSelectedCurrency] = useState({
      value: "0.00",
      label: "0.00",
      img: require("../assets/images/bnb.png"),
    });

    const styles = {
      option: (styles, { isFocused, isSelected, isHovered }) => ({
        ...styles,
        color: "#fff",
        background: isFocused
          ? "#00CABA"
          : isSelected
          ? "#00CABA"
          : isHovered
          ? "red"
          : "#00CABA",
  
        zIndex: 1,
        cursor: "pointer",
        fontSize: "13px",
      }),
  
      option: (styles, { isFocused, isSelected, isHovered }) => {
        // const color = chroma(data.color);
  
        return {
          ...styles,
          backgroundColor: isHovered
            ? "#16EBC3"
            : isSelected
            ? "#16EBC3"
            : isFocused
            ? "#16EBC3"
            : "#151515",
          cursor: "pointer",
          color: isHovered
            ? "#000"
            : isSelected
            ? "#000"
            : isFocused
            ? "#000"
            : "#fff",
          fontSize: "13px",
        };
      },
      indicatorSeparator: (styles) => ({display:'none'}),
      valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
        ...provided,
        height: "40px",
        paddingLeft: "60px",
        border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
        backgroundColor: isHovered
          ? "transperant"
          : isSelected
          ? "transperant"
          : isFocused
          ? "transperant"
          : "transperant",
        // border: "1px solid rgba(34, 34, 34, 0.32)",
        borderRadius: 5,
        fontSize: "13px",
        color: "#fff",
      }),
      control: (provided, { isFocused, isSelected, isHovered }) => ({
        ...provided,
        height: "40px",
        border: "1px solid #00CABA",
        borderRadius: 5,
        backgroundColor: isHovered
          ? "transperant"
          : isSelected
          ? "transperant"
          : isFocused
          ? "transperant"
          : "transperant",
        // backgroundColor: "#fff",
        border: "none",
        outline: "none",
        boxShadow: "none",
        color: "#fff",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "40px",
        position: "absolute",
        right: 0,
        top: 0,
        color: "#6C6A81",
        display: "none",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: theme == 'dark' ? "#fff" : "#343434",
      }),
      menuList: (base) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
      }),
    };
  return (
    <>
    <Container fluid className="common_spacer position-relative">
    <img
          src={require("../assets/images/fish_blurball.png")}
          className={theme == 'dark' ? "inner_fishblur" : 'd-none'}
        />
        <Header />
         <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_swiper_holder mt-3'>
          <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {swiperCont.map((item) => 
        <SwiperSlide>
            <div className='convert_swiper_singleSlide position-relative'>
                <img src={item.img} className='img-fluid convert_swiper_img' />
                <div className='swiper_textConts d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='convert_swiper_title inter_font text-center'>{item.title}</h3>
                    <p className='convert_swiper_hint inter_font text-center mt-4'>{item.hint}</p>
                </div>

            </div>
            </SwiperSlide>
            )}
      </Swiper>
          </div>

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4'>
            <Row className='justify-content-center'>

              <Col lg={6} md={6} sm={6} className='mb-3 mb-sm-0'>
              <p className="market_select_label inter_font">
                    Fiat Currency Selection
                  </p>
              <div className="cmn_flag_select rounded-2 d-flex align-items-center mt-2" title={selectedFlag}>
                    
                    <ReactFlagsSelect
                    className=" pb-0 w-100"
      selected={selectedFlag}
      showSecondarySelectedLabel={true}
      selectedSize={10}
      onSelect={(code) =>{console.log("code",code); setSelectedFlag(code)}}
    />
    </div>
              </Col>
            <Col lg={6} md={6} sm={6} className='mb-3 mb-sm-0'>
                          <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency.img}
                    className="img-fluid market_coinname_img conver_coinName_img"
                  />
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">
                    Select Cryptocurrency
                  </p>
                  <Select
                    className="mt-2"
                    defaultValue={selectedCurrency}
                    onChange={selectedCurrency}
                    options={options}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
                            
                          </Col>

                          <Col lg={11} className='my-5'>
                            <Row className='justify-content-between align-items-center'>
                              <Col lg={2} md={4} sm={4} xs={6} className='mb-4 mb-lg-0'>
                                <div className='convert_divide_holder d-flex justify-content-start align-items-start gap-2'>
                                  <img src={require('../assets/images/bin.svg').default} className='img-fluid' />
                                  <div className='divider'>
                                    <p className='convert_divider_coinName inter_font mb-1'>BTC</p>
                                    <div className='convert_hr' />
                                    <p className='convert_doubleStrike inter_font mt-1'><span className='me-1'>W</span>69,530,806</p>
                                  </div>
                                  
                                </div>
                              </Col>

                              <Col lg={2} md={4} sm={4} xs={6} className='mb-4 mb-lg-0'>
                                <div className='convert_divide_holder d-flex justify-content-start align-items-start gap-2'>
                                  <img src={require('../assets/images/eth.svg').default} className='img-fluid rounded-5' />
                                  <div className='divider'>
                                    <p className='convert_divider_coinName inter_font mb-1'>ETH</p>
                                    <div className='convert_hr' />
                                    <p className='convert_doubleStrike inter_font mt-1'><span className='me-1'>W</span>69,530,806</p>
                                  </div>
                                  
                                </div>
                              </Col>

                              <Col lg={2} md={4} sm={4} xs={6} className='mb-4 mb-lg-0'>
                                <div className='convert_divide_holder d-flex justify-content-start align-items-start gap-2'>
                                  <img src={require('../assets/images/x.svg').default} className='img-fluid rounded-5' />
                                  <div className='divider'>
                                    <p className='convert_divider_coinName inter_font mb-1'>XRP</p>
                                    <div className='convert_hr' />
                                    <p className='convert_doubleStrike inter_font mt-1'><span className='me-1'>W</span>69,530,806</p>
                                  </div>
                                  
                                </div>
                              </Col>

                              <Col lg={2} md={4} sm={4} xs={6} className='mb-4 mb-lg-0'>
                                <div className='convert_divide_holder d-flex justify-content-start align-items-start gap-2'>
                                  <img src={require('../assets/images/bubble.svg').default} className='img-fluid rounded-5' />
                                  <div className='divider'>
                                    <p className='convert_divider_coinName inter_font mb-1'>ADA</p>
                                    <div className='convert_hr' />
                                    <p className='convert_doubleStrike inter_font mt-1'><span className='me-1'>W</span>69,530,806</p>
                                  </div>
                                  
                                </div>
                              </Col>

                              <Col lg={2} md={4} sm={4} xs={6} className='mb-4 mb-lg-0'>
                                <div className='convert_divide_holder d-flex justify-content-start align-items-start gap-2'>
                                  <img src={require('../assets/images/doge.svg').default} className='img-fluid rounded-5' />
                                  <div className='divider'>
                                    <p className='convert_divider_coinName inter_font mb-1'>DOGE</p>
                                    <div className='convert_hr' />
                                    <p className='convert_doubleStrike inter_font mt-1'><span className='me-1'>W</span>69,530,806</p>
                                  </div>
                                  
                                </div>
                              </Col>

                              <Col lg={2} md={4} sm={4} xs={6} className='mb-4 mb-lg-0'>
                                <div className='convert_divide_holder d-flex justify-content-start align-items-start gap-2'>
                                  <img src={require('../assets/images/bin.svg').default} className='img-fluid rounded-5' />
                                  <div className='divider'>
                                    <p className='convert_divider_coinName inter_font mb-1'>BTC</p>
                                    <div className='convert_hr' />
                                    <p className='convert_doubleStrike inter_font mt-1'><span className='me-1'>W</span>69,530,806</p>
                                  </div>
                                  
                                </div>
                              </Col>
                            </Row>
                          </Col>
            </Row>


          </div>
         </Container>
    </Container>

    <Footer/>
    </>
  )
}

export default Convert