import React, { useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

function InquiryModal({show,handleClose,setShow}) {

    const doSubmit = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;
 
        if (validateCaptcha(user_captcha)===true) {
            alert('Captcha Matched');
            loadCaptchaEnginge(6); 
            document.getElementById('user_captcha_input').value = "";
        }
 
        else {
            alert('Captcha Does Not Match');
            document.getElementById('user_captcha_input').value = "";
        }
    };

    useEffect(() =>{
        // setTimeout(()=>{
            loadCaptchaEnginge(6,'','#fff'); 
        // },2000)
       },[])
  return (
    <>
    <Modal
      className="purchase_modal"
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
         <button
          className="cmn_modal_closer inter_font rounded-5"
          onClick={handleClose}
        >
          <i class="fa-solid fa-xmark" />
        </button>
      <Modal.Body className="p-4">
       

        <h6 className='security_modal_titles inter_font'><i class="fa-solid fa-arrow-left-long me-2" /> Please fill out your inquiry</h6>

    <div className='mt-4'>
    <div className='sm_inputs_holder mb-3'>
            <label className='sm_inputs_label inter_font'>Title</label>
            <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex'>
<input type='text' className='inter_font flex-grow-1' placeholder='Pleae enter title' />
</div>
          </div>

          <div className='sm_inputs_holder mb-3'>
            <Row className='justify-content-between'>
                <Col lg={6}>
            <label className='sm_inputs_label inter_font'>Details</label>
                </Col>
                <Col lg={6} className='d-flex justify-content-end align-items-center'>
                    <p className='cm_editors_hint inter_font'>Normal: <strong className='ms-4'>B</strong> <i className='ms-2'><i class="fa-solid fa-italic" /></i><u className='ms-3'>U</u></p>
                </Col>
            </Row>
            <div className='sm_mobnum_select_holder im_textarea_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex flex-column gap-2'>
                <textarea className='inter_font im_textarea' cols={20} maxLength={500} rows={8} placeholder='Enter your feedback, no more than 500 characters.' />

                <p className='im_textarea_count inter_font text-end'>0/500</p>
</div>
          </div>

          <div className='sm_inputs_holder mb-3'>
            <label className='sm_inputs_label inter_font'>Upload picture (optional)</label>
           <div className='im_captcha_file_holder'>
            <Row className='align-items-end'>
                <Col lg={5} className='d-flex justify-content-start align-items-center gap-2'>
                    
                            <div className='im_fileInp_btn position-relative rounded-2'>
                                <img src={require('../assets/images/plusser.png')} className='img-fluid im_uploadImg' />
                                <input type='file' className='im_fileInp' />
                            </div>
                       
                            <div className='im_fileInp_btn position-relative rounded-2'>
                                <img src={require('../assets/images/plusser.png')} className='img-fluid im_uploadImg' />
                                <input type='file' className='im_fileInp' />
                            </div>
                        
                            <div className='im_fileInp_btn position-relative rounded-2'>
                                <img src={require('../assets/images/plusser.png')} className='img-fluid im_uploadImg' />
                                <input type='file' className='im_fileInp' />
                            </div>
                </Col>

                <Col lg={7} className='d-flex gap-2 justify-content-end'>
                <div className='im_captcha_holder position-relative'>
               <LoadCanvasTemplate 
                reloadText= 'Reload'
                reloadColor="#00caba"
               />
               </div>

               <div className='im_captcha_typer position-relative'>
                <p className='m-0 im_captcha_flower'>*</p>
                <input className='im_captcha_typerInp inter_font  py-1 px-2' maxLength={6} id="user_captcha_input" name="user_captcha_input" type="text" />
               </div> 
               <button className='im_speaker_btn px-2 rounded-1'><img src={require('../assets/images/speaker.svg').default} className='img-fluid' /></button>
               {/* <button className='im_speaker_btn px-2 rounded-1'><img src={require('../assets/images/arr.svg').default} className='img-fluid' /></button> */}
                </Col>
            </Row>

            <p className='im_small_hint inter_font mt-4'>Please upload relevant screenshots for the issue, in JPG or PNG format, with a file size not exceeding 2MB</p>

            <div className='im_bottom_btnsHolder d-flex justify-content-end align-items-center gap-3 mt-4'>
                <button className='im_red_btn' onClick={handleClose}>Close</button>
                <button className='primary_btn' onClick={ () => {doSubmit();handleClose()}}>Confirm</button>
            </div>
           </div>
          </div>
    </div>


       
      </Modal.Body>
    </Modal>

  </> 
  )
}

export default InquiryModal