import React, { useState, useEffect } from "react";
import { FiEye } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import user from "../assets/images/cmn_tabs/user.png";
import date from "../assets/images/cmn_tabs/date.png";
import time from "../assets/images/cmn_tabs/time.png";
import { Doughnut } from "react-chartjs-2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from "faker";
import { GoCalendar } from "react-icons/go";
import { LuAlarmCheck } from "react-icons/lu";
import ReactApexChart from 'react-apexcharts'
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function Assets() {
  const theme = useSelector((state) => state?.User?.theme);
  const [changer,setChanger] = useState(false)

  const [pieChart,setPieChart] = useState([100,0,0,0])
  const datas = {

    series: [100, 0, 0,0],

    options: {
      labels: [`Spot &nbsp;&nbsp; - &nbsp;&nbsp; ${pieChart[0]} `, `Store &nbsp; - &nbsp; ${pieChart[1]}`, `Bot  &nbsp; &nbsp;&nbsp; - &nbsp; ${pieChart[2]}`, `Earn &nbsp; &nbsp; - &nbsp; ${pieChart[3]}`],
      colors: ['#00CABA', '#BC8026', '#0B80ED','#A730D1'],
      chart: {
        type: 'donut',
        height: 100,
        width: '100%',
        border: 'none',
        foreColor: theme == 'dark' ? '#fff' : '#000'

      },
      legend: {
        // show: false,
        position: 'right'

      },
      stroke:{
        colors:['transperant']
       },
      fill: {
        colors: ['#00CABA', '#BC8026', '#0B80ED','#A730D1'],
      },


       dataLabels: {
              enabled: true,},


      responsive: [{
        breakpoint: 992,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            // show: false,
            position: 'bottom'
    
          },
          
        }
      }]
    },

  };
  const lineData = {
    options: {
      colors:['#00CABA'],
      chart: {
        height: 300,
        type: 'area',
        foreColor: theme == 'dark' ? '#fff' : '#000'
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'text',
        categories: ['10/23','10/24','10/25','10/26','10/27','10/28','10/29','10/30']
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
    stroke:{
      colors:['transperant']
     },
    fill: {
      colors: ['#00CABA'],
      background :  ['#00CABA'],
    },
    series: [
      {
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100,110,55],
      },
    ],
  };
  const recentActivity = [
    {
      id: 1,
      head: "Withdraw",
      subhead: "From spot account",
      usd: "-137.4793175 USDT",
      date: "2023-10-12",
      time: "11:40",
      user: user,
    },
    {
      id: 2,
      head: "Withdraw",
      subhead: "From spot account",
      usd: "-137.4793175 USDT",
      date: "2023-10-12",
      time: "11:40",
      user: user,
    },
    {
      id: 3,
      head: "Withdraw",
      subhead: "From spot account",
      usd: "-137.4793175 USDT",
      date: "2023-10-12",
      time: "11:40",
      user: user,
    },
  ];



 



  const [themeVal, setThemeVal] = useState("");

  useEffect(() => {
    const val = localStorage.getItem("selectedTheme");
    setThemeVal(val);
  }, [themeVal]);

  // console.log("valueee", themeVal);

  return (
    <div className="assets">
      <Row>
        <Col lg={6} className="d-flex justify-content-center justify-content-sm-start">
        <div className="total_bal">
            <p className="mb-0 balance inter_font">
              Total Balance{" "}
              <span className="eye_icon ms-2">
                <FiEye />
              </span>
            </p>
            <div>
              <Dropdown className="custom_usd_drop">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  117.6074237 USDT
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    129.6074237 USDT
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    346.6074237 USDT
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    872.6074237 USDT
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="sub_drop">
                <Dropdown className="">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    = $117.61
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">= $117.61</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">= $117.61</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">= $117.61</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="row mt-5">
        <div className="col-lg-6 col-md-6 mb-4">
          <div className="donut_chart_sec">
            <div className="header mb-4">
              <h4 className="mb-0 inter_font assets_space_aligner mb-3 mb-sm-0">Asset Allocation</h4>
              <div className="asset_custom_toggler rounded-5 p-1">
                <button className={`asset_ct_button inter_font rounded-5 ${changer == false ? 'active' : ''}`} onClick={() => setChanger(false)}>Accounts</button>
                <button className={`asset_ct_button inter_font rounded-5 ${changer ? 'active' : ''}`} onClick={() => setChanger(true)}>Coins</button>
              </div>
              {/* <div className="custom_toggle">
                <div class="btn-container">
                  <label class="switch btn-color-mode-switch">
                    <input
                      type="checkbox"
                      name="color_mode"
                      id="color_mode"
                      // value="1"
                    />
                    <label
                      for="color_mode"
                      data-on="Coins"
                      data-off="Accounts"
                      class="btn-color-mode-switch-inner inter_font"
                    ></label>
                  </label>
                </div>
              </div> */}
            </div>
            <div className="cmntabs_donut_holder">
            <ReactApexChart options={datas.options} series={datas.series} type="donut" height={250} />
            </div>
            {/* <div className="doughnut_cus">
              <Doughnut data={data} />
            </div> */}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-4">
          <div className="area_chart_sec">
            <div className="d-flex justify-content-end">
              <div className="header mb-4">
                <span className="active_date date">7D</span>
                <span className="inactive_date date">30D</span>
                <span className="inactive_date date">90D</span>
              </div>
            </div>
            {/* <div className="area_chart">
              <Line options={options} data={datas} />
            </div> */}
            <div className="cmntabs_linechart_holder">
              <ReactApexChart
                options={lineData.options}
                series={lineData.series}
                type="area"
                height={250}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="recent_activity mt-5">
        <h5 className="mb-0 inter_font">Recent Activity</h5>
        <div className="details_list mt-4">
          {recentActivity.map((item, index) => {
            return (
              <>
              
              
              <Row className="rounded-2 justify-content-between align-items-center asset_single_row mb-3 py-3">
                <Col lg={6} sm={6} className="d-flex justify-content-center justify-content-sm-start mb-3 mb-sm-0">
                  <div className="cnt">
                    <NavLink className='header_links' to='/spot'>
                <div className="fst_sec">
                  <div className="position-relative">
                    <span className="noti"></span>
                    <img
                      src={item.user}
                      alt="user"
                      className="img-fluid user_logo"
                    />
                  </div>
                  <div>
                    <p className="mb-0 withdraw">{item.head}</p>
                    <p className="mb-0 account">{item.subhead}</p>
                  </div>
                </div>
                </NavLink>
                </div>
                </Col>
                <Col lg={6} sm={6} className="d-flex justify-content-center justify-content-sm-end">
                <div className="snd_sec">
                  <p className=" asset_table_rightttl">{item.usd}</p>
                  <div className="mb-0 date_time">
                    <span className="">
                      {/* <img
                        src={date}
                        alt="date"
                        className="img-fluid date me-2"
                      /> */}
                      <GoCalendar className="date me-1" size={17} />
                      {item.date}
                    </span>
                    <span>
                      {/* <img
                        src={time}
                        alt="time"
                        className="img-fluid time me-2"
                      /> */}
                      <LuAlarmCheck className="time me-1" size={17} />
                      {item.time}
                    </span>
                  </div>
                </div>
                </Col>
                
                </Row>

                
                
               
              </>
            );
          })}
        </div>
      </div>

      <div className="cmn__adSwiper mt-5">
                <Swiper loop={true}
                  navigation={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Navigation, Autoplay]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad1.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad2.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad3.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad3.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad1.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
    </div>
  );
}

export default Assets;
