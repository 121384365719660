import React,{ useState } from 'react';
import {Col, Offcanvas, Row, Dropdown} from 'react-bootstrap';

export default function DataTable(props) {
  console.log(props,"propsprops");

  return (

 <>
  <Offcanvas show={props.show} placement='end' onHide={props.handleClose} className='market_offcanva' backdrop="static">
        
        <Offcanvas.Body>
          <div className='market_canva_header d-flex justify-content-between align-items-center'>
            <h6 className='inter_font'>Market</h6>
            <i class="fa-solid fa-xmark" onClick={() => props.handleClose()}></i>
          </div>
        <div className='market_canva_table'>
        <div className="market_tabler_filter_holder d-flex justify-content-end mb-3 mt-4">
                  <Dropdown className="market_sort_dropdown" drop="start">
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      <i class="fa-solid fa-arrow-down-wide-short" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.ItemText className="inter_font">
                        <i class="fa-solid fa-arrow-down-wide-short me-2" />{" "}
                        Sorted By
                      </Dropdown.ItemText>
                      <Dropdown.Item className="inter_font">
                        Price: Lowest to Highest
                      </Dropdown.Item>
                      <Dropdown.Item className="inter_font">
                        Price: Highest to Lowest
                      </Dropdown.Item>
                      <Dropdown.Item className="inter_font">
                        Avg. Trade Speed: Fast
                      </Dropdown.Item>
                      <Dropdown.Item className="inter_font">
                        Avg. Trade Speed: Slow
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
          <Row className='market_canvatable_singleBox rounded-2 p-3 mb-3'>
            <Col lg={2} md={2} sm={4} xs={6} className='mb-3 mb-md-0'>
              <h6 className='market_canvatable_title inter_font'>Coin</h6>
              <div className='market_canvatable_hint_holder d-flex justify-content-start align-items-center gap-2 mt-2'>
                <img src={require('../assets/images/btc.svg').default} className='img-fluid market_canavtable_img' />
              <p className='market_canvatable_hint inter_font'>BTC</p>

              </div>
              <p className='market_canvatable_hint'></p>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} className='mb-3 mb-md-0'>
              <h6 className='market_canvatable_title inter_font'>24h.VoL</h6>
             
              <p className='market_canvatable_hint inter_font mt-2'>334.96</p>
            </Col>
            <Col lg={2} md={2} sm={4} xs={6} className='mb-3 mb-md-0'>
              <h6 className='market_canvatable_title inter_font'>7d VoL</h6>
             
              <p className='market_canvatable_hint inter_font mt-2'>2.51K</p>
            </Col>
            <Col lg={3} md={3} sm={4} xs={6} className=''>
              <h6 className='market_canvatable_title inter_font'>Latest Price</h6>
             
              <p className='market_canvatable_hint inter_font mt-2'>0.012</p>
            </Col>
            <Col lg={2} md={2} sm={4} xs={6} className=''>
              <h6 className='market_canvatable_title inter_font'>Change</h6>
             
              <p className='market_canvatable_hint inter_font mt-2'>-23.13%</p>
            </Col>
          </Row>

          
          

                </div>
        </Offcanvas.Body>
      </Offcanvas>

 </>
  );
}