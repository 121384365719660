import React, { useState } from "react";
import { Col, Modal, Row, Form } from "react-bootstrap";
import ChatBoxModal from "./ChatBoxModal";
import { useSelector } from "react-redux";

function Purchase({ showPurchase, handleClosePurchase,showChat,handleShowChat,handleShowWalBal}) {

    const theme = useSelector((state) => state?.User?.theme);
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity((quantity) => quantity + 1);
  };
  const handleDecrement = () => {
    setQuantity((quantity) => quantity - 1);
  };


 
  return (
    <>
      <Modal
        className="purchase_modal"
        centered
        size="lg"
        show={showPurchase}
        onHide={handleClosePurchase}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-4">
          <button
            className="cmn_modal_closer inter_font rounded-5"
            onClick={handleClosePurchase}
          >
            <i class="fa-solid fa-xmark" />
          </button>

          <Row>
            <Col lg={3}>
              <img
                src={require("../assets/images/shop.svg").default}
                className="img-fluid"
              />
            </Col>
            <Col lg={9}>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="si_desc_title inter_font">MyShopPrime</h6>
              </div>
              <p className="si_desc_sub_title mt-4 inter_font">
                Product description
              </p>
              <p className="si_desc mt-2 inter_font">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                quis nisl eget nunc finibus volutpat et eu lectus. Sed at elit a
                elit faucibus ultricies.
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={4}>
              <p className="puchasepop_secondary_ttl inter_font text-start">
               My Wallet Assets
              </p>
            </Col>
            <Col lg={4}>
              <p className="puchasepop_secondary_ttl inter_font text-end">
                1,000,000 USDT(TRC20)
              </p>
            </Col>
            <Col lg={4}>
              <p className="puchasepop_secondary_ttl inter_font text-end">
                2,000,000 KRW
              </p>
            </Col>

            
            
          </Row>
          <Row className="mt-4 mt-lg-0">
          <Col lg={4}>
              <p className="puchasepop_secondary_ttl inter_font text-start">
               Available Assets
              </p>
            </Col>
            <Col lg={4}>
              <p className="puchasepop_secondary_ttl inter_font text-end">
                9,000,000,000 USDT(TRC20)
              </p>
            </Col>
            <Col lg={4}>
              <p className="puchasepop_secondary_ttl inter_font text-end">
                2,000,000 KRW
              </p>
            </Col>
          </Row>
          <div className={`my-4 ${theme == 'dark' ? 'table_triple_grad_rel' : 'table_triple_grad_rel_light'}`}></div>

          <Row>
            <Col lg={4} className="mb-3">
              <Row className="purchasepop_borderBox rounded-2 me-1 p-0">
                <Col lg={6} className="p-0">
                  <button className="purchasepop_gray_label inter_font rounded-2 px-2">
                    Sellet ID
                  </button>
                </Col>
                <Col lg={6} className="p-0">
                  <div className="purchasepop_value_shower d-flex justify-content-center align-items-center h-100">
                    <p className="purchasepop_valueinp inter_font">Dexter</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={8} className="mb-3">
              <Row className="purchasepop_borderBox rounded-2">
                <Col lg={5} className="p-0">
                  <button className="purchasepop_gray_label inter_font rounded-2 px-2">
                    Shipping Address (Optional)
                  </button>
                </Col>
                <Col lg={7} className="p-0 d-flex justify-content-center align-items-center ">
                  <div className="puchasepop_checker d-flex justify-content-center align-items-center gap-2">

                  <p className="purchasepop_valueinp inter_font">Same as the address on my page </p>
                  
                    <Form className="">
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid />
                      </Form.Check>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg={7} className="mb-3">
              <Row className="purchasepop_borderBox rounded-2 me-1 p-0">
                <Col lg={4} className="p-0">
                  <button className="purchasepop_gray_label inter_font rounded-2 px-2">
                    Payment Address
                  </button>
                </Col>
                <Col lg={8} className="p-0">
                  <div className="purchasepop_value_shower d-flex justify-content-center align-items-center h-100">
                    <input type="text" value='iugw87g4rob349g4t' className="inter_font px-2 text-end" />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="mb-3">
              <Row className="purchasepop_borderBox rounded-2">
                <Col lg={5} className="p-0">
                  <button className="purchasepop_gray_label inter_font rounded-2 px-2">
                    Token Type
                  </button>
                </Col>
                <Col
                  lg={7}
                  className="p-0 d-flex justify-content-center align-items-center" onClick={() =>{ handleShowWalBal();handleClosePurchase();}}
                >
                  <p className="purchasepop_valueinp inter_font">TRC20</p>
                </Col>
              </Row>
            </Col>

            <Col lg={7} className="mb-3">
              <Row className="purchasepop_borderBox rounded-2 me-1 p-0">
                <Col lg={4} className="p-0">
                  <button className="purchasepop_gray_label inter_font rounded-2 px-2">
                    Product Amount
                  </button>
                </Col>
                <Col lg={8} className="p-0">
                  <div className="purchasepop_value_shower d-flex justify-content-end align-items-center h-100 pe-2">
                    <p className="purchasepop_valueinp inter_font text-end">
                    9,000,000,000 USDT <br/> <span>9,000,000,000 USDT(TRC20)</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="mb-3">
              <Row className="purchasepop_borderBox rounded-2">
                <Col lg={5} className="p-0">
                  <button className="purchasepop_gray_label inter_font rounded-2 px-2">
                    Select quantity
                  </button>
                </Col>
                <Col
                  lg={7}
                  className="p-0 d-flex justify-content-center align-items-center gap-4"
                >
                  <button
                    className="purchasepop_incdec_btn"
                    onClick={() => handleDecrement()}
                  >
                    <i class="fa-solid fa-chevron-left" />
                  </button>
                  <input type="number" value={quantity} onChange={(e) => setQuantity(Number(e.target.value))} className="inter_font text-center purchasepop_valueinpBox flex-grow-1" />
                  {/* <p className="purchasepop_valueinp inter_font text-center">
                    {" "}
                    {quantity}{" "}
                  </p> */}
                  <button
                    className="purchasepop_incdec_btn"
                    onClick={() => handleIncrement()}
                  >
                    <i class="fa-solid fa-chevron-right" />
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className={`my-4 ${theme == 'dark' ? 'table_triple_grad_rel' : 'table_triple_grad_rel_light'}`}></div>

          <Row className="d-flex justify-content-between align-items-start">
            <Col lg={3}>
            <p className="puchasepop_secondary_ttl inter_font">
            Total order amount
              </p>
              <div className="purchasepop_borderBox py-2 px-3 rounded-2 mt-3">
              <p className="purchasepop_valueinp inter_font">100,000 USDT</p>
              </div>
              <p className="purchasepop_lowerfont inter_font mt-2 ms-3">300,000,000 KRW</p>
            </Col>
            <Col lg={3} className="d-flex justify-content-center align-items-center">
                <img src={require('../assets/images/qrcode.svg').default} className={`img-fluid reg_qrcoder ${theme == 'dark' ? '' : 'reg_qrcoder_filter'}`} />
            </Col>
            <Col lg={3} className="d-flex justify-content-center align-items-center">
            <div className="primary_sec d-flex flex-column justify-content-center align-items-center">
                  <p className="text-center m-0  inter_font">
                  Escrow Payment Order
                  </p>
                  <div className="puchasepop_checker">
                  <Form className="">
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid />
                      </Form.Check>
                    </Form>
                    </div>
                </div>
            </Col>
          </Row>

          <h5 className="inter_font text-center registerpop_title mt-4">Payment</h5>

          <Row className="d-flex justify-content-between align-items-center mt-4">
            <Col lg={3}>
            <button className="purchasepop_gray_label inter_font rounded-2 px-2">
            Product details
                  </button>
            </Col>
            <Col lg={3}>
            <p className="si_blue_text inter_font" onClick={() => {handleShowChat();handleClosePurchase();}}>1:1 Conversation</p>
            </Col>
          </Row>

          <Row className="justify-content-center mt-4">
            <Col lg={11}>
                <Row>
                    <Col xs={12} className="registerpop_bottomImg_holder p-0">
                    <img
                src={require("../assets/images/shop.svg").default}
                className="img-fluid registerpop_bottomImg"
              />
                    </Col>
                </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

    </> 
  );
}

export default Purchase;
