import React,{useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileAccord from "../Components/ProfileAccord";
import { Col, Form, Row } from "react-bootstrap";
import { allData } from "../CommonData/Data";
import ReactFlagsSelect from "react-flags-select";
import { AddUpiPaymentHooks, GetUserHooks, GetUserId, UploadBankStatementHooks } from "../Hooks/UserHooks";
import {ValidateFile} from "../Validation/UserValidation"
import { toast } from "react-hot-toast";

function PaymentMethod() {
  const theme = useSelector((state) => state?.User?.theme);
  const dispatch = useDispatch();
  const [selectedFlag, setSelectedFlag] = useState("");
  const [user , setUser] = useState({});
  const [userkyc , setUserkyc] = useState({});

  const [initialstate , setInitialstate] = useState({
    idnumber : "",
    ifsc : "",
    branchcode : "",
    branchName : "",
    type : "remittance account"
  })
  const [front , setFront] = useState({});
  const [frontview , setFrontview] = useState("");
  const [error , setError] = useState({});
  const [type , setType] = useState("");

  const [upiinitial , setUpiinitial] = useState({
    paymenttype : "Swift",
    upiid : ""
  })

  const [upierror , setUpierror] = useState({});

  const fetchdata = async() => {
    let user = await GetUserHooks({userid : GetUserId()} , dispatch);
    console.log("userdata ," , user);
    setUser(user?.record);
    setUserkyc(user?.kyc); 
    setUpiinitial({...upiinitial , ["paymenttype"] : user?.record?.upi?.paymenttype , ["upiid"] : user?.record?.upi?.upiid})
    if(user?.bankProof?.status == "new"){
      setType("form");
    }
    else if(user?.bankProof?.status == "pending"){
      setType("pending");
    }
    else if(user?.bankProof?.status == "approved"){
        setType("approved")
    }
    else if(user?.bankProof?.status == "rejected"){
      setType("rejected");
    }
  }

  useEffect(() => {
    fetchdata();
  } , [])

  const HandleChange = (e) => {
    try{
      setInitialstate({...initialstate , [e?.target?.name] : e?.target?.value});
      if(!e.target.value){
        setError({...error , [e?.target?.name] : "Field is required"});
      }
      else{
        setError({...error , [e?.target?.name] : ""})
      }
    }
    catch(e){
      console.log("Error on handle change " , e);
    }
  }

  const HandleFileChange = (e) => {
    try{
      let validate = ValidateFile(e?.target?.files[0]);
      if(validate){
        setError({...error , ["front"] : validate});
      }
      else{
        setFront(e?.target?.files[0]);
        setFrontview(URL.createObjectURL(e?.target?.files[0]));
      }
    }
    catch(e){
      console.log("Error on handle file change" , e);
    }
  }

  const handleSubmit = async() => {
    try{
      var formdata = new FormData();
      formdata.append("idnumber" , initialstate?.idnumber)
      formdata.append("ifsc" , initialstate?.ifsc)
      formdata.append("branchcode" , initialstate?.branchcode)
      formdata.append("branchName" , initialstate?.branchName)
      formdata.append("userid" , GetUserId());
      formdata.append("frontimage" , front);
      formdata.append("type" , initialstate?.type);
      let result = await UploadBankStatementHooks(formdata);
      console.log("result" , result);
    }
    catch(e){
      console.log("Error on handle submit" , e);
    }
  }

  const handleAddupi = async() => {
    try{
      let payload = {
        paymenttype : upiinitial?.paymenttype,
        upiid : upiinitial?.upiid,
        userid : GetUserId()
      }
      let result = await AddUpiPaymentHooks(payload);
      console.log("result" , result);
      if(result?.type == "Success"){
        toast.success("Updated Successfully")
      }
    }
    catch(e){
      console.log("Error on handle add upi" , e);
    }
  }

  const handlechangeupi = async(e) => {
    try{
      setUpiinitial({...upiinitial , [e?.target?.name] : e?.target?.value});
      if(/^[\w.-]+@[\w.-]+$/.test(e?.target?.value)){
        setUpierror({...upierror , [e?.target?.name] : ""});
      }
      else{
        setUpierror({...upierror , [e?.target?.name] : "Invalid upi id"});
      }
      if(!e?.target?.value){
        setUpierror({...upierror , [e?.target?.name] : "Field is required"});
      }
    }
    catch(e){
      console.log("Error on handle change upi" , e);
    }
  }


  return (
    <>
      <div className="notices_holder p-2 p-sm-3 pb-md-5">
        <h5 className="notices_hader_title ">Payment Methods</h5>
        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

        <div className="prfl_profile_dtls_holder d-flex justify-content-start align-items-center gap-3">
          <div className="prfl_profile_img_holder position-relative">
            <img
              src={require("../assets/images/person.svg").default}
              className="img-fluid"
            />
            <div className="prfl_online_indicator rounded-5"></div>
          </div>
          <div className="prfl_username_holder d-flex jutify-content-start align-items-center gap-2">
            <p className="prfl_username">{user?.firstName + " " + user?.lastName}</p>
            <img
              src={require("../assets/images/vendor.svg").default}
              className="img-fluid"
            />
          </div>
        </div>
        <ProfileAccord user = {user}/>

       { <div className="prfl_accord_holder p-3 rounded-3">
          <p className="prfl_gray_ttile inter_font">
            Bank Proof
          </p>
          {/* <div className="pm_payment_checker pm_checker_align mt-3">
            {" "}
            <Form>
              <Row>
                <Col lg={5} md={6} sm={6}>
                  <div className = "pm_checker_title">
                    <Form.Check
                    label='Preferred fiat currency'
                      name="group1"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    
                  </div>
                </Col>
              </Row>
            </Form>
          </div> */}
{type == "form" &&<>
<Row className="mt-3">
<Col lg={6} md={6} sm={6} xs={12}>
    <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Account Number</p>


 {/* <div className="cmn_flag_select pm_trade_flas_select rounded-2 d-flex align-items-center mt-2 " title={selectedFlag}> */}
                <input
                  type="text"
                  name="idnumber"
                  className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                  placeholder="Enter registered email address "
                  onChange={(e) => HandleChange(e)}
                />
                <p className="text-danger">{error?.idnumber}</p>
              </div>
                 
                {/* </div> */}

    </Col>
    <Col lg={6} md={6} sm={6} xs={12}>
    <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Ifsc Code</p>

                  <input
                    type="text"
                    name = "ifsc"
                    className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                    placeholder="Enter registered email address "
                    onChange={(e) => HandleChange(e)}
                  />
                  <p className="text-danger">{error?.ifsc}</p>
                </div>

    </Col>
    <Col lg={6} md={6} sm={6} xs={12}>
    <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Branch Code</p>

                  <input
                    type="text"
                    name="branchcode"
                    className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                    placeholder="Enter registered email address "
                    onChange={(e) => HandleChange(e)}
                  />
                  <p className="text-danger">{error?.branchcode}</p>
                </div>

    </Col>
    <Col lg={6} md={6} sm={6} xs={12}>
    <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Branch Name</p>

                  <input
                    type="text"
                    name = "branchName"
                    className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                    placeholder="Enter registered email address "
                    onChange={(e) => HandleChange(e)}
                  />
                  <p className="text-danger">{error?.branchName}</p>
                </div>

    </Col>

    <Col lg={6} md={6} sm={6} xs={12}>
    <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Select Proof (It's like passbook,checkbook, etc.)</p>
                  <div className="position-relative mt-3">
                  <img src={frontview ? frontview : require('../assets/images/nodata.svg').default} className='img-fluid prfl__proofSelector' />
                  <input
                    type="file"
                    className="prfl__fileInput rounded-2 p-2 mt-2"
                    placeholder="Enter registered email address "
                    onChange={(e) => HandleFileChange(e)}
                  />
                  </div>

                  
                  <p className="text-danger">{error?.front}</p>
                </div>

    </Col>
</Row>
<div className="pm_payment_checker mt-4">
<div className="pm_mapping_check">

<p className="prfl_addrs_text inter_font m-0">Account Type</p>
<Row className="mt-2">
                <Col lg={5} md={6} sm={6}>
                  <div className="pm_checker_title">
                    <Form.Check
                      name="group1"
                      label='No remittance certificate required'
                      type="radio"
                      id={`inline-radio-1`}
                      checked ={initialstate?.type == "remittance account" ? true : false}
                      onChange = {() => setInitialstate({...initialstate , ["type"] : "remittance account" })}
                    />
                  
                  </div>
                </Col>
                <Col lg={5} md={6} sm={6}>
                  <div className="pm_checker_title">
                    <Form.Check
                      label='No remittance certificate required'
                      name="group1"
                      type="radio"
                      id={`inline-radio-2`}
                      checked ={initialstate?.type == "non remittance account" ? true : false}
                      onChange = {() => setInitialstate({...initialstate , ["type"] : "non remittance account" })}
                    />
                  </div>
                </Col>
              </Row>
              </div>
              </div>

<Row className="justify-content-center mt-3">
    <Col lg={5} md={6} sm={8} xs={10}>
        <button className="primary_btn w-100"
        disabled={!initialstate?.idnumber || !initialstate?.ifsc || !initialstate?.branchcode || !initialstate?.branchName || !front}
        onClick={handleSubmit}
        >Confirm</button>
    </Col>
</Row>

</>}


{type != "form" && <div className="  w-100">
              <>
              <p className={`prfl_gray_ttile text-center inter_font mb-2 ${userkyc?.bankProof?.status == 'rejected' ? 'prfl_red_ttl' : userkyc?.bankProof?.status == 'approved' ? 'prfl_green_ttl' : ''}`}>
              {userkyc?.bankProof?.status == 'rejected' ? 'Verification Rejected by admin' : 
              
              userkyc?.bankProof?.status == 'approved' ? 'Verification Success' : 
              
              userkyc?.bankProof?.status == 'pending' ? 'Verification on progress' : ''}
                
              </p>
              <h5 className="prfl_status_shower inter_font text-center">
                {userkyc?.bankProof?.status == 'pending' ? 'It will take up to 24 hours.' : 

                userkyc?.bankProof?.status == 'rejected' ? 'Once again, fill it out correctly and apply for KYC registration with a clear file.' : 

                userkyc?.bankProof?.status == 'approved' ? 'You can proceed now.' : ''}
                
              </h5>
              {userkyc?.bankProof?.status == 'rejected'  && type == "" && <button className="primary_btn mt-3 w-100"
              onClick={() => setType("form")}
              >Continue</button>}
              </>
{userkyc?.bankProof?.status == 'approved' ? 
             <Row className="justify-content-center">
              <Col lg={7} md={8} sm={10} xs={10}>
                  <button className="primary_btn mt-3 w-100">Confirm</button>
              </Col>
             </Row> 
             : <></>
            }               
            </div>}
        </div>}

        {/* <div className="prfl_accord_holder p-3 rounded-3 mt-3">
          <p className="prfl_gray_ttile inter_font">
            Bank Proof
          </p>

          <div className="pm_payment_checker pm_checker_align mt-3">
            <Form>
              <Row>
                <Col lg={5} md={6} sm={6}>
                  <div className="pm_checker_title">
                    <Form.Check
                      name="group1"
                      label='No remittance certificate required'
                      type="radio"
                      id={`inline-radio-1`}
                    />
                  
                  </div>
                </Col>
                <Col lg={5} md={6} sm={6}>
                  <div className="pm_checker_title">
                    <Form.Check
                    label='No remittance certificate required'
                      name="group1"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div> */}

        <p className="inter_font pm_primary_text mt-3">For all transaction methods other than bank account remittance, please communicate information directly between the parties in the state of transaction chat after the transaction begins.</p>

        <div className="prfl_accord_holder p-3 rounded-3 mt-3">
      <p className="prfl_gray_ttile inter_font">
      Select payment method other than bank transfer
          </p>


          <div className="pm_payment_checker mt-3">
            {" "}
            <Form>
              <Row>
                {allData?.paymentChecks?.map((item) =>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label={item.type}
                      type="radio"
                      id={`inline-radio-1`}
                      checked = {upiinitial?.paymenttype == item?.type ? true : false}
                      onChange = {(e) => setUpiinitial({...upiinitial , ["paymenttype"] : item?.type})}
                    />
                  </div>
                </Col>
                 )}
                 <Col lg={12} className="mt-3">
                    <Row className="align-items-center">
                        <Col lg={3}>
                        <div className="pm_mapping_check">
                        {/* <Form.Check 
                        label='Payment method input'
                      name="upiid"
                      type="radio"
                      id={`inline-radio-1`}
                      onChange = {(e) => handlechangeupi(e)}
                    /> */}
                    <p className="rec_table_ttl_light m-0">Enter UPI id :</p>
                    </div>
                        </Col>
                        <Col lg={9}>
                        <input
                          name="upiid"
                          value={upiinitial?.upiid}
                          type="text"
                          className="prfl_tiny_inputs rounded-2 p-2"
                          placeholder=""
                          onChange={(e) => handlechangeupi(e)}
                  />
                   <p className="text-danger">{upierror?.upiid}</p>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <button className="primary_btn w-100" 
                            type="button"
                            disabled = {!upiinitial?.upiid || upierror?.upiid}
                            onClick = {(e) => {handleAddupi()}} 
                            >Confirm</button>
                        </Col>
                    </Row>
                 </Col>
              </Row>
            </Form>
          </div>
      </div>
      </div>

     
    </>
  );
}

export default PaymentMethod;
