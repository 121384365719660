import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink } from 'react-router-dom';
import ReactFlagsSelect from "react-flags-select";

const StoreRegistration = () => {
    const theme = useSelector((state) => state?.User?.theme);
    const [selectedFlag, setSelectedFlag] = useState("");
  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower storemanager__title inter_font m-0 text-center text-uppercase mt-4 mb-4 mb-sm-0'>STORE REGISTRATION</h5>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5'>
            <Row className='align-items-center justify-content-between'>
                <Col lg={6} md={6} sm={12} className='mb-4'>
                <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Business registration name</label>
                    <input
                      type="text"
                      placeholder="Please enter a registered email"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                    </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='mb-4'>


                    <div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Business registration number</label>
                    <input
                      type="text"
                      placeholder="Enter business registration number"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                  </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} className='mb-4'>


                    <div className="login_single_input d-flex flex-column mb-4">
<label className="mb-2 inter_font">Business representative</label>
                    <input
                      type="text"
                      placeholder="Enter business representative"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                  </div>
                  <div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font mt-2">Country selection</label>
<div className="cmn_flag_select rounded-2 d-flex align-items-center" title={selectedFlag}>
                  <ReactFlagsSelect
                  className=" pb-0 w-100"
    selected={selectedFlag}
    showSecondarySelectedLabel={true}
    selectedSize={10}
    onSelect={(code) =>{console.log("code",code); setSelectedFlag(code)}}
  />
  </div>
</div>
                  </Col>
                  <Col lg={6} md={6} sm={12} className='mb-4'>
                    <div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Business registration address</label>
<textarea className='inter_font createOffer_ti_textarea rounded-1 p-3' 
                    name="terms"
                    cols={20}  rows={6} placeholder="Enter Offer Terms..."/>
                  </div>
                  </Col>


<Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Store name</label>
<div className='d-flex justify-content-start align-items-center mt-2 gap-2 gap-sm-3'>
                    <input
                      type="text"
                      placeholder="Please enter store name"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
                    <button className='primary_btn storeReg__primaryBtn storeReg_mobBtn rounded-2 inter_font'>Check</button>
                    </div>
                    </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Store Type</label>
                    <input
                      type="text"
                      placeholder="Please enter store name"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1 mt-1"
                    />
                    </div>
                    </Col>

                    <Col lg={6} md={6} sm={6} className='mb-4'>
<div className='d-flex justify-content-center justify-content-sm-start align-items-center mt-2 gap-2 gap-sm-3'>
<label className="inter_font storeReg__subLabel">Register License File</label>
<button className='primary_btn storeReg__primaryBtn storeReg_mobBtn rounded-2 inter_font position-relative'>File
  <input type='file' className='prodReg__fileInputter' /></button>
                    </div>
                    </Col>

                    <Col lg={6} md={6} sm={6} className='mb-4'>   
<div className='d-flex justify-content-center justify-content-sm-start align-items-center mt-2 gap-2 gap-sm-3'>
<label className=" inter_font storeReg__subLabel">Operator Certified ID</label>
<button className='primary_btn storeReg__primaryBtn storeReg_mobBtn rounded-2 inter_font position-relative'>File
  <input type='file' className='prodReg__fileInputter' /></button>
                    </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">Phone Number</label>
<Row>
    <Col lg={6} md={12} sm={6} className='mb-3 mb-sm-0 mb-md-3 mb-lg-0'>
    <input
                      type="number"
                    //   placeholder="Please enter phone number"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
    </Col>
    <Col lg={6} md={12} sm={6} className='d-flex justify-content-between align-items-center gap-2'>
        <div className='storeReg__certifyBadge rounded-2 text-center flex-grow-1'>
            <p className='m-0 inter_font stoerReg__certifyStatus text-center'>Certified Number</p>
        </div>
        <button className='primary_btn storeReg__primaryBtn rounded-2 inter_font'>Check</button>
    </Col>
</Row>
                 
                    </div>
                    </Col>


                    <Col lg={6} md={6} sm={12} className='mb-4'>

<div className="login_single_input d-flex flex-column">
<label className="mb-2 inter_font">E-mail account</label>

<Row>
    <Col lg={6} md={12} sm={6} className='mb-3 mb-sm-0 mb-md-3 mb-lg-0'>
    <input
                      type="number"
                    //   placeholder="Please enter phone number"
                      className="primary_input storeReg__primaryInp rounded-1 p-2 inter_font flex-grow-1"
                    />
    </Col>
    <Col lg={6} md={12} sm={6} className='d-flex justify-content-between align-items-center gap-2'>
        <div className='storeReg__certifyBadge rounded-2 text-center flex-grow-1'>
            <p className='m-0 inter_font stoerReg__certifyStatus text-center'>Certified Email</p>
        </div>
        <button className='primary_btn storeReg__primaryBtn rounded-2 inter_font'>Check</button>
    </Col>

  
</Row>
                 
                    </div>
                    </Col>
                    <Col xs={12} className='text-center mt-4'>
                      <NavLink to='/productRegistration' className='header_links'>
    <button className='primary_btn rounded-2 inter_font'>Submit</button>
    </NavLink>
    </Col>

      

            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default StoreRegistration