var key = ""

var keyvalue = {};
if(key == "production"){
    keyvalue.API_URL = "http://localhost:2056/";
    keyvalue.getGeoInfo = "https://ipapi.co/json/";
}
else if(key == "demo"){
    keyvalue.API_URL = ""
    keyvalue.getGeoInfo = "https://ipapi.co/json/"
}
else{
    keyvalue.API_URL = "http://localhost:2056/";
    keyvalue.getGeoInfo = "https://ipapi.co/json/";
}

export default  keyvalue;