import React, { useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink } from 'react-router-dom';
import RegisterProduct from '../Components/RegisterProduct';
import SaleStatus from '../Components/SaleStatus';
import SaleSettlement from '../Components/SaleSettlement';

const ProductRegistration = () => {
  const theme = useSelector((state) => state?.User?.theme);
  const [viewer,setViewer] = useState('registerProduct')
  const [loginSource, setLoginSource] = useState("productRegisteration");
     
  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower storemanager__title inter_font m-0 text-center text-uppercase mt-4 mb-4 mb-sm-0'>STORE REGISTRATION</h5>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">
    <div className="login_selector d-flex justify-content-start align-items-cente mt-4 gap-2">
                  <div
                    className={`prodReg__topTaber text-center ${
                      viewer == "registerProduct" ? "active" : ""
                    }`}
                    onClick={() => {
                      setViewer("registerProduct");
                    }}
                  >
                    <p className="inter_font">Product registration</p>
                  </div>
                  <div
                    className={`prodReg__topTaber text-center ${
                      viewer == "saleStatus" ? "active" : ""
                    }`}
                    onClick={() => {
                      setViewer("saleStatus");
                    }}
                  >
                    <p className="inter_font">Sale Status</p>
                  </div>
                  <div
                    className={`prodReg__topTaber rounded-end text-center ${
                      viewer == "saleSettlement" ? "active" : ""
                    }`}
                    onClick={() => {
                      setViewer("saleSettlement");
                    }}
                  >
                    <p className="inter_font">Sale Settlement</p>
                  </div>
                </div>
      {viewer == 'registerProduct' && 
   <RegisterProduct/>
  }

{viewer == 'saleStatus' && 
   <SaleStatus/>
  }

  {viewer == 'saleSettlement' && <SaleSettlement/>}
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default ProductRegistration