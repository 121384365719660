import React, { useRef, useState } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";

function WalletBalanceModal({
  showWalBal,
  handleCloseWalBal,
  handleShowPurchase,
  showPurchase,
}) {
    const theme = useSelector((state) => state?.User?.theme);
  const [closer, setCloser] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const ref = useRef();
  const options = [
    {
      value: "outside",
      label: "Outside Escrow",
    },
    {
      value: "badOffer",
      label: "Bad Offer Terms",
    },
    {
      value: "scam",
      label: "Obvious Scam",
    },
    {
      value: "negotiation",
      label: "Negotiation",
    },
  ];
  const [selected, setSelected] = useState({
    value: "BNB",
    label: "BNB",
    img: require("../assets/images/BNB.png"),
  });
  const [selectedPayment, setSelectedPayment] = useState({
    value: "upi",
    label: "UPI Payment",
    img: require("../assets/images/CRON.png"),
  });
  const locationOption = [
    {
      value: "india",
      label: "India",
      img: require("../assets/images/flag.svg").default,
    },
    {
      value: "USA",
      label: "USA",
      img: require("../assets/images/flag.svg").default,
    },
    {
      value: "UAE",
      label: "UAE",
      img: require("../assets/images/flag.svg").default,
    },
  ];
  const customPaymentRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedPayment({ label, value, img: data.img });
        ref.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const stylesSearch = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "30px",
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00CABA",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <>
      <Modal centered
        className="purchase_modal"
        show={showWalBal}
        onHide={handleCloseWalBal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
         
          <p className="puchasepop_secondary_ttl inter_font">Wallet Balance</p>
          <Select
            className="mt-3"
            defaultValue={selectedOption}
            onChange={setSelected}
            options={options}
            value={selected}
            ref={ref}
            styles={styles}
            placeholder=""
          />

          <Row className="mt-3 align-items-center">
            <Col lg={4}>
              <p className="puchasepop_secondary_ttl inter_font">
              Crypto Wallet
              </p>
            </Col>
            <Col lg={4}>
            <div className="puchasepop_checker d-flex justify-content-center align-items-center gap-2">

<p className="purchasepop_valueinp inter_font">Same as the address on my page </p>

  <Form className="">
    <Form.Check type="checkbox">
      <Form.Check.Input type="checkbox" isValid />
    </Form.Check>
  </Form>
</div>
            </Col>

            <Col lg={4}>
            <div className="market_coinname_select position-relative">
                  <img
                    src={
                      theme == "dark"
                        ? require("../assets/images/searchglass.svg").default
                        : require("../assets/images/blacksearch.svg").default
                    }
                    className="img-fluid market_coinname_img walBal_img"
                  />
                  <Select
                    defaultValue={selectedPayment}
                    onChange={setSelectedPayment}
                    options={locationOption}
                    value={selectedPayment}
                    components={{ Option: customPaymentRenderer }}
                    ref={ref}
                    styles={stylesSearch}
                    placeholder="All Payment Methods"
                  />
                </div>
            </Col>
          </Row>

          <div className="walBal_tableCont mt-3 rounded-2 px-2 py-3">
            <div className="walBal_tableCont_single d-flex justify-content-between align-items-center ">
            <div className="walBal_coinName_dtls d-flex justify-content-start align-items-center gap-2">
                <img src={require('../assets/images/btc.svg').default} className="img-fluid walBal_coinImg" />
                <p className="inter_font purchasepop_valueinp">BTC</p>
                <p className="inter_font si_blue_text ms-3">0</p>
            </div>

            <div className="walBal_triple_btns d-flex justify-content-start align-items-center gap-2">
                <button className="primary_btn">Deposit</button>
                <button className="primary_btn">Withdraw</button>
                <button className="primary_btn">History</button>
            </div>
            </div>
            <div className="walBal_tableCont_single d-flex justify-content-between align-items-center ">
            <div className="walBal_coinName_dtls d-flex justify-content-start align-items-center gap-2">
                <img src={require('../assets/images/btc.svg').default} className="img-fluid walBal_coinImg" />
                <p className="inter_font purchasepop_valueinp">BTC</p>
                <p className="inter_font si_blue_text ms-3">0</p>
            </div>

            <div className="walBal_triple_btns d-flex justify-content-start align-items-center gap-2">
                <button className="primary_btn">Deposit</button>
                <button className="primary_btn">Withdraw</button>
                <button className="primary_btn">History</button>
            </div>
            </div>
            <div className="walBal_tableCont_single d-flex justify-content-between align-items-center ">
            <div className="walBal_coinName_dtls d-flex justify-content-start align-items-center gap-2">
                <img src={require('../assets/images/btc.svg').default} className="img-fluid walBal_coinImg" />
                <p className="inter_font purchasepop_valueinp">BTC</p>
                <p className="inter_font si_blue_text ms-3">0</p>
            </div>

            <div className="walBal_triple_btns d-flex justify-content-start align-items-center gap-2">
                <button className="primary_btn">Deposit</button>
                <button className="primary_btn">Withdraw</button>
                <button className="primary_btn">History</button>
            </div>
            </div>
            <div className="walBal_tableCont_single d-flex justify-content-between align-items-center ">
            <div className="walBal_coinName_dtls d-flex justify-content-start align-items-center gap-2">
                <img src={require('../assets/images/btc.svg').default} className="img-fluid walBal_coinImg" />
                <p className="inter_font purchasepop_valueinp">BTC</p>
                <p className="inter_font si_blue_text ms-3">0</p>
            </div>

            <div className="walBal_triple_btns d-flex justify-content-start align-items-center gap-2">
                <button className="primary_btn">Deposit</button>
                <button className="primary_btn">Withdraw</button>
                <button className="primary_btn">History</button>
            </div>
            </div>
          </div>

          <div className="walBal_modal_footer my-4 d-flex justify-content-between align-items-center">
            <button className="secondary_btn"  onClick={() => {
              handleCloseWalBal();
              handleShowPurchase();
            }}>Close</button>
            <button className="primary_btn"  onClick={() => {
              handleCloseWalBal();
              handleShowPurchase();
            }}>Report</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WalletBalanceModal;
