import axios from 'axios';

import config from "../Config/env";
import { CreateOfferApi, GetCurrencyApi, GetPaymentTypesApi, GetUserOrderApi , GetOfferListApi, GetSpecificOfferApi} from '../Routes/P2pRoutesFront';

const headers = {
    headers: {
        'Authorization':  localStorage.getItem("TKN") ? `Bearer ${atob( localStorage.getItem("TKN"))}` : ""
    }
}

export const CreateOfferHooks = async(data) => {
    try{
        var payload = {...data}
        let result =  await axios.post(config?.API_URL + CreateOfferApi, payload );
        return result?.data;
    }
    catch(e){
        console.log("Error on CreateOfferHooks" , e);
    }
}

export const GetPaymentTypeHooks = async() => {
    try{
        let result =  await axios.get(config?.API_URL + GetPaymentTypesApi);
        return result?.data;
    }
    catch(e){
        console.log("Error on GetPaymentTypeHooks" , e);
    }
}

export const GetCurrencyHooks = async() => {
    try{
        let result =  await axios.get(config?.API_URL + GetCurrencyApi);
        return result?.data;
    }
    catch(e){
        console.log("Error on GetPaymentTypeHooks" , e);
    }
}

export const GetUserOrderHooks = async(data) => {
    try{
        var payload = {...data}
        let result =  await axios.get(config?.API_URL + GetUserOrderApi , {params : payload});
        return result?.data;
    }
    catch(e){
        console.log("Error on GetPaymentTypeHooks" , e);
    }
}

export const GetOfferListHooks = async(data) => {
    try{
        var payload = {...data}
        let result =  await axios.get(config?.API_URL + GetOfferListApi , {params : payload});
        return result?.data;
    }
    catch(e){
        console.log("Error on GetPaymentTypeHooks" , e);
    }
}

export const GetSpecificOrderHooks = async(data) => {
    try{
        var payload = { ...data }
        let result = await axios.get(config?.API_URL + GetSpecificOfferApi, { params: payload });
        return result?.data;
    }
    catch(e){
        console.log("Error on specific order hooks" , e);
    }
}