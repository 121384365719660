import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { useSelector } from "react-redux";
import Select from "react-select";
import { GetCurrencyHooks, GetPaymentTypeHooks } from "../Hooks/P2pHooks";

function CreateOfferPaymentMethod({setOfferSec,setStep , Setdata , defaultvalue}) {
    const theme = useSelector((state) => state?.User?.theme);
    const [closer, setCloser] = useState();
  const [closerPrefer, setCloserPrefer] = useState();
  const currencyRef = useRef();
  const paymentRef = useRef();
  const preferedRef = useRef();
  const [action, setAction] = useState(null);
  const [options , setOptions] = useState();
  
  // const options = [
  //   {
  //     value: "BNB",
  //     label: "BNB",
  //     img: require("../assets/images/BNB.png"),
  //   },
  //   {
  //     value: "Cron",
  //     label: "Cron",
  //     img: require("../assets/images/CRON.png"),
  //   },
  //   {
  //     value: "Crons",
  //     label: "Crons",
  //     img: require("../assets/images/CRONS.png"),
  //   },
  //   {
  //     value: "Polygon",
  //     label: "Polygon",
  //     img: require("../assets/images/polygon.png"),
  //   },
  // ];

  const doOptions = [
    { value: "buy", label: "Buy" },
    { value: "sell", label: "Sell" },
  ];

  // const paymentOption = [
  //   {
  //     value: "upi",
  //     label: "UPI Payment",
  //   },
  //   {
  //     value: "paypal",
  //     label: "Paypal",
  //   },
  //   {
  //     value: "gpay",
  //     label: "Google Pay",
  //   },
  // ];
  const [paymentOption , setPaymentoption] = useState();
  // const [doOptions , setDooptions] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState( {
    value: "BNB",
    label: "BNB",
    img: require("../assets/images/BNB.png"),
  });

  const [preferedCurrency, setPreferedCurrency] = useState({
   
  });

  const [selectedPayment, setSelectedPayment] = useState();
//state
  const [error , setError] = useState({});

  useEffect(() => {
    console.log("doOptions.find(e => e?.value == defaultvalue?.ordertype)" , defaultvalue);
    if(defaultvalue?.ordertype && doOptions.find(e => e?.value?.toLowerCase() == defaultvalue?.ordertype?.toLowerCase())){
      setAction(doOptions.find(e => e?.value?.toLowerCase() == defaultvalue?.ordertype?.toLowerCase()));
    }
    if(defaultvalue?.paymentmethod && defaultvalue?.paymentmethod?.length > 0){
      setSelectedPayment(defaultvalue?.paymentmethod)
    }
    if(defaultvalue?.preferedcurrency && options?.find(e => e?.value?.toLowerCase() == defaultvalue?.preferedcurrency?.toLowerCase())){
      setPreferedCurrency(options?.find(e => e?.value?.toLowerCase() == defaultvalue?.preferedcurrency?.toLowerCase()));
    }
    if(defaultvalue?.currency && options?.find(e => e?.value?.toLowerCase() == defaultvalue?.currency?.toLowerCase())){
      setSelectedCurrency(options?.find(e => e?.value?.toLowerCase() == defaultvalue?.coin?.toLowerCase()));
    }
  } , []);

  const fetchdata = async() => {
    let result = await GetPaymentTypeHooks();
    console.log("result in useft" , result);
    setPaymentoption(result?.record);
    setSelectedPayment(result?.record[0]);
    let currencyresult = await GetCurrencyHooks();
    setOptions(currencyresult?.record);
    setPreferedCurrency(currencyresult?.record[1]);
    setSelectedCurrency(currencyresult?.record[0]);
  }
  useEffect(() => {
    fetchdata();
  } , [])

  const HandleNext = () => {
    var err = error;
    if(selectedCurrency?.value == preferedCurrency?.value){
      err.currency = "prefered currency must be differ as crypto"
      setError({...error , ["currency"] : "prefered currency must be differ as crypto"})
    }
    else{
      err.currency = ""
      setError({...error , ["currency"] : ""})
    }
    if(!action){
      err.action = "Field is required";
      setError({...error , ["action"] : "Field is required"})
    }
    else{
      err.action = "";
      setError({...error , ["action"] : ""})
    }
    if(selectedPayment?.length <= 0){
      err.payment = "Field is required";
      setError({...error , ["payment"] : "Field is required"})
    }
    else{
      err.payment = "";
      setError({...error , ["payment"] : ""})
    }
    // setError(err);
    setTimeout(() => {setError(err)} , 4000)
    if(!err.currency && !err?.action && !err?.payment){
      let payload = {
        coin : selectedCurrency?.value,
        ordertype  : action?.value,
        preferedcurrency : preferedCurrency?.value,
        paymentmethod : selectedPayment
      }
      Setdata(payload);
      setOfferSec('tradePricing');
      setStep(2);
    }
  }

  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedCurrency({ label, value, img: data.img });
        currencyRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data?.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const customPreferenceRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setPreferedCurrency({ label, value, img: data.img });
        preferedRef.current.blur();
        setCloserPrefer(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data?.img} style={{ width: "20px", marginRight: "10px" }} /> 
      {label}
    </div>
  );//data.img

  const customPaymentRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedPayment({ label, value, img: data.img });
        paymentRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "40px",
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == "dark" ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const stylesNew = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == "dark" ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  
console.log(error , "errorrrr");
  return (
    <>
     <Row className="mt-5">
              <Col md={6} className="mb-4 mb-md-0">
                <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency.img}
                    className="img-fluid market_coinname_img createOffer__select_coinImg"
                  />
                  <img
                    src={
                      theme == "dark"
                        ? require("../assets/images/drophint.svg").default
                        : require("../assets/images/drophint_light.svg").default
                    }
                    className="market_drophint createOffer__select_dropHint"
                  />
                  <p className="market_select_label createOffer__select_label inter_font">
                    Choose your cryptocurrency
                  </p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedCurrency}
                    onChange={selectedCurrency}
                    options={options}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>

                <Row className="mt-4">
                  <Col lg={6} md={12} sm={6} className="mb-3 mb-md-0">
                    <div className="market_coinname_select position-relative">
                      <img
                        src={
                          theme == "dark"
                            ? require("../assets/images/drophint.svg").default
                            : require("../assets/images/drophint_light.svg")
                                .default
                        }
                        className="market_drophint "
                      />
                      <p className="market_select_label createOffer__select_label inter_font">
                        What would you like to do?
                      </p>
                      <Select
                        className="mt-3"
                        onChange={setAction}
                        options={doOptions}
                        value={action}
                        styles={stylesNew}
                      />
                    </div>
                    <p className="text-danger">{error?.action}</p>
                  </Col>

                  <Col lg={6} md={12} sm={6}>
                    <div className="market_coinname_select position-relative">
                      <img
                        src={preferedCurrency.img}
                        className="img-fluid market_coinname_img"
                      />
                      <img
                        src={
                          theme == "dark"
                            ? require("../assets/images/drophint.svg").default
                            : require("../assets/images/drophint_light.svg")
                                .default
                        }
                        className="market_drophint"
                      />
                      <p className="market_select_label createOffer__select_label inter_font">
                        Preferred Currency
                      </p>
                      <Select
                        className="mt-3"
                        defaultValue={preferedCurrency}
                        onChange={preferedCurrency}
                        options={options}
                        value={preferedCurrency}
                        components={{ Option: customPreferenceRenderer }}
                        ref={preferedRef}
                        styles={styles}
                        placeholder="All Payment Methods"
                      />
                    </div>
                    <p className="text-danger">{error?.currency}</p>
                  </Col>
                </Row>

                <div className="market_coinname_select position-relative mt-4">
                  <img
                    src={
                      theme == "dark"
                        ? require("../assets/images/drophint.svg").default
                        : require("../assets/images/drophint_light.svg").default
                    }
                    className="market_drophint"
                  />
                  <p className="market_select_label createOffer__select_label inter_font">
                    Payment Method
                  </p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedPayment}
                    onChange={setSelectedPayment}
                    options={paymentOption}
                    value={selectedPayment}
                    styles={stylesNew}
                    placeholder="All Payment Methods"
                    isMulti={true}
                  />
                </div>
                <p className="text-danger">{error?.payment}</p>
              </Col>

              <Col md={6}>
                <h6 className="createOffer_steps_title inter_font m-0">
                  About this step
                </h6>

                <p className="createOffer_steps_hint inter_font m-0 mt-3">
                  Start creating your offer by selecting the cryptocurrency you
                  want to trade, whether or not you want to buy or sell, and the
                  payment method you want to use.
                </p>

                <div className="pm_payment_checker mt-3">
                    <Form>
                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='You want to to sell BTC'
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    </div>
                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='And get paid in BTC'
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    </div>
                    </Form>
                </div>

                <button className="primary_btn rounded-1 w-100 mt-4" onClick={() => {
                 
                  HandleNext()
                  }}>Next</button>
              </Col>
            </Row>
    </>
  )
}

export default CreateOfferPaymentMethod