import React, { useState } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ValidatePassword } from '../Validation/UserValidation';

function SetPasscode() {
    const theme = useSelector((state) => state?.User?.theme);
    const navigate = useNavigate();
    const location = useLocation();
    const [password,setPassword] = useState(false);

    const [passcode , setPasscode] = useState("");
    const [confirmPasscode , setConfirmpasscode] = useState("");
    const [passcodeerror , setPasscodeerror] = useState("");
    const [confirmpasscodeerror , setConfirmpasscodeerror] = useState("");
    const [confirmpassword , setConfirmpassword] = useState("");

    const handlePassword = () => {
      let checkpassword = ValidatePassword(passcode);
      setPasscodeerror(checkpassword);
      let checkconfirmpasscodeerror = ValidatePassword(confirmPasscode);
      setConfirmpasscodeerror(checkconfirmpasscodeerror);
      if(!passcodeerror && !confirmpasscodeerror){
        if(passcode != confirmPasscode){
          setConfirmpasscodeerror("confirm passcode must be same as passcode");
        }
        else{
          let st = location.state
          st.password = passcode
          navigate("/setupAccount" , {state : st})
        }
      }
      
    }

  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center'>Welcome to Otex!</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card position-relative rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <p className='mailver_backBtn inter_font m-0'>&lt; Back</p>
            <Row className='align-items-center justify-content-between mt-2'>
                <Col lg={7} md={7} sm={12} className='mb-4 mb-md-0'>
                    <h5 className='creacteacc_title m-0 inter_font mt-3 mt-lg-0'>Set Passcode</h5>
                    <p className='mailver_hint inter_font m-0 mt-2'>Please enter the 6-digit verification code that was sent to dummy123@gmail.com. The code is valid for 30 minutes.</p>
                <div className="login_single_input d-flex flex-column mt-4">
                    <label className="mb-2 inter_font">Password</label>
                    <div className='mailver_inputHolder p-2 rounded-2 d-flex justify-content-between align-items-center gap-3'>
                    <input
                      type={password ? "text" : 'password'}
                      className="inter_font flex-grow-1"
                      onChange={(e) => {
                        setPasscode(e?.target?.value);
                      }}
                    />
                    <p className='mailver_eye inter_font m-0' onClick={() => setPassword(!password)}><i class={password ? 'fa-regular fa-eye-slash' : "fa-regular fa-eye"}/></p>
                    </div>
                  </div>

                  <div className='sp_passcode_steps mt-3'>
                    <div className='sp_passcode_singleStep d-flex justify-content-start align-items-center gap-2 mb-2'>
                    <img src={require('../assets/images/checker.svg').default} className='img-fluid sp_passcode_checker' />
                    <p className='mailver_hint inter_font m-0 mt-2'>At least 8 characters</p>
                    </div>
                    <div className='sp_passcode_singleStep d-flex justify-content-start align-items-center gap-2 mb-2'>
                    <img src={require('../assets/images/checker.svg').default} className='img-fluid sp_passcode_checker' />
                    <p className='mailver_hint inter_font m-0 mt-2'>At least 1 number</p>
                    </div>
                    <div className='sp_passcode_singleStep d-flex justify-content-start align-items-center gap-2 mb-2'>
                    <img src={require('../assets/images/checker.svg').default} className='img-fluid sp_passcode_checker' />
                    <p className='mailver_hint inter_font m-0 mt-2'>At least 1 upper case letter</p>
                    </div>
                  </div>

                  <div className="login_single_input d-flex flex-column mt-4">
                    <label className="mb-2 inter_font">Confirm Password</label>
                    <div className='mailver_inputHolder p-2 rounded-2 d-flex justify-content-between align-items-center gap-3'>
                    <input
                      type={password ? "text" : 'password'}
                      className="inter_font flex-grow-1"
                      onChange={(e) => {
                        setConfirmpasscode(e?.target?.value);
                      }}
                    />
                    <p className='mailver_eye inter_font m-0' onClick={() => setConfirmpassword(!confirmpassword)}><i class={password ? 'fa-regular fa-eye-slash' : "fa-regular fa-eye"}/></p>
                    </div>
                  </div>

                 
                  {/* <NavLink className="header_links" to='/setupAccount'> */}
                  <button className='primary_btn w-100 mt-4' 
                  disabled = {!passcode || !confirmPasscode}
                  onClick={handlePassword}
                  >Next</button>
                  {/* </NavLink> */}
                </Col>

                <Col lg={4} md={5} sm={12}>
                    <img src={require('../assets/images/regCrypto.svg').default} className='img-fluid' />
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default SetPasscode