import React,{useState} from "react";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import { allData } from "../CommonData/Data";
import ReactPaginate from 'react-paginate';
import { NavLink } from "react-router-dom";

function Notices({activeCont,setActiveCont}) {
  const theme = useSelector((state) => state?.User?.theme);
  const [repositories, setRepositories] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(5);
  const handlePageChange = (event) => {
    console.log(event);
    setPageOffset(event.selected);
  };

  return (
    <>
      <div className="notices_holder p-2 p-sm-3">
      {/* <div className="sidebar_canva_shower d-xl-none mb-3">
              <button
                className="sidebar_canva_burg rounded-5 pt-1 px-2"
                onClick={handleShowSidebar}
              >
                <i class="fa-solid fa-bars" />
              </button>
            </div> */}
        <Row>
          <Col lg={5} md={5} className="d-flex align-items-center mb-3 mb-md-0">
            <h5 className="notices_hader_title ">Notices</h5>
          </Col>
          <Col
            lg={7} md={7}
            className="d-flex flex-column flex-sm-row justify-content-end align-items-start gap-3"
          >
            <div className="notices_searcher d-flex jutify-content-start align-items-center gap-3 p-2 rounded-2 flex-grow-1 w-100 ">
              <img
                src={require("../assets/images/searchglass.svg").default}
                className="img-fluid notices_search_glass"
              />
              <input
                type="text"
                placeholder="Enter search text"
                className="inter_font flex-grow-1"
              />
            </div>
            <button className="primary_btn">Search</button>
          </Col>
        </Row>
        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>
        <Row className="justify-content-between d-none d-lg-flex">
          <Col lg={4} >
            {" "}
            <h6 className="notices_table_ttile inter_font text-center">
              Title
            </h6>
          </Col>
          <Col lg={3}>
            <h6 className="notices_table_ttile inter_font text-center">Date</h6>
          </Col>
        </Row>
        {allData.noticeData.map((e) => (
        <Row onClick={() => setActiveCont('noticeInfo')} style={{cursor:"pointer"}}>
          <Col lg={9} md={9}>
            
              <p className="notices_title_hints inter_font mt-3">{e.title}</p>
            
          </Col>
          <Col lg={3} md={3}>           
              <p className="notices_title_hints inter_font text-start text-md-center mt-md-3">
                {e.date}
              </p>
          </Col>
        </Row>
        ))}

        <Row className="pagnation_holder justify-content-center justify-content-md-end mt-5">
          <Col lg={5} className="d-flex justify-content-center justify-content-md-end">
            <div className="">
            <ReactPaginate
        previousLabel={<i class="fa-solid fa-arrow-left-long" />}
        nextLabel={<i class="fa-solid fa-arrow-right-long" />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={pageOffset}
      />

<div className={`mt-2 ${
            theme == "dark"
              ? "primary_triple_grad_rel"
              : "primary_triple_grad_rel_light"
          }`}></div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Notices;
