export const allData = {
    homepageCard : [
        {
            img : require('../assets/images/triple_layer.svg').default,
            title : "Triple-Layer Asset Protection",
            desc : "User funds are securely stored offline in cold wallets. Experience Triple protection with our cold wallet storage, ensuring strong security for user assets through comprehensive protection, for a safe and relatable experience"
        },
        {
            img : require('../assets/images/lock.svg').default,
            title : "Privacy Protection",
            desc : "We integrate a privacy first approach in all our products and services. We are transparent about the data we gather from you, clearly stating how we utilize and share it. "
        },
        {
            img : require('../assets/images/safedata.svg').default,
            title : "Advance Data Protection",
            desc : "Data is encrypted during storage and while in transit. Strict authorization controls are in place to ensure that only you can access your personal and private information"
        },
        {
            img : require('../assets/images/monitor.svg').default,
            title : "Real-Time Monitoring",
            desc : "User funds are securely stored offline in cold wallets. Experience Triple protection with our cold wallet storage, ensuring strong security for user assets through comprehensive protection, for a safe and relatable experience"
        },
        {
            img : require('../assets/images/security.svg').default,
            title : "Security by Design",
            desc : "We integrate a privacy first approach in all our products and ervices. We are transparent about the data we gather from you, clearly tating how we utilize and share it. "
        },
    ],
    marketTableData : [
        {
            name : 'John Doe',
            status : true,
            country : require('../assets/images/flag.svg').default,
            userImage : require('../assets/images/person.svg').default,
            paymentMethod : 'UPI Payment',
            price : '56,351,423.54 KRW',
            quantity : '56,351,423.54 BTC',
            min : 'Min purchase 3,000,000,000 KRW',
            max : 'Max purchase 3,000,000,000 KRW',
            vendor : true,
            transaction : 'progress'
        },
        {
            name : 'John Doe',
            status : false,
            country : require('../assets/images/flag.svg').default,
            userImage : require('../assets/images/person.svg').default,
            paymentMethod : 'UPI Payment',
            price : '56,351,423.54 KRW',
            quantity : '56,351,423.54 BTC',
            min : 'Min purchase 3,000,000,000 KRW',
            max : 'Max purchase 3,000,000,000 KRW',
            vendor : true,
            transaction : 'sell'
        },
        {
            name : 'John Doe',
            status : false,
            country : require('../assets/images/flag.svg').default,
            userImage : require('../assets/images/person.svg').default,
            paymentMethod : 'UPI Payment',
            price : '56,351,423.54 KRW',
            quantity : '56,351,423.54 BTC',
            min : 'Min purchase 3,000,000,000 KRW',
            max : 'Max purchase 3,000,000,000 KRW',
            vendor : true,
            transaction : 'progress'
        },
        {
            name : 'John Doe',
            status : true,
            country : require('../assets/images/flag.svg').default,
            userImage : require('../assets/images/person.svg').default,
            paymentMethod : 'UPI Payment',
            price : '56,351,423.54 KRW',
            quantity : '56,351,423.54 BTC',
            min : 'Min purchase 3,000,000,000 KRW',
            max : 'Max purchase 3,000,000,000 KRW',
            vendor : false,
            transaction : 'sell'
        },
        {
            name : 'John Doe',
            status : true,
            country : require('../assets/images/flag.svg').default,
            userImage : require('../assets/images/person.svg').default,
            paymentMethod : 'UPI Payment',
            price : '56,351,423.54 KRW',
            quantity : '56,351,423.54 BTC',
            min : 'Min purchase 3,000,000,000 KRW',
            max : 'Max purchase 3,000,000,000 KRW',
            vendor : false,
            transaction : 'sell'
        },
    ],
    specializedData : [
        {
            id:1,
        title : 'Sopping Mall',
        img : require('../assets/images/mall.svg').default,
        darkHover : require('../assets/images/blackmall.svg').default,
        lightHover : require('../assets/images/whitemall.svg').default,
    },
    {
        id:2,
        title : 'Cryptocurrency Exchange',
        img : require('../assets/images/cryptoExchange.svg').default,
        darkHover : require('../assets/images/blackcryptoex.svg').default,
        lightHover : require('../assets/images/whitecryptoex.svg').default,
    },
    {
        id:3,
        title : 'Travel',
        img : require('../assets/images/travel.svg').default,
        darkHover : require('../assets/images/blacktravel.svg').default,
        lightHover : require('../assets/images/whitetravel.svg').default,
    },
    {
        id:4,
        title : 'Cryptocurrency Exchange',
        img : require('../assets/images/mall.svg').default,
        darkHover : require('../assets/images/blackmall.svg').default,
        lightHover : require('../assets/images/whitemall.svg').default,
    },
    {
        id:5,
        title : 'Sopping Mall',
        img : require('../assets/images/tags.svg').default,
        darkHover : require('../assets/images/blacktags.svg').default,
        lightHover : require('../assets/images/whitetags.svg').default,
    },
    {
        id:6,
        title : 'Digital Marketing',
        img : require('../assets/images/certify.svg').default,
        darkHover : require('../assets/images/blackcertify.svg').default,
        lightHover : require('../assets/images/whitecertify.svg').default,
    },
    {
        id:7,
        title : 'Video/Special Video',
        img : require('../assets/images/video.svg').default,
        darkHover : require('../assets/images/blackvideo.svg').default,
        lightHover : require('../assets/images/whitevideo.svg').default,
    },
    {
        id:8,
        title : 'Gambling',
        img : require('../assets/images/gambling.svg').default,
        darkHover : require('../assets/images/blackgambling.svg').default,
        lightHover : require('../assets/images/whitegambling.svg').default,
    },
],
benefitsData : [
    {
        hint : 'Cryptocurrency holders worldwide',
        darkImg : require('../assets/images/binance.svg').default,
        hoverImg : require('../assets/images/blueactivebinance.svg').default,
        lightImg : require('../assets/images/blackbinance.svg').default
    },
    {
        hint : 'Prevention of payment fraud',
        darkImg : require('../assets/images/fraud.svg').default,
        hoverImg : require('../assets/images/fraudblue.svg').default,
        lightImg : require('../assets/images/blackfraud.svg').default
    },
    {
        hint : 'Low Payment Fee',
        darkImg : require('../assets/images/paymentfee.svg').default,
        hoverImg : require('../assets/images/bluepaymentfee.svg').default,
        lightImg : require('../assets/images/blackpaymentfee.svg').default
    },
    {
        hint : 'Integrated Settlement',
        darkImg : require('../assets/images/integration.svg').default,
        hoverImg : require('../assets/images/blueintegration.svg').default,
        lightImg : require('../assets/images/blackintegration.svg').default
    },
    {
        hint : 'Integrated Settlement',
        darkImg : require('../assets/images/integration.svg').default,
        hoverImg : require('../assets/images/blueintegration.svg').default,
        lightImg : require('../assets/images/blackintegration.svg').default
    },
    {
        hint : 'Autonomous selection of payment cryptocurrency',
        darkImg : require('../assets/images/autonomous.svg').default,
        hoverImg : require('../assets/images/blueautonomous.svg').default,
        lightImg : require('../assets/images/blackautonomous.svg').default
    },
],
noticeData : [
    {
        title : '[Note] Upbit Singapore is now officially licensed by the MAS!',
        date : '08 jan 2024'
    },
    {
        title : '[Airdrop] LUNA2 Airdrop for LUNC Holders (14th)',
        date : '08 jan 2024'
    },
    {
        title : '[Wallet] Temporary Suspension of ETC Withdraw/Deposit due to ETC hardfork',
        date : '08 jan 2024'
    },
    {
        title : '[Note] Upbit Singapore is now officially licensed by the MAS!',
        date : '08 jan 2024'
    },
    {
        title : '[Feature] New Look in Announcement',
        date : '08 jan 2024'
    },
    {
        title : '[Wallet] Scheduled Maintenance of SGD Deposit/Withdrawal',
        date : '08 jan 2024'
    },

    {
        title : '[Note] Be Aware Fake Website/Potential Fraud Attempt',
        date : '08 jan 2024'
    },
    {
        title : '[Note] Upbit Singapore is now officially licensed by the MAS!',
        date : '08 jan 2024'
    },
    {
        title : '[Airdrop] AQUA Airdrop for XLM Holders (23rd round)',
        date : '08 jan 2024'
    },
    {
        title : '[Note] Upbit Singapore is now officially licensed by the MAS!',
        date : '08 jan 2024'
    },

    {
        title : '[Airdrop] LUNA2 Airdrop for LUNC Holders (14th))',
        date : '08 jan 2024'
    },
],
paymentChecks : [
    {
        type : 'Swift'
    },
    {
        type : 'Paypal'
    },
    {
        type : 'Wise'
    },
    {
        type : 'Payoneer'
    },
    {
        type : 'Western Union'
    },
    {
        type : 'Western Union'
    },
    {
        type : 'SEPA'
    },
    {
        type : 'Amazon pay'
    },
    {
        type : 'ApplePay'
    },
    {
        type : 'GooglePay'
    },
    {
        type : 'NaverPay'
    },
    {
        type : 'Kakao Pay '
    },
    {
        type : 'Alipay'
    },
    {
        type : 'Wechat Pay'
    },
    {
        type : 'Suica'
    },
    {
        type : 'LINE Pay'
    },
    {
        type : 'Pay pay'
    },
    {
        type : 'Rakuten pay'
    },
    {
        type : 'MoMo Pay'
    },
    {
        type : 'Zalo Pay'
    },
    {
        type : 'Viettel Pay'
    },
    {
        type : 'UPI'
    },
    {
        type : 'Paytm'
    },
    {
        type : 'Gopay'
    },
    {
        type : 'OVO'
    },
    {
        type : 'GrabPay'
    },
    {
        type : 'Touch’n Go eWallet'
    },
    
],
withdrawHistory : [
    // {
    //     coin : 'ETH',
    //     Time : '12/12/24',
    //     type : 'escrow',
    //     amount : 45,
    //     status : 'Completed',
    //     action : <div className='withdraw__historyActions d-flex justify-content-center align-items-center gap-2'>
    //         <button className='withdraw__editAction rounded-1 inter_font'><i class="fa-solid fa-pencil" /></button>
    //         <button className='withdraw__deleteAction rounded-1 inter_font'><i class="fa-solid fa-trash-can" /></button>
    //     </div>
    // },
    // {
    //     coin : 'ETH',
    //     Time : '12/12/24',
    //     type : 'escrow',
    //     amount : 55,
    //     status : 'Failed',
    //     action : <div className='withdraw__historyActions d-flex justify-content-center align-items-center gap-2'>
    //         <button className='withdraw__editAction rounded-1 inter_font'><i class="fa-solid fa-pencil" /></button>
    //         <button className='withdraw__deleteAction rounded-1 inter_font'><i class="fa-solid fa-trash-can" /></button>
    //     </div>
    // },
    // {
    //     coin : 'ETH',
    //     Time : '12/12/24',
    //     type : 'escrow',
    //     amount : 99,
    //     status : 'InProgress',
    //     action : <div className='withdraw__historyActions d-flex justify-content-center align-items-center gap-2'>
    //         <button className='withdraw__editAction rounded-1 inter_font'><i class="fa-solid fa-pencil" /></button>
    //         <button className='withdraw__deleteAction rounded-1 inter_font'><i class="fa-solid fa-trash-can" /></button>
    //     </div>
    // },
   
],
productRegImages : [
    {
        img : require('../assets/images/home.svg').default,
    },
    {
        img : require('../assets/images/store.svg').default,
    },
    {
        img : require('../assets/images/monitor.svg').default,
    },
    {
        img : require('../assets/images/offers.svg').default,
    },
    {
        img : require('../assets/images/tradechart.svg').default,
    },
],
saleSettlementCard : [
    {
        no : '3000',
        userId : 'Kkkkkkkaddsadgsdf@gmail.com',
        buyerName : 'Jered',
        phoneNumber : '+91 0101 0101 23',
        productName : 'Chocolate Waffle',
        tokenType : 'USDT (TRC20)',
        buyerAddress : 'Tx123245sff1dfsffsfsdfsd1sfsd3',
        commodityPrice : '1,000,000 USDT',
        assetBeforePayment : '1,000,000 USDT',
        orderQuantity : '1.000',
        paymentAmount : '1,000,000 USDT',
        fee : '10',
        feeAmount : '1,000,000 USDT',
        assetAfterPayment : '1,000,000 USDT',
        paymentDate : '2023.12.30  23:59:59'
    },
    {
        no : '3000',
        userId : 'Kkkkkkkaddsadgsdf@gmail.com',
        buyerName : 'Jered',
        phoneNumber : '+91 0101 0101 23',
        productName : 'Chocolate Waffle',
        tokenType : 'USDT (TRC20)',
        buyerAddress : 'Tx123245sff1dfsffsfsdfsd1sfsd3',
        commodityPrice : '1,000,000 USDT',
        assetBeforePayment : '1,000,000 USDT',
        orderQuantity : '1.000',
        paymentAmount : '1,000,000 USDT',
        fee : '10',
        feeAmount : '1,000,000 USDT',
        assetAfterPayment : '1,000,000 USDT',
        paymentDate : '2023.12.30  23:59:59'
    },
    {
        no : '3000',
        userId : 'Kkkkkkkaddsadgsdf@gmail.com',
        buyerName : 'Jered',
        phoneNumber : '+91 0101 0101 23',
        productName : 'Chocolate Waffle',
        tokenType : 'USDT (TRC20)',
        buyerAddress : 'Tx123245sff1dfsffsfsdfsd1sfsd3',
        commodityPrice : '1,000,000 USDT',
        assetBeforePayment : '1,000,000 USDT',
        orderQuantity : '1.000',
        paymentAmount : '1,000,000 USDT',
        fee : '10',
        feeAmount : '1,000,000 USDT',
        assetAfterPayment : '1,000,000 USDT',
        paymentDate : '2023.12.30  23:59:59'
    },
],
coinSelector : [
    {
        img : require('../assets/images/tehther.svg').default,
        title : 'Tehther'
    },
    {
        img : require('../assets/images/btc.png'),
        title : 'Bitcoin'
    },
    {
        img : require('../assets/images/eth.svg').default,
        title : 'Ethereum'
    },
    {
        img : require('../assets/images/tehther.svg').default,
        title : 'Tehther'
    },
],
currentPrice : [
    {
        img : require('../assets/images/binances.svg').default,
        coinName : 'Binance',
        price : '9,000,000,000',
    },
    {
        img : require('../assets/images/coinbase.svg').default,
        coinName : 'Coinbase',
        price : '9,000,000,000',
    },
    {
        img : require('../assets/images/bybit.svg').default,
        coinName : 'Bybit',
        price : '9,000,000,000',
    },
    {
        img : require('../assets/images/okx.svg').default,
        coinName : 'OKX',
        price : '9,000,000,000',
    },
    {
        img : require('../assets/images/bidget.svg').default,
        coinName : 'Bitget',
        price : '9,000,000,000',
    },
    {
        img : require('../assets/images/Upbit.svg').default,
        coinName : 'Upbit',
        price : '9,000,000,000',
    },
    {
        img : require('../assets/images/bithumb.svg').default,
        coinName : 'Bithumb',
        price : '9,000,000,000',
    },
]
}