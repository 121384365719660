import React from 'react'
import { Container } from 'react-bootstrap'
import Deposit from '../Components/Deposit'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

function DepositPage() {
  return (
    <>
    <Container fluid className="common_spacer">
        <Header/>
        <Container className='custom_container  p-0 p-sm-2'>
        <div className="box_container p-2 p-sm-3 p-md-4 p-lg-5 mt-3">
        <Deposit />
            </div>
        </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default DepositPage