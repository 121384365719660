import React, { useState } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CreateNewUserHooks } from '../Hooks/UserHooks';

function SetUpAccount() {
    const theme = useSelector((state) => state?.User?.theme);
    const location = useLocation();
    const navigate = useNavigate();


    const [referalid , setReferalid] = useState("");

    const HandleSubmit = async() => {
      try{
        let payload = {
          email : location?.state?.email ? location?.state?.email : "",
          registerType :  location?.state?.email  ? "Email" : "Mobile",
          phoneCode : location?.state?.phoneCode,
          country : location?.state?.country,
          termsandcondition : true,
          password : location?.state?.password,
          phoneNo : location?.state?.phoneNo,
          referal : referalid
        }
        let create = await CreateNewUserHooks(payload);
        console.log("Create usr" , create);
        if(create?.type == "Success" && create?.message){
          navigate("/");
        }
      }
      catch(e){
        console.log("Error on handlesubmit" , e);
      }
    }

  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center'>Welcome to Otex!</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card position-relative rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <p className='mailver_backBtn inter_font m-0'>&lt; Back</p>
            <Row className='align-items-center justify-content-between mt-2'>
                <Col lg={7} md={7} sm={12} className='mb-4 mb-md-0'>
                    <h5 className='creacteacc_title m-0 inter_font mt-3 mt-lg-0'>Set up your account</h5>
                    <p className='mailver_hint inter_font m-0 mt-2'>Your account has been created successfully. Set it up now.</p>
                <div className="login_single_input d-flex flex-column mt-4">
                    <label className="mb-2 inter_font">Referral ID (Optional)</label>
                    <div className='mailver_inputHolder p-2 rounded-2 d-flex justify-content-between align-items-center gap-3'>
                    <input
                      type='text'
                      className="inter_font flex-grow-1"
                      onChange={(e) => setReferalid(e?.target?.value)}
                    />
                   <p className='mailver_inpBtb inter_font m-0'>Submit</p>
                    </div>
                  </div>

                  <p className='mailver_hint mailver_weighter inter_font m-0 mt-3'>Marketing Updates</p>  
                  <p className='mailver_hint mailver_weighter inter_font m-0'>I agree to receive marketing updates from Otex about rewards and special offers.</p>  
                  {/* <NavLink className="header_links" to='/stayLogged'> */}
                  <button className='primary_btn w-100 mt-4' onClick={HandleSubmit}>Next</button>
                  {/* </NavLink> */}
                </Col>

                <Col lg={4} md={5} sm={12}>
                    <img src={require('../assets/images/regCrypto.svg').default} className='img-fluid' />
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default SetUpAccount