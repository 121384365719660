import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink } from 'react-router-dom';

const StoreManagementRegister = () => {
    const theme = useSelector((state) => state?.User?.theme);
   
  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower storemanager__title inter_font m-0 text-center text-uppercase mt-4 mb-4 mb-sm-0'>STORE MANAGEMENT REGISTRATION</h5>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5'>
            <Row className='align-items-center justify-content-between'>
                <Col lg={7} md={7} sm={12} className='mb-5 mb-md-0'>
                <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Login Email Authentication</label>
                    <div className='d-flex justify-content-start align-items-center mt-2 gap-2 gap-sm-3'>
                    <input
                      type="text"
                      placeholder="Please enter a registered email"
                      className="primary_input rounded-1 p-2 inter_font flex-grow-1"
                    />
                    <button className='primary_btn storeReg_mobBtn rounded-2 inter_font'>Send</button>
                    </div>

                    <div className='d-flex justify-content-start align-items-center gap-2 gap-sm-3 mt-4'>
                    <input
                      type="text"
                      placeholder="Enter authentication number"
                      className="primary_input rounded-1 p-2 inter_font flex-grow-1"
                    />
                    <button className='primary_btn storeReg_mobBtn rounded-2 inter_font'>Check</button>
                    </div>
                    <p className='reg_primaryText inter_font mt-2'>Resend authentication number</p>
                  </div>
                  <NavLink className="header_links" to='/storeRegistration'>
                  <button className='primary_btn w-100 mt-4'>Join new store</button>
                  </NavLink>

                </Col>

                <Col lg={4} md={5} sm={12}>
                 <img src={require('../assets/images/home.svg').default} className='img-fluid' />
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default StoreManagementRegister