import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SecurityPhoneNumber from '../Modals/SecurityPhoneNumber';
import SecurityEmail from '../Modals/SecurityEmail';
import SecurityPassowrd from '../Modals/SecurityPassowrd';
import SecurityGoogleAuth from '../Modals/SecurityGoogleAuth';
import SecurityDeviceManage from '../Modals/SecurityDeviceManage';
import SecurityAccountActivity from '../Modals/SecurityAccountActivity';
import { GetLoginHistoryHooks, GetUserHooks, GetUserId } from '../Hooks/UserHooks';

function Security() {
    const theme = useSelector((state) => state?.User?.theme);
    const dispatch = useDispatch();

    // phone Number modal
    const [showSeucityPhone, setShowSeucityPhone] = useState(false);

  const handleCloseSeucityPhone = () => setShowSeucityPhone(false);
  const handleShowSeucityPhone = () => setShowSeucityPhone(true);

  // Email modal
  const [showSeucityEmail, setShowSeucityEmail] = useState(false);

  const handleCloseSeucityEmail = () => setShowSeucityEmail(false);
  const handleShowSeucityEmail = () => setShowSeucityEmail(true);

  // Password Reset modal
  const [showPassword, setShowPassword] = useState(false);

  const handleClosePassword = () => setShowPassword(false);
  const handleShowPassword = () => setShowPassword(true);

    // Password Reset modal
    const [showGoogleAuth, setGoogleAuth] = useState(false);

    const handleClosesetGoogleAuth = () => setGoogleAuth(false);
    const handleShowsetGoogleAuth = () => setGoogleAuth(true);

    // Password Reset modal
    const [showDeviceManager, setDeviceManager] = useState(false);

    const handleCloseDeviceManager = () => setDeviceManager(false);
    const handleShowDeviceManager = () => setDeviceManager(true);

     // Password Reset modal
     const [showAccountActivity, setAccountActivity] = useState(false);

     const handleCloseAccountActivity = () => setAccountActivity(false);
     const handleShowAccountActivity = () => setAccountActivity(true);


     const [user , setUser] = useState({});
     const [userkyc , setUserkyc] = useState({});
     const [Activityhistory , setActivityhistory] = useState([]);
     const [operationhistory , setOperationhistory] = useState([]);


     const fetchdata = async() => {
        let user = await GetUserHooks({userid : GetUserId()} , dispatch);
        console.log("userdata ," , user);
        setUser(user?.record);
        setUserkyc(user?.kyc); 
        let history = await GetLoginHistoryHooks({userid : GetUserId()});
        console.log("history" , history);
        if(history?.type == "Success"){
            setActivityhistory(history?.record);
            setOperationhistory(history?.operationhistory);
        }
      }
    
      useEffect(() => {
        fetchdata();
      } , [])
    


  return (
    <>
     <div className="notices_holder p-2 p-sm-3 pb-md-5">
     <Row>
          <Col lg={6} md={6} className="d-flex align-items-center mb-3 mb-md-0">
          <div className="prfl_profile_dtls_holder d-flex justify-content-start align-items-center gap-3">
          <div className="prfl_profile_img_holder position-relative">
            <img
              src={require("../assets/images/person.svg").default}
              className="img-fluid"
            />
            <div className="prfl_online_indicator rounded-5"></div>
          </div>
          <div className="prfl_username_holder d-flex jutify-content-start align-items-center gap-2">
            <p className="prfl_username">{user?.firstName + " " + user?.lastName}</p>
            <img
              src={require("../assets/images/vendor.svg").default}
              className="img-fluid"
            />
          </div>
        </div>
          </Col>
          <Col
            lg={6}
            md={6}
            className="d-flex flex-column flex-sm-row justify-content-end align-items-start gap-3"
          >
            {/* <button className="profile_change_btn inter_font">Change</button> */}
          </Col>
        </Row>
        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

<Row>
<Col lg={8}>
<h5 className="notices_hader_title  inter_font">Security</h5>
<h6 className='security_base_hint inter_font mt-2'>Please setup the following information as soon as possible</h6>
<p className='pm_primary_text inter_font mt-2'>Withdraw whitelist</p>
</Col>
<Col lg={4} className='d-flex justify-content-end align-items-center gap-3'>
    <img src={require('../assets/images/shield.svg').default} className='img-fluid' />
    <p className='security_base_hint inter_font'>High <br/><span>Security Level</span></p>
</Col>
</Row>

<Row className='mt-5'>
    <Col lg={4} md={6} xs={12}  className='d-flex align-items-end mb-3'>
        <div className='security_card_holder'>
            <p className='security_card_provider inter_font mb-3'>Two-Factor Authentication</p>
            <div className='security_card p-2 rounded-3'>
                <div className='security_card_head d-flex justify-content-between align-items-center'>
                    <img src={require('../assets/images/mob.svg').default} className='img-fluid' />
                    <div className='security_card_head_badge rounded-pill d-flex justify-content-center align-items-center gap-3'>
                        <div className='security_indicatorr rounded-5'></div>
                        <p className='inter_font'>012356****</p>
                    </div>
                </div>

                <div className='security_card_body'>
                    <h6 className='inter_font mt-3'>Phone number verification</h6>
                    <p className='mt-3 inter_font'>Phone verification codes help guarantee account and transaction security. Changing your bound phone number will disable payment and withdrawal for 24 hours </p>
<button className='security_edit_btn inter_font mt-3' onClick={() => handleShowSeucityPhone()}>Edit</button>
                </div>
            </div>
        </div>
    </Col>

    <Col lg={4} md={6} xs={12}  className='d-flex align-items-end mb-3'>
        <div className='security_card_holder'>
            <p className='security_card_provider inter_font mb-3'></p>
            <div className='security_card p-2 rounded-3'>
                <div className='security_card_head d-flex justify-content-between align-items-center'>
                    <img src={require('../assets/images/mail.svg').default} className='img-fluid' />
                    <div className='security_card_head_badge rounded-pill d-flex justify-content-center align-items-center gap-1'>
                        <div className='security_indicatorr rounded-5'></div>
                        <p className='inter_font'>{user?.email}</p>
                    </div>
                </div>

                <div className='security_card_body'>
                    <h6 className='inter_font mt-3'>Email verification</h6>
                    <p className='mt-3 inter_font'>Email verification codes help guarantee account and transaction security. Changing your bound email will disable payment and withdrawal for 24 hours </p>
                    <button className='security_edit_btn inter_font mt-3' onClick={() => handleShowSeucityEmail()}>Edit</button>
                </div>
            </div>
        </div>
    </Col>

    <Col lg={4} md={6} xs={12}  className='d-flex align-items-end mb-3'>
        <div className='security_card_holder'>
            <p className='security_card_provider inter_font mb-3'></p>
            <div className='security_card p-2 rounded-3'>
                <div className='security_card_head d-flex justify-content-between align-items-center'>
                    <img src={require('../assets/images/google.svg').default} className='img-fluid' />
                    <div className='security_card_head_badge rounded-pill d-flex justify-content-center align-items-center gap-3'>
                        <div className='security_indicatorr rounded-5'></div>
                        <p className='inter_font'>Authenticator</p>
                    </div>
                </div>

                <div className='security_card_body'>
                    <h6 className='inter_font mt-3'>Google Authenticator (recommended)</h6>
                    <p className='mt-3 inter_font'>Google Authenticator codes help  guarantee account and transaction security. Changing your bound email will disable payment and withdrawal for 24 hours </p>
                    <button className='security_edit_btn inter_font mt-3' onClick={() => handleShowsetGoogleAuth()}>Edit</button>
                </div>
            </div>
        </div>
    </Col>

    <Col lg={4} md={6} xs={12}  className='d-flex align-items-end mb-3'>
        <div className='security_card_holder'>
            <p className='security_card_provider inter_font mb-3'>Advanced Security</p>
            <div className='security_card p-2 rounded-3'>
                <div className='security_card_head d-flex justify-content-between align-items-center'>
                    <img src={require('../assets/images/pass.svg').default} className='img-fluid' />
                    <div className='security_card_head_badge rounded-pill d-flex justify-content-center align-items-center gap-3'>
                        <div className='security_indicatorr rounded-5'></div>
                        <p className='inter_font'>*******</p>
                    </div>
                </div>

                <div className='security_card_body'>
                    <h6 className='inter_font mt-3'>Login password</h6>
                    <p className='mt-3 inter_font'>The login password help guarantee account and transaction security. Changing the login password will disable payment and withdrawal for 24 hours  </p>
                    <button className='security_edit_btn inter_font mt-3' onClick={() => handleShowPassword()}>Edit</button>
                </div>
            </div>
        </div>
    </Col>

    <Col lg={4} md={6} xs={12}  className='d-flex align-items-end mb-3'>
        <div className='security_card_holder'>
            <p className='security_card_provider inter_font mb-3'>Device & Activities</p>
            <div className='security_card p-2 rounded-3'>
                <div className='security_card_head d-flex justify-content-between align-items-center'>
                    <img src={require('../assets/images/device.svg').default} className='img-fluid' />
                    <div className='security_card_head_badge rounded-pill d-flex justify-content-center align-items-center gap-1'>
                        <div className='security_indicatorr rounded-5'></div>
                        <p className='inter_font'>{user?.email}</p>
                    </div>
                </div>

                <div className='security_card_body'>
                    <h6 className='inter_font mt-3'>Trusted device management</h6>
                    <p className='mt-3 inter_font'>Email verification codes help guarantee account and transaction security. Changing your bound email will disable payment and withdrawal for 24 hours </p>
                    <button className='security_manage_btn inter_font rounded-2' onClick={() => handleShowDeviceManager()}>Manage</button>
                </div>
            </div>
        </div>
    </Col>

    <Col lg={4} md={6} xs={12}  className='d-flex align-items-end mb-3'>
        <div className='security_card_holder w-100'>
            <p className='security_card_provider inter_font mb-3'></p>
            <div className='security_card p-2 rounded-3'>
                <div className='security_card_head d-flex justify-content-between align-items-center'>
                    <img src={require('../assets/images/activity.svg').default} className='img-fluid' />
                    <div className='security_card_head_badge rounded-pill d-flex justify-content-center align-items-center gap-3'>
                        <div className='security_indicatorr rounded-5'></div>
                        <p className='inter_font'>Activity</p>
                    </div>
                </div>

                <div className='security_card_body'>
                    <h6 className='inter_font mt-3'>Account activity</h6>
                    <p className='mt-3 inter_font'>Recent login: {new Date(Activityhistory[0]?.loginTime).toLocaleDateString() + " "}   {new Date(Activityhistory[0]?.loginTime)?.toLocaleTimeString()}
{"   "}IP: {Activityhistory[0]?.ipAddress}</p>
<button className='security_manage_btn inter_font rounded-2' onClick={() => handleShowAccountActivity()}>Manage</button>
                </div>
            </div>
        </div>
    </Col>
</Row>

        
     </div>

     <SecurityPhoneNumber showSeucityPhone={showSeucityPhone} setShowSeucityPhone={setShowSeucityPhone} handleCloseSeucityPhone={handleCloseSeucityPhone}/>

     <SecurityEmail show = {showSeucityEmail} user = {user} handleClose = {handleCloseSeucityEmail} />

     <SecurityPassowrd user = {user} show={showPassword} handleClose={handleClosePassword} />

     <SecurityGoogleAuth show={showGoogleAuth} user = {user} handleClose = {handleClosesetGoogleAuth} />

     <SecurityDeviceManage show={showDeviceManager} loginhistory = {Activityhistory} handleClose = {handleCloseDeviceManager} />

     <SecurityAccountActivity show={showAccountActivity} loginhistory = {Activityhistory} 
        operationhistory = {operationhistory}
        handleClose={handleCloseAccountActivity} />
    </>
  )
}

export default Security