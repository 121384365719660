import React from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function StoreCard() {
  return (
    <div className="storepage_card p-4 rounded-2">
      <Row className="d-flex justify-content-between align-items-start">
        <Col xs={6} sm={6} md={2} className="mb-3 mb-md-0">
          <p className="sc_coinName inter_font text-center">Bitcoin</p>
          <p className="sc_coinSym inter_font text-center">BTC</p>
        </Col>
        <Col xs={6} sm={6} md={4} className="mb-3 mb-md-0">
          <p className="sc_labeltext inter_font text-center">
            Lowest Selling Price
          </p>
          <p className="sc_coinValue inter_font text-center">300,000,000</p>
        </Col>
        <Col xs={6} sm={6} md={3} className="mb-3 mb-md-0">
          <p className="sc_labeltext inter_font text-center">High Buy price</p>
          <p className="sc_coinValue inter_font text-center">300,000,000</p>
        </Col>
        <Col xs={6} sm={6} md={3} className="mb-3 mb-md-0">
          <p className="sc_labeltext inter_font text-center">Total Volume</p>
          <p className="sc_coinValue inter_font text-center">300,000,000 </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          xs={5}
          className="d-flex justify-content-center justify-content-md-start"
        >
          <img
            src={require("../assets/images/btc.svg").default}
            className="img-fluid store_cardImg"
          />
        </Col>
        <Col
          xs={7}
          className="d-flex flex-column flex-sm-row justify-content-end align-items-end align-items-sm-center gap-2"
        >
          <NavLink to="/storeDetail" className="header_links">
            <button className="primary_btn mb-2 mb-sm-0 me-sm-0">Buy</button>
          </NavLink>
          <NavLink to="/storeDetail" className="header_links">
            <button className="secondary_btn">Sell</button>
          </NavLink>
        </Col>
      </Row>
    </div>
  );
}

export default StoreCard;
