import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Components/Header";
import { allData } from "../CommonData/Data";
import { NavLink } from "react-router-dom";
import Footer from "../Components/Footer";
import { useSelector } from "react-redux";

function Home() {
  console.log(allData, "sdfsfsf");
  const cardData = allData.homepageCard;
  const theme = useSelector((state) => state?.User?.theme);
  const [onHover, setOnhover] = useState();
  const [benefitHover,setBenefitHover] = useState()
  const handleMouseOver = (event, index) => {
    event.preventDefault();
    setOnhover(index);
  };
  const handleBenefitHover = (event,index) =>{
    event.preventDefault();
    setBenefitHover(index);
  }
  console.log("onHover", onHover);
  console.log(theme, "theherjh");

  useEffect(() => {
    window.scroll(0,0)
  },[])
  return (
    <>
      <Container fluid className="common_spacer pb-5">
        <section className="fit_vh_section">
          <div className="box_container position-relative overflow-hidden">
            <img
              src={require("../assets/images/banner_blurball.png")}
              className={ theme == 'dark' ? "banner_blurball" : 'd-none'}
            />
            <Header />

            <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
            <Container className="mt-5 mt-md-0 custom_container ">
              <Row>
                <Col
                  lg={6}
                  md={6}
                  xs={12}
                  className="d-flex justify-content-center align-items-center mb-4 mb-md-0"
                >
                  <div className="home_bannerLeft">
                    <h4>
                      Register for a store & make easy payments with{" "}
                      <span>stablecoins!</span>
                    </h4>
                    <p className="mt-2">
                      World’s safest smart contract escrow system!
                    </p>
                    <button className="primary_btn mt-3">Get Started</button>
                  </div>
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-4 mb-md-0">
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="home_bannerRight py-4">
                        <p className="violet_text">
                          P2P Global Largest Exchange Payment methods in{" "}
                          <span>120 countries</span>
                        </p>
                        <img
                          src={
                            theme === "dark"
                              ? require("../assets/images/banner_escrow.svg")
                                  .default
                              : require("../assets/images/banner_escrowlight.svg")
                                  .default
                          }
                          className="img-fluid"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className="fit_vh_section position-relative">
          <img
            src={require("../assets/images/middle_blurball.png")}
            className={ theme =='dark' ? "img-fluid middle_blurball" : 'd-none' }
          />
          <Container className="mt-5 custom_container">
            <h6 className="home_section_title ">
              Our Commitment to Compliance and Your Protection
            </h6>

            <Row className="home_higher_top">
              {cardData.map((e) => (
                <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
                  <div className="commitment_card">
                    <img
                      src={e.img}
                      className="img-fluid commitment_card_img"
                    />
                    <p className="commitment_card_title mt-5">{e.title}</p>
                    <p className="commitment_card_desc mt-2">{e.desc}</p>
                  </div>
                </Col>
              ))}
              <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
                <div className="">
                  <p className="commitment_card_title mt-5">
                    Your Trusted Crypto Exchange
                  </p>
                  <button className="primary_btn mt-3">
                    Sign Up Now{" "}
                    <img
                      src={require("../assets/images/rightLong.svg").default}
                      className="img-fluid"
                    />
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="home_simpleSteps">
      </section> */}
        <Container className="mt-5">
          <h6 className="home_section_title text-center">
            Three Simple steps to Start Trading
          </h6>
          <Row className="justify-content-center mt-5">
            <Col lg={11}>
              <Row className="d-none d-sm-flex">
                <Col lg={2} md={2} sm={2} xs={12} className="p-0">
                  <div className="home_stepsHolder">
                    <img
                      src={require("../assets/images/email.svg").default}
                      className="img-fluid home_steps_img"
                    />
                    <img
                      src={
                        require("../assets/images/right_rectangle.svg").default
                      }
                      className="img-fluid right_rectangle d-none d-sm-block"
                    />
                  </div>
                  <p className="home_steps_hint text-center inter_font mt-3">
                    Register with E-mail
                  </p>
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className="d-flex justify-content-center align-items-center p-0"
                >
                  <hr className="home_hr_connector d-flex" />
                </Col>
                <Col lg={2} md={2} sm={2} xs={12} className="p-0">
                  <div className="home_stepsHolder">
                    <img
                      src={require("../assets/images/fund.svg").default}
                      className="img-fluid home_steps_img"
                    />
                    <img
                      src={
                        require("../assets/images/right_rectangle.svg").default
                      }
                      className="img-fluid center_right_rectangle"
                    />
                    <img
                      src={
                        require("../assets/images/left_rectangle.svg").default
                      }
                      className="img-fluid center_left_rectangle"
                    />
                  </div>
                  <p className="home_steps_hint text-center inter_font mt-3">
                    Verify Your Identity
                  </p>
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className="d-flex justify-content-center align-items-center p-0"
                >
                  <hr className="home_hr_connector" />
                </Col>
                <Col lg={2} md={2} sm={2} xs={12} className="p-0">
                  <div className="home_stepsHolder">
                    <img
                      src={require("../assets/images/user.svg").default}
                      className="img-fluid home_steps_img"
                    />
                    <img
                      src={
                        require("../assets/images/left_rectangle.svg").default
                      }
                      className="img-fluid left_rectangle"
                    />
                  </div>
                  <p className="home_steps_hint text-center inter_font mt-3">
                    Deposit Funds
                  </p>
                </Col>
              </Row>

              <div className="home_mob_steps d-sm-none">
                <div className="home_mob_singleStep_holder d-flex align-items-center justify-content-center">
                  <div className="home_mob_singleStep position-relative overflow-hidden d-flex align-items-start justify-content-center">
                    <img
                      src={require("../assets/images/mailarrow.svg").default}
                      className="img-fluid mob_stepImg"
                    />
                  </div>
                  <p className="home_steps_hint text-center inter_font home_steps_mobhint mb-5">
                    Register with E-mail
                  </p>
                </div>
                <div className="home_mob_singleStep_holder d-flex align-items-center justify-content-center">
                  <div className="home_mob_singleStep position-relative overflow-hidden d-flex align-items-center justify-content-center">
                    <img
                      src={require("../assets/images/pricearrow.svg").default}
                      className="img-fluid mob_stepImg"
                    />
                  </div>
                  <p className="home_steps_hint text-center inter_font home_steps_mobhint">
                    Verify Your Identity
                  </p>
                </div>
                <div className="home_mob_singleStep_holder d-flex align-items-center justify-content-center">
                  <div className="home_mob_singleStep position-relative overflow-hidden d-flex align-items-end justify-content-center">
                    <img
                      src={require("../assets/images/userarrow.svg").default}
                      className="img-fluid mob_stepImg"
                    />
                  </div>
                  <p className="home_steps_hint text-center inter_font home_steps_mobhint mt-5">
                    Deposit Funds
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
            <Col lg={6}>
              <div className="d-flex flex-column justify-content-center align-items-center ">
                <h5 className="home_paxText inter_font text-center">
                  Trade on Paxful and start your very own Fintech business
                </h5>
                <button className="primary_btn mt-5">Get Started</button>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="box_container stable_boxContainer home_higher_top rounded-4 pb-5">
          <Container className="position-relative">
            <img
              src={require("../assets/images/fish_blurball.png")}
              className={theme == 'dark' ? "home_fishblurball" : 'd-none' }
            />
            <img 
              src={require("../assets/images/fish_blurball.png")}
              className={theme == 'dark' ? "home_fishblurball_right" : 'd-none' }
            />
            <Row className="justify-content-center">
              <Col lg={6}>
                <h6 className="home_section_title text-center mt-5">
                  Stablecoin Liquidity Supply
                </h6>
                <h3 className="home_blueTitle text-center inter_font mt-2">
                  Trade on OTEX and start your very own Fintech business
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-center ">
              <Col lg={10} md={12} xs={12}>
                <Row className="home_medium_top flex-column-reverse flex-sm-row">
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                    className="mb-4  d-flex justify-content-center "
                  >
                    <img
                      src={require("../assets/images/offers.svg").default}
                      className="img-fluid"
                    />
                  </Col>
                  <Col
                    lg={{ span: 7, offset: 1 }}
                    md={{ span: 7, offset: 1 }}
                    sm={{ span: 7, offset: 1 }}
                    xs={{ span: 12, offset: 0 }}
                    className="d-flex flex-column justify-content-center align-items-start mb-4"
                  >
                    <h5 className="home_paxText inter_font">
                      1. Create or Find An Offer
                    </h5>
                    <p className="commitment_card_desc mt-2">
                      You can browse or create offers for both buying and
                      selling Bitcoin. Set your own rates, find a trade partner,
                      and make your first transaction today.
                    </p>
                  </Col>
                </Row>
                <Row className="home_medium_top">
                  <Col
                    lg={{ span: 7, offset: 1 }}
                    md={{ span: 7, offset: 1 }}
                    sm={{ span: 7, offset: 1 }}
                    xs={{ span: 12, offset: 0 }}
                    className="d-flex flex-column justify-content-center align-items-start mb-4"
                  >
                    <h5 className="home_paxText inter_font">
                      2. Build Your Profile
                    </h5>
                    <p className="commitment_card_desc mt-2">
                      Find buyers by completing verification, adding a profile
                      image, and gathering healthy feedback. Having a long
                      healthy trade history increase your reputation and help
                      you gain the trust of potential customers.{" "}
                    </p>
                  </Col>
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                    className="mb-4 d-flex justify-content-center"
                  >
                    <img
                      src={require("../assets/images/profile.svg").default}
                      className="img-fluid"
                    />
                  </Col>
                </Row>
                <Row className="home_medium_top flex-column-reverse flex-sm-row">
                  <Col
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                    className="mb-4 d-flex justify-content-center"
                  >
                    <img
                      src={require("../assets/images/tradechart.svg").default}
                      className="img-fluid"
                    />
                  </Col>
                  <Col
                    lg={{ span: 7, offset: 1 }}
                    md={{ span: 7, offset: 1 }}
                    sm={{ span: 7, offset: 1 }}
                    xs={{ span: 12, offset: 0 }}
                    className="d-flex flex-column justify-content-center align-items-start mb-4"
                  >
                    <h5 className="home_paxText inter_font">
                      3. Trade and Earn Profits
                    </h5>
                    <p className="commitment_card_desc mt-2">
                      Buy low, sell high. This is the foundation of a great
                      business model and we agree. Learn more about the nuances
                      of trading on OTEX, from our{" "}
                      <span>
                        <NavLink className="">help center.</NavLink>
                      </span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="mt-5">
          <Row>
            <Col
              lg={6}
              md={6}
              xs={12}
              className="d-flex flex-column justify-content-center align-items-start mb-5"
            >
              <p className="enterprice_desc inter_font">
                Enterprise-style innovative API systems
              </p>
              <p className="enterprice_desc inter_font">
                Now, with <span>OTEX</span>, the enterprise is globalized.
              </p>
              <h6
                data-text="OTEX"
                className="enterprice_capsHint mt-3 inter_font"
              >
                Get over 50 Wallets, including USDT USDC TUSD BUSD KUSD BTC ETH
                XRP SOL and more Globalization of Enterprises Now join{" "}
              </h6>
            </Col>
            <Col lg={6} md={6} xs={12} className="mb-5">
              <img
                src={require("../assets/images/api.png")}
                className="img-fluid"
              />
            </Col>
            <Col lg={12} className="text-center mt-3">
              <button className="primary_btn">Get Started</button>
            </Col>
          </Row>

          <Row className="home_higher_top justify-content-center">
            <Col xl={11} lg={10} md={12} xs={12}>
              <Row className="mt-5">
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="home_higher_bottom"
                >
                  <div className="home_walletCard">
                    <img
                      src={require("../assets/images/walletcoin.svg").default}
                      className="img-fluid walletCard_img"
                    />
                    <h6 className="walletCard_title home_medium_top text-center pt-3 inter_font">
                      Free Crypto Currency Wallet
                    </h6>
                    <p className="commitment_card_desc mt-3 text-center inter_font">
                      Reliable, easy-to-use and no additional costs. The{" "}
                      <span>OTEX</span> Digital Wallet gives everyone a safe
                      place to store their assets. You can use the{" "}
                      <span>OTEX</span> wallet no matter who you are or where
                      you are.{" "}
                    </p>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 5, offset: 1 }}
                  xl={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  md={{ span: 6, offset: 0 }}
                  sm={12}
                  xs={12}
                  className="home_higher_bottom"
                >
                  <div className="home_walletCard">
                    <img
                      src={require("../assets/images/lockcoin.svg").default}
                      className="img-fluid walletCard_img"
                    />
                    <h6 className="walletCard_title home_medium_top text-center inter_font pt-3">
                      Security
                    </h6>
                    <p className="commitment_card_desc mt-3 text-center inter_font">
                      When it come to money, the most important factors are
                      safety and security. All transactions at <span>OTEX</span>{" "}
                      are protected by safety escrow services, so you can rest
                      assured.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <div className="home_businessSec">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg={6} md={8} sm={10} xs={11}>
              <h5 className="business_hint text-center inter_font">
                Take crypto payments and develop into a growing global business
              </h5>
            </Col>
          </Row>
        </div>

        <Container className="mt-5">
          <Row className="justify-content-center">
            <h3 className="home_blueTitle text-center inter_font">
              Specialized fast payment solutions
            </h3>
            <h6 className="home_section_title text-center inter_font mt-2">
              Applicable to all parts of commerce{" "}
            </h6>
            <Col lg={9} xs={12}>
              <Row className="mt-5">
                {allData?.specializedData?.map((item, index) => (
                  <Col
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className="d-flex justify-content-center align-items-center mb-4"
                  >
                    <div
                    
                      className={"home_specialize_card d-flex flex-column justify-content-start align-items-center " + (theme == 'dark' ? '' : 'home_specialize_lightHover')}
                      
                    >
                      <div className="home_spel_card_hovered" onMouseEnter={(event) => handleMouseOver(event, index)} onMouseOut={() => setOnhover()}></div>
                      <div className="home_spel_imgHolder d-flex justify-content-center align-items-center">
                        <img
                          src={
                            onHover == index
                              ? theme == "dark"
                                ? item.darkHover
                                : item.lightHover
                              : item.img
                          }
                          className="img-fluid specialized_img"
                        />
                      </div>
                      <p className="home_spel_hint inter_font mt-3 mb-5 text-center">
                        {item.title}
                      </p>
                      <div className="home_specialize_card_bottom"></div>
                    </div>
                  </Col>
                ))}

                
              </Row>
            </Col>
          </Row>

          <Row className="home_higher_top">
            <h3 className="home_blueTitle text-center inter_font mb-5">
              Benefits of OTEX payment
            </h3>
            {allData?.benefitsData?.map((item,index) =>
            <Col lg={4} md={4} sm={6} xs={12} className="mb-5">
              <div className="home_benefits_card d-flex justify-content-start align-items-center gap-3 position-relative">
                <div className="home_benefit_card_hoverer" onMouseEnter={(event) => handleBenefitHover(event,index)} onMouseOut={() => setBenefitHover()}></div>
                <div className="benefits_img_holder d-flex justify-content-center align-items-center">
                  <img
                    src={benefitHover == index ? item.hoverImg : theme == 'dark' ? item.darkImg : item.lightImg}
                    className="img-fluid"
                  />
                </div>
                <p className="home_spel_hint inter_font">
                  {item.hint}
                </p>
              </div>
            </Col>
            )}
            {/* <Col lg={4} md={4} sm={6} xs={12} className="mb-5">
              <div className="home_benefits_card d-flex justify-content-start align-items-center gap-3">
                <div className="benefits_img_holder d-flex justify-content-center align-items-center">
                  <img
                    onMouseOver={(e) => {
                      e.preventDefault();
                      e.target.src =
                        require("../assets/images/fraudblue.svg").default;
                    }}
                    onMouseOut={(e) => {
                      e.preventDefault();
                      e.target.src =
                        theme == "dark"
                          ? require("../assets/images/fraud.svg").default
                          : require("../assets/images/blackfraud.svg").default;
                    }}
                    src={
                      theme == "dark"
                        ? require("../assets/images/fraud.svg").default
                        : require("../assets/images/blackfraud.svg").default
                    }
                    className="img-fluid"
                  />
                </div>
                <p className="home_spel_hint inter_font">
                  Prevention of payment fraud
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6} xs={12} className="mb-5">
              <div className="home_benefits_card d-flex justify-content-start align-items-center gap-3">
                <div className="benefits_img_holder d-flex justify-content-center align-items-center">
                  <img
                    onMouseOver={(e) => {
                      e.preventDefault();
                      e.target.src =
                        require("../assets/images/bluepaymentfee.svg").default;
                    }}
                    onMouseOut={(e) => {
                      e.preventDefault();
                      e.target.src =
                        theme == "dark"
                          ? require("../assets/images/paymentfee.svg").default
                          : require("../assets/images/blackpaymentfee.svg")
                              .default;
                    }}
                    src={
                      theme == "dark"
                        ? require("../assets/images/paymentfee.svg").default
                        : require("../assets/images/blackpaymentfee.svg")
                            .default
                    }
                    className="img-fluid"
                  />
                </div>
                <p className="home_spel_hint inter_font">Low Payment Fee</p>
              </div>
            </Col>

            <Col lg={4} md={4} sm={6} xs={12} className="mb-5">
              <div className="home_benefits_card d-flex justify-content-start align-items-center gap-3">
                <div className="benefits_img_holder d-flex justify-content-center align-items-center">
                  <img
                    onMouseOver={(e) => {
                      e.preventDefault();
                      e.target.src =
                        require("../assets/images/blueintegration.svg").default;
                    }}
                    onMouseOut={(e) => {
                      e.preventDefault();
                      e.target.src =
                        theme == "dark"
                          ? require("../assets/images/integration.svg").default
                          : require("../assets/images/blackintegration.svg")
                              .default;
                    }}
                    src={
                      theme == "dark"
                        ? require("../assets/images/integration.svg").default
                        : require("../assets/images/blackintegration.svg")
                            .default
                    }
                    className="img-fluid"
                  />
                </div>
                <p className="home_spel_hint inter_font">
                  Integrated Settlement
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6} xs={12} className="mb-5">
              <div className="home_benefits_card d-flex justify-content-start align-items-center gap-3">
                <div className="benefits_img_holder d-flex justify-content-center align-items-center">
                  <img
                    onMouseOver={(e) => {
                      e.preventDefault();
                      e.target.src =
                        require("../assets/images/blueintegration.svg").default;
                    }}
                    onMouseOut={(e) => {
                      e.preventDefault();
                      e.target.src =
                        theme == "dark"
                          ? require("../assets/images/integration.svg").default
                          : require("../assets/images/blackintegration.svg")
                              .default;
                    }}
                    src={
                      theme == "dark"
                        ? require("../assets/images/integration.svg").default
                        : require("../assets/images/blackintegration.svg")
                            .default
                    }
                    className="img-fluid"
                  />
                </div>
                <p className="home_spel_hint inter_font">
                  Integrated Settlement
                </p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6} xs={12} className="mb-5">
              <div className="home_benefits_card d-flex justify-content-start align-items-center gap-3">
                <div className="benefits_img_holder d-flex justify-content-center align-items-center">
                  <img
                    onMouseOver={(e) => {
                      e.preventDefault();
                      e.target.src =
                        require("../assets/images/blueautonomous.svg").default;
                    }}
                    onMouseOut={(e) => {
                      e.preventDefault();
                      e.target.src =
                        theme == "dark"
                          ? require("../assets/images/autonomous.svg").default
                          : require("../assets/images/blackautonomous.svg")
                              .default;
                    }}
                    src={
                      theme == "dark"
                        ? require("../assets/images/autonomous.svg").default
                        : require("../assets/images/blackautonomous.svg")
                            .default
                    }
                    className="img-fluid"
                  />
                </div>
                <p className="home_spel_hint inter_font">
                  Autonomous selection of payment cryptocurrency
                </p>
              </div>
            </Col> */}
          </Row>
        </Container>

        <div className="box_container home_medium_top pb-5">
          <h5 className="home_paxText inter_font text-center pt-5">
            How to use it?
          </h5>
          <Container>
            <Row className="home_higher_top justify-content-center">
              <Col lg={5} md={6} xs={12} className="mb-5">
                <div className="home_helpCard rounded-5">
                  <img
                    src={require("../assets/images/member.svg").default}
                    className="img-fluid helpCard_img"
                  />
                  <h6 className="walletCard_title text-center padding_medium_top inter_font mt-xl-5">
                    Member
                  </h6>
                  <div className="d-flex justify-content-center">
                    <p className="commitment_card_desc mt-3 text-center inter_font">
                      You can pay with cryptocurrency in your wallet. You can
                      choose instant payment and escrow payment all.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={5} md={6} xs={12} className="mb-5 mt-5 mt-md-0">
                <div className="home_helpCard rounded-5">
                  <img
                    src={require("../assets/images/store.svg").default}
                    className="img-fluid helpCard_img"
                  />
                  <h6 className="walletCard_title text-center padding_medium_top inter_font mt-xl-5">
                    Store
                  </h6>
                  <div className="d-flex justify-content-center">
                    <p className="commitment_card_desc mt-3 text-center inter_font">
                      Select the cryptocurrency you want to pay for. All
                      cryptocurrencies that can be set in your wallet are paid
                      it’s available as a single unit. The immediate payment and
                      escrow payment modules can be selected
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="home_arrow_steps mt-5 pb-3">
              <div className="home_singleStep  d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
                <img
                  src={require("../assets/images/one.svg").default}
                  className="img-fluid"
                />
                <div className="home_stepArrow_cont position-relative">
                  <img
                    src={require("../assets/images/blueArrow.svg").default}
                    className="img-fluid home_blueArrowImg"
                  />
                  <p className="home_stepHint inter_font">Store Selection</p>
                </div>
              </div>

              <div className="home_singleStep  d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
                <img
                  src={require("../assets/images/two.svg").default}
                  className="img-fluid"
                />
                <div className="home_stepArrow_cont position-relative">
                  <img
                    src={require("../assets/images/blueArrow.svg").default}
                    className="img-fluid home_blueArrowImg"
                  />
                  <p className="home_stepHint inter_font">
                    Check the store list
                  </p>
                </div>
              </div>

              <div className="home_singleStep  d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
                <img
                  src={require("../assets/images/three.svg").default}
                  className="img-fluid"
                />
                <div className="home_stepArrow_cont position-relative">
                  <img
                    src={require("../assets/images/blueArrow.svg").default}
                    className="img-fluid home_blueArrowImg"
                  />
                  <p className="home_stepHint inter_font">Check product list</p>
                </div>
              </div>

              <div className="home_singleStep  d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
                <img
                  src={require("../assets/images/four.svg").default}
                  className="img-fluid"
                />
                <div className="home_stepArrow_cont position-relative">
                  <img
                    src={require("../assets/images/blueArrow.svg").default}
                    className="img-fluid home_blueArrowImg"
                  />
                  <p className="home_stepHint inter_font">
                    Check token type & quantity
                  </p>
                </div>
              </div>

              <div className="home_singleStep  d-flex justify-content-center justify-content-sm-start align-items-center gap-2">
                <img
                  src={require("../assets/images/five.svg").default}
                  className="img-fluid"
                />
                <div className="home_stepArrow_cont position-relative">
                  <img
                    src={require("../assets/images/blueArrow.svg").default}
                    className="img-fluid home_blueArrowImg"
                  />
                  <p className="home_stepHint inter_font">
                    Click the payment button
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Home;
