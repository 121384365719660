import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import OfferTagsModal from "../Modals/OfferTagsModal";
import VendorTermsModal from "../Modals/VendorTermsModal";
import { ValidateEmptyString } from "../Validation/UserValidation";

function CreateOfferTradeInstructions({setOfferSec,setStep , Setdata , defaultvalue}) {
  const user = useSelector((state) => console.log("redux" , state?.User));
  const [activeTab, setActiveTab] = useState("marketPrice");
  const [count, setCount] = useState(1);
  const [disable, setDisable] = useState(true);

  const [show,setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showVendor,setShowVendor] = useState(false);
  const handleCloseVendor = () => setShowVendor(false);
  const handleShowVendor = () => setShowVendor(true);


  const [initialvalue , setInitialvalue] = useState({
    label : "",
    terms : "",
    instruction : "",
    offertags : [],
    singleoffertag : ""
  });
  const [error , setError] = useState({});
  const [verifyid , setVerifyid] = useState(false);
  const [verifyname , setVerifyname] = useState(false);

  useEffect(() => {
    let init = initialvalue;
    if(defaultvalue?.offertags){
      init.offertags = defaultvalue?.offertags;
    }
    if(defaultvalue?.yourofferlable){
      init.label = defaultvalue?.yourofferlable;
    }
    if(defaultvalue?.offerterms){
      init.terms = defaultvalue?.offerterms
    }
    if(defaultvalue?.tradeinstruction){
      init.instruction = defaultvalue?.tradeinstruction;
    }
    if(defaultvalue?.verifiyid){
      setVerifyid(defaultvalue?.verifiyid)
    }
    if(defaultvalue?.verifiyfullname){
      setVerifyname(defaultvalue?.verifiyfullname)
    }
    if(defaultvalue?.singleoffertag){
      init.singleoffertag = defaultvalue?.singleoffertag;
    }
    // setInitialvalue(init);
    // setTimeout(() => {setInitialvalue(init)} ,  2000)
  } , [])

    const HandleChange = (e) => {
      try{
        setInitialvalue({...initialvalue , [e?.target?.name] : e?.target?.value})
        if(!e?.target?.value){
          setError({...error , [e?.target?.name] : "Field is required"});
        }
        else {
          setError({...error , [e?.target?.name] : ""});
        }
        HandleNext()
      }
      catch(e){
        console.log("Error on handle change" , e);
      }
    }

    const HandleChangeOffertag = (e) => {
      try{console.log("e?.key" , e?.target?.value);
        if(e?.key === 'Enter'){
          let ofrtg = initialvalue?.offertags;
          ofrtg?.push(e?.target?.value);
          setInitialvalue({...initialvalue , ["offertags"] : ofrtg , ["singleoffertag"] : ""})
          if(ofrtg?.length > 0){
            setError({...error , ["offertags"] : ""});
          }
          else{
            setError({...error , ["offertags"] : "Please Enter Offertags"});
          }
        }
        else{
          setInitialvalue({...initialvalue , ["singleoffertag"] : e?.target?.value})
        if(initialvalue?.offertags?.length <= 0 && !e?.target?.value){
          setError({...error , ["offertags"] : "Please Enter Offertags"});
        }
        else{
          setError({...error , ["offertags"] : ""});
        }
        }
        HandleNext()
      }
      catch(e){
        console.log("error on handle change offertag" , e);
      }
    }

    const HandleDelete = (value) => {
      try{
        let arr = initialvalue.offertags;
        const index = arr.indexOf(value);
        if (index > -1) { // only splice array when item is found
          arr.splice(index, 1); // 2nd parameter means remove one item only
        }
        setInitialvalue({...initialvalue , ["offertags"] : arr})
        if(arr.length <= 0){
          setError({...error , ["offertags"] : "Field is required"})
        }
        else{
          setError({...error , ["offertags"] : ""})
        }
        HandleNext()
      }
      catch(e){
        console.log("Error on handle delete" , e);
      }
    }

    const HandleNext = () => {
      try{
        let payload = {
          offertags : initialvalue?.offertags,
          oferlabel : initialvalue?.label,
          offerterms : initialvalue?.terms,
          instruction : initialvalue?.instruction,
          verifyid : verifyid,
          verifyname : verifyname,
          singleoffertag : initialvalue?.singleoffertag
        }
        Setdata(payload)
      }
      catch(e){
        console.log("Error on handle next" , e);
      }
    }
console.log("initial value" , initialvalue , defaultvalue);

    const HandleFinal = () => {
      try{
        if(initialvalue?.offertags?.length > 0 && initialvalue?.label && initialvalue?.instruction && initialvalue?.terms && verifyid && verifyname){
          handleShowVendor();
        }
        else{
          toast.error("Fill all the fields")
        }
      }
      catch(e){
        console.log("Error on handle final" , e);
      }
    }


  return (
    <>
    <div className="mt-5">
      <h6 className="inter_font m-0 createOffer__tc_title">
        Trade Instructions
      </h6>
      <p className="createOffer_steps_hint inter_font m-0"></p>

    <Row className="mt-4">
        <Col md={6}>
          <>
              <h6 className="inter_font m-0 createOffer__tc_title mb-2">
                Selected Offer Tags
              </h6>
              <div className="createOffer__ti_offersHolder rounded-1 p-2 d-flex justify-content-start align-items-center gap-2 flex-wrap" 
              // onClick={handleShow}
              >
                {/* <button className="createOffer_ti_offer text-uppercase rounded-1 m-0 ">Offer 1 </button> */}
              {initialvalue?.offertags?.length <= 0 &&  <div className="createOffer_ti_offer text-uppercase rounded-1 m-0 d-flex justify-content-between align-items-center">Enter the value of offer tag and Press Enter to create offer tags</div>}
                {initialvalue?.offertags?.length > 0 && 
                initialvalue?.offertags?.map((data , i) => <div className="createOffer_ti_offer text-uppercase rounded-1 m-0 d-flex justify-content-between align-items-center"><p className="m-0">{data}</p> <span className="text-danger" onClick={() => HandleDelete(data)}><i class="fa-solid fa-xmark"/></span></div>)
                }
                {/* <button className="createOffer_ti_offer text-uppercase rounded-1 m-0">Offer 2</button>
                <button className="createOffer_ti_offer text-uppercase rounded-1 m-0">Offer 3</button>
                <button className="createOffer_ti_offer text-uppercase rounded-1 m-0">Offer 4</button> */}
              </div>
              <p className="text-danger">{error?.offertags}</p>
          </>
      <div className="login_single_input d-flex flex-column mt-3">
                    <label className="mb-2 inter_font">Offer Tags</label>
                    <input
                      type="text"
                      value={initialvalue.singleoffertag}
                      placeholder='Enter Value'
                      className="primary_input rounded-1 p-2 inter_font"
                      name="singleoffertag"
                      onKeyDown ={(e) => HandleChangeOffertag(e)}
                      onChange={HandleChangeOffertag}
                    />
                  </div>

      <div className="login_single_input d-flex flex-column mt-3">
                    <label className="mb-2 inter_font">Offer Label</label>
                    <input
                      type="text"
                      value={initialvalue?.label}
                      placeholder='Enter Value'
                      className="primary_input rounded-1 p-2 inter_font"
                      name="label"
                      onChange={HandleChange}
                    />
                    <p className="text-danger">{error?.label}</p>
                  </div>

                  <div className="login_single_input d-flex flex-column mt-3">
                    <label className="mb-2 inter_font">Offer Terms</label>
                    <textarea className='inter_font createOffer_ti_textarea rounded-1 p-3' 
                    name="terms"
                    value={initialvalue?.terms}
                    onChange={HandleChange}
                    cols={20}  rows={6} placeholder="Enter Offer Terms..."/>
                     <p className="text-danger">{error?.terms}</p>
                  </div>

                  <div className="login_single_input d-flex flex-column mt-3">
                    <label className="mb-2 inter_font">Offer Instructions</label>
                    <textarea className='inter_font createOffer_ti_textarea rounded-1 p-3' 
                    name="instruction"
                    value={initialvalue?.instruction}
                    onChange={HandleChange}
                    cols={20}  rows={6} placeholder="Enter Offer Instruction..."/>
                    <p className="text-danger">{error?.instruction}</p>
                  </div>

                  <div className="createOffer__ti_checksHolder">
                  <div className="login_single_input d-flex flex-column mt-3">
                    <label className="mb-2 inter_font">Verification</label>
                    <div className="login_check_holder d-flex justify-content-start align-items-center gap-2">
                    <Form>
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid checked = {verifyid}
                      onChange = {(e) => {
                        setVerifyid(e?.target?.checked);
                        if(e?.target?.checked){
                          setError({...error , ["verifyid"] : ""})
                        }
                        else{
                          setError({...error , ["verifyid"] : "Accept the verification"})
                        }
                        }}/>
                      </Form.Check>
                    </Form>
                    <span className="createacc_checklabel createOffer__ti_check_label inter_font m-0">
                    Require your trade partner to have verified their ID
                    </span>
                    <p className="text-danger">{error?.verifyid}</p>
                  </div>
                  <div className="login_check_holder d-flex justify-content-start align-items-center gap-2">
                    <Form>
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid 
                        checked = {verifyname}
                        onChange = {(e) => {setVerifyname(e?.target?.checked);
                        if(e?.target?.checked){
                          setError({...error , ["verifyname"] : ""})
                        }
                        else{
                          setError({...error , ["verifyname"] : "Accept the verification"})
                        }
                        }}/>
                      </Form.Check>
                    </Form>
                    <span className="createacc_checklabel createOffer__ti_check_label inter_font m-0">
                    Require your trade partner to show their full name
                    </span>
                    <p className="text-danger">{error?.verifyname}</p>
                  </div>
                  </div>
                 
                  </div>
        </Col>

        <Col md={6} className="mt-5 mt-md-0">
                <h6 className="createOffer_steps_title inter_font m-0">
                  About this step
                </h6>

                <p className="createOffer_steps_hint inter_font m-0 mt-3">
                Decide the price you want to trade at, and set the limits for your offer.
                </p>

                <div className="pm_payment_checker mt-3">
                    <Form>
                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='You want to to sell BTC'
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    </div>
                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='You get paid in ETH'
                      type="radio"
                      id={`inline-radio-1`}
                      
                    />
                    </div>

                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='People can trade between 1 ETH and 10 ETH'
                      type="radio"
                      id={`inline-radio-1`}
                      
                    />
                    </div>
                    </Form>
                </div>

                <Row className="mt-4">
                  <Col xs={6}>
                <button className="secondary_btn rounded-1 w-100" onClick={() => {setOfferSec('tradePricing');setStep(2)}}>Previous</button>
                  </Col>
                  <Col xs={6}>
                <button className="primary_btn rounded-1 w-100" onClick={HandleFinal}>Create</button>
                  </Col>
                </Row>
              </Col>
    </Row>

    <OfferTagsModal show={show} handleClose={handleClose} />

    <VendorTermsModal payload = {defaultvalue} show={showVendor} handleClose={handleCloseVendor} />

    </div>
    </>
  );
}

export default CreateOfferTradeInstructions;
