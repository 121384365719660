export const UseLoginValidation = async(data) => {
    try{
    
    }
    catch(e){
        console.log("Error on use login validation" , e);
    }
}

export const RegisterStep1 = (data) => {
    try {console.log("Data" , data);
        var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var error = {};
        var isvalid = true;
        if (data?.emailorphone) {
            if (!emailRegx.test(data?.emailorphone)) {
                error.emailorphone = "invalid email or phone number"
                isvalid = false
            }
        }
        else{
            error.emailorphone = "email or phone number field is required";
            isvalid = false;
        }

        if(!data?.termsandcondition){
            error.termsandcondition = "Please accept terms and condition";
            isvalid = false
        }
        if(!data?.country){
            error.country = "Country field is required"
            isvalid = false
        }
        if(!isvalid){
            return {isvalid : isvalid , error : error}
        }
        else{
            return {isvalid : isvalid , error : {}}
        }
    }
    catch (e) {
        console.log("Error o n register step1", e);
    }
}

export const ValidatePassword = (pw) => {
    try{
        var error = ""
        if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/.test(pw))){
            error= "Passcode must satisfy below condition";
        }
        return error;
    }
    catch(e){
        console.log("Error on validate password" , e);
    }
}

export const ValidateLogin = (data) => {
    var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var error = {};
        var isvalid = true;
        if(data?.registerType == "email"){
            if (data?.email) {
                if (!emailRegx.test(data?.email)) {
                    error.email = "invalid email"
                    isvalid = false
                }
            }
            else{
                error.email = "email field is required";
                isvalid = false;
            }
        }
        else{
            if(data?.phoneNo){
                if(!isNaN(data?.phoneNo)){
                    error.phoneNo = "Invalid mobile number";
                    isvalid = false;
                }
            }
            else{
                error.phoneNo = "email or phone number field is required";
                isvalid = false;
            }
        }

        if(!data?.termsandcondition){
            error.termsandcondition = "Please accept terms and condition";
            isvalid = false
        }

        if(data?.password){
            let val = ValidatePassword(data?.password);
            if(val){
                error.password = "Invalid password";
                isvalid = false;
            }
        }
        else{
            error.password = "Password field is required";
            isvalid = false;   
        }

        if(!isvalid){
            return {isvalid : isvalid , error : error}
        }
        else{
            return {isvalid : isvalid , error : {}}
        }
}

export const ValidateForgototp = (data) => {
    try{
        var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var error = {};
        var isvalid = true;
      if(data?.type == "email"){
        if (data?.email) {
          if (!emailRegx.test(data?.email)) {
            error.phoneoremail = "Invalid email or mobile number"
            isvalid = false
          }
        }
        else {
          error.phoneoremail = "Email/Phone field is required";
          isvalid = false;
        }
      }
      else{
        if(data?.phoneNo){
          if(isNaN(data?.phoneNo)){
              error.phoneoremail = "Invalid email or mobile number";
              isvalid = false;
          }
      }
      else{
          error.phoneoremail = "Email/Phone field is required";
          isvalid = false;
      }
      }

    if(!isvalid){
        return {isvalid : isvalid , error : error}
    }
    else{
        return {isvalid : isvalid , error : {}}
    }
    }
    catch(e){
      console.log("Error on validate forgot otp" , e);
    }
  }

export const ValidateEmptyString = (data) => {
    // debugger
    console.log("validate" , data);
    if(data){
        return true;
    }
    else{
        return false;
    }
} 

export const ValidNumber = (data) => {
    if(parseFloat(data) <= 0){
      return false;
    }
    else{
      return true;
    }
  }


export const ValidateFile = (data) => {
    try{
        var fileName = data.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp" || extFile=="svg"){
            return ""
        }
        else{
            return "Invalid file format"
        }
    }
    catch(e){
        console.log("Error on validate filer" , e);
    }
}

export const ValidateEmail = (data) => {
    var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(data){
        if (!emailRegx.test(data?.email)){
            return "Invalid Email"
        }
        else{
            return "";
        }
    }
    else{
        return "Field is required"
    }
}

export const ValidateResetPassword = (data , key , ref) => {
    try{
      let error = {};
      let isValid = true;
      if(key == "oldpassword" || ref){
        if(!data?.oldpassword){
          error.oldpassword = "Field is required"
          isValid = false;
        }
        else {
        //   let val = ValidatePassword(data?.oldpassword)
        //   if(val){
        //     error.oldpassword = val;
        //     isValid = false;
        //   }
        //   else{
            error.oldpassword = "";
        //   }
        }
      }

      if(key == "newpassword" || ref){
        if(!data?.newpassword){
          error.newpassword = "Field is required"
          isValid = false;
        }
        else {
          let val = ValidatePassword(data?.newpassword)
          if(val){
            error.newpassword = val;
            isValid = false;
          }
          else{
            error.newpassword = "";
          }
        }
      }

      if(key == "confirmnewpassword" || ref){
        if(!data?.confirmnewpassword){
          error.confirmnewpassword = "Field is required"
          isValid = false;
        }
        else {
          let val = ValidatePassword(data?.confirmnewpassword)
          if(val){
            error.confirmnewpassword = val;
            isValid = false;
          }
          else if(data?.confirmnewpassword == data?.newpassword){
            error.confirmnewpassword = "Confirm new password must be same as new password";
          }
        }
      }
      if(isValid){
        return {}
      }
      else{
        return error;
      }
    }
    catch(e){
      console.log("Error on validate reset password" , e);
    }
  }
