import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Components/Header";
import Select from "react-select";
import Countdown from "react-countdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoIosInformationCircleOutline } from "react-icons/io";
import MessageBox from "../Components/MessageBox";
import Footer from "../Components/Footer";
import { useSelector } from "react-redux";
import { GetSpecificOrderHooks } from "../Hooks/P2pHooks";

const UserOfferDetail = () => {

    const   theme = useSelector((state) => state?.User?.theme);
    const ref = useRef();
    const options = [
      {
        value: "outside",
        label: "Outside Escrow",
      },
      {
        value: "badOffer",
        label: "Bad Offer Terms",
      },
      {
        value: "scam",
        label: "Obvious Scam",
      },
      {
        value: "negotiation",
        label: "Negotiation",
      },
    ];
    const [selected, setSelected] = useState({
      value: "BNB",
      label: "BNB",
      img: require("../assets/images/BNB.png"),
    });
    const [selectedOption, setSelectedOption] = useState(null);
    const Completionist = () => <span className="text-danger">Time Expired</span>;
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist />;
      } else {
        return (
          <span className="inter_font">
            {hours}:{minutes}:{seconds}
          </span>
        );
      }
    };
    const Completionist1 = () => (
      <span className="text-danger">Time Expired</span>
    );
    const renderer1 = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist1 />;
      } else {
        return (
          <span className="inter_font">
            {minutes}&nbsp;:&nbsp;{seconds}
          </span>
        );
      }
    };
    const styles = {
      option: (styles, { isFocused, isSelected, isHovered }) => ({
        ...styles,
        color: "#fff",
        background: isFocused
          ? "#00CABA"
          : isSelected
          ? "#00CABA"
          : isHovered
          ? "red"
          : "#00CABA",
  
        zIndex: 1,
        cursor: "pointer",
        fontSize: "13px",
        border:'none'
      }),
  
      option: (styles, { isFocused, isSelected, isHovered }) => {
        // const color = chroma(data.color);
  
        return {
          ...styles,
          backgroundColor: isHovered
            ? "#16EBC3"
            : isSelected
            ? "#16EBC3"
            : isFocused
            ? "#16EBC3"
            : theme == 'dark' ? '#181818' : '#fff',
          cursor: "pointer",
          color: isHovered
            ? "#000"
            : isSelected
            ? "#000"
            : isFocused
            ? "#000"
            : theme == 'dark' ? "#fff" : '#000',
          fontSize: "13px",
          border:'none'
        };
      },
      indicatorSeparator: (styles) => ({display:'none'}),
      valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
        ...provided,
        height: "40px",
        border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
        backgroundColor: isHovered
          ? "transperant"
          : isSelected
          ? "transperant"
          : isFocused
          ? "transperant"
          : "transperant",
        // border: "1px solid rgba(34, 34, 34, 0.32)",
        borderRadius: 5,
        fontSize: "13px",
        color: "#fff",
      }),
      control: (provided, { isFocused, isSelected, isHovered }) => ({
        ...provided,
        height: "40px",
        border: "1px solid #00CABA",
        borderRadius: 5,
        backgroundColor: isHovered
          ? "transperant"
          : isSelected
          ? "transperant"
          : isFocused
          ? "transperant"
          : "transperant",
        // backgroundColor: "#fff",
        border: "none",
        outline: "none",
        boxShadow: "none",
        color: "#fff",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "40px",
        position: "absolute",
        right: 0,
        top: 0,
        border: "none",
        color: "#6C6A81",
        display: "none",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: theme == 'dark' ? "#fff" : "#343434",
      }),
      menuList: (base) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
      }),
    };
  
    useEffect(() => {
      fetchdata()
      window.scroll(0, 0);
    }, []);
  
    const fetchdata = async() => {
      try{
        let result = await GetSpecificOrderHooks({orderid : window.location.href.split("/")[4]})
      }
      catch(e){
        console.log("Error on fetchdata" , e);
      }
    }
  return (
    <>
      <Container fluid className="common_spacer pb-5">
          <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="mt_selects_holder ">
            <Row>
              <Col
                lg={4}
                md={6}
                sm={12}
                className="d-flex flex-column flex-sm-row  justify-content-start align-items-start align-items-sm-center gap-0 gap-md-3"
              >
                <p className="mt_selects_label inter_font mt-2">Token Type</p>
                <div className="market_coinname_select position-relative w-100">
                 
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint market_tv_drophint"
                  />
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className="d-flex flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center gap-0 gap-md-3"
              >
                <p className="mt_selects_label inter_font mt-2">Pay Via</p>
                <div className="market_coinname_select position-relative w-100">
                <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint market_tv_drophint"
                  />
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
              <Col
                lg={4}
                md={6}
                sm={12}
                className="d-flex flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center gap-0 gap-md-3"
              >
                <p className="mt_selects_label inter_font mt-2">
                  Currency by Country
                </p>
                <div className="market_coinname_select position-relative w-100">
                <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint market_tv_drophint"
                  />
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt_transaction_counter  mt-2">
            <Row className="">
             
             

             
              <Col lg={12} className="">
                <div className="mt_greengradient_box d-flex justify-content-center align-items-center">
                  <h6 className="mt_usdt_leftHead inter_font text-center">
                    Transaction quantity registration
                  </h6>
                </div>
                <div className="mt_seller_quantity_side">

                    <Row className="justify-content-center">
                        <Col lg={8} md={10}>
                        <Row className="align-items-end">
                    <Col lg={6} sm={6}>
                      <div className="mt_seller_quanlabels_holder">
                        <p className="mt_date_count_title inter_font text-start mt-2">
                          <span className="mt_seller_qunalabels_span inter_font">
                            USDT{" "}
                          </span>
                          holding quantity
                        </p>
                        <p className="mt_seller_quanlabels_bold inter_font text-start mt-2">
                          1,000,000,000,000 USDT
                        </p>
                        <p className="inter_font text-start mt_seller_quanlabels_primary mt-2">
                          1,000,000,000 KRW
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} sm={6} className="mt-4 mt-md-0">
                      <div class="mt_seller_border_box d-flex justify-content-start align-items-center mb-2">
                        <div class="mt_seller_transDtls p-2 d-flex justify-content-center align-items-center rounded-start">
                          <input
                            type="number"
                            placeholder="1,000,000,000"
                            className="mt_seller_trans_input inter_font"
                          />
                        </div>

                        <div class="mt_seller_flagHolder d-flex justify-content-center align-items-center p-2 rounded-end">
                          <p className="mt_seller_qunalabels_whitespan inter_font">
                            USDT{" "}
                          </p>
                        </div>
                      </div>
                      <p className="mt_seller_transdtls_hint inter_font">
                        <IoIosInformationCircleOutline className="me-2" />
                        Enter the amount you want to buy
                      </p>
                    </Col>
                  </Row>
                  <Row className="align-items-end mt-5">
                    <Col lg={6} sm={6}>
                      <div className="mt_seller_quanlabels_holder">
                        <p className="mt_date_count_title inter_font text-start  mt-2">
                          <span className="mt_seller_qunalabels_span inter_font">
                            USDT{" "}
                          </span>
                          Available quantity for sale
                        </p>
                        <p className="mt_seller_quanlabels_bold inter_font text-start mt-2">
                          1,000,000,000,000 USDT
                        </p>
                        <p className="inter_font text-start mt_seller_quanlabels_primary mt-2">
                          1,000,000,000 KRW
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} sm={6} className="mt-4 mt-md-0">
                      <div class="mt_seller_border_box d-flex justify-content-end align-items-center mb-2">
                        <div class="mt_seller_transDtls p-2 d-flex justify-content-center align-items-center rounded-start">
                          <input
                            type="number"
                            placeholder="1,000,000,000"
                            className="mt_seller_trans_input inter_font"
                          />
                        </div>

                        <div class="mt_seller_flagHolder d-flex justify-content-center align-items-center p-2 rounded-end">
                          <p className="mt_seller_qunalabels_whitespan inter_font">
                            KRW{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                        </Col>
                    </Row>
                
                </div>
              </Col>
            </Row>
          </div>

        
        
        </Container>
      </Container>
      <Footer />
    </>
  )
}

export default UserOfferDetail