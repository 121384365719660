import React,{useEffect, useRef, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { useSelector } from "react-redux";
import StoreDetailCard from "../Components/StoreDetailCard";
import Footer from "../Components/Footer";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import { NavLink } from "react-router-dom";

function StoreDetails() {
    const theme = useSelector((state) => state?.User?.theme);
    const [closer, setCloser] = useState();
    const [selectedFlag, setSelectedFlag] = useState("");
    const options = [
      {
        value: "BNB",
        label: "BNB",
        img: require("../assets/images/BNB.png"),
      },
      {
        value: "bnb",
        label: "bnb",
        img: require("../assets/images/bnb.png"),
      },
      {
        value: "Cron",
        label: "Cron",
        img: require("../assets/images/CRON.png"),
      },
      {
        value: "Crons",
        label: "Crons",
        img: require("../assets/images/CRONS.png"),
      },
      {
        value: "Polygon",
        label: "Polygon",
        img: require("../assets/images/polygon.png"),
      },
    ];
    const products = [
      {
        value: "electronics",
        label: "electronics",
        img: require("../assets/images/BNB.png"),
      },
      {
        value: "program",
        label: "program",
        img: require("../assets/images/bnb.png"),
      },
      {
        value: "Marketing",
        label: "Marketing",
        img: require("../assets/images/CRON.png"),
      },
      {
        value: "Restaurant",
        label: "Restaurant",
        img: require("../assets/images/CRONS.png"),
      },
      {
        value: "Storing",
        label: "Storing",
        img: require("../assets/images/polygon.png"),
      },
    ];
    const [selected, setSelected] = useState({
      value: "Product Item",
      label: "Product Item",
    });
    const [selectedCurrency, setSelectedCurrency] = useState({
      value: "coinType",
      label: "Coin Type",
      img: theme == 'dark' ? require("../assets/images/searchglass.svg").default : require('../assets/images/blacksearch.svg').default,
    });

  const currencyRef = useRef();
  const ref = useRef()
  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedCurrency({ label, value, img: data.img });
        currencyRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "30px",
      border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == 'dark' ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };


    useEffect(() =>{
      window.scroll(0,0)
    },[])
  return (
    <>
      <Container fluid className="common_spacer position-relative">
      <img
          src={require("../assets/images/fish_blurball.png")}
          className={theme == 'dark' ? "inner_fishblur" : 'd-none'}
        />
        <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="box_container p-2 p-sm-3 p-md-3 p-lg-4 mt-3">
            <Row className="align-items-start">
              <Col lg={12} className="mb-4">
                <img src={require('../assets/images/imageset.svg').default} className="img-fluid sd_bannerer" />
              </Col>
              <Col lg={10} sm={12} md={8} xs={12} className="mb-3 mb-sm-0">
                <Row>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                  <div className="store_top_search rounded-1 d-flex justify-content-start align-items-center gap-2">
                  <img
                    src={theme == 'dark' ? require("../assets/images/searchglass.svg").default : require('../assets/images/blacksearch.svg').default}
                    className="img-fluid store_searchglass"
                  />
                  <input type="text" placeholder="Store Name" className="inter_font" />
                </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                  <div className="store_top_search rounded-1 d-flex justify-content-start align-items-center gap-2">
                  <img
                    src={theme == 'dark' ? require("../assets/images/searchglass.svg").default : require('../assets/images/blacksearch.svg').default}
                    className="img-fluid store_searchglass"
                  />
                  <input type="text" placeholder="Seller ID" className="inter_font" />
                </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                  <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency.img}
                    className="img-fluid market_coinname_img  sd_store_searchImg"
                  />
                 
                  <Select
                    className=""
                    defaultValue={selectedCurrency}
                    onChange={selectedCurrency}
                    options={options}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                  <div className="market_coinname_select position-relative">
                  <img
                    src={
                      theme == "dark"
                        ? require("../assets/images/searchglass.svg").default
                        : require("../assets/images/blacksearch.svg").default
                    }
                    className="img-fluid market_coinname_img sd_store_searchImg"
                  />
                 
                  <Select
                    className=""
                    defaultValue={selected}
                    onChange={setSelected}
                    options={products}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder=""
                  />
                </div>
                
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <div className="cmn_flag_select rounded-2 d-flex align-items-center" title={selectedFlag}>
                  <ReactFlagsSelect
                  className=" pb-0 w-100"
    selected={selectedFlag}
    showSecondarySelectedLabel={true}
    selectedSize={10}
    onSelect={(code) =>{console.log("code",code); setSelectedFlag(code)}}
  />
  </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <button className="primary_btn heig_primary_btn w-100">Search</button>
                  </Col>
                </Row>
               
              </Col>
              <Col lg={2} md={4} sm={12} xs={12} className="mb-3 mb-sm-0">
                <NavLink className='header_links' to='/storeManagementRegister'>
                <button className="st_store_yellowbtn inter_font w-100 rounded-2">Store Management</button>
                </NavLink>
              </Col>

              <div className="si_tableScroller">
              <Col xs={12} className="mt-3">
                <StoreDetailCard/>
              </Col>
              </div>
              
            </Row>
          </div>
        </Container>
      </Container>
      <Footer/>
    </>
  );
}

export default StoreDetails;
