import React,{useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import StoreCard from '../Components/StoreCard'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useSelector } from 'react-redux'

function StorePage() {
  const theme = useSelector((state) => state?.User?.theme);
  useEffect(() =>{
    window.scroll(0,0)
  },[])
  return (
   <>
   <Container fluid className='common_spacer pb-5 position-relative'>
     <img
          src={require("../assets/images/fish_blurball.png")}
          className={theme == 'dark' ? "inner_fishblur" : 'd-none'}
        />
        <Header />
   <Container className="p-0 p-sm-2 custom_container">
          <Row className='mt-4'>
            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>
            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>
            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>
            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>
            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>
            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>
            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>

            <Col lg={6} className='mb-4'>
              <StoreCard/>
            </Col>
            
          </Row>
          </Container>
   </Container>
   <Footer/>
   </>
  )
}

export default StorePage