import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink } from 'react-router-dom';

function Welcome() {
    const theme = useSelector((state) => state?.User?.theme);
  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center'>Welcome to Otex!</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <Row className='align-items-center justify-content-between'>
                <Col lg={7} md={7} sm={12} className='mb-4 mb-sm-0'>
                <NavLink className="header_links" to='/createAccount'>
                  <button className='primary_btn w-100 mt-4'>Next</button>
                  </NavLink>

                  <div className='reg_orhr_holder position-relative my-5'>
                    <p className='reg_or m-0 inter_font px-4'>or</p>
                    <div className='reg_hr'></div>
                  </div>

                  <div className='reg_google_auth px-3 rounded-2 position-relative'>
                    <img src={require('../assets/images/google.svg').default} className={`img-fluid ${theme == 'dark' ? 'reg_google_imgerdark' : 'reg_google_imger'}`} /> 
                    <p className={`reg_googleText  inter_font m-0 text-center ${theme == 'dark' ? 'white_text' : 'black_text'}`}>Continue with Google</p>
                  </div>

                  <p className='mailver_hint inter_font mt-4 m-0'>Already have an account? <span className='reg_primaryText'>Log in</span></p>
                  <p className='mailver_hint inter_font mt-2 m-0'>Need an entity account? <span className='reg_primaryText'>Sign Up</span></p>
                </Col>
                <Col lg={4} md={5} sm={12}>
                    <img src={require('../assets/images/regCrypto.svg').default} className='img-fluid' />
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default Welcome