import React,{useRef, useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import usdt from "../assets/images/cmn_tabs/usdt.png";
import chain from "../assets/images/cmn_tabs/address_chain.png";
import warning from "../assets/images/cmn_tabs/warning.png";
import address from "../assets/images/cmn_tabs/address_logo.png";
import info from "../assets/images/cmn_tabs/info.png";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { Button } from "react-bootstrap";
import tips from "../assets/images/cmn_tabs/tips.png";
import Accordion from "react-bootstrap/Accordion";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Select from "react-select";
import { allData } from "../CommonData/Data";
import { useSelector } from "react-redux";
function Withdraw() {
  const theme = useSelector((state) => state?.User?.theme);
  const ref = useRef();
  const coinRef = useRef()
  const [selectedOption, setSelectedOption] = useState(null);
  const [closer, setCloser] = useState();
  const [subActive,setSubActive] = useState('email')
  const [selected, setSelected] = useState({
    value: "BNB",
    label: "BNB",
    img: require("../assets/images/BNB.png"),
  });
  const [selected1, setSelected1] = useState({
    value: "BNB",
    label: "BNB",
    img: require("../assets/images/BNB.png"),
  });

  const options = [
    {
      value: "BNB",
      label: "BNB",
    },
    {
      value: "bnb",
      label: "bnb",
    },
    {
      value: "Cron",
      label: "Cron",
    },
    {
      value: "Crons",
      label: "Crons",
    },
    {
      value: "Polygon",
      label: "Polygon",
    },
  ];
  const options2 = [
    {
      value: "BNB",
      label: "BNB",
      img: require("../assets/images/BNB.png"),
    },
    {
      value: "bnb",
      label: "bnb",
      img: require("../assets/images/bnb.png"),
    },
    {
      value: "Cron",
      label: "Cron",
      img: require("../assets/images/CRON.png"),
    },
    {
      value: "Crons",
      label: "Crons",
      img: require("../assets/images/CRONS.png"),
    },
    {
      value: "Polygon",
      label: "Polygon",
      img: require("../assets/images/polygon.png"),
    },
  ];
  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft : '60px',
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      border:'none',
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00CABA",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const styles2 = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({display:'none'}),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft : '60px',
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      border:'none',
      color: "#6C6A81",
      display: 'none'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00CABA",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <div className="withdraw">
      <div className="row withdraw_content">
        <div className="col-xl-6 col-lg-6 mb-4">
          <div className="fst_sec">
            <div>
              <span className="step inter_font">Step 1</span>
              <div className="coin mt-4">
                {/* <Dropdown className="common_drop coin_drop inter_font">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <span className="d-flex align-items-center inter_font">
                      <img
                        src={usdt}
                        alt="usdt"
                        className="usdt img-fluid me-2 cmn_icon"
                      />
                      USDT
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <span className="d-flex align-items-center">
                        <img
                          src={usdt}
                          alt="usdt"
                          className="BTS img-fluid me-2 cmn_icon"
                        />
                        BTS
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <span className="d-flex align-items-center">
                        <img
                          src={usdt}
                          alt="usdt"
                          className="BTS img-fluid me-2 cmn_icon"
                        />
                        BITCOIN
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <span className="d-flex align-items-center">
                        <img
                          src={usdt}
                          alt="usdt"
                          className="BTS img-fluid me-2 cmn_icon"
                        />
                        SHIBU
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

<div className="market_coinname_select position-relative">
                  <img
                    src={selected.img}
                    className="img-fluid market_coinname_img"
                  />
                  <img
                    src={require("../assets/images/drophint.svg").default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">Select a coin</p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options2}
                    value={selected}
                    components={{ Option: customOptionRenderer }}
                    ref={coinRef}
                    styles={styles2}
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 mt-lg-5">
              <span className="step">Step 2</span>
              <div className="mt-4 withdraw_tabs">
                <p className="mb-2 label inter_font">Withdraw to</p>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first" className="inter_font">On-chain </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second" className="inter_font">
                            Internal Transfer
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="on_chain">
                            <div className="network_sec d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start gap-2 mb-2 mt-4">
                              <p className="mb-0 network inter_font">Select network</p>
                              <p className="mb-0 cnt_address">
                                contact address{" "}
                                <span className="address_val">
                                  0xaca...1bc7
                                </span>
                                <img
                                  src={chain}
                                  alt="chain"
                                  className="img-fluid ms-1"
                                />
                              </p>
                            </div>
                            <div className="market_coinname_select position-relative">
                 <p className="withdraw_select_cointType inter_font">ETH</p>

                            <Select
            className="mt-3"
            defaultValue={selectedOption}
            onChange={setSelected1}
            options={options}
            value={selected1}
            ref={ref}
            styles={styles}
            placeholder=""
          />
          </div>
                            {/* <Dropdown className="common_drop coin_drop">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                ETH ERC20
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  BTS ERC20
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}
                            <div className="alert_sec mt-4">
                              <img
                                src={warning}
                                alt="warning"
                                className="img-fluid warning"
                              />
                              <span className="inter_font">
                                Confirm that the receiving platform accepts the
                                crypto and network you have selected. You may
                                lose your assets if you choose an incompatible
                                network.
                              </span>
                            </div>
                            <div className="withdraw_address mt-4">
                              <p className="label mb-2 inter_font">Withdrawal address</p>
                              <div className="withdraw_wtaddrss_holder px-2 rounded-2 d-flex justify-content-start align-items-center">
                              <input className="inter_font" type="text"
                                  value="0x148f356654514df21d2f1a2dsf453332f1202"
                                  disabled
                                />
                                 <img
                                  src={address}
                                  alt="address"
                                  className="img-fluid address_icons"
                                />
                              </div>
                              {/* <div className="position-relative">
                                {" "}
                                <input className="inter_font"
                                  value="0x148f356654514df21d2f1a2dsf453332f1202"
                                  disabled
                                />
                                <img
                                  src={address}
                                  alt="address"
                                  className="img-fluid address_icons"
                                />
                              </div> */}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          {/* Second tab content */}
                          <div className="withdraw__intransitTabHolder d-flex justify-content-between align-items-center mt-4">
                <div className="withdraw__intransitTaber text-center"><p className={`m-0 withdraw__secTaber text-center inter_font ${subActive == 'email' && 'active'}`} onClick={() => setSubActive('email')}>Email</p></div>
                <div className="withdraw__intransitTaber text-center"><p className={`m-0 withdraw__secTaber text-center inter_font ${subActive == 'phone' && 'active'}`} onClick={() => setSubActive('phone')}>Phone Number</p></div>
                <div className="withdraw__intransitTaber text-center"><p className={`m-0 withdraw__secTaber text-center inter_font ${subActive == 'uid' && 'active'}`} onClick={() => setSubActive('uid')}>UID</p></div>
              </div>

{subActive == 'email' &&
              <div className="login_single_input d-flex flex-column mt-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <label className="mb-2">Email Address</label>
                    <p className="m-0 inter_font withdraw__manageText">Manage Addresses</p>
                    </div>
                    <input
                      type="email"
                      placeholder="Enter registered Email ID"   
                      className="primary_input rounded-1 p-2 inter_font"
                    />
                  </div>
                  }

{subActive == 'phone' &&
              <div className="login_single_input d-flex flex-column mt-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <label className="mb-2">Email Phone Number</label>
                    <p className="m-0 inter_font withdraw__manageText">Manage Addresses</p>
                    </div>
                    <input
                      type="email"
                      placeholder="Enter Registered Phone Number"   
                      className="primary_input rounded-1 p-2 inter_font"
                    />
                  </div>
                  }

{subActive == 'uid' &&
              <div className="login_single_input d-flex flex-column mt-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <label className="mb-2">Email UID</label>
                    <p className="m-0 inter_font withdraw__manageText">Manage Addresses</p>
                    </div>
                    <input
                      type="email"
                      placeholder="Enter your UID"   
                      className="primary_input rounded-1 p-2 inter_font"
                    />
                  </div>
                  }
                          
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
      


            <div className="mt-4 mt-lg-5 step_3">

              
              <span className="step">Step 3</span>
              <div className="withdrawal_amt mb-2 mt-4">
                <p className="mb-0 amt inter_font">Withdrawal amount</p>
                <p className="mb-0 limit">
                  <span className="address_val inter_font">
                    Withdrawal limit : 3,000,000 USDT
                  </span>
                  <img src={info} alt="chain" className="img-fluid ms-1" />
                </p>
              </div>
              <div className="min_amt">
                <span className="inter_font">Minimum withdrawal amount</span>
                <span className="inter_font">
                  <span className="green">All </span>USDT
                </span>
              </div>
              <div className="mb-0 available mt-3">
                <p className="inter_font mb-0">Available <span className="withdraw_avail_value"> 117.60744237 USDT </span></p>
                <FaArrowRightArrowLeft className="arrow_icon mx-2" />
                <span className="transfer inter_font">Transfer</span>
              </div>
              <div className="ftr_sec mt-4">
                <span className="inter_font">Transaction fees</span>
                <span className="inter_font">3.0638816 USDT</span>
              </div>
              <div className="ftr_sec mt-3">
                <span className="inter_font">Actual amount received</span>
                <span className="inter_font">--USDT</span>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4 mt-lg-5">
                <Button className="withdraw_btn primary_btn">Withdraw</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 ">
          <div className="snd_sec">
            <div className="tips_sec">
              <p className="tips mb-0 px-3 py-3 inter_font">
                <img src={tips} alt="tips" className="img-fluid tips me-2" />
                Tips
              </p>
              <div className="pt-0 pb-0 pt-lg-4 pb-4 px-4 px-lg-5 dec">
                <p className="inter_font">
                  For the safety of your funds, our customer support team may
                  contact you by phone to confirm your withdrawal request.
                  Please pay attention to incoming calls.
                </p>
                <p className="inter_font">
                  {" "}
                  Ensure the security of your computer and browser to prevent
                  information from being tampered with or leaked.
                </p>{" "}
                <p className="inter_font">
                  Be aware of scams! Otex will never ask you for your account,
                  Password or any personal information, nor will it request
                  private transfers or withdrawals.
                </p>
              </div>
            </div>
            <div className="faq_accr mt-3 mt-lg-5">
              <div className="head_sec  px-3 py-2">
                <span className="header_txt inter_font">FAQs</span>
                <Link className="more_faq inter_font" to='/faq'>
                  More <IoIosArrowForward className="arrow_icon" />
                </Link>
              </div>

              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Withdrawal Limit Adjustment
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="inter_font withdraw_accord_bodcnt">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How to Withdraw Cryptocurrency from Otex Account
                  </Accordion.Header>
                  <Accordion.Body>
                  <p className="inter_font withdraw_accord_bodcnt">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How to Withdraw Cryptocurrency from Otex Account
                  </Accordion.Header>
                  <Accordion.Body>
                  <p className="inter_font withdraw_accord_bodcnt">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <div className="withdraw__historyHolder mt-3">

        <h5 className="m-0 inter_font withdraw__title mb-3">Withdraw History</h5>
        {allData.withdrawHistory.length >= 1 ? 
        <div className="storepage_card withdraw__historyScroller py-3 rounded-2">
        {allData.withdrawHistory.map((item) => 
            <Row className="withdraw__singleFlex">
              <Col lg={2} md={2} sm={4} xs={6} className="my-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Coin :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.coin}</p>
              </Col>
              <Col lg={2} md={2} sm={4} xs={6} className="my-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Time :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.Time}</p>
              </Col>
              <Col lg={2} md={2} sm={4} xs={6} className="my-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Type :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.type}</p>
              </Col>
              <Col lg={2} md={2} sm={4} xs={6} className="my-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Amount :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.amount}</p>
              </Col>
              <Col lg={2} md={2} sm={4} xs={6} className="my-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">status :</p>
              <div className="d-flex justify-content-center">
              <div className={`withdraw__historyBadge p-1 rounded-1 ${item.status.toLowerCase() == 'completed' ? 'withdraw__historySuccess' : item.status.toLowerCase() == 'inprogress' ? 'withdraw__historyProgress' : item.status.toLowerCase() == 'failed' ? 'withdraw__historyFailed' : ''}`}>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.status}</p>
              </div>
              </div>
              </Col>
              <Col lg={2} md={2} sm={4} xs={6} className="my-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Action :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.action}</p>
              </Col>
            </Row>
        )}
            {/* {allData.withdrawHistory.map((item) => 
          <div className="withdraw__historyFlex d-flex justify-content-between align-items-center">
            <div className="withdraw__singleFlex mb-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Coin :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.coin}</p>
            </div>

            <div className="withdraw__singleFlex mb-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Time :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.Time}</p>
            </div>

            <div className="withdraw__singleFlex mb-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Type :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.type}</p>
            </div>

            <div className="withdraw__singleFlex mb-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Amount :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.amount}</p>
            </div>

            <div className="withdraw__singleFlex mb-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">status :</p>
              <div className={`withdraw__historyBadge p-1 rounded-1 ${item.status.toLowerCase() == 'completed' ? 'withdraw__historySuccess' : item.status.toLowerCase() == 'inprogress' ? 'withdraw__historyProgress' : item.status.toLowerCase() == 'failed' ? 'withdraw__historyFailed' : ''}`}>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.status}</p>
              </div>
            </div>

            <div className="withdraw__singleFlex mb-3">
              <p className="withdraw__historyTitle text-center mb-1 inter_font m-0">Action :</p>
              <p className="m-0 withdraw__historyCoinName text-center inter_font">{item.action}</p>
            </div>
          </div>
          )} */}

        </div>
        :

        <div className="withdraw__noData d-flex flex-column justify-content-center align-items-center gap-3">
            <img src={require('../assets/images/notfound.png')} className="img-fluid withdraw__historyNoData" />
            <p className="withdraw__historyTitle text-center inter_font m-0">No History Found</p>
        </div> }

      </div>
    </div>
  );
}

export default Withdraw;
