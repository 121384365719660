import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

function MessageBox() {
    const [sendMsg, setSendMsg] = useState([]);
  const [receiveMsg, setReceiveMsg] = useState([]);
  const [msgCont, setMsgCont] = useState("");
  const scrollRef = useRef(null);
  const chatContainerRef = useRef(null);
  const theme = useSelector((state) => state?.User?.theme);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sendBtn, setSendBtn] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    console.log(windowWidth, "windowWidthwindowWidth");
    if (windowWidth < 576) setSendBtn(true);
    else setSendBtn(false);
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowWidth]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const handleSend = () => {
    if (msgCont.length > 0) {
      setSendMsg((newMsg) => [...newMsg, { message: msgCont }]);
      setMsgCont("");
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [sendMsg]);


  return (
    <>
    <div className="box_container rounded-4 p-2 p-sm-3">
     
        <Row className="justify-content-between ">
        
         
        
          <div className="si_chat_holder mt-3 p-3">
            <div className="msgBox_waitingContHolder">
          <div className="si_msgBox_waitingCont rounded-4 p-3">
        <h6 className="m-0 inter_font waitingCont_title text-center">Applied for a deal with the seller</h6>
        <p className="m-0 inter_font waitingCont_desc text-center">The transaction starts after the seller accepts the transaction.</p>
        <p className="m-0 inter_font waitingCont_hint text-center mt-2">Please Wait...</p>
      </div>
      <p className="m-0 inter_font waitingCont_hint text-center mt-2">Sep 15 2024 12:12</p>
      </div>
            <div
              className="si_chatbox_scroller p-2 mb-3"
              ref={chatContainerRef}
            >
              <div className="d-flex justify-content-start align-items-center">
                <div className="si_received_msg  d-flex justify-content-start align-items-center mb-3">
                  <div className="d-flex justify-content-center align-items-start flex-column">
                    <div className="si_msgcnt_holderLeft mt_transact_received_msg position-relative py-2 px-3">
                      <div className="si_msgbox_curveLeft mt_transact_curveLeft"></div>
                      <p className="inter_font">Hi sadfhsadfh asdhsah</p>
                    </div>
                    <p className="si_received_msgtime inter_font mt-3">
                      Sep 15 2023 12:12
                    </p>
                  </div>
                </div>
              </div>

              {sendMsg?.map((msg) => (
                <div className="d-flex justify-content-end align-items-center">
                  <div className="si_send_msg d-flex justify-content-end align-items-center mb-3">
                    <div className="d-flex justify-content-center align-items-end flex-column">
                      <div className="si_msgcnt_holder mt_transact_sendmsg position-relative py-2 px-3">
                        <div className="si_msgbox_curve mt_transac_curveRight"></div>
                        <p className="inter_font">{msg.message}</p>
                      </div>
                      <p className="si_received_msgtime inter_font mt-3">
                        Sep 15 2023 12:12
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="si_inputer_holder d-flex justify-content-between align-items-center gap-2"
              ref={scrollRef}
            >
              <div className="si_sendImg_holder rounded-2 d-flex justify-content-center align-items-center position-relative">
                <img
                  src={require("../assets/images/send.svg").default}
                  className="img-fluid si_sendImg"
                />
                <input type="file" className="si_chat_fileInput" />
              </div>
              <input
              onKeyDown={(e) => {if(e.key === 'Enter') handleSend()}}
                onResize={true}
                value={msgCont}
                onChange={(e) => setMsgCont(e.target.value)}
                type="text"
                className="si_chat_input rounded-2 inter_font"
                placeholder="Write a message..."
              />
              {sendBtn ? (
                <button
                  className="si_send_btn_icon rounded-2"
                  onClick={() => handleSend()}
                >
                  <i class="fa-solid fa-paper-plane" />
                </button>
              ) : (
                <button
                  className="si_send_btn inter_font rounded-2"
                  onClick={() => handleSend()}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </Row>
      </div>
    </>
  )
}

export default MessageBox