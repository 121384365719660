import React, { useRef, useState } from "react";
import Header from "../Components/Header";
import { Col, Container, Form, Row } from "react-bootstrap";
import { allData } from "../CommonData/Data";
import { useSelector } from "react-redux";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import Footer from "../Components/Footer";
import { NavLink } from "react-router-dom";

const MarketKyc = () => {
  const theme = useSelector((state) => state?.User?.theme);
  const [activeTab, setActiveTab] = useState("sell");
  const [selectedFlag, setSelectedFlag] = useState("");
  const [coinSelect, setCoinSelect] = useState(0);
  const [centralizedPrice, setCentralizedPrice] = useState(true);
  const [fixedPrice, setFixedPrice] = useState(false);
  const [margin,setMargin] = useState(1)
  const [radioTab, setRadioTab] = useState("centralizedPrice");
  const ref = useRef();
  const [selected, setSelected] = useState("");
  const options = [
    {
      value: "showAll",
      label: "Show All",
    },
    {
      value: "buy",
      label: "Buy",
    },
    {
      value: "sell",
      label: "Sell",
    },
  ];
  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      minWidth : '110px',
      border: theme == "dark" ? "1px solid #5B5B5B" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      maxHeight: "40px",
      minHeight: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == "dark" ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const styles1 = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: theme == "dark" ? "1px solid #5B5B5B" : "1px solid #d1d1d1",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      maxHeight: "40px",
      minHeight: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == "dark" ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const handleMarginDecrement = () =>{
    if(margin >= 1) 
    setMargin(margin - 1)
  }
  const handleMarginIncrement = () =>{
    if(margin <= 1) 
    setMargin(margin + 1)
  }
  console.log(":fixedPrice", fixedPrice,centralizedPrice);
  return (
    <>
      <Container fluid className="common_spacer">
        <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="box_container p-2 p-sm-3 p-md-4 p-lg-5 mt-3">
            <Row>
              <Col lg={4} sm={6} className="mb-4">
                <div className="mk__tripleTab rounded-3 d-flex justify-content-center align-items-center gap-2 py-4 px-2">
                  <img
                    src={require("../assets/images/id.svg").default}
                    className="img-fluid"
                  />
                  <div className="mk__tripleTabHolder">
                    <p className="m-0 inter_font mk__tripleTabTitle text-center">
                      KYC Unregistered Membership{" "}
                    </p>
                    <p className="m-0 inter_font mk__tripleTabHint text-center mt-3">
                      KYC registraction
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} sm={6} className="mb-4">
                <NavLink to='/market' className='header_links'>
                <div className="mk__tripleTab rounded-3 py-4 px-2">
                  <p className="m-0 inter_font mk__tripleTabTitle text-center">
                    Coin trading restrictions
                  </p>
                  <div className="mk__tripleTabHolder d-flex justify-content-center align-items-start gap-2 mt-2">
                    <p className="m-0 inter_font mk__tripleTabHint text-center">
                      KYC not registered -
                    </p>
                    <div>
                      <p className="m-0 inter_font mk__tripleTabHint text-center">
                        1,000 USD per time
                      </p>
                      <p className="m-0 inter_font mk__tripleTabHint text-center">
                        3,000 USD per time
                      </p>
                    </div>
                  </div>
                </div>
                </NavLink>
              </Col>

              <Col lg={4} sm={6} className="mb-4">
              <NavLink to='/market' className='header_links'>
                <div className="mk__tripleTab rounded-3 py-4 px-2">
                  <div className="mk__tripleTabHolder mt-2">
                    <p className="m-0 inter_font mk__tripleTabTitle text-center">
                      Restriction of deposit/withdrawal quantity
                    </p>
                    <p className="m-0 inter_font mk__tripleTabHint text-center">
                      Coin deposit - Unlimited for anyone
                    </p>
                    <p className="m-0 inter_font mk__tripleTabHint text-center">
                      KYC registration - Unlimited
                    </p>
                    <p className="m-0 inter_font mk__tripleTabHint text-center">
                      KYC Not registered - 1,000 USD Pertime / 3,000 per day
                    </p>
                  </div>
                </div>
                </NavLink>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={6}>
                <div
                  className={`mk__coloredTaber p-2 p-sm-3 rounded-2 ${
                    activeTab == "sell" && "active"
                  }`}
                >
                  <h4
                    className={`m-0 inter_font  mk__coloredTaberRed text-uppercase text-center ${
                      activeTab == "sell" && "active"
                    }`}
                    onClick={() => setActiveTab("sell")}
                  >
                    SELL Registration
                  </h4>
                </div>
              </Col>
              <Col xs={6}>
                <div
                  className={`mk__coloredTaber p-2 p-sm-3 rounded-2 ${
                    activeTab == "buy" && "mk_colorTaberPrimary"
                  }`}
                >
                  <h4
                    className={`m-0 inter_font  mk__coloredTaberPrimary text-uppercase text-center ${
                      activeTab == "buy" && "active"
                    }`}
                    onClick={() => setActiveTab("buy")}
                  >
                    BUY REGISTRATION
                  </h4>
                </div>
              </Col>
            </Row>

            <div className="mk__chooseCoin mt-4">
              <h6 className="m-0 inter_font mk__chooseCoinTitle">
                Choose your cryptocurrency
              </h6>

              <div className="mk__coinGrid mt-3">
                {allData.coinSelector.map((item, index) => (
                  <div
                    className={`mk__coinGridSingle rounded-2 d-flex justify-content-center align-items-center gap-1 ${
                      coinSelect == index && "activeCoin"
                    }`}
                    onClick={() => setCoinSelect(index)}
                  >
                    <img src={item.img} className="img-fluid mk__coinGridImg" />
                    <p className="m-0 inter_font mk__coinGridName">
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <Row className="mt-4">
              <Col sm={6} className="mb-4">
                <div className="mk__contentTaber rounded-3 px-2 py-3">
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <img
                      src={require("../assets/images/btc.svg").default}
                      className="img-fluid mk__coinGridImg"
                    />
                    <p className="m-0 inter_font mk__tripleTabTitle text-center">
                      Bitcoin Asset Quantity
                    </p>
                  </div>
                  <p className="m-0 inter_font mk__tripleTabHint text-center mt-1">
                    9,000,000,000 BTC
                  </p>
                </div>
              </Col>

              <Col sm={6} className="mb-4">
                <div className="mk__contentTaber rounded-3 px-2 py-3 d-flex flex-column justify-content-center align-items-center">
                  <p className="m-0 inter_font mk__tripleTabTitle text-center">
                    Available Quantity for{" "}
                    <span className="span__redText">SELL</span>
                  </p>
                  <p className="m-0 inter_font mk__tripleTabHint text-center mt-1">
                    9,000,000,000 BTC
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg={4} md={6} sm={12} className="mb-4">
                <h6 className="m-0 inter_font mk__chooseCoinTitle">
                  Select transaction method{" "}
                  
                    <i class="fa-regular fa-circle-question mk__quesIcon"></i>
                </h6>
                <div className="mk__inputSelectHolder d-flex justify-content-between align-items-center gap-2 rounded-2 ps-2 mt-2">
                  <img
                    src={require("../assets/images/searchglass.svg").default}
                    className="img-fluid mk__searchGlass"
                  />
                  <input
                    type="text"
                    placeholder="Start typing..."
                    className="mk__inputter inter_font"
                  />

                  <Select
                    className="flex-grow-1"
                    defaultValue={selected}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    ref={ref}
                    styles={styles}
                    placeholder="Select..."
                  />
                </div>
              </Col>
              <Col lg={4} md={6} sm={12} className="mb-4">
                <h6 className="m-0 inter_font mk__chooseCoinTitle">Country</h6>
                <div
                  className="cmn_flag_select mk__flagSelect rounded-2 d-flex align-items-center mt-2"
                  title={selectedFlag}
                >
                  <ReactFlagsSelect
                    className=" pb-0 w-100"
                    selected={selectedFlag}
                    showSecondarySelectedLabel={true}
                    selectedSize={10}
                    onSelect={(code) => {
                      console.log("code", code);
                      setSelectedFlag(code);
                    }}
                  />
                </div>
              </Col>

              <Col lg={4} md={6} sm={12} className="mb-4">
                <h6 className="m-0 inter_font mk__chooseCoinTitle">
                  Preferred Currency{" "}
                  <span>
                    <i class="fa-regular fa-circle-question mk__quesIcon"></i>
                  </span>
                </h6>
                <Select
                  className="mt-2"
                  defaultValue={selected}
                  onChange={setSelected}
                  options={options}
                  value={selected}
                  ref={ref}
                  styles={styles1}
                  placeholder="Select..."
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col sm={6} className="mb-4">
                <div
                  className={`mk__radioTaber d-flex justify-content-center align-items-center gap-3 rounded-2 p-2 ${
                    radioTab == "centralizePrice" && "active"
                  }`}
                  onClick={() => {
                    setRadioTab("centralizePrice");
                    setCentralizedPrice(true);
                    setFixedPrice(false);
                  }}
                >
                  <div className="mk__custRadio">
                    <Form>
                      {/* {["radio"].map((type) => ( */}
                        <div key={"radio"} className="mb-3">
                          <Form.Check
                            type={"radio"}
                            checked={centralizedPrice}
                            id={`check-api-${"radio"}`}
                          >
                            <Form.Check.Input type={"radio"} checked={centralizedPrice} isValid={true} />
                          </Form.Check>
                        </div>
                      {/* )) */}
                      {/* } */}
                    </Form>
                  </div>

                  <div className="">
                    <p className="m-0 inter_font mk__tripleTabTitle">
                      Centralized Exchange Market Price
                    </p>
                    <p className="m-0 inter_font mk__tripleTabHint mt-2">
                      The coin price market base is based on the selected
                      Centralized Exchange price. The price is updated every 5
                      minutes.
                    </p>
                    <p className="m-0 inter_font mk__tripleTabHint mt-2">
                      It will be traded at a frozen price after the start of the
                      transaction.
                    </p>
                  </div>
                </div>
              </Col>

              <Col sm={6} className="mb-4">
                <div
                  className={`mk__radioTaber h-100 d-flex justify-content-center align-items-center gap-3 rounded-2 p-2 ${
                    radioTab == "fixedPrice" && "active"
                  }`}
                  onClick={() => {
                    setRadioTab("fixedPrice");
                    setFixedPrice(true);
                    setCentralizedPrice(false)
                  }}
                >
                  <div className="mk__custRadio">
                    <Form>
                      {["radio"].map((type) => (
                        <div key={type} className="mb-3">
                          <Form.Check
                            type={type}
                            checked={fixedPrice}
                            id={`check-api-${type}`}
                          >
                            <Form.Check.Input type={type} checked={fixedPrice} isValid={fixedPrice} />
                          </Form.Check>
                        </div>
                      ))}
                    </Form>
                  </div>

                  <div className="">
                    <p className="m-0 inter_font mk__tripleTabTitle">
                      Fixed price
                    </p>
                    <p className="m-0 inter_font mk__tripleTabHint mt-2">
                      Your offer’s selling price is locked when you create it,
                      and won’t change with the market price.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="mk__currentPriceHolder mt-4">
              <h6 className="m-0 inter_font mk__chooseCoinTitle">
                Bitcoin Current Price
              </h6>
              <div className="mk__currentPriceGrid mt-3">
                {allData.currentPrice.map((item) => (
                  <div className="mk__currentPriceSingle p-2 rounded-2">
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <img
                        src={item.img}
                        className="img-fluid mk__currentPriceImg"
                      />
                      <p className="m-0 inter_font mk__currentPriceLabel">
                        {item.coinName}
                      </p>
                    </div>
                    <p className="m-0 inter_font mk__currentPriceHint text-center">
                      $ {item.price}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <Row className="mt-5">
              <Col lg={3} md={4} sm={6} className="mb-4">
                <div className="mk__inputsTitleFlex d-flex justify-content-between align-items-center">
                  <p className="m-0 inter_font mk__inputsTitle"><span className={`${activeTab == 'sell' ? 'mk__spanBuy' : 'mk__spanSell'}`}>{activeTab == 'sell' ? 'SELL' : 'BUY' }</span> amount per BTC</p>
                  <p className="m-0 inter_font mk__violetText">Upbit</p>
                </div>
                <div className="mk__inputSelectHolder d-flex justify-content-between align-items-center gap-2 rounded-2 p-2 mt-2">
                <p className="m-0 inter_font mk__currentPriceHint text-center">
                7,000,000,000
                    </p>
                <p className="m-0 inter_font mk__tripleTabTitle text-center">
                      USD
                    </p>
                </div>
              </Col>

              <Col xl={activeTab == 'buy' ? 3 : 2} lg={3} md={4} sm={6}   className="mb-4">
              <p className="m-0 inter_font mk__inputsTitle">Set <span className={`${activeTab == 'sell' ? 'mk__spanBuy' : 'mk__spanSell'}`}>{activeTab == 'sell' ? 'SELL' : 'BUY' }</span> margin  <i class="fa-regular fa-circle-question mk__quesIcon"></i></p>

              <div className="mk__incDecHolder rounded-2 mt-2 d-flex justify-content-start align-items-center">
                <div className={`mk__incDec d-flex justify-content-between align-items-center ${activeTab == 'buy' && 'mk__incDecWidAligner'}`}>
                  <button className="mk__transBtn" onClick={handleMarginDecrement}>-</button>
                  <input type="text" className={`mk__incDecInput text-center flex-grow-1  inter_font ${activeTab == 'buy' && 'mk__inpWidthAligner'}`} value={margin} />
                  <button className="mk__transBtn" onClick={handleMarginIncrement}>+</button>
                </div>
                {/* <button className="mk__transBtn mk__transBtnPercent">%</button> */}
                <p className="inter_font m-0 mk__percentHolder h-100 text-center w-100">%</p>
              </div>
              </Col>

              {activeTab == 'sell' &&
              <Col lg={3} md={4} sm={6}  className="mb-4">
                <div className="mk__inputsTitleFlex d-flex justify-content-between align-items-center">
                  <p className="m-0 inter_font mk__inputsTitle">Percent applied BTC price <i class="fa-regular fa-circle-question mk__quesIcon"></i></p>
                </div>
                <div className="mk__inputSelectHolder px-2 d-flex justify-content-between align-items-center gap-2 rounded-2 mt-2">
                <p className="m-0 inter_font mk__currentPriceHint text-center">
                7,000,000,000
                    </p>
                    <div className="mk__leftBorder p-2">
                <p className="m-0 inter_font mk__tripleTabTitle text-center">
                      BTC
                    </p>
                    </div>
                </div>
              </Col>
               }

              <Col xl={activeTab == 'buy' ? 3 : 2} lg={3} md={4} sm={6} className="mb-4">
              <p className="m-0 inter_font mk__inputsTitle">Set <span className={`${activeTab == 'sell' ? 'mk__spanBuy' : 'mk__spanSell'}`}>{activeTab == 'sell' ? 'SELL' : 'BUY' }</span> Quantity  </p>

              <div className="mk__inputSelectHolder px-2 d-flex justify-content-between align-items-center gap-2 rounded-2 mt-2">
              <input type="text" className="mk__incDecInput mk__quantityInp text-center inter_font" placeholder="Enter Sell Quantity" />
                    <div className="mk__leftBorder p-2">
                <p className="m-0 inter_font mk__tripleTabTitle text-center">
                      BTC
                    </p>
                    </div>
                </div>
              </Col>

              <Col xl={activeTab == 'buy' ? 3 : 2} lg={3} md={4} sm={6} className="mb-4">
              <p className="m-0 inter_font mk__inputsTitle">Minimum transaction <i class="fa-regular fa-circle-question mk__quesIcon"></i></p>

              <div className="mk__inputSelectHolder px-2 d-flex justify-content-between align-items-center gap-2 rounded-2 mt-2">
              <p className="m-0 inter_font mk__currentPriceHint text-center">
                7,00
                    </p>
                    <div className="mk__leftBorder p-2">
                <p className="m-0 inter_font mk__tripleTabTitle text-center">
                      USD
                    </p>
                    </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg={6} md={8} sm={6} className="mb-4 mb-sm-0">
                <Row className="align-items-center">
                  <Col md={4} className="mb-3 mb-md-0">
                  <p className="m-0 inter_font mk__inputsTitle">Transaction time limit</p>
                  </Col>
                  <Col md={8}>
                 <div className="mk__timerHolder rounded-2 d-flex justify-content-start align-items-center">
                  
                  <div className="mk__timerChanger d-flex justify-content-between  align-items-center">
                    <button className="mk__transBtn">-</button>
                    <input type="text" className="mk__timeInputter flex-grow-1 inter_font text-center" /> 
                    <button className="mk__transBtn">+</button>
                  </div>
                  <div className=" d-flex justify-content-center align-items-center">
                  <p className="m-0 inter_font mk__timerMinutes text-center px-3">Minutes</p>
                  </div>
                  </div>
                 
                  </Col>
                </Row>
              
              </Col>

              <Col lg={6} md={4} sm={6}>
              <p className="m-0 inter_font mk__inputsTitle">Set the time when the <span className={`${activeTab == 'sell' ? 'mk__spanSell' : 'mk__spanBuy'}`}>{activeTab == 'sell' ? 'BUYER' : 'SELLER' }</span> clicks the deposit complete button after the transaction starts.
If the time is over, the transaction will br automatically canceled.</p>
              </Col>
            </Row>

            <Row className="mt-5 justify-content-center">
              <Col md={6} sm={8}>
                <button className={` inter_font rounded-2 mb-4 mb-lg-0 p-2 w-100 ${activeTab == 'sell' ? 'mk__sellRegBtn' : activeTab == 'buy' && 'mk__buyPrimaryBtn'}`}>{activeTab == 'sell' ? 'SELL' : activeTab == 'buy' && 'BUY'} Registration</button>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>

      <Footer/>
    </>
  );
};

export default MarketKyc;
