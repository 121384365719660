import React, { useState } from 'react'
import {Modal} from 'react-bootstrap';
import Countdown from 'react-countdown';
import { toast } from 'react-hot-toast';
import { IoMdInformationCircleOutline } from "react-icons/io";
import OTPInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css'
import { ChangeEmailHooks, GetUserId, UpdateNewEmailHooks, VerifyOldEmailHooks } from '../Hooks/UserHooks';

function SecurityEmail({handleClose,show , user}) {
  const [email , setEmail] = useState("");
  const [emailerror , setEmailerror] = useState("");
  const [showotp , setShowotp] = useState(false);
  const [otp , setOtp] = useState('');
  const [counter , setCounter] = useState(0);
  const [oldemailverified , setOldemailverified] = useState(false);


  const Completionist = () => <span className="text-danger">OTP Expired</span>
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist />;
      } else {
        return <span className="inter_font">{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</span>;
      }
    };


  const handleChangeemail = async() => {
    try{
      if(user?.email != email){
        let payload = {
          email : email,
          userid : GetUserId()
        }
        let result = await ChangeEmailHooks(payload);
        if(result?.type == "Success"){
          setShowotp(true);
          setCounter(Date.now());
        }
        console.log("REsult in handle change email" , result);
      }
      else{
        toast.error("New email must be differ as oldemail")
      }
    }
    catch(e){
      console.log("Error on handle change email" , e);
    }
  }

  const handleVerifyOldEmail = async() => {
    try{
      let payload = {
        otp : otp,
        userid : GetUserId()
      }
      let result = await VerifyOldEmailHooks(payload);
      console.log("Result in verify old email" , result);
      if(result?.type == "Success" && result?.message){
        setCounter(Date.now());
        setOldemailverified(true);
        setOtp("");
        // toast.success("Email Updated Successfylly");
      }
      else if(result?.type == "error" && result?.error){
        toast.error(result?.error);
      }
      else{
        toast.error("Error On Server")
      }
    }
    catch(e){
      console.log("Error on handle verify old email" , e);
    }
  }

  const handleVerifyNewEmail = async() => {
    try{
      let payload = {
        otp : otp,
        userid : GetUserId()
      }
      let result = await UpdateNewEmailHooks(payload);
      console.log("Result in handle verify new email" , result);
      if(result?.type == "Success" && result?.message){
        toast.success("Email Updated Successfully");
        handleCloseall();
      }
      else if(result?.type == "error" && result?.error){
        toast.error(result?.error);
      }
      else{
        toast.error("Error On Server");
      }
    }
    catch(e){
      console.log("Error on handle verify new email" , e);
    }
  }

  const handleCloseall = async() => {
    setEmail("");
    setEmailerror("");
    setCounter(0);
    setShowotp(false);
    setOtp("");
    setOldemailverified(false);
    handleClose()
  }


  return (
    <Modal
    centered
    className="purchase_modal"  
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
         <button
            className="cmn_modal_closer rounded-5"
            onClick={handleCloseall}
          >
            <i class="fa-solid fa-xmark" />
          </button>
        <Modal.Body>
            <h6 className='security_modal_titles inter_font'><i class="fa-solid fa-arrow-left-long me-2" /> Change Email address</h6>
          
          <div className='sm_green_badge rounded-3 d-flex justify-content-start align-items-center gap-3 p-3 mt-4'>
          <IoMdInformationCircleOutline />
          <p className='inter_font'>To ensure the security of your assets, withdraws and P2P are disabled for 24 hours after modifying your email address.</p>
          </div>

          <div className='sm_inputs_holder mt-3'>
            <label className='sm_inputs_label inter_font'>New Email Address</label>
            <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex'>
            <input type='text' className='inter_font flex-grow-1'
            disabled={showotp}
              onChange={(e) => {
                setEmail(e?.target?.value);
                if (e?.target?.value) {
                  let validate = (e?.target?.value)?.toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ); //ValidateEmail(e?.target?.value);
                  console.log("validateion", validate);
                  if (validate) {
                    setEmailerror("");
                  }
                  else {
                    setEmailerror("Invalid Email");
                  }
                }
                else {
                  setEmailerror("Field is required");
                }
              }}
              placeholder='Enter your mobile verification code' />
              
</div>
<p className='text-danger'>{emailerror}</p>
          </div>
          {/* <div className='sm_inputs_holder mt-3'>
            <label className='sm_inputs_label inter_font'>Email verification Code</label>
            <div className='sm_mobnum_select_holder sm_input_holderer rounded-2 p-2 mt-2 d-flex justify-content-between align-items-center gap-3'>
<input type='number' className='inter_font flex-grow-1' placeholder='Enter your mobile verification code' />
<button className='security_edit_btn inter_font '>Send</button>
</div>


          </div> */}
          <p className='pm_primary_text inter_font mt-3'>if you can’t find the verification code in your email,
please check your spam folder </p>

{!showotp && <button className='primary_btn w-100 mt-4' disabled = {!email || emailerror}
  onClick={handleChangeemail}
>Submit</button>}

{showotp && <><div className="login_otp_holder d-flex justify-content-center align-items-center mt-4">
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        /></div>
                        <div className="login_otpOption_holder inter_font text-center mt-2">
                        <Countdown
                          date={counter + 300000}
                          renderer={renderer}
                        />
                      </div>
                      <div className="text-center">
                        {!oldemailverified &&<button className="login_resendOTP inter_font ms-3 mt-4" 
                        onClick={handleChangeemail}
                        >Resend OTP</button>}

                      {/* {oldemailverified &&<button className="login_resendOTP inter_font ms-3 mt-4" 
                        onClick={handleChangeemail}
                        >Resend OTP</button>} */}
                      </div>
          {!oldemailverified && <button className='primary_btn w-100 mt-4' disabled={otp?.length < 6}
            onClick={handleVerifyOldEmail}
          >Submit</button>}
          {oldemailverified && <button className='primary_btn w-100 mt-4' disabled={!email || emailerror}
            onClick={handleVerifyNewEmail}
          >Submit</button>}
                      </>

                        }

        </Modal.Body>
      
      </Modal>
  )
}

export default SecurityEmail