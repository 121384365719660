import React, { useEffect, useState } from "react";
import { Col, Row, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DatePicker from "react-datepicker";
import ProfileFaqs from "../Components/ProfileFaqs";
import ProfileProof from "../Components/ProfileProof";
import ProfileAccord from "../Components/ProfileAccord";
import { GetUserHooks, GetUserId, UploadKycHooks } from "../Hooks/UserHooks";
import { ValidateFile } from "../Validation/UserValidation";

function Profile({activeCont,setActiveCont}) {
  const theme = useSelector((state) => state?.User?.theme);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [proof, setProof] = useState("passport");
  const [verifyStatus,setVerifyStatus] = useState('')
  const [process,setProcess] = useState('form')
  console.log("Theme in profile" , theme);
  const [user , setUser] = useState({});

  //dev

  const [initialstate , setInitialstate] = useState({
    email : "",
    firstname : "",
    lastname : "",
    type : "",
    idnumber : ""
  });
  const  [error , setError] = useState({});
  const [front , setFront] = useState({});
  const [back , setBack] = useState({});
  const [selfie , setSelfie] = useState({});
  const [frontview , setFrontview] = useState("");
  const [backview  , setBackview] = useState("");
  const [selfieview , setSelfieview] = useState("");
  const [fronterror , setFronterror] = useState("");
  const [backerror , setBackerror] = useState("");
  const [selfieerror , setSelfieerror] = useState("");
  const [userkyc , setUserkyc] = useState({});

  const HandleChange = (e) => {
    try{
      let init = initialstate;
      init[e?.target?.name] = e?.target?.value
      setInitialstate({...initialstate , [e?.target?.name] : e?.target?.value})
      let validate = ValidateIdverification(init , e?.target?.name , false);
      console.log("validate" , validate);
      setError(validate);
    }
    catch(e){
      console.log("Error on handlechange" , e);
    }
  }

  const ValidateIdverification = (data , key , ref) => {
    try{
      var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let error = {};
      let isvalid = true
      if(key == "email" || ref){
        if(!data?.email){
          error.email = "Field is required";
          isvalid = false
        }
        else if(!emailRegx.test(data?.email)){
          error.email = "Invalid Email"
          isvalid = false
        }
      }
      if(key == "firstname" || ref){
        if(!data?.firstname){
          error.firstname = "Field is required";
          isvalid = false
        }
      }
      if(key == "lastname" || ref){
        if(!data?.lastname){
          error.lastname = "Field is required";
          isvalid = false
        }
      }
      if(key == "idnumber" || ref){
        if(!data?.idnumber){
          error.idnumber = "Field is required";
          isvalid = false
        }
      }
      if(isvalid){return {}}
      else{return error}
    }
    catch(e){
      console.log("error on validate id verification"  , e);
    }
  }

  const fetchdata = async() => {
    let user = await GetUserHooks({userid : GetUserId()} , dispatch);
    console.log("userdata ," , user);
    setUser(user?.record);
    setUserkyc(user?.kyc); //new, pending, approved, rejected
    if(user?.kyc?.idProof?.status == "pending" || user?.kyc?.idProof?.status == "approved" 
    || user?.kyc?.idProof?.status == "rejected"){
      setProcess("");
    }
    setInitialstate({...initialstate , ["email"] : user?.record?.email});
  }

  useEffect(() => {
    fetchdata();
  } , [])

  const Handlefront = (e) => {
    try{console.log("e" , e?.target?.value);
      let validate = ValidateFile(e?.target?.files[0])
      if(validate){
        setFronterror(validate);
      }
      else{
        setFront(e?.target?.files[0])
        setFrontview(URL.createObjectURL(e?.target?.files[0]));
        setFronterror("");
      }
      
    }
    catch(e){
      console.log("Error on handle front" , e);
    }
  }

  const Handleback = (e) => {
    try{
      let validate = ValidateFile(e?.target?.files[0])
      if(validate){
        setBackerror(validate);
      }
      else{
        setBackerror("");
        setBack(e?.target?.files[0])
        setBackview(URL.createObjectURL(e?.target?.files[0]));
      }
    }
    catch(e){
      console.log("Error on handle front" , e);
    }
  }

  const Handleselfie = (e) => {
    try{
      let validate = ValidateFile(e?.target?.files[0])
      if(validate){
        setSelfieerror(validate);
      }
      else{
        setSelfieerror("");
        setSelfie(e?.target?.files[0]);
        setSelfieview(URL.createObjectURL(e?.target?.files[0]));
      }
    }
    catch(e){
      console.log("Error on handle front" , e);
    }
  }

  const HandleSubmit = async() => {
    try{
      var formdata = new FormData();
      formdata.append("idtype" , proof);
      formdata.append("idnumber" , initialstate?.idnumber);
      formdata.append("userid" , GetUserId());
      formdata.append("dob" , date);
      formdata.append("frontimage" , front);
      formdata.append("backimage" , back);
      formdata.append("selfieimage" , selfie);
      let result = await UploadKycHooks(formdata);
      console.log("Result" , result);
      // setVerifyStatus('progress');
      // setProcess('')
    }
    catch(e){
      console.log("Error on handle submit" , e);
    }
  }


  return (
    <>
      <div className="notices_holder p-2 p-sm-3 pb-md-5">
        <Row>
          <Col lg={5} md={5} className="d-flex align-items-center mb-3 mb-md-0">
            <h5 className="notices_hader_title ">Profile</h5>
          </Col>
          <Col
            lg={7}
            md={7}
            className="d-flex flex-column flex-sm-row justify-content-end align-items-start gap-3"
          >
            <button className="profile_change_btn inter_font" onClick={() => setActiveCont('editProfile')}>Change</button>
          </Col>
        </Row>
        <div
          className={`my-4 ${
            theme == "dark"
              ? "table_triple_grad_rel"
              : "table_triple_grad_rel_light"
          }`}
        ></div>

        <div className="prfl_profile_dtls_holder d-flex justify-content-start align-items-center gap-3">
          <div className="prfl_profile_img_holder position-relative">
            <img
              src={require("../assets/images/person.svg").default}
              className="img-fluid"
            />
            <div className="prfl_online_indicator rounded-5"></div>
          </div>
          <div className="prfl_username_holder d-flex jutify-content-start align-items-center gap-2">
            <p className="prfl_username">{user?.firstName +" "+ user?.lastName}</p>
            <img
              src={require("../assets/images/vendor.svg").default}
              className="img-fluid"
            />
          </div>
        </div>

       <ProfileAccord user = {user}/>

        <Row>
          {process == 'proof' ? <></> :
          <>
          <Col lg={4} md={4} sm={6} className="mb-3">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2">
              <p className="prfl_gray_ttile text-center inter_font">User ID</p>
              <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
                <p className="prfl_box_value inter_font">{user?.userId}</p>
                <CopyToClipboard onCopy={() => console.log("copycopycopu")}>
                  <p className="d-flex align-items-center mb-0">
                    <img
                      src={require("../assets/images/whitecopy.svg").default}
                      alt="copy"
                      className="mt_whitecopy_img"
                    />
                  </p>
                </CopyToClipboard>
              </div>
            </div>
          </Col>

          <Col lg={4} md={4} sm={6} className="mb-3">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2">
              <p className="prfl_gray_ttile text-center inter_font">
                Email address
              </p>
              <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
                {user?.email ? <p className="prfl_box_value inter_font">{user?.email?.substring(0 , 3) + "******"+ user?.email?.substring(user?.email?.length-10 , user?.email?.length)}</p>
                : <p className="text-danger">Not Provided!</p>  
              }
              </div>
            </div>
          </Col>

          <Col lg={4} md={4} sm={6} className="mb-3">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2">
              <p className="prfl_gray_ttile text-center inter_font">
                Phone number
              </p>
              <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
                {user?.phoneCode && user?.phoneNo ? <p className="prfl_box_value inter_font">{user?.phoneCode + user?.phoneNo}</p>
                : <p className="text-danger">Not Provided!</p>  
              }
              </div>
            </div>
          </Col>

          <Col lg={4} md={4} sm={6} className="mb-3">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2">
              <p className="prfl_gray_ttile text-center inter_font">
                Shipping address
              </p>
              <div className="mt-3">
                <p className="prfl_addrs_text text-center inter_font">
                  {user?.address+" "+user?.city+" "+user?.state+" "+user?.country}
                </p>
                <p className="prfl_addrs_text text-center inter_font mt-2">
                  Postal code : {user?.postalCode}
                </p>
              </div>
            </div>
          </Col>

          <Col lg={4} md={4} sm={6} className="mb-3">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2 h-100">
              <p className="prfl_gray_ttile text-center inter_font">
                Date of subscription
              </p>
              <div className="mt-3">
                <p className="prfl_addrs_text text-center inter_font">
                  {new Date(user?.createdAt)?.toLocaleDateString() +" "+ " " + new Date(user?.createdAt)?.toLocaleTimeString()}
                </p>
              </div>
            </div>
          </Col>

          <Col lg={4} md={4} sm={6} className="mb-3">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2 h-100">
              <p className="prfl_gray_ttile text-center prfl_red_ttl inter_font">
                Close account
              </p>
              <div className="text-center mt-3">
                <button className="primary_btn" onClick={() => setActiveCont('closeAcc')}>Confirm</button>
              </div>
            </div>
          </Col>
          </>}
          {process == 'form' ?
<>
          <Col lg={4} md={4} sm={6} className="mb-3 ">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2 ">
              <p className="prfl_gray_ttile text-center inter_font">
                ID verification
              </p>
              <p className="prfl_addrs_text text-center inter_font">
                Identify document type
              </p>
              <div className="text-center mt-3">
                <div className="prfl_id_selector">
                  <div
                    className={`prfl_single_id py-2 d-flex justify-content-center align-items-center ${
                      proof == "passport" ? "active" : ""
                    }`}
                    onClick={() => setProof("passport")}
                  >
                    <div className=" d-flex justify-content-between align-items-center gap-3 w-75">
                      <p className="prfl_addrs_text">Passport</p>
                      <img
                        src={require("../assets/images/passport.svg").default}
                        className={`img-fluid prfl_passport_img  prfl_id_image ${theme == 'light' && 'prfl__proofInvert'}`}
                      />
                    </div>
                  </div>

                  <div
                    className={`prfl_single_id py-2 d-flex justify-content-center align-items-center mt-2 ${
                      proof == "license" ? "active" : ""
                    }`}
                    onClick={() => setProof("license")}
                  >
                    <div className=" d-flex justify-content-between align-items-center gap-3 w-75">
                      <p className="prfl_addrs_text">Driving license</p>
                      <img
                        src={require("../assets/images/license.svg").default}
                        className={`img-fluid prfl_id_image ${theme == 'light' && 'prfl__proofInvertSec'}`}
                      />
                    </div>
                  </div>

                  <div
                    className={`prfl_single_id py-2 d-flex justify-content-center align-items-center mt-2 ${
                      proof == "idcard" ? "active" : ""
                    }`}
                    onClick={() => setProof("idcard")}
                  >
                    <div className=" d-flex justify-content-between align-items-center gap-3 w-75">
                      <p className="prfl_addrs_text">ID card</p>
                      <img
                        src={require("../assets/images/idcard.svg").default}
                        className={`img-fluid prfl_id_image ${theme == 'light' && 'prfl__proofInvertSec'}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={4} md={4} sm={6} className="mb-3 ">
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-2 h-100">
              <p className="prfl_gray_ttile text-center inter_font">
                ID verification
              </p>
              <div className="mt-3">
                <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Email Address</p>

                  <input
                    type="text"
                    className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                    placeholder="Enter registered email address "
                    name="email"
                    readOnly = {true}
                    value={initialstate?.email}
                    onChange={(e) => HandleChange(e)}
                  />
                  <p className="text-danger">{error?.email}</p>
                </div>
                <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">First name</p>

                  <input
                    type="text"
                    className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                    placeholder="Enter registered first name "
                    name="firstname"
                    value={initialstate?.firstname}
                    onChange={(e) => HandleChange(e)}
                  />
                  <p className="text-danger">{error?.firstname}</p>
                </div>
                <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Last name</p>

                  <input
                    type="text"
                    className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                    placeholder="Enter registered last name "
                    name="lastname"
                    value={initialstate?.lastname}
                    onChange={(e) => HandleChange(e)}
                  />
                  <p className="text-danger">{error?.lastname}</p>
                </div>

                <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Id Number</p>

                  <input
                    type="text"
                    className="prfl_tiny_inputs rounded-2 p-2 mt-2"
                    placeholder="Enter registered last name "
                    name="idnumber"
                    value={initialstate?.idnumber}
                    onChange={(e) => HandleChange(e)}
                  />
                  <p className="text-danger">{error?.idnumber}</p>
                </div>

                <div className="prfl_tiny_inputs_holder mb-3">
                  <p className="prfl_addrs_text inter_font">Date of birth</p>

                  <div className="deposit_custom_datepoicker profile_custom_datepicker rounded-2 d-flex justify-content-start align-items-center px-2 position-relative mt-2">
                    <DatePicker
                      selectsRange={false}
                      startDate={date}
                      selected={date}
                      // endDate={endDate}
                      onChange={(update) => {
                        setDate(update);
                      }}
                      // monthsShown={2}
                    />
                    <img
                      src={
                        require("../assets/images/white_calendar.svg").default
                      }
                      className={`img-fluid ${
                        theme == "dark"
                          ? "deposit_calImg"
                          : "deposit_calImg_light"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={6} className="mb-3">
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div className="prfl_primary_sec d-flex flex-column justify-content-cener align-items-center rounded-3" onClick={() =>{
                let validate = ValidateIdverification(initialstate , "" , true);console.log("Validate" , Object.keys(validate) , validate);
                setError(validate)
                if(Object.keys(validate)?.length <= 0){
                  setProcess('proof')
                }
              }}>
                <p className="inter_font text-center">Continue</p>
                <img
                  src={require("../assets/images/rightLong.svg").default}
                  className="img-fluid"
                />
              </div>
            </div>
          </Col>
          </> : <></>
           }
{process == 'proof' ?
          <Col xs={12}>

     <ProfileProof setProcess={setProcess} setVerifyStatus={setVerifyStatus} 
     front = {front} back = {back} selfie = {selfie} frontview = {frontview}
     backview = {backview} selfieview = {selfieview}
     handlefront = {(e) => Handlefront(e)}
     handleselfie = {(e) => Handleselfie(e)}
     handleback = {(e) => Handleback(e)}
     fronterror = {fronterror} backerror = {backerror} selfieerror = {selfieerror}
     handleSubmit = {HandleSubmit}
     />
          </Col> : <></> }
          {(userkyc?.idProof?.status == 'pending' || userkyc?.idProof?.status =='approved' ||userkyc?.idProof?.status == 'rejected') && process == "" ?
          <Col xs={12}>
            <div className="prfl_accord_holder prfl_gray_box rounded-4 p-4 ">
          <Row className="justify-content-center">
                <Col lg={9} md={10} sm={12} xs={12}>
            <div className="  w-100">
              <>
              <p className={`prfl_gray_ttile text-center inter_font mb-2 ${userkyc?.idProof?.status == 'failed' ? 'prfl_red_ttl' : userkyc?.idProof?.status == 'success' ? 'prfl_green_ttl' : ''}`}>
              {userkyc?.idProof?.status == 'rejected' ? 'Verification Rejected by admin' : 
              
              userkyc?.idProof?.status == 'approved' ? 'Verification Success' : 
              
              userkyc?.idProof?.status == 'pending' ? 'Verification on progress' : ''}
                
              </p>
              <h5 className="prfl_status_shower inter_font text-center">
                {userkyc?.idProof?.status == 'pending' ? 'It will take up to 24 hours.' : 

                userkyc?.idProof?.status == 'rejected' ? 'Once again, fill it out correctly and apply for KYC registration with a clear file.' : 

                userkyc?.idProof?.status == 'approved' ? 'You can proceed now.' : ''}
                
              </h5>
              {userkyc?.idProof?.status == 'rejected'  && process == "" && <button className="primary_btn mt-3 w-100"
              onClick={() => setProcess("form")}
              >Continue</button>}
              </>
{userkyc?.idProof?.status == 'approved' ? 
             <Row className="justify-content-center">
              <Col lg={7} md={8} sm={10} xs={10}>
                  <button className="primary_btn mt-3 w-100">Confirm</button>
              </Col>
             </Row> 
             : <></>
            }               
            </div>
            </Col>
              </Row>
              </div>
          </Col> : <></> }
        </Row>
      </div>

      <Row className="mt-4">
        <Col xs={12}>
          <ProfileFaqs />
        </Col>
      </Row>

    </>
  );
}

export default Profile;
