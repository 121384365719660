import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Purchase from "../Modals/Purchase";
import ChatBox from "../Components/ChatBox";
import ChatBoxModal from "../Modals/ChatBoxModal";
import WalletBalanceModal from "../Modals/WalletBalanceModal";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

function StoreInfo() {
  const theme = useSelector((state) => state?.User?.theme);

  // purchase modal controls
  const [showPurchase, setShowPurchase] = useState(false);

  const handleClosePurchase = () => setShowPurchase(false);
  const handleShowPurchase = () => setShowPurchase(true);

  // chatbox modal controls
  const [showChat, setShowChat] = useState(false);

  const handleCloseChat = () => setShowChat(false);
  const handleShowChat = () => setShowChat(true);

  // walletBalance modal controls

  const [showWalBal, setShowWalBal] = useState(false);

  const handleCloseWalBal = () => setShowWalBal(false);
  const handleShowWalBal = () => setShowWalBal(true);

  const [closer, setCloser] = useState();
  const [selectedFlag, setSelectedFlag] = useState("");
  const options = [
    {
      value: "BNB",
      label: "BNB",
      img: require("../assets/images/BNB.png"),
    },
    {
      value: "bnb",
      label: "bnb",
      img: require("../assets/images/bnb.png"),
    },
    {
      value: "Cron",
      label: "Cron",
      img: require("../assets/images/CRON.png"),
    },
    {
      value: "Crons",
      label: "Crons",
      img: require("../assets/images/CRONS.png"),
    },
    {
      value: "Polygon",
      label: "Polygon",
      img: require("../assets/images/polygon.png"),
    },
  ];
  const products = [
    {
      value: "electronics",
      label: "electronics",
      img: require("../assets/images/BNB.png"),
    },
    {
      value: "program",
      label: "program",
      img: require("../assets/images/bnb.png"),
    },
    {
      value: "Marketing",
      label: "Marketing",
      img: require("../assets/images/CRON.png"),
    },
    {
      value: "Restaurant",
      label: "Restaurant",
      img: require("../assets/images/CRONS.png"),
    },
    {
      value: "Storing",
      label: "Storing",
      img: require("../assets/images/polygon.png"),
    },
  ];
  const [selected, setSelected] = useState({
    value: "productItem",
    label: "Product Item",
  });
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "coinType",
    label: "Coin Type",
    img:
      theme == "dark"
        ? require("../assets/images/searchglass.svg").default
        : require("../assets/images/blacksearch.svg").default,
  });

  const currencyRef = useRef();
  const ref = useRef();
  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedCurrency({ label, value, img: data.img });
        currencyRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
        ? "#00CABA"
        : isHovered
        ? "red"
        : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "30px",
      border: theme == "dark" ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
        ? "transperant"
        : isFocused
        ? "transperant"
        : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == "dark" ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="common_spacer position-relative">
        <img
          src={require("../assets/images/fish_blurball.png")}
          className={theme == "dark" ? "inner_fishblur" : "d-none"}
        />
        <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="box_container p-2 p-sm-3 p-md-4 pt-lg-5 p-lg-5 ps-lg-5 pe-lg-5 pb-lg-5 mt-3">
            <Row>
              <Col lg={12} className="mb-4">
                <img
                  src={require("../assets/images/imageset.svg").default}
                  className="img-fluid sd_bannerer"
                />
              </Col>

              <div className="cmn__adSwiper mb-4">
                <Swiper loop={true}
                  navigation={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Navigation, Autoplay]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad1.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad2.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad3.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad3.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={require("../assets/images/ad1.jpg")}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
              <Col lg={10} sm={12} md={8} xs={12} className="mb-3 mb-sm-0">
                <Row>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <div className="store_top_search rounded-1 d-flex justify-content-start align-items-center gap-2">
                      <img
                        src={
                          theme == "dark"
                            ? require("../assets/images/searchglass.svg")
                                .default
                            : require("../assets/images/blacksearch.svg")
                                .default
                        }
                        className="img-fluid store_searchglass"
                      />
                      <input
                        type="text"
                        placeholder="Store Name"
                        className="inter_font"
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <div className="store_top_search rounded-1 d-flex justify-content-start align-items-center gap-2">
                      <img
                        src={
                          theme == "dark"
                            ? require("../assets/images/searchglass.svg")
                                .default
                            : require("../assets/images/blacksearch.svg")
                                .default
                        }
                        className="img-fluid store_searchglass"
                      />
                      <input
                        type="text"
                        placeholder="Seller ID"
                        className="inter_font"
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <div className="market_coinname_select position-relative">
                      <img
                        src={selectedCurrency.img}
                        className="img-fluid market_coinname_img sd_store_coingImg"
                      />
                      <Select
                        className=""
                        defaultValue={selectedCurrency}
                        onChange={selectedCurrency}
                        options={options}
                        value={selectedCurrency}
                        components={{ Option: customCurrencyRenderer }}
                        ref={currencyRef}
                        styles={styles}
                        placeholder="All Payment Methods"
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <div className="market_coinname_select position-relative">
                      <img
                        src={
                          theme == "dark"
                            ? require("../assets/images/searchglass.svg")
                                .default
                            : require("../assets/images/blacksearch.svg")
                                .default
                        }
                        className="img-fluid market_coinname_img sd_store_searchImg"
                      />
                      <Select
                        className=""
                        defaultValue={selected}
                        onChange={setSelected}
                        options={products}
                        value={selected}
                        ref={ref}
                        styles={styles}
                        placeholder=""
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <div
                      className="cmn_flag_select rounded-2 d-flex align-items-center"
                      title={selectedFlag}
                    >
                      <ReactFlagsSelect
                        className=" pb-0 w-100"
                        selected={selectedFlag}
                        showSecondarySelectedLabel={true}
                        selectedSize={10}
                        onSelect={(code) => {
                          console.log("code", code);
                          setSelectedFlag(code);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={4} md={6} sm={6} className="mb-3">
                    <button className="primary_btn heig_primary_btn w-100">
                      Search
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col lg={2} md={4} sm={12} xs={12} className="mb-3 mb-sm-0">
                <button className="st_store_yellowbtn inter_font w-100 rounded-2">
                  Store Management
                </button>
              </Col>
              <Col
                lg={3}
                md={4}
                sm={4}
                xs={12}
                className="mb-3 mt-sm-4 mt-md-2 mb-lg-0"
              >
                <img
                  src={require("../assets/images/shop.svg").default}
                  className="img-fluid rounded-5 si_mainprod_img"
                />
              </Col>
              <Col
                lg={9}
                md={8}
                sm={8}
                xs={12}
                className="mb-3 mt-sm-4 mt-md-2 mb-lg-0"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="si_desc_title inter_font">MyShopPrime</h6>
                  <p className="si_blue_text inter_font">1:1 Conversation</p>
                </div>
                <p className="si_desc_sub_title mt-4 inter_font">
                  Product description
                </p>
                <p className="si_desc mt-2 inter_font">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  quis nisl eget nunc finibus volutpat et eu lectus. Sed at elit
                  a elit faucibus ultricies. Aliquam nec magna vel risus egestas
                  dictum. Phasellus non facilisis erat. Pellentesque porta
                  iaculis interdum. Donec dapibus eget orci non venenatis. Etiam
                  id risus in urna vestibulum accumsan.
                </p>
              </Col>

              <Col xs={12} className="mt-5">
                <div className="si_tableScroller pe-3">
                  <div className="si_table mb-3 rounded-1 position-relative px-2 px-sm-3 px-md-4 py-4">
                    {/* <div className={`${theme == 'dark' ? 'table_triple_grad' : 'table_triple_grad_light'}`}></div> */}
                    {/* <div className="table_title_grid mb-4">
                    <div className="table_title_single_grid text-center">
                      <p className="si_gird_title text-center inter_font">
                        Select Options
                      </p>
                    </div>
                    <div className="table_title_single_grid text-center">
                      <p className="si_gird_title text-center inter_font">
                        Product Name
                      </p>
                    </div>
                    <div className="table_title_single_grid text-center">
                      <p className="si_gird_title text-center inter_font">
                        Transaction Method
                      </p>
                    </div>
                    <div className="table_title_single_grid text-center">
                      <p className="si_gird_title text-center inter_font">
                        Price
                      </p>
                    </div>
                    <div className="table_title_single_grid text-center">
                      <p className="si_gird_title text-center inter_font">
                        Choice
                      </p>
                    </div>
                  </div> */}

                    <Row className="p-0 m-0">
                      <Col
                        lg={4}
                        md={6}
                        sm={6}
                        className="d-flex justify-content-start align-items-start mb-3 p-0"
                      >
                        <div className="table_title_single_grid text-center">
                          <div className="table_title_prodDtlss d-flex justify-content-start align-items-center gap-3">
                            <img
                              src={require("../assets/images/shop.svg").default}
                              className="img-fluid si_table_prodImg rounded-3"
                            />
                            <div className="d-flex flex-column align-items-start">
                              <p className="si_gird_title inter_font">
                                Product Name
                              </p>
                              <p className="si_gird_hint text-center inter_font">
                                Amazon gift certificate
                              </p>
                              <p className="si_gird_hint inter_font">
                                5% discount
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={3}
                        md={6}
                        sm={6}
                        xs={7}
                        className="d-flex justify-content-start justify-content-sm-end justify-content-md-start justify-content-lg-center align-items-start mb-3 p-0"
                      >
                        <div>
                          <p className="si_gird_title inter_font">
                            Transaction Method
                          </p>
                          <p className="si_gird_hint inter_font">escrow</p>
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        md={6}
                        sm={6}
                        xs={5}
                        className="d-flex justify-content-center  justify-content-sm-start justify-content-lg-center  align-items-start mb-3 p-0"
                      >
                        <div>
                          <p className="si_gird_title inter_font">Price</p>
                          <p className="si_gird_hint text-center inter_font">
                            22.213 USDT
                          </p>
                          <p className="si_gird_hint text-center inter_font">
                            30,000 KRW
                          </p>
                        </div>
                      </Col>
                      <Col
                        lg={3}
                        md={6}
                        sm={6}
                        className="d-flex justify-content-center justify-content-sm-start justify-content-lg-end align-items-start p-0"
                      >
                        <div>
                          <p className="si_gird_hint text-center inter_font">
                            escrow
                          </p>
                          <button
                            className="primary_btn  mt-1"
                            onClick={handleShowPurchase}
                          >
                            Buy
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="table_title_grid d-flex justify-content-between align-items-center pt-3 mb-3">
                    <div className="table_title_single_grid text-center">
                      <div className="table_title_prodDtlss d-flex justify-content-start align-items-center gap-3">
                      <img
                        src={require("../assets/images/shop.svg").default}
                        className="img-fluid si_table_prodImg rounded-3"
                      />
                      <div className="d-flex flex-column align-items-start">
                      <p className="si_gird_title inter_font">
                        Product Name
                      </p>
                      <p className="si_gird_hint text-center inter_font">
                        Amazon gift certificate
                      </p>
                      <p className="si_gird_hint inter_font">
                        5% discount
                      </p>
                      </div>
                      </div>
                    </div>
                    
                    <div className="table_title_single_grid ">
                    <p className="si_gird_title inter_font">
                        Transaction Method
                      </p>
                      <p className="si_gird_hint inter_font">
                        escrow
                      </p>
                    </div>
                    <div className="table_title_single_grid">
                    <p className="si_gird_title inter_font">
                        Price
                      </p>
                      <p className="si_gird_hint text-center inter_font">
                        22.213 USDT
                      </p>
                      <p className="si_gird_hint text-center inter_font">
                        30,000 KRW
                      </p>
                    </div>
                    <div className="table_title_single_grid text-center">
                      <p className="si_gird_hint text-center inter_font">
                        escrow
                      </p>
                      <button className="primary_btn  mt-1" onClick={handleShowPurchase}>Buy</button>
                    </div>
                  </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* <div className="mt-5">
        <ChatBox/>
        </div> */}
        </Container>
      </Container>

      <Footer />

      {showPurchase && (
        <Purchase
          showChat={showChat}
          showWalBal={showWalBal}
          handleShowWalBal={handleShowWalBal}
          handleShowChat={handleShowChat}
          showPurchase={showPurchase}
          handleClosePurchase={handleClosePurchase}
        />
      )}
      {showChat && (
        <ChatBoxModal
          showChat={showChat}
          handleCloseChat={handleCloseChat}
          handleShowChat={handleShowChat}
          showPurchase={showPurchase}
          handleShowPurchase={handleShowPurchase}
        />
      )}

      {showWalBal && (
        <WalletBalanceModal
          showWalBal={showWalBal}
          handleCloseWalBal={handleCloseWalBal}
          showPurchase={showPurchase}
          handleClosePurchase={handleClosePurchase}
          handleShowPurchase={handleShowPurchase}
        />
      )}
    </>
  );
}

export default StoreInfo;
