import React from 'react'
import {Modal} from 'react-bootstrap';
import ChatBox from '../Components/ChatBox';

function ChatBoxModal({showChat,handleCloseChat,showPurchase,handleShowPurchase}) {
   
  return (
   <>
   <Modal className="purchase_modal"
   size='md'
        show={showChat}
        onHide={handleCloseChat}
        backdrop="static"
        keyboard={false}
      >
       
        <Modal.Body className='p-0'>
        <button
            className="cmn_modal_closer inter_font rounded-5"
            onClick={() =>{handleCloseChat();handleShowPurchase();}}
          >
            <i class="fa-solid fa-xmark" />
          </button>

          <ChatBox/>
          
        </Modal.Body>
        
      </Modal>
   </>
  )
}

export default ChatBoxModal