import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Notices from "./Notices";
import Footer from "../Components/Footer";
import NoticeInfo from "./NoticeInfo";
import Profile from "./Profile";
import Recommend from "./Recommend";
import CloseAccount from "../Components/CloseAccount";
import PaymentMethod from "./PaymentMethod";
import Security from "./Security";
import HistoryPage from "./HistoryPage";
import EnterPriseAPI from "./EnterPriseAPI";
import Faq from "./Faq";
import MarketList from "./MarketList";
import Inquiry from "./Inquiry";
import EditProfile from "./EditProfile";

function Preferences() {
  const [showSidebar, setShowSidebar] = useState(false);
  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  const [activeCont, setActiveCont] = useState("profile");
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Container
        fluid
        className="common_spacer common_bg_holder pb-5 position-relative"
      >
        <img
          src={require("../assets/images/fish_blurball.png")}
          className="img-fluid prefer_fishblue"
        />
        <Header activeCont = {activeCont} setActiveCont = {setActiveCont} />
        <Container className="custom_container">
          <div className="sidebar_canva_shower d-xl-none">
              <button
                className="sidebar_canva_burg rounded-2"
                onClick={handleShowSidebar}
              >
               <i class="fa-solid fa-left-long" />
              </button>
            </div>
          <Row className="mt-3">
            <Col xxl={2} xl={3} className="d-none d-xl-flex">
              <Sidebar
                activeCont={activeCont}
                setActiveCont={setActiveCont}
                showSidebar={showSidebar}
                handleCloseSidebar={handleCloseSidebar}
              />
            </Col>
            <Col xxl={10} xl={9}>
              {activeCont == "notice" && (
                <Notices
                  activeCont={activeCont}
                  setActiveCont={setActiveCont}
                />
              )}
              {activeCont == "noticeInfo" && <NoticeInfo />}
              {activeCont == "profile" && <Profile activeCont={activeCont}
                setActiveCont={setActiveCont}/>}
              {activeCont == "recommend" && (
                <Recommend
                  activeCont={activeCont}
                  setActiveCont={setActiveCont}
                />
              )}
              {activeCont == "closeAcc" && <CloseAccount />}
              {activeCont == "payment" && <PaymentMethod />}
              {activeCont == "security" && <Security />}
              {activeCont == "history" && (
                <HistoryPage
                  showSidebar={showSidebar}
                  handleShow={handleShowSidebar}
                />
              )}
              {activeCont == "api" && <EnterPriseAPI />}
              {activeCont == "faq" && (
                <Faq showSidebar={showSidebar} handleShow={handleShowSidebar} />
              )}

              {activeCont == 'inquiry'  && <Inquiry showSidebar={showSidebar} handleShow={handleShowSidebar}/>}
              {activeCont == 'editProfile' && <EditProfile showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} activeCont={activeCont} setActiveCont = {setActiveCont} />}
            </Col>
            
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default Preferences;
