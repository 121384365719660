import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { ValidNumber } from "../Validation/UserValidation";

function CreateOfferTradePricing({setOfferSec,setStep , Setdata , defaultvalue}) {
    const[activeTab,setActiveTab] = useState('marketPrice');
    const [count,setCount] = useState(1)
    const [disable,setDisable] = useState(true)
    const [min , setMin] = useState(0);
    const [max , setMax] = useState(0);
    const [offermargin , setOffermargin] = useState(0);
    const [price , setPrice] = useState(0);
    const [offerlimit , setOfferlimit] = useState(0);
    const [error , setError] = useState({});
    const [initialstate , setInitialstate] = useState({
      min : 0,
      max : 0,
      margin : 0,
      price : 0,
      offerlimit : 0,
    })

    useEffect(() => {
      if(count >= 1) setDisable(false)
    },[])

    useEffect(() => {console.log("default valu" , defaultvalue);
    // setTimeout(() => {
      let init = initialstate
      if(defaultvalue?.min){
        init.min = parseFloat(defaultvalue?.min)
        // setInitialstate({...initialstate , ["min"] : parseFloat(defaultvalue?.min)})
      }
      if(defaultvalue?.max){
        init.max = parseFloat(defaultvalue?.max)
        // setInitialstate({...initialstate , ["max"] : parseFloat(defaultvalue?.max)})
      }
      if(defaultvalue?.offermargin){
        // setActiveTab("marketPrice")
        // setInitialstate({...initialstate , ["margin"] : parseFloat(defaultvalue?.offermargin)})
        init.margin = parseFloat(defaultvalue?.offermargin);
      }
      if(defaultvalue?.fixedmarketrate){
        // setActiveTab("fixedPrice")
        // setInitialstate({...initialstate , ["price"] : parseFloat(defaultvalue?.fixedmarketrate)})
        init.price = parseFloat(defaultvalue?.fixedmarketrate);
      }
      if(defaultvalue?.offertimelimit){
        // setInitialstate({...initialstate , ["offerlimit"] : parseFloat(defaultvalue?.offertimelimit)})
        init.offerlimit = parseFloat(defaultvalue?.offertimelimit)
      }
      if(defaultvalue?.offerPricing){
        setActiveTab(defaultvalue?.offerPricing)
      }
    // } , 1000)
      setInitialstate(init);
    } , [])

    console.log(initialstate , "initial state in outer");
    const HandleNext = () => {
      try{
       if(activeTab == "marketPrice"){
        if(initialstate?.min && initialstate?.max && initialstate?.margin && initialstate?.offerlimit){
          let payload = {
            min : initialstate?.min,
            max : initialstate?.max,
            offermargin : initialstate?.margin,
            fixedmarketrate : initialstate?.price,
            offertimelimit : initialstate?.offerlimit,
            offerpricing : activeTab
          }
          Setdata(payload)
          setOfferSec('tradeInstruct');
          setStep(3)
        }
        else{
          toast.error("Fill all the fields");
        }
       }
       else{
        if(initialstate?.min && initialstate?.max && initialstate?.price && initialstate?.offerlimit){
          let payload = {
            min : initialstate?.min,
            max : initialstate?.max,
            offermargin : initialstate?.margin,
            fixedmarketrate : initialstate?.price,
            offertimelimit : initialstate?.offerlimit,
            offerpricing : activeTab
          }
          Setdata(payload)
          setOfferSec('tradeInstruct');
          setStep(3)
        }
        else{
          toast.error("Fill all the fields");
        }
       }
      }
      catch(e){
        console.log("Error on handle next" , e);
      }
    }

    const HandleChange = (e) => {
      try{
        let validate = ValidNumber(e?.target?.value);
        setInitialstate({...initialstate , [e?.target?.name] : e?.target?.value})

        if(!e?.target?.value){
          setError({...error , [e?.target?.name] : "Field is required"});
        }
        else if(!validate){
          setError({...error , [e?.target?.name] : "Invalid Value"});
        }
        else {
          setError({...error , [e?.target?.name] : ""});
        }
        
        if(e?.target?.name == "min" || e?.target?.name == "max"){
          if(e?.target?.name == "min"){
            if(initialstate?.max <= e?.target?.value){
              setError({...error , ["max"] : "Max value must be greater than Min"})
            }
            else{
              setError({...error , ["max"] : ""})
            }

            if(!e?.target?.value){
              setError({...error , [e?.target?.name] : "Field is required"});
            }
            else if(!validate){
              setError({...error , [e?.target?.name] : "Invalid Value"});
            }
            else {
              setError({...error , [e?.target?.name] : ""});
            }
          }

          if(e?.target?.name == "max"){console.log("initialstate?.min >  e?.target?.value" , initialstate?.min >  e?.target?.value);
            if(parseFloat(initialstate?.min) >=  e?.target?.value){
              setError({...error , ["max"] : "Max value must be greater than Min"})
            }
            else{
              setError({...error , ["max"] : ""})
            }
          }
        }
        // else{

         

        // }
        
      }
      catch(e){
        console.log("Error on handle change" , e);
      }
    }

    const HandlePlus = (e) => {
      try{
       console.log(e?.target?.name);
       setError({...error , [e?.target?.name] : ""});
        setInitialstate({...initialstate , [e?.target?.name] : parseFloat(initialstate[e?.target?.name]) ? parseFloat(initialstate[e?.target?.name])+1 : 1});
      }
      catch(err){
        console.log("Error on handl plus" , err);
      }
    }

    const HandleMinus = (e) => {
      try{
        console.log(e?.target?.value);
        if(parseFloat(initialstate[e?.target?.name]) >0){
          setInitialstate({...initialstate , [e?.target?.name] : parseFloat(initialstate[e?.target?.name])-1});
        }
      }
      catch(err){
        console.log("Error on handl plus" , err);
      }
    }
    


  return (
    <>
    <div className="mt-5">
      <h6 className="inter_font m-0 createOffer__tc_title">Trade Pricing</h6>
      <p className="createOffer_steps_hint inter_font m-0">
        Choose Bitcoin rate you want to use
      </p>

      <Row className="mt-4">
        <Col md={6}>
            <Row className="mb-4">
              <Col lg={6} md={12} sm={6} className="mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <div className={`d-flex justify-content-start align-items-center gap-2 rounded-2 p-2 ${activeTab == 'marketPrice' ? 'createOffer__tc_tab_active' : 'createOffer__tc_tab'}`} onClick={() => setActiveTab('marketPrice')}>
                  <img
                    src={require("../assets/images/tag.svg").default}
                    className="img-fluid createOffer__tab_img"
                  />
                  <div className="createOffer__tab_textHolder">
                    <p className="inter_font m-0 createOffer__tab_title">Market Price</p>
                    <p className="inter_font m-0 createOffer__tab_hinter">
                      Your offer’s selling price will change according to the market
                      price of Bitcoin
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <div className={`d-flex justify-content-start align-items-center gap-2 rounded-2 p-2 ${activeTab == 'fixedPrice' ? 'createOffer__tc_tab_active' : 'createOffer__tc_tab'}`} onClick={() => setActiveTab('fixedPrice')}>
                  <img
                    src={require("../assets/images/fixedprice.svg").default}
                    className="img-fluid createOffer__tab_img"
                  />
                  <div className="createOffer__tab_textHolder">
                    <p className="inter_font m-0 createOffer__tab_title">Fixed Price</p>
                    <p className="inter_font m-0 createOffer__tab_hinter">
                      Your offer’s selling price will change according to the market
                      price of Bitcoin
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="createOffer__borbot"></div>

            <div className="createOffer__market__dtls mt-4">
            <h6 className="inter_font m-0 createOffer__tc_title">Offer Trade Limits</h6>

            <Row className="mt-4 mb-4">
              <Col lg={6} md={12} sm={6} className="mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Min</label>
                    <input
                      type="number"
                      name="min"
                      value={initialstate?.min}
                      placeholder='Enter Value'
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={HandleChange}
                    />
                  </div>
                  <p className="text-danger">{error?.min}</p>

              </Col>

              <Col lg={6} md={12} sm={6}>
              <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 inter_font">Max</label>
                    <input
                      type="number"
                      name="max"
                      value={initialstate?.max}
                      placeholder='Enter Value'
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={HandleChange}
                    />
                    
                  </div>
                  <p className="text-danger">{error?.max}</p>
              </Col>
            </Row>
            <div className="createOffer__borbot"></div>

            <Row className="mt-4">
              {activeTab == 'marketPrice' &&
              <Col lg={6} md={12} sm={6} className="mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <p className="inter_font m-0 createOffer__tp_inpgrp_title mb-2">Offer Margin</p>
                <div className="createOffer__tp_inputGroup d-flex justify-content-between align-items-center rounded-1">
                  <button className="createOffer__tp_inpgrp_btn text-center"
                   name = "margin"
                  onClick={(e) => HandleMinus(e)} disabled={initialstate?.margin<=0}>-</button>
                  <input type="number" className="createOffer__tp_inpgrp_input flex-grow-1 text-center" 
                  value={initialstate?.margin} 
                  name = "margin"
                  onChange={HandleChange}
                  />
                  <button className="createOffer__tp_inpgrp_btn text-center" 
                  name = "margin"
                  onClick={(e) => HandlePlus(e)}>+</button>
                  <button className="createOffer__tp_percent">%</button>
                </div>
                <p className="text-danger">{error?.margin}</p>

              </Col> }


          {activeTab == 'fixedPrice' &&
              <Col lg={6} md={12} sm={6} className="mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <p className="inter_font m-0 createOffer__tp_inpgrp_title mb-2">Set Price</p>
                <div className="createOffer__tp_inputGroup d-flex justify-content-between align-items-center rounded-1">
                  <button className="createOffer__tp_inpgrp_btn text-center" name = "price"
                  onClick={(e) => HandleMinus(e)}disabled={initialstate?.price<=0}>-</button>
                  <input type="number" className="createOffer__tp_inpgrp_input flex-grow-1 text-center" 
                  value={initialstate?.price} 
                  name = "price"
                  onChange={(e) => HandleChange(e)}
                  />
                  <button className="createOffer__tp_inpgrp_btn text-center" 
                  name = "price"
                  onClick={(e) => HandlePlus(e)}
                  >+</button>
                  <button className="createOffer__tp_percent">%</button>
                </div>
                <p className="text-danger">{error?.price}</p>
              </Col>}

              <Col lg={6} md={12} sm={6}>
                <p className="inter_font m-0 createOffer__tp_inpgrp_title mb-2">Offer Time Limits</p>
                <div className="createOffer__tp_inputGroup d-flex justify-content-between align-items-center rounded-1">
                  <button className="createOffer__tp_inpgrp_btn text-center" 
                   name = "offerlimit"
                   disabled={initialstate?.offerlimit<=0}
                  onClick={(e) => HandleMinus(e)}>-</button>
                  <input type="number" className="createOffer__tp_inpgrp_input flex-grow-1 text-center" value={initialstate?.offerlimit} 
                  name = "offerlimit"
                  onChange = {HandleChange}
                  />
                  <button className="createOffer__tp_inpgrp_btn text-center" 
                   name = "offerlimit"
                  onClick={(e) => HandlePlus(e)}>+</button>
                  <button className="createOffer__tp_percent">%</button>
                </div>
                <p className="text-danger">{error?.offerlimit}</p>
              </Col>
            </Row>
            </div>
         
        </Col>

        <Col md={6} className='mt-5 mt-md-0'>
                <h6 className="createOffer_steps_title inter_font m-0">
                  About this step
                </h6>

                <p className="createOffer_steps_hint inter_font m-0 mt-3">
                Decide the price you want to trade at, and set the limits for your offer.
                </p>

                <div className="pm_payment_checker mt-3">
                    <Form>
                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='You want to to sell BTC'
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    </div>
                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='You get paid in ETH'
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    </div>

                    <div className="pm_mapping_check">
                    <Form.Check className="mt-1"
                      name="group1"
                      label='People can trade between 1 ETH and 10 ETH'
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    </div>
                    </Form>
                </div>

                <Row className="mt-4">
                  <Col xs={6}>
                <button className="secondary_btn rounded-1 w-100" onClick={() => {setOfferSec('paymentMethod');setStep(1)}}>Previous</button>
                  </Col>
                  <Col xs={6}>
                <button className="primary_btn rounded-1 w-100" onClick={() => {
                  HandleNext()
                 
                  }}>Next</button>
                  </Col>
                </Row>
              </Col>
      </Row>
      </div>
    </>
    
  );
}

export default CreateOfferTradePricing;
