import React, { useState } from 'react'
import { Col, Container, Row, Form, Dropdown } from 'react-bootstrap'
import Header from '../Components/Header'
import { useSelector } from 'react-redux';
import Footer from '../Components/Footer';
import { NavLink, useNavigate } from 'react-router-dom';
import {Country} from "../Config/Country";
import { SendRegisterOtpHooks } from '../Hooks/UserHooks';
import { RegisterStep1 } from '../Validation/UserValidation';
import PhoneInput from 'react-phone-input-2';

function CreateAccount() {
    const theme = useSelector((state) => state?.User?.theme);
    const [loginSource, setLoginSource] = useState("email");
    const navigate = useNavigate();
    const [emailOrPhone , setEmailOrPhone] = useState("");
    const [type , setType] = useState("");
    const [country , setCountry] = useState("");
    const [countrycode , setCountrycode] = useState("");
    const [termsandcondition , setTermsandcondition] = useState(false);
    const [error , setError] = useState({});

    const HandleNext = async() => {
      var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(emailRegx.test(emailOrPhone)){
        setType("Email");
      }
      else{
        setType("Mobile");
      }
      let payload = {
        emailorphone : emailOrPhone,
        registerType : emailRegx.test(emailOrPhone) ? "Email" : "Mobile",
        phoneCode : countrycode,
        country : country,
        termsandcondition : termsandcondition
      }
      let validate = RegisterStep1(payload);
      if(validate?.isvalid){
        let result = await SendRegisterOtpHooks(payload);
        console.log("result in send register otp" , result);
        if(result?.type == "Success"){
          navigate("/mailVerification" , {state : result?.record});
        }
      }
      else{
        setError(validate?.error);
      }
    }


  return (
    <>
    <Container fluid className="common_spacer pb-2 position-relative">
          <Header />
    <Container className="p-0 p-sm-2 custom_container">

          <div className="home_banner">
              <div className="banner_content">
                <h1>Stablecoin </h1>
                <p>Largest Liquidity P2P Exchange</p>
                <button className="orange_btn mt-2">Sign Up Now</button>
              </div>
            </div>
    </Container>
    </Container>

    <h5 className='reg_stats_shower inter_font m-0 text-center'>Welcome to Otex!</h5>

            <div className='reg_url_holder d-flex justify-content-center align-items-center gap-2 p-2 mt-3'>
              <img src={require('../assets/images/greenLock.svg').default} className='img-fluid' />
              <p className='inter_font m-0 mt-1'>URL verification: https://accounts.otex.com</p>
            </div>

            <Container fluid className="common_spacer pb-5 position-relative">
    <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
    <Container className="p-0 p-sm-2 custom_container">

          <div className='conver_bottom_card rounded-2 py-3 px-2 px-sm-4 p-lg-5 mt-4'>
            <Row className='align-items-center justify-content-between'>
                <Col lg={7} md={7} sm={12} className='mb-4 mb-md-0'>
                    <h5 className='creacteacc_title m-0 inter_font'>Create Account</h5>
                <div className="login_single_input d-flex flex-column mt-4">

                <div className="login_selector d-flex justify-content-start align-items-cente mt-4">
                  <div
                    className={`login_via text-center ${
                      loginSource == "email" ? "active" : ""
                    }`}
                    onClick={() => {
                      setLoginSource("email");
                    }}
                  >
                    <p className="inter_font">Email</p>
                  </div>
                  <div
                    className={`login_via rounded-end text-center ${
                      loginSource == "mobile" ? "active" : ""
                    }`}
                    onClick={() => {
                      setLoginSource("mobile");
                    }}
                  >
                    <p className="inter_font">Mobile</p>
                  </div>
                </div>
                <label className="mb-2 inter_font mt-4">{loginSource == 'email' ? 'Enter Email ID' : 'Enter Phone Number'}</label>

                {loginSource == 'email' ? 
                   
                  <input
                    type="text"
                    placeholder="Enter Email ID"
                    className="primary_input rounded-1 p-2 inter_font"
                    onChange={e => setEmailOrPhone(e?.target?.value)}
                  />
                  :
                  <div className='sm_mobnum_select_holder rounded-1 editprfl__mob_input px-2'>
                <PhoneInput
                  placeholder='Enter Phone Number'
                  country={'us'}
                />
                </div>
}
                  <p className='text-danger'>{error?.emailorphone}</p>
                  <div className='register_dropdown_holder w-100 mt-3'>
                    <Dropdown className='register_dropDown w-100' >
                      <Dropdown.Toggle id="dropdown-basic" >
                        {country ? country : "Select Country..."}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='register_dropdown_menu rounded-2'>
                        {Country?.map((data, i) =>
                          <Dropdown.Item className='register_dropdown_item inter_font'
                            onClick={() => {
                              setCountry(data?.name);
                              setCountrycode(data?.dial_code);
                            }}
                          >{data?.name}</Dropdown.Item>

                        )}
                        {/* <Dropdown.Item className='register_dropdown_item inter_font'>IND</Dropdown.Item>
        <Dropdown.Item className='register_dropdown_item inter_font'>UAE</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    <p className='text-danger'>{error?.country}</p>
                    </div>
                  </div>

                  <div className="login_check_holder d-flex justify-content-start align-items-center gap-2 mt-3">
                    <Form>
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid checked = {termsandcondition}
                          onChange = {(e) => {setTermsandcondition(e?.target?.checked)}}
                        />
                      </Form.Check>
                    </Form>
                    <span className="createacc_checklabel inter_font m-0">
                    By creating an account, I agree to Otex <br/>
<NavLink>Terms of Service</NavLink> and <NavLink>Privacy Policy</NavLink>
                    </span>
                    <p className='text-danger'>{error?.termsandcondition}</p>
                  </div>

                  {/* <NavLink className="header_links" to='/mailVerification'> */}
                  <button className='primary_btn w-100 mt-4'disabled = {!emailOrPhone}
                  onClick={HandleNext}
                  >Next</button>
                  {/* </NavLink> */}
                </Col>

                <Col lg={4} md={5} sm={12}>
                    <img src={require('../assets/images/regCrypto.svg').default} className='img-fluid' />
                </Col>
            </Row>
         
          </div>
    </Container>
    </Container>
    <Footer/>
    </>
  )
}

export default CreateAccount