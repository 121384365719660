import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { NavLink } from "react-router-dom";
import Footer from "../Components/Footer";
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import { useSelector } from "react-redux";
import PhoneInput from 'react-phone-input-2';

function StoreLogin() {
  const theme = useSelector((state) => state?.User?.theme);

  const [loginSource, setLoginSource] = useState("email");
  const [otp, setOtp] = useState('');
  const [otpShow,setOtpShow] = useState(false)
const [mail,setMail] = useState('')
const [password,setPassword] = useState('')
const [errorText,setErrorText] = useState(false)
  const Completionist = () => <span className="text-danger">OTP Expired</span>
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <span className="inter_font">{minutes}:{seconds}</span>;
    }
  };

  // useEffect(() =>{
  //   window.scroll(0,0)
  // },[])
  return (
    <>
      <Container fluid className="common_spacer pb-5 position-relative">
      <img src={require('../assets/images/fish_blurball.png')} className={theme == 'dark' ? "inner_fishblur" : 'd-none'} />
          <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="box_container login_box_container  p-0 p-sm-2">
            <Row className="justify-content-center mb-5 px-1 px-sm-3 px-sm-0">
              <Col lg={8} md={8} sm={10} xs={12} className="login_cont_holder">
                <h6 className="inter_font mt-5">Store Login</h6>
                <p className="inter_font mt-2">
                  Anonymus P2P deals on your terms. Trade globally.
                </p>
                <div className="login_selector d-flex justify-content-start align-items-cente mt-4">
                  <div
                    className={`login_via text-center ${
                      loginSource == "email" ? "active" : ""
                    }`}
                    onClick={() => setLoginSource("email")}
                  >
                    <p className="inter_font">Email</p>
                  </div>
                  <div
                    className={`login_via rounded-end text-center ${
                      loginSource == "mobile" ? "active" : ""
                    }`}
                    onClick={() => setLoginSource("mobile")}
                  >
                    <p className="inter_font">Mobile</p>
                  </div>
                </div>
                <div className="login_form_holder p-3 p-sm-3 p-md-4 p-lg-5">
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2">{loginSource == "email" ? 'Email Address' : 'Mobile Number'}</label>
                    {loginSource == 'email' ? 
                    <input
                      type="email"
                      placeholder="Enter registered Email ID"
                      onChange={(e) => setMail(e.target.value)}
                      className="primary_input rounded-1 p-2 inter_font"
                    />
                    :
                    <div className='sm_mobnum_select_holder rounded-1 editprfl__mob_input px-2'>
                <PhoneInput
                  placeholder='Enter Phone Number'
                  country={'us'}

                />
              </div>
              }
                    {/* <span className="error_text inter_font text-danger mt-2">Enter Valid Email (or) Mobile Number</span> */}
                  </div>
                  <div className="login_single_input d-flex flex-column">
                    <label className="mb-2 mt-4">Password</label>
                    <input
                      type="password"
                      placeholder="Enter your Password"
                      className="primary_input rounded-1 p-2 inter_font"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {console.log(password,"osidfhoishdnf")}
                    {/* <span className="error_text inter_font text-danger mt-2">Enter Valid Password</span> */}
                  </div>
                  <div className="text-end mt-3">
                    <button className={otpShow ? "primary_btn disabled" : 'primary_btn'} disabled={otpShow} onClick={() => setOtpShow(true)}>Get OTP</button>
                  </div>

                  {/* <div className="login_check_holder d-flex justify-content-start align-items-center gap-2 mt-2">
                    <Form>
                      <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" isValid />
                        <Form.Control.Feedback type="valid">
            You did it!
          </Form.Control.Feedback>
                      </Form.Check>
                    </Form>
                    <span className="login_check_label inter_font">
                      Keep me signed in on this computer
                    </span>
                  </div> */}
                  {otpShow && 
<Row className="justify-content-center mt-4">
    <Col lg={5} md={7} sm={10} xs={12}>
    <div className="login_otp_holder d-flex justify-content-center align-items-center mt-4">
                  <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
                  </div>
                  <div className="login_otpOption_holder inter_font text-center mt-2">
                    <Countdown
    date={Date.now() + 30000}
    renderer={renderer}
  />
                  </div>
                  <div className="text-center">
                  <button className="login_resendOTP inter_font ms-3 mt-4">Resend OTP</button>
                  </div>
    </Col>
    <div className="text-end mt-3">
      <NavLink className='header_links' to='/storePage'>
                    <button className="primary_btn">Sign In</button>
                    </NavLink>
                  </div>
</Row>}


                 
                  <div className="login_form_bottom d-flex justify-content-between align-items-center mt-4">
                    <NavLink className="inter_font" to="#">
                      Forgot Password?
                    </NavLink>
                    <NavLink className="inter_font" to="#">
                      Sign up for free
                    </NavLink>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default StoreLogin;
