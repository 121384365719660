import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Dropdown, Button } from "react-bootstrap";
import Header from "../Components/Header";
import Select from "react-select";
import { allData } from "../CommonData/Data";
import Tablelist from "../Components/table";
import Footer from "../Components/Footer";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { GetCurrencyHooks, GetPaymentTypeHooks, GetOfferListHooks } from "../Hooks/P2pHooks";
import {Country} from "../Config/Country";


function Market() {
  const theme = useSelector((state) => state?.User?.theme);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(show, "asdhfiashfi");
  const [tableopen, setTableopen] = useState(false);
  // const options = [
  //   {
  //     value: "BNB",
  //     label: "BNB",
  //     img: require("../assets/images/BNB.png"),
  //   },
  //   {
  //     value: "bnb",
  //     label: "bnb",
  //     img: require("../assets/images/bnb.png"),
  //   },
  //   {
  //     value: "Cron",
  //     label: "Cron",
  //     img: require("../assets/images/CRON.png"),
  //   },
  //   {
  //     value: "Crons",
  //     label: "Crons",
  //     img: require("../assets/images/CRONS.png"),
  //   },
  //   {
  //     value: "Polygon",
  //     label: "Polygon",
  //     img: require("../assets/images/polygon.png"),
  //   },
  // ];

  const [options, setOptions] = useState([]);

  // const paymentOption = [
  //   {
  //     value: "upi",
  //     label: "UPI Payment",
  //     img: require("../assets/images/BNB.png"),
  //   },
  //   {
  //     value: "paypal",
  //     label: "Paypal",
  //     img: require("../assets/images/BNB.png"),
  //   },
  //   {
  //     value: "gpay",
  //     label: "Google Pay",
  //     img: require("../assets/images/BNB.png"),
  //   },
  // ];
  const [paymentOption, setPaymentoption] = useState([]);

  const locationOption = [
    {
      value: "india",
      label: "India",
      img: require("../assets/images/flag.svg").default,
    },
    {
      value: "USA",
      label: "USA",
      img: require("../assets/images/flag.svg").default,
    },
    {
      value: "UAE",
      label: "UAE",
      img: require("../assets/images/flag.svg").default,
    },
  ];
  const memberOption = [
    {
      value: "vendor",
      label: "Vendor",
    },
    {
      value: "excellent",
      label: "Excellent",
    },
    {
      value: "general",
      label: "General",
    },
    {
      value: "defective",
      label: "Defective",
    },
  ];
  const [selected, setSelected] = useState({
    value: "Select Coin",
    label: "Select Coin",
    img: require("../assets/images/BNB.png"),
  });
  const [selectedPayment, setSelectedPayment] = useState({
    value: "Select Payment",
    label: "Select Payment",
    img: require("../assets/images/CRON.png"),
  });
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "Select PreferredCurrency",
    label: "Select PreferredCurrency",
    img: require("../assets/images/bnb.png"),
  });
  const [selectedLocation, setSelectedLocation] = useState({
    value: "upi",
    label: "UPI Payment",
    img: require("../assets/images/CRON.png"),
  });
  const [selectedMember, setSelectedMember] = useState({
    value: "vendor",
    label: "Vendor",
  });

  const [tableDetails, setTableDetails] = useState("");

  const ref = useRef();
  const locationRef = useRef();
  const paymentRef = useRef();
  const coinRef = useRef();
  const currencyRef = useRef();
  const [closer, setCloser] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  //functionality
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialloading, setInitialloading] = useState(false);
  const [offerlist, setOfferlist] = useState([]);
  const [finished, setFinished] = useState(false);
  // const [finalpayload , setFinalpayload] = useState({});

  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const customPaymentRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedPayment({ label, value, img: data.img });
        paymentRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedCurrency({ label, value, img: data.img });
        currencyRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const customLocationRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedLocation({ label, value, img: data.img });
        locationRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );
  console.log("refrefref", ref.current);

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
          ? "#00CABA"
          : isHovered
            ? "red"
            : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
            ? "#16EBC3"
            : isFocused
              ? "#16EBC3"
              : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
            ? "#000"
            : isFocused
              ? "#000"
              : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: 'none' }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "40px",
      border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == 'dark' ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const stylesMember = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
          ? "#00CABA"
          : isHovered
            ? "red"
            : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
            ? "#16EBC3"
            : isFocused
              ? "#16EBC3"
              : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
            ? "#000"
            : isFocused
              ? "#000"
              : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: 'none' }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00CABA",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };


  const fetchdata = async () => {
    setInitialloading(true);
    let result = await GetPaymentTypeHooks();
    console.log("result in useft", result);
    setPaymentoption(result?.record);
    // setSelectedPayment(result?.record[0]);
    let currencyresult = await GetCurrencyHooks();
    setOptions(currencyresult?.record);
    // setPreferedCurrency(currencyresult?.record[1]);
    // setSelectedCurrency(currencyresult?.record[0]);
    let payload = {
      page: 1,
      limit: limit
    }
    if(window.location.href.split("/")[4]){
      console.log(window.atob(window.location.href.split("/")[4]) , "window.atob(window.location.href.split("/")[4]);");
      let data = JSON.parse(window.atob(window.location.href.split("/")[4]));
      if(data?.coin){
        payload.coin = data?.coin;
        setSelected(currencyresult?.record?.find(e => e?.value?.toLowerCase() == data?.coin?.toLowerCase()));
      }
      if(data?.preferredCurrency){
        payload.preferredCurrency = data?.preferredCurrency;
        setSelectedCurrency(currencyresult?.record?.find(e => e?.value?.toLowerCase() == data?.preferredCurrency?.toLowerCase()));
      }
      if(data?.offerLocation){
        payload.offerLocation = Country?.find(e => e.code == data?.offerLocation)?.name;
        setSelectedFlag(data?.offerLocation);
      }
      if(data?.ordertype){
        payload.ordertype = data?.ordertype;
        setTableDetails(data?.ordertype);
      }
      if(data?.paymentMethod){
        payload.paymentMethod = data?.paymentMethod;
        setSelectedPayment(result?.record?.find(e => e?.value?.toLowerCase() == data?.paymentMethod?.toLowerCase()));
      }
     
    }
    let offers = await GetOfferListHooks(payload);
    // console.log("offerlist result" , offerlist);
    if (offers?.type == "Success" && offers?.record) {
      setOfferlist(offers?.record);
      setPage(page + 1);
      setCount(offers?.count);
      setFinished(true);
    }
    else if (offers?.type == "Success" && offers?.error) {

    }
    setInitialloading(false);
  }

  const FilterData = async () => {
    try {
      setInitialloading(true)
      let payload = {
        coin: selected?.value == "Select Coin" ? "" : selected?.value,
        preferredCurrency: selectedCurrency?.value == "Select PreferredCurrency" ? "" : selectedCurrency?.value,
        offerLocation: selectedFlag,
        ordertype: tableDetails,
        paymentMethod: selectedPayment?.value == "Select Payment" ? "" : selectedPayment?.value
      };
      payload.offerLocation = Country?.find(e => e.code == selectedFlag)?.name
      navigate(`/market/${window.btoa(JSON.stringify(payload))}`);
      let result = await GetOfferListHooks(payload);
      console.log("Result in filter", result);
      if (result?.type == "Success" && result?.record) {
        setOfferlist(result?.record);
        setPage(2);
        setCount(result?.count);
        setFinished(true);
      }
      else {

      }
      setInitialloading(false);
    }
    catch (e) {
      console.log("Error on filter data", e);
    }
  }

  const LoadMore = async() => {
    try{
      setLoading(true);
      let payload = {
        coin: selected?.value == "Select Coin" ? "" : selected?.value,
        preferredCurrency: selectedCurrency?.value == "Select PreferredCurrency" ? "" : selectedCurrency?.value,
        offerLocation: selectedFlag,
        ordertype: tableDetails,
        paymentMethod: selectedPayment?.value == "Select Payment" ? "" : selectedPayment?.value
      }
      let result = await GetOfferListHooks(payload);
      console.log("Result in filter", result);
      if (result?.type == "Success" && result?.record) {
        setOfferlist(offerlist.concat(result?.record));
        setPage(2);
      }
      else {

      }
      setLoading(false);
    }
    catch(e){
      console.log("Error on load more" , e);
    }
  }

  useEffect(() => {
    if (finished) {
      FilterData();
    }
  }, [selected, selectedCurrency, selectedFlag, tableDetails, selectedPayment])

  useEffect(() => {
    if (!finished) {
      fetchdata();
    }
  }, [])

console.log("offerlist" , offerlist);
  return (
    <>
      <Container fluid className="common_spacer">
        <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="box_container p-2 p-sm-3 p-md-4 p-lg-5 mt-3">
            <Row>
              <Col
                xl={3}
                lg={tableDetails == "buy" ? 3 : 4}
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                <div className="market_coinname_select position-relative">
                  <img
                    src={selected?.img}
                    className="img-fluid market_coinname_img"
                  />
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">Coin Type</p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    components={{ Option: customOptionRenderer }}
                    ref={coinRef}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
              <Col
                xl={3}
                lg={3} //tableDetails == "buy" ? 3 : 4
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                <div className="market_coinname_select position-relative">
                  <img
                    src={
                      theme == "dark"
                        ? require("../assets/images/searchglass.svg").default
                        : require("../assets/images/blacksearch.svg").default
                    }
                    className="img-fluid market_coinname_img"
                  />
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">
                    Payment Method
                  </p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedPayment}
                    onChange={setSelectedPayment}
                    options={paymentOption}
                    value={selectedPayment}
                    components={{ Option: customPaymentRenderer }}
                    ref={paymentRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
              </Col>
              <Col
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency?.img}
                    className="img-fluid market_coinname_img"
                  />
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">
                    Preferred Currency
                  </p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={selectedCurrency}
                    options={options}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
              </Col>
              <Col
                xl={3} //tableDetails == "buy" ? 3 : 2
                lg={3} //tableDetails == "buy" ? 3 : 4
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                {/* <div className="market_coinname_select position-relative">
                    <img
                      src={
                        theme == "dark"
                          ? require("../assets/images/searchglass.svg").default
                          : require("../assets/images/blacksearch.svg").default
                      }
                      className="img-fluid market_coinname_img"
                    />
                  <img
                      src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                      className="market_drophint"
                    />
                    <p className="market_select_label inter_font">
                      Offer Location
                    </p>
                    <Select
                      className="mt-3"
                      defaultValue={selectedLocation}
                      onChange={setSelectedLocation}
                      options={locationOption}
                      value={selectedLocation}
                      components={{ Option: customLocationRenderer }}
                      ref={locationRef}
                      styles={styles}
                      placeholder="Search Location"
                    />
                  </div> */}
                <p className="market_select_label inter_font">
                  Offer Location
                </p>
                <div className="cmn_flag_select rounded-2 d-flex align-items-center mt-3" title={selectedFlag}>

                  <ReactFlagsSelect
                    className=" pb-0 w-100"
                    selected={selectedFlag}
                    showSecondarySelectedLabel={true}
                    selectedSize={10}
                    onSelect={(code) => { console.log("code", code); setSelectedFlag(code); }}
                  />
                </div>
              </Col>
              {/* {tableDetails == "sell" ? (
                <Col xl={2} lg={4} md={6} sm={6} xs={12} className="mb-3">
                  <div className="market_coinname_select position-relative">
                    <img
                      src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                      className="market_drophint"
                    />
                    <p className="market_select_label inter_font">
                      Member Level  
                    </p>
                    <Select
                      className="mt-3"
                      // defaultValue={selectedMember}
                      onChange={setSelectedMember}
                      options={memberOption}
                      value={selectedMember}
                      ref={locationRef}
                      styles={stylesMember}
                      placeholder="Select Member"
                    />
                  </div>
                </Col>
              ) : (
                <></>
              )} */}
            </Row>
            <Row>
              <Col lg={4} md={6} sm={6} xs={12} className="mb-3">
                <div
                  className={`market_buytab p-3 rounded-3 d-flex flex-column justify-content-center align-items-center ${tableDetails == "buy" ? "active" : ""
                    }`}
                  onClick={() => setTableDetails("buy")}
                >
                  <h5 className="text-center inter_font">Buy Bitcoin (BTC)</h5>
                  <p className="text-center inter_font mt-3">
                    Buy coin with over 350 payment methods to choose from,
                    Including bank transfers, online pay, PayPal and gift cards.
                  </p>
                </div>
              </Col>
              <Col lg={4} md={6} sm={6} xs={12} className="mb-3">
                <div
                  className={`market_buytab p-3 rounded-3 d-flex flex-column justify-content-center align-items-center ${tableDetails == "sell" ? "activeSell" : ""
                    }`}
                  onClick={() => setTableDetails("sell")}
                >
                  {console.log(tableDetails, "tableDetailstableDetails")}
                  <h5 className="text-center inter_font">Sell Bitcoin (BTC)</h5>
                  <p className="text-center inter_font mt-3">
                    Sell coins and receive payment through more than 350 payment
                    methods, including bank transfers, online payments, gift
                    cards and Paypal
                  </p>
                </div>
              </Col>
              <Col
                lg={2}
                md={6}
                sm={6}
                xs={12}
                className="p-3 mb-3 d-flex flex-column justify-content-center align-items-center gap-2"
              >
                <button
                  className="market_tab_button rounded-2 inter_font"
                  onClick={() => handleShow()}
                >
                  Buy Bitcoin (BTC)
                </button>
                <button
                  className="market_tab_sellbutton rounded-2 inter_font"
                  onClick={() => handleShow()}
                >
                  Sell Bitcoin (BTC)
                </button>
              </Col>
              <Col
                lg={2}
                md={6}
                sm={6}
                xs={12}
                className="p-3 mb-3 d-flex flex-column justify-content-center align-items-center gap-2"
              >
                <NavLink to='/marketTransaction' className='header_links'>
                <div className="primary_sec d-flex flex-column justify-content-center align-items-center">
                  <p className="text-center m-0  inter_font">
                    Register with Sales and Buyers List
                  </p>
                  <img
                    src={require("../assets/images/blackrightlong.svg").default}
                    className="img-fluid"
                  />
                </div>
                </NavLink>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} className="ps-0 ms-2">
                <div className="market_tabler_filter_holder d-flex justify-content-end mb-3">
                  <Dropdown className="market_sort_dropdown" drop="start">
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      <i class="fa-solid fa-arrow-down-wide-short" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.ItemText className="inter_font">
                        <i class="fa-solid fa-arrow-down-wide-short me-2" />{" "}
                        Sorted By
                      </Dropdown.ItemText>
                      <Dropdown.Item className="inter_font">
                        Price: Lowest to Highest
                      </Dropdown.Item>
                      <Dropdown.Item className="inter_font">
                        Price: Highest to Lowest
                      </Dropdown.Item>
                      <Dropdown.Item className="inter_font">
                        Avg. Trade Speed: Fast
                      </Dropdown.Item>
                      <Dropdown.Item className="inter_font">
                        Avg. Trade Speed: Slow
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {!initialloading && offerlist?.map((item) => (
                  <div className="market_tablerView mb-4 p-3 d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap gap-3 rounded-2">
                    <div className="market_tablerView_single d-flex justify-content-start mb-4">
                      <div>
                        <p className="market_tv_title inter_font text-center mb-2">
                         {item?.ordertype == "buy" ?  "Buyer:" : "Seller:"}
                        </p>
                        <div className="market_table_username position-relative">
                          {/* {tableDetails == "buy" && (
                            <>
                              {item?.vendor ? (
                                <button className="vendor_badge inter_font d-flex gap-1">
                                  Vendor
                                  <img
                                    src={
                                      require("../assets/images/vendor.svg")
                                        .default
                                    }
                                    className="img-fluid"
                                  />
                                </button>
                              ) : (
                                <></>
                              )}
                            </>
                          )} */}

                          <div className="table_userimg_holder d-flex justify-content-center align-items-center gap-2">
                            <div className="user_activity_holder position-relative">
                              <img
                                src={allData?.marketTableData[0]?.userImage}
                                className="img-fluid market_tv_userImg"
                              />
                              {/* {tableDetails == "buy" && (
                                <div
                                  className={
                                    item?.status
                                      ? "user_activity_online_indicator"
                                      : "user_activity_offline_indicator"
                                  }
                                ></div>
                              )} */}
                            </div>
                            <img
                              src={item.country}
                              className="img-fluid user_country_flag"
                            />
                            <p className="market_tbluser_name inter_font">
                              {item?.userdata?.firstName + item?.userdata?.lastName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="market_tablerView_single">
                      <p className="market_tv_title inter_font text-center mb-2">
                        Payment Method :
                      </p>
                      <button className="market_payment_btn rounded-5 inter_font">
                        {item?.paymentmethod[0]}
                      </button>
                    </div>
                    <div className="market_tablerView_single">
                      <p className="market_tv_title inter_font text-center">
                        Price per 1 {item?.currency} :
                      </p>
                      <p className="market_table_intvalues inter_font text-center">
                        {item?.prefferedcurrencyvalue} {item?.preferedcurrency}
                      </p>
                      {/* <div className="mt-2">
                        <p className="market_tv_title inter_font text-center">
                          Total Quantity :
                        </p>
                        <p className="market_table_intvalues inter_font text-center">
                          {item.quantity}
                        </p>
                      </div> */}
                    </div>
                    {/* <div className="market_tablerView_single">
                     
                    </div> */}
                    <div className="market_tablerView_single mb-2">
                      <p className="market_tv_title inter_font text-center mb-2">
                        Transaction Limit :
                      </p>
                      <p className="market_table_intvalues inter_font text-center">
                       Min : {item?.min} {item?.preferedcurrency}
                      </p>
                      <p className="market_table_intvalues inter_font text-center">
                       Max : {item?.max} {item?.preferedcurrency}
                      </p>
                    </div>

                    <div className="market_tablerView_single">
                      {/* {tableDetails == "buy" ? (
                        <NavLink
                          to="/marketTransaction"
                          className="header_links"
                        > */}
                          <button className="primary_btn"
                          
                          onClick={() => navigate(`/marketTransaction/${item?.orderid}`)}
                          >{item?.ordertype == "buy" ? "Sell" : "Buy"}</button>
                        {/* </NavLink>
                      ) : (
                        <></>
                      )} */}
                      {/* {tableDetails == "sell" ? (
                        <button
                          className={
                            item.transaction == "progress"
                              ? "market_progressBtn rounded-3 inter_font"
                              : item.transaction == "sell"
                                ? "market_sellBtn rounded-3 inter_font"
                                : "primary_btn rounded-3 inter_font"
                          }
                        >
                          {item.transaction == "progress"
                            ? "Transaction on progress"
                            : item.transaction == "sell"
                              ? "Sell"
                              : "Buy"}
                        </button>
                      ) : (
                        <></>
                      )} */}
                    </div>
                  </div>
                ))}
                {initialloading  && <p>Loading...</p>}
                {!initialloading && offerlist?.length <= 0 && <p>No data found</p>}
                {!initialloading && offerlist?.length < count && <Button 
                disabled = {loading}
                onClick={LoadMore}>{loading ? "Loading..." : "View More"}</Button>}
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
      <Footer />

      <Tablelist show={show} handleClose={handleClose} />
    </>
  );
}

export default Market;
