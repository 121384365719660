import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MarketCard from "../Components/MarketCard";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import { useSelector } from "react-redux";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";

function MarketList({showSidebar,handleShowSidebar,handleCloseSidebar}) {
  const theme = useSelector((state) => state?.User?.theme);
  const [options, setOptions] = useState([]);

  const ref = useRef();
  const locationRef = useRef();
  const paymentRef = useRef();
  const coinRef = useRef();
  const currencyRef = useRef();
  const [closer, setCloser] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFlag, setSelectedFlag] = useState("");
  const [paymentOption, setPaymentoption] = useState([]);

  const [selected, setSelected] = useState({
    value: "Select Coin",
    label: "Select Coin",
    img: require("../assets/images/BNB.png"),
  });

  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "Select PreferredCurrency",
    label: "Select PreferredCurrency",
    img: require("../assets/images/bnb.png"),
  });
  const [selectedPayment, setSelectedPayment] = useState({
    value: "Select Payment",
    label: "Select Payment",
    img: require("../assets/images/CRON.png"),
  });
  const [tableDetails, setTableDetails] = useState("");
  const customPaymentRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedPayment({ label, value, img: data.img });
        paymentRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const customOptionRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelected({ label, value, img: data.img });
        coinRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedCurrency({ label, value, img: data.img });
        currencyRef.current.blur();
        setCloser(!closer);
      }}
    >
      {console.log("sdhguisd", label, data, value)}
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );



  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#00CABA"
        : isSelected
          ? "#00CABA"
          : isHovered
            ? "red"
            : "#00CABA",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
            ? "#16EBC3"
            : isFocused
              ? "#16EBC3"
              : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
            ? "#000"
            : isFocused
              ? "#000"
              : "#fff",
        fontSize: "13px",
      };
    },
    indicatorSeparator: (styles) => ({ display: 'none' }),
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      paddingLeft: "40px",
      border: theme == 'dark' ? "1px solid #343434" : "1px solid #e3e0e0",
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      border: "1px solid #00CABA",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "transperant"
        : isSelected
          ? "transperant"
          : isFocused
            ? "transperant"
            : "transperant",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#fff",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      display: "none",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme == 'dark' ? "#fff" : "#343434",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  return (
    <>
      <Container fluid className="common_spacer pb-5 position-relative">
        <img
          src={require("../assets/images/fish_blurball.png")}
          className={theme == 'dark' ? "inner_fishblur" : 'd-none'}
        />
        <Header />
        <Container className="p-0 p-sm-2 custom_container">
          <div className="stable_boxContainer p-3 pt-5 pt-xl-3">
            {/* <div className="sidebar_canva_shower d-xl-none mb-3">
              <button
                className="sidebar_canva_burg rounded-5 pt-1 px-2"
                onClick={handleShowSidebar}
              >
                <i class="fa-solid fa-bars" />
              </button>
            </div> */}

            <Row>
              <Col
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                <div className="market_coinname_select position-relative">
                  <img
                    src={selected?.img}
                    className="img-fluid market_coinname_img"
                  />
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">Coin Type</p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={setSelected}
                    options={options}
                    value={selected}
                    components={{ Option: customOptionRenderer }}
                    ref={coinRef}
                    styles={styles}
                    placeholder=""
                  />
                </div>
              </Col>
              <Col
                xl={3}
                lg={3} //tableDetails == "buy" ? 3 : 4
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                <div className="market_coinname_select position-relative">
                  <img
                    src={
                      theme == "dark"
                        ? require("../assets/images/searchglass.svg").default
                        : require("../assets/images/blacksearch.svg").default
                    }
                    className="img-fluid market_coinname_img"
                  />
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">
                    Payment Method
                  </p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedPayment}
                    onChange={setSelectedPayment}
                    options={paymentOption}
                    value={selectedPayment}
                    components={{ Option: customPaymentRenderer }}
                    ref={paymentRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
              </Col>
              <Col
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                <div className="market_coinname_select position-relative">
                  <img
                    src={selectedCurrency?.img}
                    className="img-fluid market_coinname_img"
                  />
                  <img
                    src={theme == 'dark' ? require("../assets/images/drophint.svg").default : require('../assets/images/drophint_light.svg').default}
                    className="market_drophint"
                  />
                  <p className="market_select_label inter_font">
                    Preferred Currency
                  </p>
                  <Select
                    className="mt-3"
                    defaultValue={selectedOption}
                    onChange={selectedCurrency}
                    options={options}
                    value={selectedCurrency}
                    components={{ Option: customCurrencyRenderer }}
                    ref={currencyRef}
                    styles={styles}
                    placeholder="All Payment Methods"
                  />
                </div>
              </Col>
              <Col
                xl={3} //tableDetails == "buy" ? 3 : 2
                lg={3} //tableDetails == "buy" ? 3 : 4
                md={6}
                sm={6}
                xs={12}
                className="mb-3"
              >
                
                <p className="market_select_label inter_font">
                  Offer Location
                </p>
                <div className="cmn_flag_select rounded-2 d-flex align-items-center mt-3" title={selectedFlag}>

                  <ReactFlagsSelect
                    className=" pb-0 w-100"
                    selected={selectedFlag}
                    showSecondarySelectedLabel={true}
                    selectedSize={10}
                    onSelect={(code) => { console.log("code", code); setSelectedFlag(code); }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
                  <Col xxl={4} xl={6} lg={6} className="mb-4">
                    <MarketCard />
                  </Col>
               
            </Row>
          </div>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export default MarketList;
