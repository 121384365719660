import React from "react";

function ProfileFaqs() {
  return (
    <>
      <div className="prfl_faqs_whole">
        <p className="prfl_box_value inter_font ms-2">FAQs</p>

        <div className="prfl_faqs_holder p-4 rounded-1 mt-3">
          <div className="prlf_single_faq mb-4">
            <p className="prfl_acrd_value inter_font">
              Why do i need to undergo identify verification?{" "}
            </p>
            <p className="rec_table_ttl_light inter_font mt-2">
              Identity verification is a process used by financial institutions
              and other regular organizations to confirm your identity. OTEX
              will verify your identity and conduct risk assessment to mitigate
              risks.{" "}
            </p>
          </div>
          <div className="prlf_single_faq mb-4">
            <p className="prfl_acrd_value inter_font">
              Why can’t i select country/region?{" "}
            </p>
            <p className="rec_table_ttl_light inter_font mt-2">
              OTEX does not provide services to users in the following countries/regions:
              Canada (Alberta), Crimea, Cuba, Hong Kong, Iran, North Korea,
              Singapore, Sudan, Syria, the United States, Iraq, Libya, Yemen,
              Afghanistan, Central African Republic, Democratic Republic of
              the Congo, Guinea-Bissau, Haiti, Lebanon, Somalia, South Sudan and
              the Netherlands.{" "}
            </p>
          </div>
          <div className="prlf_single_faq mb-4">
            <p className="prfl_acrd_value inter_font">
              What documents can I submit for identity verification?{" "}
            </p>
            <p className="rec_table_ttl_light inter_font mt-2">
              Level 1: ID card, passport, driver's license, and proof of
              residence. Level 2: Bank statements, utility bills 
              (within the last three months), internet/cable/home phone bills,
              tax returns, council tax bills, and government-issued proof of
              residence.{" "}
            </p>
          </div>
          <div className="prlf_single_faq">
            <p className="prfl_acrd_value inter_font">
              What are the requirements for identity verification?
            </p>
            <p className="rec_table_ttl_light inter_font mt-2">
              1. You must be at least 18 years old.
            </p>
            <p className="rec_table_ttl_light inter_font ">
              2. You can only complete the identity verification on one account.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileFaqs;
